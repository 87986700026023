import React, { useState, useEffect, useCallback } from "react";
import Auth from "./navigation/auth";
import {
  Col,
  Container,
  Row,
  Stack,
  Modal,
  Button,
  Accordion,
} from "react-bootstrap";
import HomeNav from "./navigation/HomeNav";
import {
  BsPlusCircleDotted,
  BsHandThumbsUp,
  BsGripHorizontal,
} from "react-icons/bs";
import {
  IoIosTrash,
  IoMdPerson,
  IoMdEasel,
  IoIosAdd,
  IoIosCheckmark,
  IoIosClose,
  IoMdCreate,
  IoMdSquareOutline,
  IoMdCheckbox,
  IoMdMore,
} from "react-icons/io";
import {
  IoInformationCircleOutline,
  IoEllipsisHorizontalCircleOutline,
} from "react-icons/io5";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import { useNavigate, Link, useLocation } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import Select from "react-select";
import Drawer from "react-bottom-drawer";
const baseURL = process.env.REACT_APP_URL;
const root_url = process.env.REACT_APP_URL_RESOURCE;
const customerid = localStorage.getItem("customerid");
const deviceid = localStorage.getItem("deviceid");
export default function Index() {
  const navigate = useNavigate();
  let location = useLocation();
  const [isVisible, setIsVisible] = useState(false);
  const [notified, setNotified] = useState("");
  const [finished, setFinished] = useState("");
  const [delivered, setDelivered] = useState("");
  const [estimated_time, setEstimatedTime] = useState("");
  const [date_sent, setDate_sent] = useState("");
  const [order_total, setOrder_total] = useState("");
  const [item_name, setItem_name] = useState("");
  const [delivered_date, setDelivered_date] = useState("");

  const [comments, setComments] = useState("");
  const [cust_id, setCust_id] = useState(0);
  const [orderid, setOrderid] = useState(0);
  const [ested, setEsted] = useState("");
  const [server_data, setServer_data] = useState(false);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [company_name, setCompany_name] = useState("");
  const [show, setShow] = useState(false);
  const [mediaDetails, setMediaDetails] = useState(false);
  const [error_modal, setError_modal] = useState(false);
  const [displayImge, setDisplayImge] = useState([]);
  const [preimage, setPreImage] = useState("");
  const [imgname, setImgname] = useState("");
  const [path, setPath] = useState("");
  const [showUploadBtn, setShowUploadBtn] = useState(false);
  const [showImage, setshowImage] = useState(false);
  const [arraySize, setArraySize] = useState(0);
  const [disabled, setDisabled] = useState("");
  const [btnColor, setBtnColor] = useState("karamale-login-btn");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [allimgs, setAllImgs] = useState("");
  const [media_count, setMedia_count] = useState(0);
  const [media, setMedia] = useState([]);
  const [orders_per_customer, setOrders_per_customer] = useState([]);
  const [order_count, setOrder_count] = useState(0);
  const [selectedMediaID, setSelectedMediaID] = useState(0);
  const [selectedMediaImg, setSelectedMediaImg] = useState(null);
  const [successOne, setSuccessOne] = useState(false);
  const [successTwo, setSuccessTwo] = useState(false);
  const [successThree, setSuccessThree] = useState(false);
  const [successFour, setSuccessFour] = useState(false);
  const [textOne, setTextOne] = useState(true);
  const [beatOne, setBeatOne] = useState(false);
  const [textTwo, setTextTwo] = useState(true);
  const [beatTwo, setBeatTwo] = useState(false);
  const [textThree, setTextThree] = useState(true);
  const [beatThree, setBeatThree] = useState(false);
  const [textFour, setTextFour] = useState(true);
  const [beatFour, setBeatFour] = useState(false);
  const [LoadBeat, setLoadBeat] = useState(true);
  const [NoMediaLoging, setNoMediaLoging] = useState(false);
  const [itemID, setItemID] = useState(0);
  const [date_uploaded, setDate_uploaded] = useState(null);
  const [media_size, setMedia_size] = useState(0);
  const [item, setItem] = useState("");
  const [normal, setNormal] = useState("");
  const [small, setSmall] = useState("");
  const [medium, setMedium] = useState("");
  const [large, setLarge] = useState("");
  const [extraLarge, setExtraLarge] = useState("");
  const [bulk, setBulk] = useState("");
  const [volume, setVolume] = useState("");
  const [unit, setUnit] = useState("");
  const [other_price, setOther_price] = useState("");
  const [item_type, setItem_type] = useState("");
  const [description, setDes] = useState("");
  const [online_availability, setOnline_availability] = useState("");
  const [more, setMore] = useState(true);
  const [less, setLess] = useState(false);
  const [intervals, setIntervals] = useState("");
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");
  const [seeMore, setSeeMore] = useState(false);
  const [UncheckedOne, setUncheckedOne] = useState(true);
  const [CheckedOne, setCheckedOne] = useState(false);
  const [quantityOne, setQuantityOne] = useState(false);

  const [UncheckedTwo, setUncheckedTwo] = useState(true);
  const [CheckedTwo, setCheckedTwo] = useState(false);
  const [quantityTwo, setQuantityTwo] = useState(false);

  const [UncheckedThree, setUncheckedThree] = useState(true);
  const [CheckedThree, setCheckedThree] = useState(false);
  const [quantityThree, setQuantityThree] = useState(false);

  const [UncheckedFour, setUncheckedFour] = useState(true);
  const [CheckedFour, setCheckedFour] = useState(false);
  const [quantityFour, setQuantityFour] = useState(false);

  const [UncheckedFive, setUncheckedFive] = useState(true);
  const [CheckedFive, setCheckedFive] = useState(false);
  const [quantityFive, setQuantityFive] = useState(false);

  const [UncheckedSix, setUncheckedSix] = useState(true);
  const [CheckedSix, setCheckedSix] = useState(false);
  const [quantitySix, setQuantitySix] = useState(false);

  const [quantitesOne, setQuantitesOne] = useState("");
  const [quantitesTwo, setQuantitesTwo] = useState("");
  const [quantitesThree, setQuantitesThree] = useState("");
  const [quantitesFour, setQuantitesFour] = useState("");
  const [quantitesFive, setQuantitesFive] = useState("");
  const [quantitesSix, setQuantitesSix] = useState("");

  const [totalOne, setTotalOne] = useState(0);
  const [totalTwo, setTotalTwo] = useState(0);
  const [totalThree, setTotalThree] = useState(0);
  const [totalFour, setTotalFour] = useState(0);
  const [totalFive, setTotalFive] = useState(0);
  const [totalSix, setTotalSix] = useState(0);
  const [grand_total, setGrand_total] = useState(0);
  const [count, setCount] = useState(0);
  const [total_value, setTotal_value] = useState(0);
  const [account_id, setcustomerid] = useState(0);

  const [added_to_basket, setAdded_to_basket] = useState(false);
  const [add_button, setAdd_button] = useState(false);
  const [total, setTotal] = useState(0);
  const [action, setAction] = useState("");
  const [token, setToken] = useState("");
  const [alias, setAlias] = useState("notify");
  const [clock, setClock] = useState("");
  const [hours, setHours] = useState("");

  let customerid = "";
  if (localStorage.getItem("customerid") != null) {
    customerid = localStorage.getItem("customerid");
  } else {
    customerid = -1;
  }

  function clear_notifications(orderid) {
    // alert(orderid);
    axios
      .get(
        baseURL +
          "?query=ITEMS-ORDERS-CLEAR-NOTIFICATIONS&customerid=" +
          customerid +
          "&orderid=" +
          orderid
      )
      .then((response) => {
        if (response.data.status == "ok") {
          setCount(count + 1);
          navigate("/historical_orders");
        }
      });
  }

  function total_amount() {
    axios
      .get(
        baseURL +
          "?query=TOTAL_AMOUNT_HISTORICAL_ORDERS_CUSTOMER&customerid=" +
          customerid
      )
      .then((response) => {
        setTotal(response.data.total);
      });
  }

  function itemCount() {
    axios
      .get(
        baseURL + "?query=ITEM-COUNT-ORDERS-CUSTOMER&customerid=" + customerid
      )
      .then((response) => {
        setMedia_count(response.data.count);
      });
  }

  function fetchItems() {
    // alert(media_count);
    axios
      .get(
        baseURL +
          "?query=ITEMS-HISTORICAL-ORDERS-CUSTOMER&customerid=" +
          customerid
      )
      .then((response) => {
        setMedia(response.data);
        setNoMediaLoging(true);
        setLoadBeat(false);
      });
  }

  useEffect(() => {
    document.title = "Obarak / Historical orders";
    itemCount();
    total_amount();
    fetchItems();

    const interval_time = setInterval(() => {
      fetchItems();
      itemCount();
      total_amount();
      clear_notifications();
    }, 5000);

    if (
      CheckedOne == false &&
      CheckedTwo == false &&
      CheckedThree == false &&
      CheckedFour == false &&
      CheckedFive == false &&
      CheckedSix == false
    ) {
      setAdd_button(false);
    } else {
      setAdd_button(true);
    }

    // localStorage.getItem("total_value", total_value);
    // const currentLocation = location.pathname;

    localStorage.removeItem("normal");
    localStorage.removeItem("small");
    localStorage.removeItem("medium");
    localStorage.removeItem("large");
    localStorage.removeItem("extra-large");
    localStorage.removeItem("bulk");
    localStorage.removeItem("other");
    // localStorage.removeItem("foodid");
    localStorage.removeItem("description");
    return () => clearInterval(interval_time);
  }, [count]);

  const handleClose = () => {
    setShow(false);
    setMediaDetails(false);
  };

  const handleShow = () => setShow(true);
  // const selectedMedia = () => setMediaDetails(true);

  function selectMedia(
    media,
    mediaid,
    itemid,
    item_name,
    normal,
    small,
    medium,
    large,
    extra_large,
    bulk,
    item_type,
    description,
    online_availability,
    intervals,
    category,
    type,
    volume,
    unit,
    other_price,
    account_id,
    company_name,
    first_name,
    last_name,
    customer_id,
    action,
    orderid,
    est_time,
    notified,
    finished,
    comments,
    order_token,
    delivered,
    est_date_sent,
    order_total,
    delivered_date
  ) {
    // alert(action);
    setDelivered_date(delivered_date);
    setItem_name(item_name);
    setOrder_total(order_total);
    setDate_sent(est_date_sent);
    setEstimatedTime(est_time);
    setDelivered(delivered);
    setFinished(finished);
    setNotified(notified);
    setToken(order_token);
    setComments(comments);
    setCust_id(customer_id);
    setEsted(est_time);
    setOrderid(orderid);
    setAction(action);
    setItemID(itemid);
    setOrder_count(0);
    if (action == "menu") {
      setIsVisible(true);
    } else {
      setLoadBeat(true);
      setServer_data(false);
      setIsVisible(false);
      axios
        .get(
          baseURL +
            "?query=ORDERS_OVERALL_TOTAL&customer_id=" +
            customer_id +
            "&customerid=" +
            customerid +
            "&token=" +
            order_token +
            "&alias=" +
            alias
        )
        .then((response) => {
          setGrand_total(response.data.grand_total);
        });

      axios
        .get(
          baseURL +
            "?query=ITEM-COUNT-PENDING-ORDERS&customer_id=" +
            customer_id +
            "&customerid=" +
            customerid +
            "&token=" +
            order_token +
            "&alias=" +
            alias
        )
        .then((response) => {
          setOrder_count(response.data.count);
        });

      axios
        .get(
          baseURL +
            "?query=ITEMS-PENDING-ORDERS&customer_id=" +
            customer_id +
            "&customerid=" +
            customerid +
            "&token=" +
            order_token +
            "&alias=" +
            alias
        )
        .then((response) => {
          setOrders_per_customer(response.data);
          setNoMediaLoging(true);
          setServer_data(true);
          setLoadBeat(false);
        });

      setMediaDetails(true);
    }

    setFname(first_name);
    setLname(last_name);
    setCompany_name(company_name);
    setAdded_to_basket(false);
    setAdd_button(false);
    setcustomerid(account_id);
    setSeeMore(false);
    setMore(true);
    setLess(false);
    setIntervals(intervals);
    setCategory(category);
    setType(type);
    setSelectedMediaID(mediaid);
    setSelectedMediaImg(media);
    setItem(item_name);

    setNormal(normal);
    setSmall(small);
    setMedium(medium);
    setLarge(large);
    setExtraLarge(extra_large);
    setBulk(bulk);
    setItem_type(item_type);
    setDes(description);
    setOnline_availability(online_availability);
    setVolume(volume);
    setUnit(unit);
    setOther_price(other_price);
    setGrand_total(0);
  }

  function map_media() {
    let itemid = 0;
    return (
      <Row>
        {" "}
        {media.length <= 0 ? (
          <div>
            {NoMediaLoging && (
              <label className="no-media-text color-silver pb-0 mb-0 w-100 text-center">
                No historical orders at the moment
              </label>
            )}
          </div>
        ) : (
          media.map((item, index) => {
            return (
              <Col
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={
                  item.notification == 0
                    ? "mb-3 text-left notifications-belt"
                    : "mb-3 text-left notifications-belt-see"
                }
              >
                <Row
                  className="align-items-center"
                  // onClick={() => clear_notifications(item.orderid)}
                >
                  <Col
                    lg={2}
                    xs={2}
                    sm={2}
                    md={1}
                    className="media-container-basket media-container-basket-mobile"
                    style={{
                      backgroundImage: `url(${root_url}/assets/${item.accountid}/${item.media})`,
                      borderLeft:
                        item.notified == "no" &&
                        item.delivered == "no" &&
                        item.finished == "no"
                          ? "4px solid red"
                          : item.notified == "yes" &&
                            item.delivered == "no" &&
                            item.finished == "yes"
                          ? "4px solid orange"
                          : item.notified == "yes" &&
                            item.delivered == "no" &&
                            item.finished == "no"
                          ? "4px solid #39c"
                          : "4px solid #4ca64c",
                    }}
                  ></Col>
                  <Col lg={2} xs={8} md={3} sm={5} className="item-attributes">
                    <b>
                      {item.item_name.length > 18
                        ? item.item_name.substring(0, 18) + "..."
                        : item.item_name}
                    </b>
                    <br />
                    <span className="color-grey font-size-12">
                      <span className="font-size-12 font-medium color-grey">
                        Date:
                      </span>{" "}
                      {item.order_date}
                    </span>
                    <br />
                    <span className="font-size-12 color-grey">
                      <span className="font-medium font-size-12">Token:</span>{" "}
                      {item.token}
                    </span>
                  </Col>
                  <Col
                    lg={2}
                    sm={4}
                    md={4}
                    xs={9}
                    className="p-0  d-none d-lg-block d-md-block d-sm-block sizes-displays"
                  >
                    <label className="">
                      {item.normal_quantity == "" ||
                      item.normal_quantity == null ? (
                        ""
                      ) : (
                        <label
                          className={"font-size-12 color-grey h-container-size"}
                        >
                          <span className="font-medium font-size-12 color-black">
                            {item.category == "drink" ? (
                              <label className="font-size-12 font-medium">
                                {item.volume}
                                {item.unit == "litre"
                                  ? "L "
                                  : item.unit == ""
                                  ? "Cup "
                                  : item.unit}
                                <label className="font-normal font-size-12 color-grey">
                                  R{item.normal_price + " " + item.other_price}{" "}
                                  x {item.normal_quantity}
                                </label>
                              </label>
                            ) : (
                              <label className="font-size-12">
                                {item.type == "normal coffee" ||
                                item.type == "tea"
                                  ? "Cup:"
                                  : item.type == "shot" ||
                                    item.type == "tequila shot"
                                  ? "Shot:"
                                  : item.type == "wine" || item.type == "whisky"
                                  ? "Glass:"
                                  : "Normal:"}{" "}
                                <label className="font-size-12 color-grey font-normal">
                                  R{item.normal_price} x {item.normal_quantity}
                                </label>
                              </label>
                            )}
                          </span>{" "}
                        </label>
                      )}

                      {item.small_quantity == "" ||
                      item.small_quantity == null ? (
                        ""
                      ) : (
                        <label
                          className={"font-size-12 color-grey h-container-size"}
                        >
                          <span className="font-medium font-size-12 color-black">
                            Small:
                          </span>{" "}
                          R{item.small_price} x {item.small_quantity}
                          <br />
                        </label>
                      )}

                      {item.medium_quantity == "" ||
                      item.medium_quantity == null ? (
                        ""
                      ) : (
                        <label
                          className={"font-size-12 color-grey h-container-size"}
                        >
                          <span className="font-medium font-size-12 color-black">
                            Medium:
                          </span>{" "}
                          R{item.medium_price} x {item.medium_quantity}
                          <br />
                        </label>
                      )}
                      {item.large_quantity == "" ||
                      item.large_quantity == null ? (
                        ""
                      ) : (
                        <label
                          className={"font-size-12 color-grey h-container-size"}
                        >
                          <span className="font-medium font-size-12 color-black">
                            Large:
                          </span>{" "}
                          R{item.large_price} x {item.large_quantity}
                          <br />
                        </label>
                      )}

                      {item.extra_large_quantity == "" ||
                      item.extra_large_quantity == null ? (
                        ""
                      ) : (
                        <label
                          className={"font-size-12 color-grey h-container-size"}
                        >
                          <span className="font-medium font-size-12 color-black">
                            Extra large:
                          </span>{" "}
                          R{item.extra_large_price} x{" "}
                          {item.extra_large_quantity}
                          <br />
                        </label>
                      )}

                      {item.bulk_quantity == "" ||
                      item.bulk_quantity == null ? (
                        ""
                      ) : (
                        <label
                          className={"font-size-12 color-grey h-container-size"}
                        >
                          <span className="font-medium font-size-12 color-black">
                            {item.category == "drink" ? (
                              <label className="font-size-12">
                                2
                                {item.unit == "litre"
                                  ? "L"
                                  : item.unit == ""
                                  ? "--"
                                  : item.unit}
                              </label>
                            ) : (
                              <label className="font-size-12">
                                {item.type == "normal coffee" ||
                                item.type == "tea"
                                  ? "Cup:"
                                  : item.type == "shot" ||
                                    item.type == "tequila shot"
                                  ? "Shot:"
                                  : item.type == "wine" || item.type == "whisky"
                                  ? "Glass:"
                                  : "Bulk:"}{" "}
                              </label>
                            )}
                          </span>{" "}
                          R{item.bulk_price} x {item.bulk_quantity}
                          <br />
                        </label>
                      )}
                    </label>
                  </Col>
                  {/* <Col
                    lg={2}
                    sm={2}
                    md={2}
                    xs={1}
                    className="font-size-12 text-center basket-cols"
                  >
                    Qty
                    <br />
                    <label className="color-grey font-size-12 w-100 text-center">
                      {item.quantity}
                    </label>
                  </Col> */}

                  <Col
                    lg={1}
                    sm={1}
                    md={2}
                    xs={1}
                    className="font-size-12 text-center basket-cols d-sm-none d-xs-none d-lg-block d-md-block"
                  >
                    Total
                    <br />
                    <label className="color-grey font-size-12">
                      R{item.total}
                    </label>
                  </Col>

                  <Col
                    lg={1}
                    sm={1}
                    md={1}
                    xs={1}
                    className="font-size-12 text-center d-lg-none d-sm-block d-xs-block"
                  >
                    <IoMdMore
                      size={30}
                      className="color-grey"
                      onClick={() =>
                        selectMedia(
                          item.media,
                          item.itemid,
                          item.itemid,
                          item.item_name,
                          item.normal_price,
                          item.small_price,
                          item.medium_price,
                          item.large_price,
                          item.extra_large_price,
                          item.bulk_price,
                          item.item_type,
                          item.description,
                          item.online_availability,
                          item.intervals,
                          item.category,
                          item.type,
                          item.volume,
                          item.unit,
                          item.other_price,
                          item.customerid,
                          item.company_name,
                          item.first_name,
                          item.last_name,
                          item.customer_id,
                          "menu",
                          item.orderid,
                          item.est_time,
                          item.notified,
                          item.finished,
                          item.comments,
                          item.token,
                          item.delivered,
                          item.est_date_sent,
                          item.total,
                          item.delivered_date
                        )
                      }
                    />
                  </Col>

                  <Col
                    lg={2}
                    sm={1}
                    md={1}
                    xs={1}
                    className="font-size-12 text-center d-none d-lg-block"
                  >
                    <label>
                      {item.notified == "yes" ? (
                        <span>
                          <span className="trash-icon">
                            {item.notified == "yes" ? (
                              item.notified == "yes" &&
                              item.finished == "no" &&
                              item.delivered == "no" ? (
                                <IoEllipsisHorizontalCircleOutline
                                  size={20}
                                  className="color-grey"
                                  title="Prep underway..."
                                />
                              ) : item.notified == "yes" &&
                                item.finished == "yes" &&
                                item.delivered == "no" ? (
                                <BsHandThumbsUp
                                  size={20}
                                  className="color-green"
                                />
                              ) : (
                                <label className="color-grey font-size-12">
                                  --
                                </label>
                              )
                            ) : (
                              ""
                            )}
                          </span>{" "}
                          <span className="food-kind-label font-size-12 font-medium color-green">
                            <span className="font-size-12 color-grey font-normal">
                              <span className="font-medium font-size-12">
                                {item.notified == "yes" &&
                                item.finished == "no" &&
                                item.delivered == "no" ? (
                                  <label
                                    className="color-grey font-size-12 font-normal"
                                    title="Order preparations are ongoing..."
                                  >
                                    Prep ongoing...
                                  </label>
                                ) : item.notified == "yes" &&
                                  item.finished == "yes" &&
                                  item.delivered == "no" ? (
                                  <label
                                    className="color-green font-size-12 font-normal"
                                    title="Order preparations are done"
                                  >
                                    Prep done <IoIosCheckmark size={25} />
                                  </label>
                                ) : (
                                  <label className="color-grey font-size-12">
                                    --
                                  </label>
                                )}
                              </span>
                              {item.estimated_time.includes("-")
                                ? ""
                                : item.estimated_time}
                            </span>
                          </span>
                        </span>
                      ) : (
                        <label
                          className={
                            item.notified == "yes"
                              ? "font-size-12 font-medium cursor font-size-12 "
                              : "font-size-12 font-medium cursor font-size-12  orders-btn"
                          }
                          data-tip={
                            "Let customer know their order is being prepared."
                          }
                          data-effect={"solid"}
                          data-place="top"
                          data-type={"dark"}
                          data-arrow-color={"transparent"}
                          data-class="tool_tips"
                          onClick={() =>
                            selectMedia(
                              item.media,
                              item.itemid,
                              item.itemid,
                              item.item_name,
                              item.normal_price,
                              item.small_price,
                              item.medium_price,
                              item.large_price,
                              item.extra_large_price,
                              item.bulk_price,
                              item.item_type,
                              item.description,
                              item.online_availability,
                              item.intervals,
                              item.category,
                              item.type,
                              item.volume,
                              item.unit,
                              item.other_price,
                              item.customerid,
                              item.company_name,
                              item.first_name,
                              item.last_name,
                              item.customer_id,
                              "notify",
                              item.orderid,
                              item.est_time,
                              item.notified,
                              item.finished,
                              item.comments,
                              item.token,
                              item.delivered,
                              item.est_date_sent,
                              item.total,
                              item.delivered_date
                            )
                          }
                        >
                          Notify <IoInformationCircleOutline size={15} />
                          <ReactTooltip />
                        </label>
                      )}
                    </label>
                  </Col>

                  <Col
                    lg={2}
                    sm={2}
                    md={3}
                    xs={2}
                    className="font-size-12 text-center d-none d-lg-block"
                  >
                    --
                  </Col>

                  <Col
                    lg={2}
                    sm={2}
                    md={1}
                    xs={1}
                    className="font-size-12 text-center d-none d-lg-block"
                  >
                    <label className="cursor font-size-12">
                      <span className="trash-icon">
                        <BsGripHorizontal size={20} />
                      </span>{" "}
                      
                        <label
                          className="food-kind-label font-size-12 font-medium color-grey"
                          
                        >
                          {item.company_name}
                        </label>
                      
                    </label>
                  </Col>
                  {/* <Col
                    lg={1}
                    sm={1}
                    md={1}
                    xs={1}
                    className="font-size-12 text-center"
                  >
                    <label
                      className="color-red cursor font-size-12"
                      onClick={() => remove(item.orderid)}
                    >
                      <span className="trash-icon">
                        <IoIosTrash size={20} />
                      </span>{" "}
                      <span className="food-kind-label font-size-12">
                        Remove
                      </span>
                    </label>
                  </Col> */}
                </Row>
              </Col>
            );
          })
        )}
      </Row>
    );
  }

  function orders() {
    let itemid = 0;
    return (
      <Row>
        {" "}
        {order_count == 0 ? (
          <div>
            {NoMediaLoging && (
              <label className="w-100 font-size-12 color-red font-medium pb-0 mb-0 text-center">
                Something went wrong, we couldn't retrieve orders <br />
                belonging to {fname + " " + lname}.
              </label>
            )}
          </div>
        ) : (
          orders_per_customer.map((item, index) => {
            return (
              <Col
                lg={4}
                md={4}
                sm={4}
                xs={6}
                className={"mb-4 text-left p-4 pb-0 pt-0"}
              >
                <Row className="align-items-center">
                  <Col
                    lg={12}
                    xs={12}
                    sm={12}
                    md={12}
                    className="media-container media-container-orders mb-2"
                    style={{
                      backgroundImage: `url(${root_url}/assets/${item.customerid}/${item.media})`,
                    }}
                  ></Col>
                  <Col
                    lg={12}
                    xs={12}
                    md={12}
                    sm={12}
                    className="p-0 font-medium"
                  >
                    {/* {item.item_name.length > 15
                      ? item.item_name.substring(0, 15) + "..."
                      : item.item_name} */}
                    {item.item_name}
                  </Col>
                </Row>
              </Col>
            );
          })
        )}
      </Row>
    );
  }

  function phase_delivered() {
    setAlias("delivered");
  }

  function phase_coming() {
    setAlias("coming");
  }

  function phase_notify() {
    setAlias("notify");
  }
  function notify_customer(e) {
    e.preventDefault();
    const formData = new FormData();
    const deviceid = localStorage.getItem("deviceid");
    const customerid = localStorage.getItem("customerid");
    formData.append("customer_id", cust_id);
    formData.append("customerid", customerid);
    formData.append("hours", hours);
    formData.append("minutes", clock);
    formData.append("comments", comments);
    formData.append("orderid", orderid);
    formData.append("grand_total", grand_total);
    formData.append("token", token);
    formData.append("alias", alias);
    formData.append("query", "NOTIFY_CUSTOMER");

    if (hours == "" && clock == "" && alias == "coming") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Set hours and minutes");
    } else if (hours == "0" && clock == "00" && alias == "coming") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Hours and minutes cannot be set to 0 at the same time.");
    } else {
      setBtnColor("disabled-btn");
      setDisabled("disabled");
      setLoadSpinner(true);
      setSaveLabel(false);

      axios({
        method: "POST",
        url: baseURL,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then((response) => {
        console.log(response.data);
        if (response.data.status == "error") {
          setErrorMsg(
            <label className="color-red">
              <IoIosClose size={35} /> {response.data.message}
            </label>
          );
          setAdded_to_basket(true);
        } else {
          setCount(count + 1);
          // setErrorMsg(
          //   <label className="color-green">
          //     <IoIosCheckmark size={35} /> {response.data.message}
          //   </label>
          // );
          setAdded_to_basket(true);
          setMediaDetails(false);

          setBgColor("#66b266");
          setDisplayMessage(true);
          setErrorMsg(response.data.message);

          navigate("/historical_orders");
        }

        setBtnColor("karamale-login-btn");
        setDisabled("");
        setLoadSpinner(false);
        setSaveLabel(true);
      });
    }

    setTimeout(() => {
      setDisplayMessage(false);
    }, 10000);
  }

  function setCover() {
    const mediaName = selectedMediaImg;
    const customerid = localStorage.getItem("customerid");
    setTextOne(false);
    setBeatOne(true);
    axios
      .get(
        baseURL +
          "?query=SET-COVER&customerid=" +
          customerid +
          "&mediaName=" +
          mediaName
      )
      .then((response) => {
        setSuccessOne(true);
        setBeatOne(false);
        localStorage.setItem("cover", response.data.cover);
        navigate("/media");

        setTimeout(() => {
          setSuccessOne(false);
          setTextOne(true);
          setBeatOne(false);
          setMediaDetails(false);
        }, 1000);
      });
  }

  function setLogo() {
    const mediaName = selectedMediaImg;
    const customerid = localStorage.getItem("customerid");
    setTextTwo(false);
    setBeatTwo(true);
    axios
      .get(
        baseURL +
          "?query=SET-LOGO&customerid=" +
          customerid +
          "&mediaName=" +
          mediaName
      )
      .then((response) => {
        setSuccessTwo(true);
        setBeatTwo(false);
        localStorage.setItem("logo", response.data.logo);
        navigate("/media");

        setTimeout(() => {
          setSuccessTwo(false);
          setTextTwo(true);
          setBeatTwo(false);
          setMediaDetails(false);
        }, 1000);
      });
  }

  function move_to_bin() {
    const mediaName = selectedMediaImg;
    const mediaid = selectedMediaID;
    const customerid = localStorage.getItem("customerid");
    setTextFour(false);
    setBeatFour(true);
    axios
      .get(
        baseURL +
          "?query=DEL-ITEM&customerid=" +
          customerid +
          "&mediaid=" +
          mediaid
      )
      .then((response) => {
        setSuccessFour(true);
        setBeatFour(false);
        navigate("/dashboard");

        setTimeout(() => {
          setSuccessFour(false);
          setTextFour(true);
          setBeatFour(false);
          setMediaDetails(false);
        }, 1000);
      });
  }

  function attachItem() {
    navigate("/media");
  }

  function editDetails() {
    localStorage.setItem("item_name", item);
    localStorage.setItem("description", description);
    navigate("/addstock");
  }

  function moreDetails() {
    setMore(false);
    setLess(true);
    setSeeMore(true);
  }
  function lessDetails() {
    setMore(true);
    setLess(false);
    setSeeMore(false);
  }

  function normalPrice() {
    if (CheckedOne == true) {
      setCheckedOne(false);
      setUncheckedOne(true);
      setQuantityOne(false);
      setQuantitesOne("");
      setTotalOne(0);
    } else {
      setCheckedOne(true);
      setUncheckedOne(false);
      setQuantityOne(true);
    }
    setCount(count + 1);
  }

  function smallPrice() {
    if (CheckedTwo == true) {
      setCheckedTwo(false);
      setUncheckedTwo(true);
      setQuantityTwo(false);
      setQuantitesTwo("");
      setTotalTwo(0);
    } else {
      setCheckedTwo(true);
      setUncheckedTwo(false);
      setQuantityTwo(true);
    }
    setCount(count + 1);
  }

  function mediumPrice() {
    if (CheckedThree == true) {
      setCheckedThree(false);
      setUncheckedThree(true);
      setQuantityThree(false);
      setQuantitesThree("");
      setTotalThree(0);
    } else {
      setCheckedThree(true);
      setUncheckedThree(false);
      setQuantityThree(true);
    }
    setCount(count + 1);
  }

  function largePrice() {
    if (CheckedFour == true) {
      setCheckedFour(false);
      setUncheckedFour(true);
      setQuantityFour(false);
      setQuantitesFour("");
      setTotalFour(0);
    } else {
      setCheckedFour(true);
      setUncheckedFour(false);
      setQuantityFour(true);
    }
    setCount(count + 1);
  }

  function xlargePrice() {
    if (CheckedFive == true) {
      setCheckedFive(false);
      setUncheckedFive(true);
      setQuantityFive(false);
      setQuantitesFive("");
      setTotalFive(0);
    } else {
      setCheckedFive(true);
      setUncheckedFive(false);
      setQuantityFive(true);
    }
    setCount(count + 1);
  }

  function bulkPrice() {
    if (CheckedSix == true) {
      setCheckedSix(false);
      setUncheckedSix(true);
      setQuantitySix(false);
      setQuantitesSix("");
      setTotalSix(0);
    } else {
      setCheckedSix(true);
      setUncheckedSix(false);
      setQuantitySix(true);
    }
    setCount(count + 1);
  }

  function f_quantityOne(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesOne(e.target.value);
      let price = 0;
      const price_one = other_price;
      const price_two = normal;
      if (price_one == "" || price_one == 0) {
        price = normal;
      } else {
        price = other_price;
      }
      setTotalOne(e.target.value * price);
      setCount(count + 1);
    }
  }

  function f_quantityTwo(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesTwo(e.target.value);
      setTotalTwo(e.target.value * small);
      setCount(count + 1);
    }
  }

  function f_quantityThree(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesThree(e.target.value);
      setTotalThree(e.target.value * medium);
      setCount(count + 1);
    }
  }

  function f_quantityFour(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesFour(e.target.value);
      setTotalFour(e.target.value * large);
      setCount(count + 1);
    }
  }

  function f_quantityFive(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesFive(e.target.value);
      setTotalFive(e.target.value * extraLarge);
      setCount(count + 1);
    }
  }

  function f_quantitySix(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesSix(e.target.value);
      setTotalSix(e.target.value * bulk);
      setCount(count + 1);
    }
  }

  function setAlert() {
    setError_modal(true);
    setMediaDetails(false);
  }
  function closeError() {
    setError_modal(false);
    setMediaDetails(true);
  }

  function empty_basket() {
    axios
      .get(
        baseURL +
          "?query=REMOVE_FROM_BASKET&device_id=" +
          deviceid +
          "&action=EMPTY_BASKET"
      )
      .then((response) => {});
    setCount(count + 1);
  }
  function remove(orderid) {
    axios
      .get(
        baseURL +
          "?query=REMOVE_FROM_BASKET&device_id=" +
          deviceid +
          "&orderid=" +
          orderid +
          "&action=REMOVE_ITEM"
      )
      .then((response) => {});
    setCount(count + 1);
  }

  function addEst(e) {
    const est_time = e.target.value;
    setEsted(est_time);
    const customerid = localStorage.getItem("customerid");
    axios
      .get(
        baseURL +
          "?query=ESTIMATED_TIME&customer_id=" +
          cust_id +
          "&orderid=" +
          orderid +
          "&customerid=" +
          customerid +
          "&est_time=" +
          est_time +
          "&token=" +
          token
      )
      .then((response) => {
        // navigate("/orders");
        // setEsted(e.target.value);
      });
    setCount(count + 1);
  }

  const times = [
    { label: "0", value: "0" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
  ];

  const time_interval = [
    { label: "00", value: "00" },
    { label: "01", value: "01" },
    { label: "02", value: "02" },
    { label: "03", value: "03" },
    { label: "04", value: "04" },
    { label: "05", value: "05" },
    { label: "06", value: "06" },
    { label: "07", value: "07" },
    { label: "08", value: "08" },
    { label: "09", value: "09" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
    { label: "13", value: "13" },
    { label: "14", value: "14" },
    { label: "15", value: "15" },
    { label: "16", value: "16" },
    { label: "17", value: "17" },
    { label: "18", value: "18" },
    { label: "19", value: "19" },
    { label: "20", value: "20" },
    { label: "21", value: "21" },
    { label: "22", value: "22" },
    { label: "23", value: "23" },
    { label: "24", value: "24" },
    { label: "25", value: "25" },
    { label: "26", value: "26" },
    { label: "27", value: "27" },
    { label: "28", value: "28" },
    { label: "29", value: "29" },
    { label: "30", value: "30" },
    { label: "31", value: "31" },
    { label: "32", value: "32" },
    { label: "33", value: "33" },
    { label: "34", value: "34" },
    { label: "35", value: "35" },
    { label: "36", value: "36" },
    { label: "37", value: "37" },
    { label: "38", value: "38" },
    { label: "39", value: "39" },
    { label: "40", value: "40" },
    { label: "41", value: "41" },
    { label: "42", value: "42" },
    { label: "43", value: "43" },
    { label: "44", value: "44" },
    { label: "45", value: "45" },
    { label: "46", value: "46" },
    { label: "47", value: "47" },
    { label: "48", value: "48" },
    { label: "49", value: "49" },
    { label: "50", value: "50" },
    { label: "51", value: "51" },
    { label: "52", value: "52" },
    { label: "53", value: "53" },
    { label: "54", value: "54" },
    { label: "55", value: "55" },
    { label: "56", value: "56" },
    { label: "57", value: "57" },
    { label: "58", value: "58" },
    { label: "59", value: "59" },
  ];

  function onClose() {
    setIsVisible(false);
  }

  return (
    <div className="body">
      <Auth />
      <Container className="container-home">
        <Row>
          <Col lg={2} xs={2} md={3} className="p-0">
            <HomeNav />
          </Col>
          <Col lg={10} xs={10} md={9} className={"middle-container"}>
            <Row className="h-100 align-items-center justify-content-center">
              <Col lg={12}>
                {localStorage.getItem("updated") != null ? (
                  <div>
                    <h6>
                      <label className="color-green">
                        <IoIosCheckmark size={30} /> Media for{" "}
                        {localStorage.getItem("food_name")} successfully updated
                      </label>
                    </h6>
                  </div>
                ) : (
                  ""
                )}
                <Stack>
                  <div className="box-container mt-3 mb-3 p-4 pb-0 pt-3">
                    <Row>
                      <Col lg={10} xs={8} sm={8} className="p-0">
                        <label className="float-left font-medium">
                          Historical orders
                          <br />
                          <span className="font-size-12 color-orange font-normal tip-text">
                            <span className="font-size-12 color-orange font-medium">
                              Important:
                            </span>{" "}
                            Rotate your device to landscape display to see
                            different order sizes
                          </span>
                        </label>
                        {/* {media_count == 0 ? (
                          ""
                        ) : (
                          <label
                            className="float-right font-size-12 color-red cursor"
                            onClick={empty_basket}
                          >
                            <IoIosTrash size={15} /> Clear orders
                          </label>
                        )} */}
                      </Col>
                      <Col
                        lg={2}
                        xs={4}
                        sm={4}
                        className="p-0 mb-3 text-right total_amount"
                      >
                        {media_count == 0 ? (
                          ""
                        ) : (
                          <label className="">
                            <b>Total: </b>
                            <span className="color-grey">R{total}</span>
                          </label>
                        )}
                      </Col>
                      <Col lg={12} className="p-0">
                        <div className="h-divider" />
                      </Col>
                      <Col lg={12} className="text-center">
                        {map_media()}
                        {LoadBeat && <BeatLoader color={"#39c"} />}
                      </Col>

                      <Col lg={12} className="p-0 mb-3 text-right">
                        {
                          media_count == 0 ? "" : ""
                          // <Row>
                          //   <Col
                          //     lg={0}
                          //     sm={0}
                          //     xs={6}
                          //     className="browse-right-padding mobile-browse-btn"
                          //   >
                          //     <Link to="/">
                          //       <label className="karamale-login-btn browse-w-100 font-medium">
                          //         Add more
                          //       </label>
                          //     </Link>
                          //   </Col>
                          //   <Col
                          //     lg={12}
                          //     xs={6}
                          //     sm={12}
                          //     className="browse-left-padding"
                          //   >
                          //     <label className="desktop-browse-btn">
                          //       <Link to="/">
                          //         <label className="karamale-login-btn browse-w-100 font-medium">
                          //           Add more items
                          //         </label>
                          //       </Link>
                          //       &nbsp;&nbsp;&nbsp;
                          //     </label>
                          //     <Link to="/checkout">
                          //       <label className="cancel-btn browse-w-100 color-light-black font-medium">
                          //         Checkout
                          //       </label>
                          //     </Link>
                          //   </Col>
                          // </Row>
                        }
                      </Col>
                    </Row>
                  </div>
                </Stack>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Modal
        show={error_modal}
        onHide={closeError}
        centered
        className="modal-z-index"
      >
        <Modal.Header closeButton className="hide-modal-border">
          <Modal.Title className="color-orange font-normal">
            {" "}
            Price not available for the selected size.
          </Modal.Title>
        </Modal.Header>
      </Modal>

      <Modal show={mediaDetails} onHide={handleClose}>
        <form onSubmit={notify_customer}>
          <Modal.Header closeButton>
            <Modal.Title>
              <b>Token: </b>
              {token}
              <br />
              Orders by{" "}
              <b>
                {fname} {lname}
              </b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-center align-items-center">
              <Col lg={12}>
                {server_data && <label className="w-100">{orders()}</label>}
                {LoadBeat && (
                  <label className="w-100 text-center">
                    <BeatLoader color={"#39c"} />
                  </label>
                )}
              </Col>
              <br />

              {order_count == 0 ? (
                ""
              ) : action == "coming" ? (
                <Row>
                  <Col lg={12} className="p-0 text-center">
                    {notified == "no" ? (
                      <label className="w-100 text-center color-grey">
                        First you have to notify the customer about their order
                        preparations.
                      </label>
                    ) : (
                      <Row>
                        <Col lg={12} className="">
                          <label className="color-grey mt-0 font-size-17 font-medium text-left w-100">
                            Items above share the same token; therefore they'll
                            share the same estimated delivery time.
                          </label>
                        </Col>

                        <Col lg={12} className="mt-3 text-left">
                          <label for="birthdaytime w-100">
                            How long will this order take to arrive at the
                            customer?
                          </label>
                        </Col>
                        <Col lg={6} className="mt-3">
                          <Select
                            value={times.find((obj) => obj.value === hours)}
                            placeholder="Set hours"
                            options={times}
                            onChange={(e) => setHours(e.value)}
                            width={"100%"}
                            className="text-left"
                          />
                        </Col>
                        <Col lg={6} className="mt-3">
                          <Select
                            value={time_interval.find(
                              (obj) => obj.value === clock
                            )}
                            placeholder="Set mintues"
                            options={time_interval}
                            onChange={(e) => setClock(e.value)}
                            width={"100%"}
                            className="text-left"
                          />
                        </Col>
                        <Col lg={12} className="p-0 mt-4">
                          Are you 100% sure the order is ready to be delivered?
                        </Col>
                      </Row>
                    )}
                    <br />
                    {notified == "no" ? (
                      ""
                    ) : (
                      <button
                        type={"submit"}
                        className={btnColor + " font-medium"}
                        disabled={disabled}
                        onClick={phase_coming}
                      >
                        {saveLabel && <span>Yes, notify customer</span>}
                        {loadSpinner && <BeatLoader size={10} />}
                      </button>
                    )}
                  </Col>
                </Row>
              ) : action == "delivered" ? (
                <Row>
                  <Col lg={12} className="p-0">
                    {notified == "no" && finished == "no" ? (
                      <label className="color-red font-normal">
                        First you have to notify the customer of their order
                        preparation and then notify them that the order is on
                        its way.
                      </label>
                    ) : (
                      <span>
                        <h5 className="color-grey mt-0">
                          Mark this order as delivered only if it has reached
                          the customer.
                        </h5>
                        <label className="font-size-12 color-grey">
                          Soon as you click the button below, the customer will
                          receive an email confirming that they've accepted the
                          order, so make sure the order was successfully
                          delivered.
                        </label>
                      </span>
                    )}
                  </Col>

                  {notified == "no" && finished == "no" ? (
                    ""
                  ) : (
                    <Col
                      lg={12}
                      xs={12}
                      sm={12}
                      md={12}
                      className="mt-3 text-right p-0"
                    >
                      <button
                        type={"submit"}
                        className={btnColor + " font-medium"}
                        disabled={disabled}
                        onClick={phase_delivered}
                      >
                        {saveLabel && <span>Mark as delivered</span>}
                        {loadSpinner && <BeatLoader size={10} />}
                      </button>
                    </Col>
                  )}
                </Row>
              ) : (
                <Row>
                  <Col lg={12} className="mt-3 p-0">
                    <span>Add comment (Optional)</span>
                    <textarea
                      placeholder={"Write here..."}
                      className="mt-1"
                      onChange={(e) => setComments(e.target.value)}
                      value={comments}
                    />
                  </Col>
                  <Col lg={12} className="font-size-12 color-grey p-0">
                    Click the button below to let the customer know their order
                    is being prepared.
                  </Col>

                  <Col lg={12}>
                    <Row className="justify-content-center align-items-center p-0">
                      <Col
                        lg={6}
                        xs={5}
                        sm={6}
                        md={6}
                        className="mt-3 text-left p-0"
                      >
                        <label className="margin-right-5">
                          <b>Overall total:</b>{" "}
                          <span className="color-grey">
                            R{parseFloat(grand_total).toFixed(1)}
                          </span>
                        </label>
                      </Col>

                      <Col
                        lg={6}
                        xs={7}
                        sm={6}
                        md={6}
                        className="mt-3 text-right p-0"
                      >
                        <button
                          type={"submit"}
                          className={btnColor + " font-medium"}
                          disabled={disabled}
                          onClick={phase_notify}
                        >
                          {saveLabel && <span>Notify customer</span>}
                          {loadSpinner && <BeatLoader size={10} />}
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </Row>
          </Modal.Body>
          {added_to_basket && (
            <Modal.Footer>
              <label className="w-100 text-center color-green">
                {errorMsg}
              </label>
              {/* <label className="cancel-btn text-bold" onClick={handleClose}>
            Close
          </label> */}
            </Modal.Footer>
          )}
        </form>
      </Modal>

      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
        >
          {errorMsg}
        </div>
      )}
      <Drawer isVisible={isVisible} onClose={onClose} className="drawer">
        <Row className="p-3 pt-0">
          <Col lg={12} className="">
            <span className="font-medium">{item_name}</span>
            <br />
            <span className="font-medium">
              Order token:{" "}
              <span className="color-grey font-normal">{token}</span>
            </span>
          </Col>
          <Col lg={12}>
            <div className="h-divider" />
          </Col>
          <Col lg={12} className="drawer-menu-items">
            <span className="color-black font-medium">Item total:</span>{" "}
            <span className="color-grey">R{order_total}</span>
            <br />
            {notified == "yes" && finished == "no" && delivered == "no" ? (
              <span className="color-grey">Preparation ongoing...</span>
            ) : notified == "yes" && finished == "yes" && delivered == "no" ? (
              <span className="color-green">
                Preparation done <IoIosCheckmark size={25} />
              </span>
            ) : notified == "yes" && finished == "yes" && delivered == "yes" ? (
              <span className="color-grey">--</span>
            ) : (
              <label
                className="font-normal color-blue cursor"
                onClick={() =>
                  selectMedia(
                    media,
                    itemID,
                    itemID,
                    item_name,
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    item_type,
                    description,
                    online_availability,
                    intervals,
                    category,
                    type,
                    volume,
                    unit,
                    other_price,
                    customerid,
                    company_name,
                    fname,
                    lname,
                    cust_id,
                    "notify",
                    orderid,
                    estimated_time,
                    notified,
                    finished,
                    comments,
                    token,
                    delivered,
                    date_sent,
                    total,
                    delivered_date
                  )
                }
              >
                Notify customer of their order preparation
              </label>
            )}
            <br />
            {notified == "yes" && finished == "yes" && delivered == "no" ? (
              <label className="color-grey deliver-message-mobile">
                Delivery expected in {estimated_time} from {date_sent}
              </label>
            ) : notified == "yes" && finished == "yes" && delivered == "yes" ? (
              <span className="font-normal color-grey">
                Order delivered at {delivered_date}
              </span>
            ) : (
              <label
                className="color-blue font-normal cursor"
                onClick={() =>
                  selectMedia(
                    media,
                    itemID,
                    itemID,
                    item_name,
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    item_type,
                    description,
                    online_availability,
                    intervals,
                    category,
                    type,
                    volume,
                    unit,
                    other_price,
                    customerid,
                    company_name,
                    fname,
                    lname,
                    cust_id,
                    "coming",
                    orderid,
                    estimated_time,
                    notified,
                    finished,
                    comments,
                    token,
                    delivered,
                    date_sent,
                    total,
                    delivered_date
                  )
                }
              >
                Notify customer the order is on the way
              </label>
            )}
            <br />
            {notified == "yes" && finished == "yes" && delivered == "yes" ? (
              <span className="color-green">
                Order delivered <IoIosCheckmark size={25} />
              </span>
            ) : (
              <label
                className="color-blue font-normal cursor"
                onClick={() =>
                  selectMedia(
                    media,
                    itemID,
                    itemID,
                    item_name,
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    item_type,
                    description,
                    online_availability,
                    intervals,
                    category,
                    type,
                    volume,
                    unit,
                    other_price,
                    customerid,
                    company_name,
                    fname,
                    lname,
                    cust_id,
                    "delivered",
                    orderid,
                    estimated_time,
                    notified,
                    finished,
                    comments,
                    token,
                    delivered,
                    date_sent,
                    total,
                    delivered_date
                  )
                }
              >
                Mark order as delivered
              </label>
            )}
          </Col>
        </Row>
      </Drawer>
    </div>
  );
}
localStorage.removeItem("updated");
