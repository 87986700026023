import React, { useState, useEffect } from "react";
import Authentication from "./navigation/authentication";
import { Col, Container, Row, Stack, Modal, Button } from "react-bootstrap";
import SideNav from "./navigation/SideNav";
import { BsPlusCircleDotted } from "react-icons/bs";
import {
  IoIosTrash,
  IoMdPerson,
  IoMdEasel,
  IoIosAdd,
  IoIosCheckmark,
} from "react-icons/io";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
const baseURL = process.env.REACT_APP_URL;
const root_url = process.env.REACT_APP_URL_RESOURCE;
const accountid = localStorage.getItem("accountid");
export default function Media() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [mediaDetails, setMediaDetails] = useState(false);
  const [displayImge, setDisplayImge] = useState([]);
  const [preimage, setPreImage] = useState("");
  const [imgname, setImgname] = useState("");
  const [type, setType] = useState("");
  const [path, setPath] = useState("");
  const [showUploadBtn, setShowUploadBtn] = useState(false);
  const [showImage, setshowImage] = useState(false);
  const [arraySize, setArraySize] = useState(0);
  const [disabled, setDisabled] = useState("");
  const [btnColor, setBtnColor] = useState("karamale-login-btn");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [allimgs, setAllImgs] = useState("");
  const [media_count, setMedia_count] = useState(0);
  const [media, setMedia] = useState([]);
  const [selectedMediaID, setSelectedMediaID] = useState(0);
  const [selectedMediaImg, setSelectedMediaImg] = useState(null);
  const [successOne, setSuccessOne] = useState(false);
  const [successTwo, setSuccessTwo] = useState(false);
  const [successThree, setSuccessThree] = useState(false);
  const [successFour, setSuccessFour] = useState(false);
  const [textOne, setTextOne] = useState(true);
  const [beatOne, setBeatOne] = useState(false);
  const [textTwo, setTextTwo] = useState(true);
  const [beatTwo, setBeatTwo] = useState(false);
  const [textThree, setTextThree] = useState(true);
  const [beatThree, setBeatThree] = useState(false);
  const [textFour, setTextFour] = useState(true);
  const [beatFour, setBeatFour] = useState(false);
  const [LoadBeat, setLoadBeat] = useState(true);
  const [NoMediaLoging, setNoMediaLoging] = useState(false);
  const [itemID, setItemID] = useState(0);
  const [date_uploaded, setDate_uploaded] = useState(null);
  const [media_size, setMedia_size] = useState(0);
  const [count, setCount] = useState(0);

  function mediaCount() {
    axios
      .get(baseURL + "?query=MEDIA-COUNT&accountid=" + accountid)
      .then((response) => {
        setMedia_count(response.data.count);
      });
  }

  function fetchMedia() {
    axios
      .get(baseURL + "?query=MEDIA&accountid=" + accountid)
      .then((response) => {
        setMedia(response.data);
        setNoMediaLoging(true);

        setLoadBeat(false);
      });
  }
  useEffect(() => {
    document.title = "Obarak | Media";

    mediaCount();
    fetchMedia();
  }, [count]);

  const handleClose = () => {
    setShow(false);
    setMediaDetails(false);
  };
  const handleShow = () => setShow(true);
  // const selectedMedia = () => setMediaDetails(true);

  function selectMedia(media, mediaid, itemid, date_uploaded, media_size) {
    // alert(itemid);
    setSelectedMediaID(mediaid);
    setSelectedMediaImg(media);
    setDate_uploaded(date_uploaded);
    setMedia_size(media_size);
    setItemID(itemid);
    setMediaDetails(true);
  }

  function map_media() {
    let itemid = 0;
    return (
      <div>
        {
          <Row>
            {" "}
            {media_count == 0 ? (
              <div>
                {NoMediaLoging && (
                  <label className="no-media-text color-silver">No media</label>
                )}
              </div>
            ) : (
              media.map((item, index) => {
                return (
                  <Col lg={3} md={4} sm={4} xs={6} className="mb-4">
                    <Col
                      lg={12}
                      className="media-container"
                      style={{
                        backgroundImage: `url(${root_url}/assets/${localStorage.getItem(
                          "accountid"
                        )}/${item.media})`,
                      }}
                      onClick={() =>
                        selectMedia(
                          item.media,
                          item.mediaid,
                          itemid,
                          item.date_uploaded,
                          item.media_size
                        )
                      }
                    ></Col>
                  </Col>
                );
              })
            )}
          </Row>
        }
      </div>
    );
  }

  function selectImage(event) {
    setAllImgs(event.target.files);
    setDisplayImge([]);
    const img = event.target.files[0];
    setShowUploadBtn(true);
    setshowImage(true);
    let images = [];
    for (let i = 0; i < event.target.files.length; i++) {
      images.push(URL.createObjectURL(event.target.files[i]));
    }
    setDisplayImge(images);
    setArraySize(event.target.files.length);
  }

  function upload(e) {
    e.preventDefault();
    const formData = new FormData();

    for (let i = 0; i < allimgs.length; i++) {
      formData.append("file[]", allimgs[i]);
    }

    formData.append("accountid", localStorage.getItem("accountid"));
    formData.append("query", "UPLOAD-MEDIA");

    setBtnColor("disabled-btn");
    setDisabled("disabled");
    setLoadSpinner(true);
    setSaveLabel(false);

    axios({
      method: "POST",
      url: baseURL,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    }).then((response) => {
      if (response.data.status == "error") {
        setBgColor("red");
        setDisplayMessage(true);
        setErrorMsg(response.data.message);
      } else {
        // navigate("/media");
        setCount(count + 1);
        setBgColor("#66b266");
        setDisplayMessage(true);
        setAllImgs("");
        setDisplayImge([]);
        setErrorMsg(response.data.message);
        setShow(false);
      }

      setBtnColor("karamale-login-btn");
      setDisabled("");
      setLoadSpinner(false);
      setSaveLabel(true);
    });
    setTimeout(() => {
      setDisplayMessage(false);
    }, 5000);
  }

  function setCover() {
    const mediaName = selectedMediaImg;
    const accountid = localStorage.getItem("accountid");
    setTextOne(false);
    setBeatOne(true);
    axios
      .get(
        baseURL +
          "?query=SET-COVER&accountid=" +
          accountid +
          "&mediaName=" +
          mediaName
      )
      .then((response) => {
        setSuccessOne(true);
        setBeatOne(false);
        localStorage.setItem("cover", response.data.cover);
        navigate("/media");

        setTimeout(() => {
          setSuccessOne(false);
          setTextOne(true);
          setBeatOne(false);
          setMediaDetails(false);
        }, 1000);
      });
  }

  function setLogo() {
    const mediaName = selectedMediaImg;
    const accountid = localStorage.getItem("accountid");
    setTextTwo(false);
    setBeatTwo(true);
    axios
      .get(
        baseURL +
          "?query=SET-LOGO&accountid=" +
          accountid +
          "&mediaName=" +
          mediaName
      )
      .then((response) => {
        setSuccessTwo(true);
        setBeatTwo(false);
        localStorage.setItem("logo", response.data.logo);
        navigate("/media");

        setTimeout(() => {
          setSuccessTwo(false);
          setTextTwo(true);
          setBeatTwo(false);
          setMediaDetails(false);
        }, 1000);
      });
  }

  function move_to_bin() {
    const mediaName = selectedMediaImg;
    const mediaid = selectedMediaID;
    const accountid = localStorage.getItem("accountid");
    setTextFour(false);
    setBeatFour(true);
    axios
      .get(
        baseURL +
          "?query=MOVE-TO-BIN&accountid=" +
          accountid +
          "&mediaid=" +
          mediaid
      )
      .then((response) => {
        setCount(count + 1);
        setSuccessFour(true);
        setBeatFour(false);
        navigate("/media");

        setTimeout(() => {
          setSuccessFour(false);
          setTextFour(true);
          setBeatFour(false);
          setMediaDetails(false);
        }, 1000);
      });
  }

  function attachItem() {
    const mediaName = selectedMediaImg;
    const mediaid = selectedMediaID;

    const accountid = localStorage.getItem("accountid");
    const foodid = localStorage.getItem("foodid");
    const food_name = localStorage.getItem("food_name");

    setTextThree(false);
    setBeatThree(true);
    axios
      .get(
        baseURL +
          "?query=ATTACH-MEDIA&accountid=" +
          accountid +
          "&mediaName=" +
          mediaName +
          "&foodid=" +
          foodid +
          "&food_name=" +
          food_name+
          "&mediaid="+mediaid
      )
      .then((response) => {
        if (response.data.status == "ok") {
          localStorage.setItem("success-message", response.data.message);
          localStorage.removeItem("foodid");
          if (localStorage.getItem("dashboard") == "yes") {
            localStorage.setItem("updated", "yes");
            navigate("/dashboard");
          } else {
            localStorage.removeItem("updated");
            navigate("/addstock");
          }
        } else {
          setSuccessThree(true);
          setBeatThree(false);
        }

        setTimeout(() => {
          setSuccessThree(false);
          setTextThree(true);
          setBeatThree(false);
          setMediaDetails(false);
        }, 1000);
      });
  }

  function remove() {
    setCount(count + 1);
    setShowUploadBtn(false);
    setAllImgs("");
    setDisplayImge([]);
    navigate("/media");
  }
  return (
    <div className="body">
      <Authentication />
      <Container className="container-home">
        <Row>
          <Col lg={2} xs={2} md={3} className="p-0">
            <SideNav />
          </Col>
          <Col lg={10} xs={10} md={9} className={"middle-container"}>
            <Row className="h-100 align-items-center justify-content-center">
              <Col lg={9}>
                {localStorage.getItem("foodid") != null ? (
                  <div>
                    <h6>
                      Select to{" "}
                      {localStorage.getItem("dashboard") == "no"
                        ? "attach"
                        : "update"}{" "}
                      media for the{" "}
                      <label className="color-green">
                        {localStorage.getItem("food_name")}
                      </label>{" "}
                      item
                    </h6>
                    {localStorage.getItem("dashboard") == "yes" ? (
                      ""
                    ) : (
                      <span className="color-grey">
                        <b>NB:</b> If you don't attach a media to this item,
                        your <b>{localStorage.getItem("food_name")}</b> will not
                        be listed.
                      </span>
                    )}
                  </div>
                ) : (
                  ""
                )}
                <Stack>
                  <div className="box-container mt-3 mb-3">
                    <Row>
                      <Col lg={12}>
                        <label className="float-left text-bold">
                          Media ({media_count})
                        </label>
                        <label
                          onClick={handleShow}
                          className="text-blue text-medium text-hover-underline float-right"
                        >
                          Add new media
                        </label>
                      </Col>
                      <Col lg={12}>
                        <div className="h-divider" />
                      </Col>
                      <Col lg={12} className="text-center">
                        {map_media()}
                        {LoadBeat && <BeatLoader color={"#39c"} />}
                      </Col>
                    </Row>
                  </div>
                </Stack>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Modal show={mediaDetails} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {localStorage.getItem("foodid") != null ? (
              "Attach media to the " +
              localStorage.getItem("food_name") +
              " food item"
            ) : (
              <label className="font-medium">Take action</label>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center align-items-center">
            <div lg={12}>
              <img
                className="img-border-radius w-100"
                src={
                  root_url +
                  "/assets/" +
                  localStorage.getItem("accountid") +
                  "/" +
                  selectedMediaImg
                }
              />
            </div>
            <Col lg={3} sm={3} className="mt-3">
              {localStorage.getItem("foodid") != null ? (
                <label className=" font-size-12 w-100 text-align-left">
                  <span className="color-silver font-size-12">
                    <IoMdEasel size={15} /> Set cover
                  </span>
                </label>
              ) : (
                <label
                  className="color-blue color-blue-hover font-size-12 w-100 text-align-left"
                  onClick={setCover}
                >
                  {beatOne && (
                    <span>
                      <BeatLoader size={10} color={"#39c"} />
                    </span>
                  )}
                  {textOne && (
                    <span className="font-size-12">
                      {" "}
                      <IoMdEasel size={15} /> Set cover
                    </span>
                  )}
                  {successOne && (
                    <span className="color-green font-size-12">
                      {" "}
                      <IoIosCheckmark size={15} /> Successful
                    </span>
                  )}
                </label>
              )}
            </Col>
            <Col lg={3} sm={3} className="mt-3">
              {localStorage.getItem("foodid") != null ? (
                <label className=" font-size-12 w-100 text-align-left">
                  <span className="color-silver font-size-12">
                    <IoMdPerson size={15} /> Set logo
                  </span>
                </label>
              ) : (
                <label
                  className="color-blue color-blue-hover font-size-12 w-100 text-align-left"
                  onClick={setLogo}
                >
                  {beatTwo && (
                    <span>
                      <BeatLoader size={10} color={"#39c"} />
                    </span>
                  )}
                  {textTwo && (
                    <span className="font-size-12">
                      {" "}
                      <IoMdPerson size={15} /> Set logo
                    </span>
                  )}
                  {successTwo && (
                    <span className="color-green font-size-12">
                      {" "}
                      <IoIosCheckmark size={15} /> Successful
                    </span>
                  )}
                </label>
              )}
            </Col>

            <Col lg={3} sm={3} className="mt-3">
              {localStorage.getItem("foodid") == null ? (
                <label className=" font-size-12 w-100 text-align-left">
                  <span className="color-silver font-size-12">
                    <IoIosAdd size={15} /> Attach
                  </span>
                </label>
              ) : (
                <label
                  className=" font-size-12 w-100 text-align-left"
                  onClick={attachItem}
                >
                  <span className="font-size-12 color-blue color-blue-hover">
                    <IoIosAdd size={13} />{" "}
                    {localStorage.getItem("dashboard") == "no"
                      ? "Attach"
                      : "Update"}
                  </span>

                  <br />
                  {successThree && (
                    <span className="color-green font-size-12">
                      {" "}
                      <IoIosCheckmark size={20} /> Successful
                    </span>
                  )}
                </label>
              )}
            </Col>

            <Col lg={3} sm={3} className="mt-3">
              {localStorage.getItem("foodid") != null ? (
                <label className=" font-size-12 w-100 text-align-left">
                  <span className="color-silver font-size-12">
                    <IoIosTrash size={15} /> Trash
                  </span>
                </label>
              ) : (
                <label
                  className="color-red text-underline-on-hover font-size-12 w-100 text-align-left"
                  onClick={move_to_bin}
                >
                  {beatFour && (
                    <span>
                      <BeatLoader size={10} color={"#39c"} />
                    </span>
                  )}
                  {textFour && (
                    <span className="font-size-12">
                      {" "}
                      <IoIosTrash size={15} /> Trash
                    </span>
                  )}
                  {successFour && (
                    <span className="color-green font-size-12">
                      {" "}
                      <IoIosCheckmark size={15} /> Successful
                    </span>
                  )}
                </label>
              )}
            </Col>
            <Col lg={12}>
              <label className="font-size-12 color-grey mt-3">
                <b>NB:</b> If you move this media to trash, any item associated
                with it will be removed from your listing until the media is
                restored or the new one is added.
              </label>
            </Col>
            <Col lg={12} className="mt-3 color-grey font-size-12">
              <h6>Media details</h6>
              Date uploaded: {date_uploaded}
              <br />
              Media size: {media_size} bytes
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <label className="cancel-btn text-bold" onClick={handleClose}>
            Close
          </label>
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={handleClose}>
        <form onSubmit={upload}>
          <Modal.Header closeButton>
            <Modal.Title><span className="text-bold">Upload media</span></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-center align-items-center">
              <label className="media-box-upload" htmlFor="image">
                <BsPlusCircleDotted size={20} />
                <input
                  name="file"
                  type="file"
                  onChange={selectImage}
                  id="image"
                  accept={".jpg,.jpeg,.png,.gif"}
                  multiple
                  className="hide-file-input-type"
                />
              </label>
              <div>
                {/* {displayImge.length} */}
                {showImage && (
                  <>
                    {" "}
                    <div className="food-container mt-3">
                      <Row>
                        {displayImge.length > 0 ? (
                          <Col lg={12} className="mb-0 remove-text">
                            <label onClick={remove} className="cursor">
                              Remove
                            </label>
                          </Col>
                        ) : (
                          ""
                        )}
                        {displayImge.map((img, index) => {
                          return (
                            <Col lg={4} xs={6} sm={4}>
                              <label
                                className={"mt-3 border-radius-5"}
                                style={{
                                  backgroundImage: "url(" + img + ")",
                                  backgroundSize: "cover",
                                  height: 120,
                                  width: "100%",
                                  border: "thin solid #ececec",

                                  // backgroundRepeat: "no-repeat",
                                  backgroundPosition: "center",
                                }}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                  </>
                )}
              </div>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <label className="cancel-btn text-bold" onClick={handleClose}>
              Close
            </label>
            {showUploadBtn && (
              <button
                type="submit"
                className={btnColor + " text-bold font-size-17"}
                disabled={disabled}
              >
                {saveLabel && <span>Upload</span>}
                {loadSpinner && <BeatLoader size={10} />}
              </button>
            )}
          </Modal.Footer>
        </form>
      </Modal>
      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}
