import React, { useState, useEffect } from "react";
import { Col, Container, Row, Stack, Modal, Button } from "react-bootstrap";
import Nav from "./navigation/Nav";
import { Link, NavLink, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import validator from "validator";
import axios from "axios";
export default function Verify() {
  const navigate = useNavigate();
  const [verification_code, setVerification_code] = useState("");

  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [btnColor, setBtnColor] = useState("login-btn");
  const [disabled, setDisabled] = useState("");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [location, setLocation] = useState("");

  useEffect(() => {
    document.title = "Obarak / Verify email";
  });
  function submit(e) {
    e.preventDefault();
    const email = localStorage.getItem("email");
    const password = localStorage.getItem("password");
    const package_type = localStorage.getItem("package");
    const deviceid = localStorage.getItem("deviceid");

    const formData = new FormData();
    formData.append("verification_code", verification_code);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("package_type", package_type);
    formData.append("device_id", deviceid);
    formData.append("query", "VERIFY");

    if (verification_code === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter the verification code");
    } else {
      setBtnColor("disabled-btn");
      setDisabled("disabled");
      setLoadSpinner(true);
      setSaveLabel(false);
      // connect to the api

      const baseURL = process.env.REACT_APP_URL;
      axios({
        method: "POST",
        url: baseURL,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then((response) => {
        if (response.data.status == "error") {
          setBgColor("red");
          setDisplayMessage(true);
          setErrorMsg(response.data.message);
        } else {
          //   localStorage.setItem("email", email);
          const package_type = localStorage.getItem("package");
          if (package_type == "professional") {
            navigate("/thank_you");
          } else {
            localStorage.setItem("customerid", response.data.customerid);
            localStorage.setItem("first_name", response.data.first_name);
            localStorage.setItem("last_name", response.data.last_name);
            navigate("/checkout");
          }
        }

        setBtnColor("login-btn");
        setDisabled("");
        setLoadSpinner(false);
        setSaveLabel(true);

        // console.log(response.data.name);
      });
    }
    setTimeout(() => {
      setDisplayMessage(false);
    }, 5000);
  }

  return (
    <div className="package-body height-100hv">
      <Container className="container-home height-100hv">
        <Row className="height-100hv justify-content-center">
          <Col lg={12} xs={12} md={12} className="p-0">
            <Nav />
          </Col>
          <Col lg={6} xs={12} md={12}>
            <Row className="align-items-center justify-content-center">
              <form onSubmit={submit}>
                <Row className="h-100 align-items-center justify-content-center">
                  <Col lg={7}>
                    <Stack>
                      <div className="text-center w-100 mb-3">
                        <h3>Verify your email</h3>
                      </div>
                      <div className="box-container mb-5">
                        <Row>
                          <Col lg={12} className="text-center">
                            <label className="text-medium font-size-17">
                              Enter verification code
                            </label>
                            <br />
                            <span className="font-size-12 color-grey">
                              Verification code was sent to{" "}
                              {localStorage.getItem("email")}
                            </span>
                          </Col>

                          <Col lg={12} className="mb-3 mt-3">
                            <input
                              type="text"
                              placeholder="Verification code"
                              onChange={(e) =>
                                setVerification_code(e.target.value)
                              }
                              value={verification_code}
                            />
                          </Col>

                          <Col lg={12} className="mb-3">
                            <button
                              type="submit"
                              className={
                                btnColor + " w-100 text-bold font-size-17"
                              }
                              disabled={disabled}
                            >
                              {saveLabel && <span>Continue</span>}
                              {loadSpinner && <BeatLoader size={10} />}
                            </button>
                          </Col>

                          <Col lg={12} className="mb-3 text-center">
                            Already a host?{" "}
                            <Link to="/login" className="anchor">
                              Login
                            </Link>
                            <br />
                            Not a host yet?{" "}
                            <Link to="/packages" className="anchor">
                              Become a one
                            </Link>
                          </Col>
                        </Row>
                      </div>
                    </Stack>
                  </Col>
                </Row>
              </form>
            </Row>
          </Col>
        </Row>
      </Container>
      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}
