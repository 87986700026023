import React, { useState, useEffect } from "react";
import Authentication from "./navigation/authentication";
import { Col, Container, Row, Stack, Modal, Button } from "react-bootstrap";
import SideNav from "./navigation/SideNav";
import BeatLoader from "react-spinners/BeatLoader";
import validator from "validator";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import axios from "axios";
const baseURL = process.env.REACT_APP_URL;
const root_url = process.env.REACT_APP_URL_RESOURCE;

export default function Index() {
  const [businessname, setBusinessname] = useState(
    localStorage.getItem("company_name")
  );
  const [phone, setPhone] = useState(null);
  const [current_phone, setCurrent_phone] = useState(null);
  const [location, setLocation] = useState("");
  const [current_location, setcurrent_location] = useState("");
  const [email, setEmail] = useState("");
  const [url, setUrl] = useState("");
  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [btnColor, setBtnColor] = useState("action-btn");
  const [disabled, setDisabled] = useState("");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [count, setCount] = useState(0);

  const [sunday_from, setsunday_from] = useState(null);
  const [sunday_to, setsunday_to] = useState(null);

  const [monday_from, setmonday_from] = useState(null);
  const [monday_to, setmonday_to] = useState(null);

  const [tuesday_from, settuesday_from] = useState(null);
  const [tuesday_to, settuesday_to] = useState(null);

  const [wednesday_from, setwednesday_from] = useState(null);
  const [wednesday_to, setwednesday_to] = useState(null);

  const [thursday_from, setthursday_from] = useState(null);
  const [thursday_to, setthursday_to] = useState(null);

  const [friday_from, setfriday_from] = useState(null);
  const [friday_to, setfriday_to] = useState(null);

  const [saturday_from, setsaturday_from] = useState(null);
  const [saturday_to, setsaturday_to] = useState(null);

  const [holiday_from, setholiday_from] = useState(null);
  const [holiday_to, setholiday_to] = useState(null);

  const [show, setShow] = useState(false);
  const [tradingHours, setTradingHours] = useState([]);

  const accountid = localStorage.getItem("accountid");

  function fetch_trading_hrs() {
    axios
      .get(baseURL + "?query=VIEW_TRADING_HOURS&accountid=" + accountid)
      .then((response) => {
        setTradingHours(response.data);
      });
  }

  function profile_details() {
    const accountid = localStorage.getItem("accountid");
    axios
      .get(
        baseURL +
          "?query=PROFILE-DETAILS&accountid=" +
          accountid +
          "&package=PROFESSIONAL"
      )
      .then((res) => {
        setEmail(res.data.email);
        setUrl(res.data.url);
        setCurrent_phone(res.data.phone);
        setcurrent_location(res.data.location);
      });
  }
  useEffect(() => {
    document.title = "Obarak / Trading hours";
    profile_details();
    fetch_trading_hrs();
  }, [count]);

  function handleLocation(event) {
    setLocation(event.target.value);
  }

  function submit(e) {
    e.preventDefault();

    const accountid = localStorage.getItem("accountid");
    const formData = new FormData();
    formData.append("sunday_from", sunday_from);
    formData.append("sunday_to", sunday_to);

    formData.append("monday_from", monday_from);
    formData.append("monday_to", monday_to);

    formData.append("tuesday_from", tuesday_from);
    formData.append("tuesday_to", tuesday_to);

    formData.append("wednesday_from", wednesday_from);
    formData.append("wednesday_to", wednesday_to);

    formData.append("thursday_from", thursday_from);
    formData.append("thursday_to", thursday_to);

    formData.append("friday_from", friday_from);
    formData.append("friday_to", friday_to);

    formData.append("friday_from", friday_from);
    formData.append("friday_to", friday_to);

    formData.append("saturday_from", saturday_from);
    formData.append("saturday_to", saturday_to);

    formData.append("holiday_from", holiday_from);
    formData.append("holiday_to", holiday_to);

    formData.append("accountid", accountid);
    formData.append("query", "TRADING-HOURS");

    setBtnColor("disabled-btn");
    setDisabled("disabled");
    setLoadSpinner(true);
    setSaveLabel(false);
    // connect to the api

    axios({
      method: "POST",
      url: baseURL,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    }).then((response) => {
      setBtnColor("action-btn");
      setDisabled("");
      setLoadSpinner(false);
      setSaveLabel(true);

      if (response.data.status == "error") {
        setBgColor("red");
        setDisplayMessage(true);
        setErrorMsg(response.data.message);
      } else {
        setCount(count + 1);
        setBgColor("#66b266");
        setDisplayMessage(true);
        setErrorMsg(response.data.message);
      }
    });

    setTimeout(() => {
      setDisplayMessage(false);
    }, 5000);
  }

  function view_trading_hrs() {
    setShow(true);
  }

  function close() {
    setShow(false);
  }

  function trading_hours() {
    return (
      <>
        {tradingHours.length <= 0 ? (
          <label className="color-grey font-size-17 font-medium w-100 text-center">
            There are no existing trading hours
          </label>
        ) : (
          tradingHours.map((item, index) => {
            return (
              <Row key={index}>
                <Col lg={4} className="text-left font-medium">
                  {item.day}
                </Col>
                <Col lg={8} className="text-left color-grey">
                  {item.from}-{item.to}
                </Col>
              </Row>
            );
          })
        )}
      </>
    );
  }
  return (
    <div className="body">
      <Authentication />
      <Container className="container-home">
        <Row>
          <Col lg={2} xs={2} md={3} className="p-0">
            <SideNav />
          </Col>
          <Col lg={10} xs={10} md={9} className={"middle-container"}>
            <Row className="h-100 align-items-center justify-content-center">
              <form onSubmit={submit}>
                <Row className="h-100 align-items-center justify-content-center">
                  <Col lg={9}>
                    <Stack>
                      <div className="box-container mt-3 mb-3">
                        <Row className="align-items-center">
                          <Col lg={12}>
                            <label className="font-medium w-100">
                              Add trading hours{" "}
                              <span
                                className="color-blue font-medium float-right cursor"
                                onClick={view_trading_hrs}
                              >
                                View trading hours
                              </span>
                            </label>
                          </Col>

                          <Col lg={12}>
                            <div className="h-divider" />
                          </Col>
                          <Col lg={6} sm={6} className="mb-3">
                            <label className="font-medium">Sunday</label>
                          </Col>
                          <Col lg={3} sm={3} className="mb-3">
                            <label className="font-medium w-100">
                              From{" "}
                              <span className="float-right font-normal color-grey">
                                am
                              </span>
                            </label>
                            <br />
                            <input
                              type="time"
                              name="email"
                              placeholder="Email address"
                              onChange={(e) => setsunday_from(e.target.value)}
                              value={sunday_from}
                            />
                          </Col>

                          <Col lg={3} sm={3} className="mb-3">
                            <label className="font-medium w-100">
                              To{" "}
                              <span className="float-right font-normal color-grey">
                                pm
                              </span>
                            </label>
                            <br />
                            <input
                              type="time"
                              name="email"
                              placeholder="Email address"
                              onChange={(e) => setsunday_to(e.target.value)}
                              value={sunday_to}
                            />
                          </Col>

                          <Col lg={6} sm={6} className="mb-3">
                            <label className="font-medium">Monday</label>
                          </Col>
                          <Col lg={3} sm={3} className="mb-3">
                            <label className="font-medium w-100">
                              From{" "}
                              <span className="float-right font-normal color-grey">
                                am
                              </span>
                            </label>
                            <br />
                            <input
                              type="time"
                              name="email"
                              placeholder="Email address"
                              onChange={(e) => setmonday_from(e.target.value)}
                              value={monday_from}
                            />
                          </Col>

                          <Col lg={3} sm={3} className="mb-3">
                            <label className="font-medium w-100">
                              To{" "}
                              <span className="float-right font-normal color-grey">
                                pm
                              </span>
                            </label>
                            <br />
                            <input
                              type="time"
                              name="email"
                              placeholder="Email address"
                              onChange={(e) => setmonday_to(e.target.value)}
                              value={monday_to}
                            />
                          </Col>

                          <Col lg={6} sm={6} className="mb-3">
                            <label className="font-medium">Tuesday</label>
                          </Col>
                          <Col lg={3} sm={3} className="mb-3">
                            <label className="font-medium w-100">
                              From{" "}
                              <span className="float-right font-normal color-grey">
                                am
                              </span>
                            </label>
                            <br />
                            <input
                              type="time"
                              name="email"
                              placeholder="Email address"
                              onChange={(e) => settuesday_from(e.target.value)}
                              value={tuesday_from}
                            />
                          </Col>

                          <Col lg={3} sm={3} className="mb-3">
                            <label className="font-medium w-100">
                              To{" "}
                              <span className="float-right font-normal color-grey">
                                pm
                              </span>
                            </label>
                            <br />
                            <input
                              type="time"
                              name="email"
                              placeholder="Email address"
                              onChange={(e) => settuesday_to(e.target.value)}
                              value={tuesday_to}
                            />
                          </Col>

                          <Col lg={6} sm={6} className="mb-3">
                            <label className="font-medium">Wednesday</label>
                          </Col>
                          <Col lg={3} sm={3} className="mb-3">
                            <label className="font-medium w-100">
                              From{" "}
                              <span className="float-right font-normal color-grey">
                                am
                              </span>
                            </label>
                            <br />
                            <input
                              type="time"
                              name="email"
                              placeholder="Email address"
                              onChange={(e) =>
                                setwednesday_from(e.target.value)
                              }
                              value={wednesday_from}
                            />
                          </Col>

                          <Col lg={3} sm={3} className="mb-3">
                            <label className="font-medium w-100">
                              To{" "}
                              <span className="float-right font-normal color-grey">
                                pm
                              </span>
                            </label>
                            <br />
                            <input
                              type="time"
                              name="email"
                              placeholder="Email address"
                              onChange={(e) => setwednesday_to(e.target.value)}
                              value={wednesday_to}
                            />
                          </Col>

                          <Col lg={6} sm={6} className="mb-3">
                            <label className="font-medium">Thurday</label>
                          </Col>
                          <Col lg={3} sm={3} className="mb-3">
                            <label className="font-medium w-100">
                              From{" "}
                              <span className="float-right font-normal color-grey">
                                am
                              </span>
                            </label>
                            <br />
                            <input
                              type="time"
                              name="email"
                              placeholder="Email address"
                              onChange={(e) => setthursday_from(e.target.value)}
                              value={thursday_from}
                            />
                          </Col>

                          <Col lg={3} sm={3} className="mb-3">
                            <label className="font-medium w-100">
                              To{" "}
                              <span className="float-right font-normal color-grey">
                                pm
                              </span>
                            </label>
                            <br />
                            <input
                              type="time"
                              name="email"
                              placeholder="Email address"
                              onChange={(e) => setthursday_to(e.target.value)}
                              value={thursday_to}
                            />
                          </Col>

                          <Col lg={6} sm={6} className="mb-3">
                            <label className="font-medium">Friday</label>
                          </Col>
                          <Col lg={3} sm={3} className="mb-3">
                            <label className="font-medium w-100">
                              From{" "}
                              <span className="float-right font-normal color-grey">
                                am
                              </span>
                            </label>
                            <br />
                            <input
                              type="time"
                              name="email"
                              placeholder="Email address"
                              onChange={(e) => setfriday_from(e.target.value)}
                              value={friday_from}
                            />
                          </Col>

                          <Col lg={3} sm={3} className="mb-3">
                            <label className="font-medium w-100">
                              To{" "}
                              <span className="float-right font-normal color-grey">
                                pm
                              </span>
                            </label>
                            <br />
                            <input
                              type="time"
                              name="email"
                              placeholder="Email address"
                              onChange={(e) => setfriday_to(e.target.value)}
                              value={friday_to}
                            />
                          </Col>

                          <Col lg={6} sm={6} className="mb-3">
                            <label className="font-medium">Saturday</label>
                          </Col>
                          <Col lg={3} sm={3} className="mb-3">
                            <label className="font-medium w-100">
                              From{" "}
                              <span className="float-right font-normal color-grey">
                                am
                              </span>
                            </label>
                            <br />
                            <input
                              type="time"
                              name="email"
                              placeholder="Email address"
                              onChange={(e) => setsaturday_from(e.target.value)}
                              value={saturday_from}
                            />
                          </Col>

                          <Col lg={3} sm={3} className="mb-3">
                            <label className="font-medium w-100">
                              To{" "}
                              <span className="float-right font-normal color-grey">
                                pm
                              </span>
                            </label>
                            <br />
                            <input
                              type="time"
                              name="email"
                              placeholder="Email address"
                              onChange={(e) => setsaturday_to(e.target.value)}
                              value={saturday_to}
                            />
                          </Col>

                          <Col lg={6} sm={6} className="mb-3">
                            <label className="font-medium color-red">
                              Holidays
                            </label>
                          </Col>
                          <Col lg={3} sm={3} className="mb-3">
                            <label className="font-medium w-100">
                              From{" "}
                              <span className="float-right font-normal color-grey">
                                am
                              </span>
                            </label>
                            <br />
                            <input
                              type="time"
                              name="email"
                              placeholder="Email address"
                              onChange={(e) => setholiday_from(e.target.value)}
                              value={holiday_from}
                            />
                          </Col>

                          <Col lg={3} sm={3} className="mb-3">
                            <label className="font-medium w-100">
                              To{" "}
                              <span className="float-right font-normal color-grey">
                                pm
                              </span>
                            </label>
                            <br />
                            <input
                              type="time"
                              name="email"
                              placeholder="Email address"
                              onChange={(e) => setholiday_to(e.target.value)}
                              value={holiday_to}
                            />
                          </Col>
                          <Col lg={12} className="mb-3">
                            <label className="color-grey">
                              <span className="font-medium color-orange">
                                Please note:
                              </span>{" "}
                              Unless you have saved these trading hours
                              previously, any day that has no existing trading
                              hours will be marked as closed.
                              <br />
                              <br />
                              <span className="color-red font-medium">
                                You may not set trading hours for holidays if
                                you going to be closed.
                              </span>
                            </label>
                          </Col>

                          <Col lg={12} className="">
                            <button
                              type="submit"
                              className={btnColor}
                              disabled={disabled}
                            >
                              {saveLabel && (
                                <span className="font-medium">Save</span>
                              )}
                              {loadSpinner && <BeatLoader size={10} />}
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </Stack>
                  </Col>
                </Row>
              </form>
            </Row>
          </Col>
        </Row>

        <Modal
          show={show}
          onHide={close}
          centered
          backdrop={true}
          className="modal-z-index"
        >
          <Modal.Header closeButton className="hide-modal-border">
            <Modal.Title className="color-black font-medium">
              Trading hours
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">{trading_hours()}</Modal.Body>
        </Modal>
      </Container>
      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}
