import React, { useState, useEffect } from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import SideNav from "./navigation/SideNav";
import Select from "react-select";
import { Link, NavLink } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
export default function AddDrinks() {
  const [sizes, setSizes] = useState("");
  const [category, setCategory] = useState("");
  const [dayTime, setDayTime] = useState("");
  const [drink, setDrink] = useState("");
  const [item, setItem] = useState("");
  const [price, setPrice] = useState(null);
  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [btnColor, setBtnColor] = useState("action-btn");
  const [disabled, setDisabled] = useState("");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);

  function handleChange(e) {
    setSizes(e.value);
  }
  function handleCategory(e) {
    setCategory(e.value);
  }

  function handleDay_time(e) {
    setDayTime(e.value);
  }
  function handleDrink(e) {
    setDrink(e.value);
  }

  const drinks = [
    { label: "Whisky", value: "whisky" },
    { label: "Wine", value: "wine" },
    { label: "Spirits", value: "spirits" },
    { label: "Ciders", value: "ciders" },
    { label: "Beer", value: "beer" },
    { label: "Mixers", value: "mixers" },
    { label: "Non-Alcohol", value: "non-alcohol" },
  ];

  const day_time = [
    { label: "Snack", value: "snack" },
    { label: "Breakfast", value: "breakfast" },
    { label: "Brunch", value: "brunch" },
    { label: "Lunch", value: "lunch" },
    { label: "Dinner", value: "dinner" },
    {
      label: "Breakfast, Brunch or Lunch",
      value: "breakfast, brunch or lunch",
    },
    { label: "Brunch or Lunch", value: "brunch or lunch" },
    { label: "Lunch or Dinner", value: "lunch or dinner" },
  ];

  const categories = [
    { label: "Solid", value: "solid" },
    { label: "Soft", value: "soft" },
    { label: "Drink", value: "drink" },
  ];
  const data = [
    { label: "Normal", value: "normal" },
    { label: "Small", value: "small" },
    { label: "Medium", value: "medium" },
    { label: "Large", value: "large" },
    { label: "Extra large", value: "extra large" },
  ];

  function submit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("item", item);
    formData.append("price", price);
    formData.append("size", sizes);

    if (item === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter item name");
    } else if (price === null) {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter item price");
    } else if (sizes === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter item size");
    } else {
      // connect to the api
      setBtnColor("disabled-btn");
      setDisabled("disabled");
      setLoadSpinner(true);
      setSaveLabel(false);

      setBgColor("#66b266");
      setDisplayMessage(true);
      setErrorMsg("Item successfully added");
    }
    setTimeout(() => {
      setDisplayMessage(false);
    }, 5000);
  }
  return (
    <div className="body">
      <Container className="container-home">
        <Row>
          <Col lg={2} xs={2} md={3} className="p-0">
            <SideNav />
          </Col>
          <Col lg={10} xs={10} md={9}>
            <Row className="h-100 align-items-center justify-content-center">
              <form onSubmit={submit}>
                <Row className="h-100 align-items-center justify-content-center">
                  <Col lg={9}>
                    <Stack>
                      <div className="box-container">
                        <Row>
                          <Col lg={6} sm={6} xs={6} md={6}>
                            <label className="text-medium">Add item</label>
                          </Col>
                          <Col
                            lg={6}
                            sm={6}
                            xs={6}
                            md={6}
                            className={"d-none d-md-block"}
                          >
                            <label className="text-medium w-100">
                              Select media{" "}
                              <NavLink to="/media">
                                {" "}
                                <label className="text-blue float-right text-normal text-hover-underline">
                                  Upload
                                </label>
                              </NavLink>
                            </label>
                          </Col>
                          <Col lg={12}>
                            <div className="h-divider" />
                          </Col>
                          <Col lg={6} sm={12} md={6}>
                            <Col lg={12} className="mb-3">
                              <input
                                type="text"
                                name="item"
                                placeholder="Item name"
                                onChange={(e) => setItem(e.target.value)}
                                value={item}
                              />
                            </Col>
                            <Col lg={12} className="mb-3">
                              <input
                                type="text"
                                name="price"
                                placeholder="Set item price"
                                onChange={(e) => setPrice(e.target.value)}
                                value={price}
                              />
                            </Col>

                            <Col lg={12}>
                              <Select
                                value={data.find((obj) => obj.value === sizes)}
                                placeholder="Select item size"
                                options={data}
                                onChange={handleChange}
                                width={"100%"}
                                className="text-left mt-2 mb-3 select"
                              />
                            </Col>
                            <Col lg={12}>
                              <Select
                                value={data.find(
                                  (obj) => obj.value === category
                                )}
                                placeholder="Select food type"
                                options={categories}
                                onChange={handleCategory}
                                width={"100%"}
                                className="text-left mt-2 mb-3 select"
                              />
                            </Col>
                            <Col lg={12}>
                              <Select
                                value={data.find(
                                  (obj) => obj.value === dayTime
                                )}
                                placeholder="Is this food breakfast, lunch or...?"
                                options={day_time}
                                onChange={handleDay_time}
                                width={"100%"}
                                className="text-left mt-2 mb-3 select"
                              />
                            </Col>

                            <Col lg={12}>
                              <Select
                                value={data.find((obj) => obj.value === drink)}
                                placeholder="Choose category"
                                options={drinks}
                                onChange={handleDrink}
                                width={"100%"}
                                className="text-left mt-2 mb-3 select"
                              />
                            </Col>
                          </Col>

                          <Col
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            className="mb-2 mt-3 d-block d-md-none"
                          >
                            <label className="text-medium w-100">
                              Select media{" "}
                              <NavLink to="/media">
                                {" "}
                                <label className="text-blue float-right text-normal text-hover-underline">
                                  Upload
                                </label>
                              </NavLink>
                            </label>
                          </Col>

                          <Col lg={6} sm={12} md={6}>
                            <Col lg={12} className="mb-3">
                              <input
                                type="text"
                                name="item"
                                placeholder="Item name"
                              />
                            </Col>
                            <Col lg={12} className="mb-3">
                              <input
                                type="text"
                                name="price"
                                placeholder="Set item price"
                              />
                            </Col>

                            <Col lg={12}>
                              <Select
                                value={data.find((obj) => obj.value === sizes)}
                                placeholder="Select item size"
                                options={data}
                                onChange={handleChange}
                                width={"100%"}
                                className="text-left mt-2 mb-3 select"
                              />
                            </Col>
                          </Col>
                          <Col lg={12} sm={6} className="">
                            <button
                              type="submit"
                              className={btnColor}
                              disabled={disabled}
                            >
                              {saveLabel && <span>Add item</span>}
                              {loadSpinner && <BeatLoader size={10} />}
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </Stack>
                  </Col>
                </Row>
              </form>
            </Row>
          </Col>
        </Row>
      </Container>
      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}
