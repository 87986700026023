import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Stack,
  Modal,
  Button,
  Accordion,
} from "react-bootstrap";
import HomeNav from "./navigation/HomeNav";
import SideNav from "./navigation/SideNav";
import { BsPlusCircleDotted } from "react-icons/bs";
import {
  IoIosTrash,
  IoMdPerson,
  IoMdEasel,
  IoIosAdd,
  IoIosCheckmark,
  IoIosClose,
  IoMdCreate,
  IoMdSquareOutline,
  IoMdCheckbox,
} from "react-icons/io";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
const baseURL = process.env.REACT_APP_URL;
const root_url = process.env.REACT_APP_URL_RESOURCE;
const accountid = localStorage.getItem("accountid");
const deviceid = localStorage.getItem("deviceid");
export default function Basket() {
  const navigate = useNavigate();
  const [company_name, setCompany_name] = useState("");
  const [show, setShow] = useState(false);
  const [mediaDetails, setMediaDetails] = useState(false);
  const [error_modal, setError_modal] = useState(false);
  const [disabled, setDisabled] = useState("");
  const [btnColor, setBtnColor] = useState("karamale-login-btn");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [media_count, setMedia_count] = useState(0);
  const [media, setMedia] = useState([]);
  const [selectedMediaID, setSelectedMediaID] = useState(0);
  const [selectedMediaImg, setSelectedMediaImg] = useState(null);
  const [LoadBeat, setLoadBeat] = useState(true);
  const [NoMediaLoging, setNoMediaLoging] = useState(false);
  const [itemID, setItemID] = useState(0);
  const [item, setItem] = useState("");
  const [normal, setNormal] = useState("");
  const [small, setSmall] = useState("");
  const [medium, setMedium] = useState("");
  const [large, setLarge] = useState("");
  const [extraLarge, setExtraLarge] = useState("");
  const [bulk, setBulk] = useState("");
  const [volume, setVolume] = useState("");
  const [unit, setUnit] = useState("");
  const [other_price, setOther_price] = useState("");
  const [item_type, setItem_type] = useState("");
  const [description, setDes] = useState("");
  const [online_availability, setOnline_availability] = useState("");
  const [more, setMore] = useState(true);
  const [less, setLess] = useState(false);
  const [intervals, setIntervals] = useState("");
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");
  const [seeMore, setSeeMore] = useState(false);
  const [UncheckedOne, setUncheckedOne] = useState(true);
  const [CheckedOne, setCheckedOne] = useState(false);
  const [quantityOne, setQuantityOne] = useState(false);

  const [UncheckedTwo, setUncheckedTwo] = useState(true);
  const [CheckedTwo, setCheckedTwo] = useState(false);
  const [quantityTwo, setQuantityTwo] = useState(false);

  const [UncheckedThree, setUncheckedThree] = useState(true);
  const [CheckedThree, setCheckedThree] = useState(false);
  const [quantityThree, setQuantityThree] = useState(false);

  const [UncheckedFour, setUncheckedFour] = useState(true);
  const [CheckedFour, setCheckedFour] = useState(false);
  const [quantityFour, setQuantityFour] = useState(false);

  const [UncheckedFive, setUncheckedFive] = useState(true);
  const [CheckedFive, setCheckedFive] = useState(false);
  const [quantityFive, setQuantityFive] = useState(false);

  const [UncheckedSix, setUncheckedSix] = useState(true);
  const [CheckedSix, setCheckedSix] = useState(false);
  const [quantitySix, setQuantitySix] = useState(false);

  const [quantitesOne, setQuantitesOne] = useState("");
  const [quantitesTwo, setQuantitesTwo] = useState("");
  const [quantitesThree, setQuantitesThree] = useState("");
  const [quantitesFour, setQuantitesFour] = useState("");
  const [quantitesFive, setQuantitesFive] = useState("");
  const [quantitesSix, setQuantitesSix] = useState("");

  const [totalOne, setTotalOne] = useState(0);
  const [totalTwo, setTotalTwo] = useState(0);
  const [totalThree, setTotalThree] = useState(0);
  const [totalFour, setTotalFour] = useState(0);
  const [totalFive, setTotalFive] = useState(0);
  const [totalSix, setTotalSix] = useState(0);
  const [grand_total, setGrand_total] = useState(0);
  const [count, setCount] = useState(0);
  const [total_value, setTotal_value] = useState(0);
  const [account_id, setAccountid] = useState(0);

  const [added_to_basket, setAdded_to_basket] = useState(false);
  const [add_button, setAdd_button] = useState(false);
  const [total, setTotal] = useState(0);
  let customerid = "";
  if (localStorage.getItem("customerid") != null) {
    customerid = localStorage.getItem("customerid");
  } else {
    customerid = -1;
  }

  function total_amount() {
    axios
      .get(
        baseURL +
          "?query=TOTAL_AMOUNT&device_id=" +
          deviceid +
          "&customerid=" +
          customerid
      )
      .then((response) => {
        setTotal(response.data.total);
      });
  }

  function itemCount() {
    axios
      .get(
        baseURL +
          "?query=ITEM-COUNT-BASKET&device_id=" +
          deviceid +
          "&customerid=" +
          customerid
      )
      .then((response) => {
        setMedia_count(response.data.count);
      });
  }

  function fetchItems() {
    // alert(media_count);
    axios
      .get(
        baseURL +
          "?query=ITEMS-BASKET&device_id=" +
          deviceid +
          "&customerid=" +
          customerid
      )
      .then((response) => {
        setMedia(response.data);
        setNoMediaLoging(true);

        setLoadBeat(false);
      });
  }
  useEffect(() => {
    document.title = "Obarak / Basket";
    setGrand_total(
      totalOne + totalTwo + totalThree + totalFour + totalFive + totalSix
    );

    if (
      CheckedOne == false &&
      CheckedTwo == false &&
      CheckedThree == false &&
      CheckedFour == false &&
      CheckedFive == false &&
      CheckedSix == false
    ) {
      setAdd_button(false);
    } else {
      setAdd_button(true);
    }

    // localStorage.getItem("total_value", total_value);

    total_amount();

    const interval_time = setInterval(() => {
      fetchItems();
      itemCount();
      total_amount();
    }, 5000);

    localStorage.removeItem("normal");
    localStorage.removeItem("small");
    localStorage.removeItem("medium");
    localStorage.removeItem("large");
    localStorage.removeItem("extra-large");
    localStorage.removeItem("bulk");
    localStorage.removeItem("other");
    // localStorage.removeItem("foodid");
    localStorage.removeItem("description");

    return () => clearInterval(interval_time);
  }, [count]);

  const handleClose = () => {
    setShow(false);
    setMediaDetails(false);
  };

  const handleShow = () => setShow(true);
  // const selectedMedia = () => setMediaDetails(true);

  function selectMedia(
    media,
    mediaid,
    itemid,
    item_name,
    normal,
    small,
    medium,
    large,
    extra_large,
    bulk,
    item_type,
    description,
    online_availability,
    intervals,
    category,
    type,
    volume,
    unit,
    other_price,
    account_id,
    company_name
  ) {
    // alert(item_type);
    setCompany_name(company_name);
    setAdded_to_basket(false);
    setAdd_button(false);
    setAccountid(account_id);
    setSeeMore(false);
    setMore(true);
    setLess(false);
    setIntervals(intervals);
    setCategory(category);
    setType(type);
    setSelectedMediaID(mediaid);
    setSelectedMediaImg(media);
    setItemID(itemid);
    setItem(item_name);

    setNormal(normal);
    setSmall(small);
    setMedium(medium);
    setLarge(large);
    setExtraLarge(extra_large);
    setBulk(bulk);
    setItem_type(item_type);
    setDes(description);
    setOnline_availability(online_availability);
    setVolume(volume);
    setUnit(unit);
    setOther_price(other_price);

    localStorage.setItem("foodid", itemid);
    localStorage.setItem("food_name", item_name);
    localStorage.setItem("dashboard", "yes");
    setMediaDetails(true);

    setCheckedOne(false);
    setUncheckedOne(true);
    setQuantityOne(false);
    setQuantitesOne("");
    setTotalOne(0);

    setCheckedTwo(false);
    setUncheckedTwo(true);
    setQuantityTwo(false);
    setQuantitesTwo("");
    setTotalTwo(0);

    setCheckedThree(false);
    setUncheckedThree(true);
    setQuantityThree(false);
    setQuantitesThree("");
    setTotalThree(0);

    setCheckedFour(false);
    setUncheckedFour(true);
    setQuantityFour(false);
    setQuantitesFour("");
    setTotalFour(0);

    setCheckedFive(false);
    setUncheckedFive(true);
    setQuantityFive(false);
    setQuantitesFive("");
    setTotalFive(0);

    setCheckedSix(false);
    setUncheckedSix(true);
    setQuantitySix(false);
    setQuantitesSix("");
    setTotalSix(0);

    setGrand_total(0);
  }

  function map_media() {
    let itemid = 0;
    return (
      <Row>
        {" "}
        {media_count == 0 ? (
          <div>
            {NoMediaLoging && (
              <label className="no-media-text color-silver pb-0 mb-0">
                Basket is empty
              </label>
            )}
          </div>
        ) : (
          media.map((item, index) => {
            return (
              <Col lg={12} md={12} sm={12} xs={12} className="mb-3 text-left">
                <Row className="align-items-center">
                  <Col
                    lg={2}
                    xs={4}
                    sm={2}
                    md={2}
                    className="media-container-basket mb-2"
                    style={{
                      backgroundImage: `url(${root_url}/assets/${item.accountid}/${item.media})`,
                    }}
                  ></Col>
                  <Col lg={5} xs={7} md={5} sm={5}>
                    <b>
                      {item.item_name.length > 18
                        ? item.item_name.substring(0, 18) + "..."
                        : item.item_name}
                    </b>
                    <br />
                    <span className="color-grey font-size-12">
                      {item.category == "solid" || item.category == "soft"
                        ? item.category.charAt(0).toUpperCase() +
                          item.category.slice(1) +
                          " food"
                        : "Soft " + item.category}
                      <br />
                      {localStorage.getItem("pos") != null ? (
                        ""
                      ) : (
                        <span>
                          by{" "}
                          <label className="color-blue cursor font-size-12">
                            {item.company_name}
                          </label>
                        </span>
                      )}
                    </span>
                  </Col>
                  <Col
                    lg={2}
                    sm={2}
                    md={2}
                    xs={1}
                    className="font-size-12 text-center basket-cols"
                  >
                    Qty
                    <br />
                    <label className="color-grey font-size-12 w-100 text-center">
                      {item.quantity}
                    </label>
                  </Col>

                  <Col
                    lg={2}
                    sm={2}
                    md={2}
                    xs={2}
                    className="font-size-12 text-center basket-cols"
                  >
                    Total
                    <br />
                    <label className="color-grey font-size-12">
                      R{item.total}
                    </label>
                  </Col>
                  <Col
                    lg={1}
                    sm={1}
                    md={1}
                    xs={1}
                    className="font-size-12 text-center"
                  >
                    <label
                      className="color-red cursor font-size-12"
                      onClick={() => remove(item.orderid)}
                    >
                      <span className="trash-icon">
                        <IoIosTrash size={20} />
                      </span>{" "}
                      <span className="food-kind-label font-size-12">
                        Remove
                      </span>
                    </label>
                  </Col>
                </Row>
              </Col>
            );
          })
        )}
      </Row>
    );
  }

  function add_to_basket_button(e) {
    e.preventDefault();
    const formData = new FormData();
    // const deviceid = localStorage.getItem("deviceid");
    const accountid = account_id;
    formData.append("device_id", localStorage.getItem("deviceid"));
    formData.append("customerid", localStorage.getItem("customerid"));
    formData.append("foodid", itemID);
    formData.append("accountid", accountid);
    formData.append("normal_quantity", quantitesOne);
    formData.append("small_quantity", quantitesTwo);
    formData.append("medium_quantity", quantitesThree);
    formData.append("large_quantity", quantitesFour);
    formData.append("extra_large_quantity", quantitesFive);
    formData.append("bulk_quantity", quantitesSix);
    formData.append("grand_total", grand_total);
    formData.append("query", "ADD_TO_BASKET");

    if (
      CheckedOne == false &&
      CheckedTwo == false &&
      CheckedThree == false &&
      CheckedFour == false &&
      CheckedFive == false &&
      CheckedSix == false
    ) {
      setErrorMsg(
        <label className="color-red">
          <IoIosClose size={35} /> Select at least one size
        </label>
      );
      setAdded_to_basket(true);
    } else {
      setBtnColor("disabled-btn");
      setDisabled("disabled");
      setLoadSpinner(true);
      setSaveLabel(false);

      axios({
        method: "POST",
        url: baseURL,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then((response) => {
        console.log(response.data);
        if (response.data.status == "error") {
          setErrorMsg(
            <label className="color-red">
              <IoIosClose size={35} /> {response.data.message}
            </label>
          );
          setAdded_to_basket(true);
        } else {
          setCount(count + 1);
          setErrorMsg(
            <label className="color-green">
              <IoIosCheckmark size={35} /> {response.data.message}
            </label>
          );
          setAdded_to_basket(true);
          setMediaDetails(false);
          navigate("/basket");
        }

        setBtnColor("karamale-login-btn");
        setDisabled("");
        setLoadSpinner(false);
        setSaveLabel(true);
      });
    }
  }

  function editDetails() {
    localStorage.setItem("item_name", item);
    localStorage.setItem("description", description);
    navigate("/addstock");
  }

  function moreDetails() {
    setMore(false);
    setLess(true);
    setSeeMore(true);
  }
  function lessDetails() {
    setMore(true);
    setLess(false);
    setSeeMore(false);
  }

  function normalPrice() {
    if (CheckedOne == true) {
      setCheckedOne(false);
      setUncheckedOne(true);
      setQuantityOne(false);
      setQuantitesOne("");
      setTotalOne(0);
    } else {
      setCheckedOne(true);
      setUncheckedOne(false);
      setQuantityOne(true);
    }
    setCount(count + 1);
  }

  function smallPrice() {
    if (CheckedTwo == true) {
      setCheckedTwo(false);
      setUncheckedTwo(true);
      setQuantityTwo(false);
      setQuantitesTwo("");
      setTotalTwo(0);
    } else {
      setCheckedTwo(true);
      setUncheckedTwo(false);
      setQuantityTwo(true);
    }
    setCount(count + 1);
  }

  function mediumPrice() {
    if (CheckedThree == true) {
      setCheckedThree(false);
      setUncheckedThree(true);
      setQuantityThree(false);
      setQuantitesThree("");
      setTotalThree(0);
    } else {
      setCheckedThree(true);
      setUncheckedThree(false);
      setQuantityThree(true);
    }
    setCount(count + 1);
  }

  function largePrice() {
    if (CheckedFour == true) {
      setCheckedFour(false);
      setUncheckedFour(true);
      setQuantityFour(false);
      setQuantitesFour("");
      setTotalFour(0);
    } else {
      setCheckedFour(true);
      setUncheckedFour(false);
      setQuantityFour(true);
    }
    setCount(count + 1);
  }

  function xlargePrice() {
    if (CheckedFive == true) {
      setCheckedFive(false);
      setUncheckedFive(true);
      setQuantityFive(false);
      setQuantitesFive("");
      setTotalFive(0);
    } else {
      setCheckedFive(true);
      setUncheckedFive(false);
      setQuantityFive(true);
    }
    setCount(count + 1);
  }

  function bulkPrice() {
    if (CheckedSix == true) {
      setCheckedSix(false);
      setUncheckedSix(true);
      setQuantitySix(false);
      setQuantitesSix("");
      setTotalSix(0);
    } else {
      setCheckedSix(true);
      setUncheckedSix(false);
      setQuantitySix(true);
    }
    setCount(count + 1);
  }

  function f_quantityOne(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesOne(e.target.value);
      let price = 0;
      const price_one = other_price;
      const price_two = normal;
      if (price_one == "" || price_one == 0) {
        price = normal;
      } else {
        price = other_price;
      }
      setTotalOne(e.target.value * price);
      setCount(count + 1);
    }
  }

  function f_quantityTwo(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesTwo(e.target.value);
      setTotalTwo(e.target.value * small);
      setCount(count + 1);
    }
  }

  function f_quantityThree(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesThree(e.target.value);
      setTotalThree(e.target.value * medium);
      setCount(count + 1);
    }
  }

  function f_quantityFour(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesFour(e.target.value);
      setTotalFour(e.target.value * large);
      setCount(count + 1);
    }
  }

  function f_quantityFive(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesFive(e.target.value);
      setTotalFive(e.target.value * extraLarge);
      setCount(count + 1);
    }
  }

  function f_quantitySix(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesSix(e.target.value);
      setTotalSix(e.target.value * bulk);
      setCount(count + 1);
    }
  }

  function setAlert() {
    setError_modal(true);
    setMediaDetails(false);
  }
  function closeError() {
    setError_modal(false);
    setMediaDetails(true);
  }

  function empty_basket() {
    axios
      .get(
        baseURL +
          "?query=REMOVE_FROM_BASKET&device_id=" +
          deviceid +
          "&action=EMPTY_BASKET&customerid=" +
          customerid
      )
      .then((response) => {});
    setCount(count + 1);
    navigate("/basket");
  }
  function remove(orderid) {
    axios
      .get(
        baseURL +
          "?query=REMOVE_FROM_BASKET&device_id=" +
          deviceid +
          "&orderid=" +
          orderid +
          "&action=REMOVE_ITEM&customerid=" +
          customerid
      )
      .then((response) => {});
    setCount(count + 1);
    navigate("/basket");
  }
  return (
    <div className="body">
      <Container className="container-home">
        <Row>
          <Col lg={2} xs={2} md={3} className="p-0">
            {localStorage.getItem("pos") == 1 &&
            localStorage.getItem("accountid") != null ? (
              <SideNav />
            ) : (
              <HomeNav />
            )}
          </Col>
          <Col lg={10} xs={10} md={9} className={"middle-container"}>
            <Row className="h-100 align-items-center justify-content-center">
              <Col lg={7}>
                {localStorage.getItem("updated") != null ? (
                  <div>
                    <h6>
                      <label className="color-green">
                        <IoIosCheckmark size={30} /> Media for{" "}
                        {localStorage.getItem("food_name")} successfully updated
                      </label>
                    </h6>
                  </div>
                ) : (
                  ""
                )}
                <Stack>
                  <div className="box-container mt-3 mb-3 p-4 pb-0 pt-3">
                    <Row>
                      <Col lg={12} className="p-0">
                        <label className="float-left font-size-12 font-medium">
                          Basket
                        </label>
                        {media_count == 0 ? (
                          ""
                        ) : (
                          <label
                            className="float-right font-size-12 color-red cursor"
                            onClick={empty_basket}
                          >
                            <IoIosTrash size={15} /> Empty basket
                          </label>
                        )}
                      </Col>
                      <Col lg={12} className="p-0">
                        <div className="h-divider" />
                      </Col>
                      <Col lg={12} className="text-center">
                        {map_media()}
                        {LoadBeat && <BeatLoader color={"#39c"} />}
                      </Col>

                      <Col lg={12} className="p-0 mb-3 text-right total_amount">
                        {media_count == 0 ? (
                          ""
                        ) : (
                          <label className="font-size-12">
                            <b>Total amount: </b>
                            <span className="font-size-12 color-grey">
                              R{total}
                            </span>
                          </label>
                        )}
                      </Col>

                      <Col lg={12} className="p-0 mb-3 text-right">
                        {media_count == 0 ? (
                          ""
                        ) : (
                          <Row>
                            <Col
                              lg={0}
                              sm={0}
                              xs={6}
                              className="browse-right-padding mobile-browse-btn"
                            >
                              <Link to="/">
                                <label className="karamale-login-btn browse-w-100 font-medium">
                                  Add more
                                </label>
                              </Link>
                            </Col>
                            <Col
                              lg={12}
                              xs={6}
                              sm={12}
                              className="browse-left-padding"
                            >
                              <label className="desktop-browse-btn">
                                <Link to="/">
                                  <label className="karamale-login-btn browse-w-100 font-medium">
                                    Add more items
                                  </label>
                                </Link>
                                &nbsp;&nbsp;&nbsp;
                              </label>
                              <Link
                                to={
                                  localStorage.getItem("pos") == null &&
                                  localStorage.getItem("customerid") == null
                                    ? "/checkout"
                                    : "/continue"
                                }
                              >
                                <label className="cancel-btn browse-w-100 color-light-black font-medium">
                                  {localStorage.getItem("pos") == null &&
                                  localStorage.getItem("customerid") == null
                                    ? "Summary"
                                    : "Continue"}
                                </label>
                              </Link>
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Stack>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Modal
        show={error_modal}
        onHide={closeError}
        centered
        className="modal-z-index"
      >
        <Modal.Header closeButton className="hide-modal-border">
          <Modal.Title className="color-orange font-normal">
            {" "}
            Price not available for the selected size.
          </Modal.Title>
        </Modal.Header>
      </Modal>

      <Modal show={mediaDetails} onHide={handleClose}>
        <form onSubmit={add_to_basket_button}>
          <Modal.Header closeButton>
            <Modal.Title>
              {item} <br />
              <label className="color-grey font-normal font-size-12">
                by {company_name}
              </label>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-center align-items-center">
              <img
                className="img-border-radius w-100"
                src={
                  root_url + "/assets/" + account_id + "/" + selectedMediaImg
                }
              />
              <br />

              <Col lg={12}>
                <label className="font-size-12 color-grey mt-3">
                  <b>From customers:</b>
                  <br /> Nice meal, really enjoyed it...
                </label>
              </Col>
              <Col lg={12} className="mt-3">
                <h6>Prices</h6>
              </Col>
              <Col lg={4} xs={4} sm={4} className="font-size-12 main-price-box">
                <div className="price-boxes">
                  <div
                    onClick={
                      other_price == "" && normal == "" ? setAlert : normalPrice
                    }
                  >
                    <b>
                      {category == "drink" && normal == "" ? (
                        <label>
                          {volume}
                          {unit == "litre" ? "L" : unit == "" ? "--" : unit}
                        </label>
                      ) : (
                        <label className="font-size-12">
                          {type == "normal coffee" || type == "tea"
                            ? "Cup"
                            : type == "shot" || type == "tequila shot"
                            ? "Shot"
                            : type == "wine" || type == "whisky"
                            ? "Glass"
                            : "Normal"}{" "}
                        </label>
                      )}
                    </b>
                    <br />
                    {normal == "" ? (
                      <label className="color-grey font-size-12">
                        {category == "drink" && unit == "not set"
                          ? ""
                          : category == "drink" && unit != ""
                          ? "R" + other_price
                          : "Not set"}
                      </label>
                    ) : (
                      <label className="color-grey font-size-12">
                        {category == "drink" && normal == "" ? (
                          <label className="font-size-12">R{other_price}</label>
                        ) : (
                          <label className="font-size-12">R{normal}</label>
                        )}
                      </label>
                    )}
                    <br />
                    {UncheckedOne && (
                      <IoMdSquareOutline
                        size={25}
                        className="color-light-grey"
                      />
                    )}
                    {CheckedOne && (
                      <IoMdCheckbox size={25} className="color-blue" />
                    )}
                  </div>
                  {quantityOne && (
                    <input
                      type="text"
                      placeholder="Quantity"
                      className="price-inputs"
                      required
                      onChange={(e) => f_quantityOne(e)}
                      value={quantitesOne}
                    />
                  )}
                </div>
                <label className="w-100 text-center font-size-12 mt-2">
                  Total:{" "}
                  <span className="font-size-12 color-grey">{totalOne}</span>
                </label>
              </Col>
              <Col lg={4} xs={4} sm={4} className="font-size-12 main-price-box">
                <div className="price-boxes">
                  <div onClick={small == "" ? setAlert : smallPrice}>
                    <b className="font-size-12">
                      {category == "drink" ? "1.5oz" : "Small"}
                    </b>
                    <br />
                    {small == "" ? (
                      <label className="color-grey font-size-12">Not set</label>
                    ) : (
                      <label className="color-grey font-size-12">
                        R{small}
                      </label>
                    )}
                    <br />
                    {UncheckedTwo && (
                      <IoMdSquareOutline
                        size={25}
                        className="color-light-grey"
                      />
                    )}
                    {CheckedTwo && (
                      <IoMdCheckbox size={25} className="color-blue" />
                    )}
                  </div>
                  {quantityTwo && (
                    <input
                      type="text"
                      placeholder="Quantity"
                      className="price-inputs"
                      required
                      onChange={(e) => f_quantityTwo(e)}
                      value={quantitesTwo}
                    />
                  )}
                </div>
                <label className="w-100 text-center font-size-12 mt-2">
                  Total:{" "}
                  <span className="font-size-12 color-grey">{totalTwo}</span>
                </label>
              </Col>
              <Col lg={4} xs={4} sm={4} className="font-size-12 main-price-box">
                <div className="price-boxes">
                  <div onClick={medium == "" ? setAlert : mediumPrice}>
                    <b className="font-size-12">
                      {category == "drink" ? "500ml" : "Medium"}
                    </b>
                    <br />
                    {medium == "" ? (
                      <label className="color-grey font-size-12">Not set</label>
                    ) : (
                      <label className="color-grey font-size-12">
                        R{medium}
                      </label>
                    )}
                    <br />
                    {UncheckedThree && (
                      <IoMdSquareOutline
                        size={25}
                        className="color-light-grey"
                      />
                    )}
                    {CheckedThree && (
                      <IoMdCheckbox size={25} className="color-blue" />
                    )}
                  </div>
                  {quantityThree && (
                    <input
                      type="text"
                      placeholder="Quantity"
                      className="price-inputs"
                      required
                      onChange={(e) => f_quantityThree(e)}
                      value={quantitesThree}
                    />
                  )}
                </div>
                <label className="w-100 text-center font-size-12 mt-2">
                  Total:{" "}
                  <span className="font-size-12 color-grey">{totalThree}</span>
                </label>
              </Col>
              <Col lg={4} xs={4} sm={4} className="font-size-12 main-price-box">
                <div className="price-boxes">
                  <div onClick={large == "" ? setAlert : largePrice}>
                    <b className="font-size-12">
                      {category == "drink" ? "750ml" : "Large"}
                    </b>
                    <br />
                    {large == "" ? (
                      <label className="color-grey font-size-12">Not set</label>
                    ) : (
                      <label className="color-grey font-size-12">
                        R{large}
                      </label>
                    )}
                    <br />
                    {UncheckedFour && (
                      <IoMdSquareOutline
                        size={25}
                        className="color-light-grey"
                      />
                    )}
                    {CheckedFour && (
                      <IoMdCheckbox size={25} className="color-blue" />
                    )}
                  </div>
                  {quantityFour && (
                    <input
                      type="text"
                      placeholder="Quantity"
                      className="price-inputs"
                      required
                      onChange={(e) => f_quantityFour(e)}
                      value={quantitesFour}
                    />
                  )}
                </div>
                <label className="w-100 text-center font-size-12 mt-2">
                  Total:{" "}
                  <span className="font-size-12 color-grey">{totalFour}</span>
                </label>
              </Col>
              <Col lg={4} xs={4} sm={4} className="font-size-12 main-price-box">
                <div className="price-boxes">
                  <div onClick={extraLarge == "" ? setAlert : xlargePrice}>
                    <b className="font-size-12">
                      {category == "drink" ? "1L" : "xLarge"}
                    </b>
                    <br />
                    {extraLarge == "" ? (
                      <label className="color-grey font-size-12">Not set</label>
                    ) : (
                      <label className="color-grey font-size-12">
                        R {extraLarge}
                      </label>
                    )}
                    <br />
                    {UncheckedFive && (
                      <IoMdSquareOutline
                        size={25}
                        className="color-light-grey"
                      />
                    )}
                    {CheckedFive && (
                      <IoMdCheckbox size={25} className="color-blue" />
                    )}
                  </div>
                  {quantityFive && (
                    <input
                      type="text"
                      placeholder="Quantity"
                      className="price-inputs"
                      required
                      onChange={(e) => f_quantityFive(e)}
                      value={quantitesFive}
                    />
                  )}
                </div>
                <label className="w-100 text-center font-size-12 mt-2">
                  Total:{" "}
                  <span className="font-size-12 color-grey">{totalFive}</span>
                </label>
              </Col>
              <Col lg={4} xs={4} sm={4} className="font-size-12 main-price-box">
                <div className="price-boxes">
                  <div onClick={bulk == "" ? setAlert : bulkPrice}>
                    <b className="font-size-12">
                      {category == "drink" ? "2L" : "Bulk"}
                    </b>
                    <br />
                    {bulk == "" ? (
                      <label className="color-grey font-size-12">Not set</label>
                    ) : (
                      <label className="color-grey font-size-12">R{bulk}</label>
                    )}
                    <br />
                    {UncheckedSix && (
                      <IoMdSquareOutline
                        size={25}
                        className="color-light-grey"
                      />
                    )}
                    {CheckedSix && (
                      <IoMdCheckbox size={25} className="color-blue" />
                    )}
                  </div>
                  {quantitySix && (
                    <input
                      type="text"
                      placeholder="Quantity"
                      className="price-inputs"
                      required
                      onChange={(e) => f_quantitySix(e)}
                      value={quantitesSix}
                    />
                  )}
                </div>
                <label className="w-100 text-center font-size-12 mt-2">
                  Total:{" "}
                  <span className="font-size-12 color-grey">{totalSix}</span>
                </label>
              </Col>

              {seeMore && (
                <Row>
                  <Col
                    lg={12}
                    className="font-size-17 mb-0 mt-3 color-grey p-0"
                  >
                    <b>Description</b>
                    <br />{" "}
                    {description == "" || description == "null"
                      ? "Not available"
                      : description}
                    <br />
                    <br />
                    <b>Consumable:</b> {intervals}
                    <br />
                    <b>Food kind:</b> {category}
                    <br />
                    <b>Type:</b> {type}
                  </Col>
                </Row>
              )}
              <Col lg={12} className="font-size-12 mb-0 mt-2">
                {more && (
                  <label
                    className="color-blue text-underline-on-hover"
                    onClick={moreDetails}
                  >
                    See more details...
                  </label>
                )}
                {less && (
                  <label
                    className="color-blue text-underline-on-hover"
                    onClick={lessDetails}
                  >
                    See less details...
                  </label>
                )}
              </Col>
              {add_button && (
                <Row>
                  <Col
                    lg={6}
                    xs={6}
                    sm={6}
                    md={6}
                    className="mt-3 text-left p-0"
                  >
                    <label className="margin-right-5">
                      <b>Total:</b>{" "}
                      <span className="color-grey">
                        R{parseFloat(grand_total).toFixed(2)}
                      </span>
                    </label>
                  </Col>

                  <Col
                    lg={6}
                    xs={6}
                    sm={6}
                    md={6}
                    className="mt-3 text-right p-0"
                  >
                    <button
                      type={"submit"}
                      className={btnColor + " font-medium"}
                      disabled={disabled}
                    >
                      {saveLabel && <span>Add to basket</span>}
                      {loadSpinner && <BeatLoader size={10} />}
                    </button>
                  </Col>
                </Row>
              )}
            </Row>
          </Modal.Body>
          {added_to_basket && (
            <Modal.Footer>
              <label className="w-100 text-center color-green">
                {errorMsg}
              </label>
              {/* <label className="cancel-btn text-bold" onClick={handleClose}>
            Close
          </label> */}
            </Modal.Footer>
          )}
        </form>
      </Modal>

      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}
localStorage.removeItem("updated");
