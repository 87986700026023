import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Stack,
  Modal,
  Button,
  Accordion,
} from "react-bootstrap";
import HomeNav from "./navigation/HomeNav";
import SideNav from "./navigation/SideNav";
import { BsFillForwardFill, BsArrowRight } from "react-icons/bs";
import {
  IoIosTrash,
  IoMdPerson,
  IoMdEasel,
  IoIosAdd,
  IoIosCheckmark,
  IoIosClose,
  IoMdCreate,
  IoMdSquareOutline,
  IoMdCheckbox,
  IoIosArrowRoundForward,
  IoMdRadioButtonOff,
  IoMdRadioButtonOn,
  IoIosCheckmarkCircleOutline,
  IoIosCloseCircleOutline,
  IoIosRadioButtonOff,
  IoIosRadioButtonOn,
} from "react-icons/io";
import {
  IoArrowForwardOutline,
  IoShieldCheckmarkOutline,
} from "react-icons/io5";
import { FaRegHandPointRight } from "react-icons/fa";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import validator from "validator";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import "react-phone-input-2/lib/style.css";
const baseURL = process.env.REACT_APP_URL;
const root_url = process.env.REACT_APP_URL_RESOURCE;
const accountid = localStorage.getItem("accountid");
const deviceid = localStorage.getItem("deviceid");
export default function Continue() {
  const [transaction_aborted, setTransaction_aborted] = useState(false);
  const [token, setToken] = useState(null);
  const [customer, setCustomer] = useState("");
  const [onShow, setonShow] = useState(false);
  const [msg, setMsg] = useState("");
  const [error, setError] = useState(false);

  const navigate = useNavigate();
  const [showMask, setShowMask] = useState(false);
  const [approve_transaction, setApproveTransaction] = useState("");
  const [OVC, setOVC] = useState("");
  const [change, setChange] = useState(0);
  const [note, setNote] = useState(0);
  const [holder, setHolder] = useState("");
  const [primary_address, setPrimary_address] = useState("address-active");
  const [secondary_address, setSecondary_address] = useState("address");
  const [primary_location_inactive, setPrimary_location_inactive] =
    useState(false);
  const [primary_location_active, setPrimary_location_active] = useState(true);

  const [secondary_location_active, setSecondary_location_active] =
    useState(false);
  const [secondary_location_inactive, setSecondary_location_inactive] =
    useState(true);

  const [virtual_active, setVirtual_active] = useState(true);
  const [virtual_inactive, setVirtual_inactive] = useState(false);

  const [cash_active, setCash_active] = useState(false);
  const [cash_inactive, setCash_inactive] = useState(true);

  const [card_active, setCard_active] = useState(false);
  const [card_inactive, setCard_inactive] = useState(true);

  const [virtual_box, setVirtual_box] = useState("address-active");
  const [cash_box, setCash_box] = useState("address");
  const [card_box, setCard_box] = useState("address");

  const [company_name, setCompany_name] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState(null);
  const [show, setShow] = useState(false);
  const [mediaDetails, setMediaDetails] = useState(false);
  const [error_modal, setError_modal] = useState(false);
  const [displayImge, setDisplayImge] = useState([]);
  const [preimage, setPreImage] = useState("");
  const [imgname, setImgname] = useState("");
  const [path, setPath] = useState("");
  const [showUploadBtn, setShowUploadBtn] = useState(false);
  const [showImage, setshowImage] = useState(false);
  const [arraySize, setArraySize] = useState(0);
  const [disabled, setDisabled] = useState("");
  const [btnColor, setBtnColor] = useState("karamale-login-btn");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [allimgs, setAllImgs] = useState("");
  const [media_count, setMedia_count] = useState(0);
  const [media, setMedia] = useState([]);
  const [selectedMediaID, setSelectedMediaID] = useState(0);
  const [selectedMediaImg, setSelectedMediaImg] = useState(null);
  const [successOne, setSuccessOne] = useState(false);
  const [successTwo, setSuccessTwo] = useState(false);
  const [successThree, setSuccessThree] = useState(false);
  const [successFour, setSuccessFour] = useState(false);
  const [textOne, setTextOne] = useState(true);
  const [beatOne, setBeatOne] = useState(false);
  const [textTwo, setTextTwo] = useState(true);
  const [beatTwo, setBeatTwo] = useState(false);
  const [textThree, setTextThree] = useState(true);
  const [beatThree, setBeatThree] = useState(false);
  const [textFour, setTextFour] = useState(true);
  const [beatFour, setBeatFour] = useState(false);
  const [LoadBeat, setLoadBeat] = useState(true);
  const [NoMediaLoging, setNoMediaLoging] = useState(false);
  const [itemID, setItemID] = useState(0);
  const [date_uploaded, setDate_uploaded] = useState(null);
  const [media_size, setMedia_size] = useState(0);
  const [item, setItem] = useState("");
  const [normal, setNormal] = useState("");
  const [small, setSmall] = useState("");
  const [medium, setMedium] = useState("");
  const [large, setLarge] = useState("");
  const [extraLarge, setExtraLarge] = useState("");
  const [bulk, setBulk] = useState("");
  const [volume, setVolume] = useState("");
  const [unit, setUnit] = useState("");
  const [other_price, setOther_price] = useState("");
  const [item_type, setItem_type] = useState("");
  const [description, setDes] = useState("");
  const [online_availability, setOnline_availability] = useState("");
  const [more, setMore] = useState(true);
  const [less, setLess] = useState(false);
  const [intervals, setIntervals] = useState("");
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");
  const [seeMore, setSeeMore] = useState(false);
  const [UncheckedOne, setUncheckedOne] = useState(true);
  const [CheckedOne, setCheckedOne] = useState(false);
  const [quantityOne, setQuantityOne] = useState(false);

  const [UncheckedTwo, setUncheckedTwo] = useState(true);
  const [CheckedTwo, setCheckedTwo] = useState(false);
  const [quantityTwo, setQuantityTwo] = useState(false);

  const [UncheckedThree, setUncheckedThree] = useState(true);
  const [CheckedThree, setCheckedThree] = useState(false);
  const [quantityThree, setQuantityThree] = useState(false);

  const [UncheckedFour, setUncheckedFour] = useState(true);
  const [CheckedFour, setCheckedFour] = useState(false);
  const [quantityFour, setQuantityFour] = useState(false);

  const [UncheckedFive, setUncheckedFive] = useState(true);
  const [CheckedFive, setCheckedFive] = useState(false);
  const [quantityFive, setQuantityFive] = useState(false);

  const [UncheckedSix, setUncheckedSix] = useState(true);
  const [CheckedSix, setCheckedSix] = useState(false);
  const [quantitySix, setQuantitySix] = useState(false);

  const [quantitesOne, setQuantitesOne] = useState("");
  const [quantitesTwo, setQuantitesTwo] = useState("");
  const [quantitesThree, setQuantitesThree] = useState("");
  const [quantitesFour, setQuantitesFour] = useState("");
  const [quantitesFive, setQuantitesFive] = useState("");
  const [quantitesSix, setQuantitesSix] = useState("");

  const [totalOne, setTotalOne] = useState(0);
  const [totalTwo, setTotalTwo] = useState(0);
  const [totalThree, setTotalThree] = useState(0);
  const [totalFour, setTotalFour] = useState(0);
  const [totalFive, setTotalFive] = useState(0);
  const [totalSix, setTotalSix] = useState(0);
  const [grand_total, setGrand_total] = useState(0);
  const [count, setCount] = useState(0);
  const [total_value, setTotal_value] = useState(0);
  const [account_id, setAccountid] = useState(0);

  const [added_to_basket, setAdded_to_basket] = useState(false);
  const [add_button, setAdd_button] = useState(false);
  const [total, setTotal] = useState(0);
  const [address, setAddress] = useState("");
  const [vat, setVat] = useState(0);
  const [basketCount, setBasketCount] = useState(0);
  const [location, setLocation] = useState("");
  const [add_address, setAdd_address] = useState(false);
  const [add_address_label, setAdd_address_label] = useState(true);
  const [new_address, setNew_address] = useState("");

  const [balance, setBalance] = useState(0);
  const [contentLoading, setContentLoading] = useState(true);
  const [virtualContent, setVirtualContent] = useState(false);

  const [avn, setAvn] = useState("");
  const [avc, setAvc] = useState("");
  const [message, setMessage] = useState("");
  const [virtual_present, setVirtual_present] = useState("no");
  const [pending_total, setPending_total] = useState(0);
  const [loadNote, setLoadNote] = useState(false);

  const [inactive_radio_one, set_inactive_radio_one] = useState(false);
  const [active_radio_one, set_active_radio_one] = useState(true);

  const [inactive_radio_two, set_inactive_radio_two] = useState(true);
  const [active_radio_two, set_active_radio_two] = useState(false);

  const [choice_one, set_choice_one] = useState("active_selection");
  const [choice_two, set_choice_two] = useState("inactive_selection");

  const [company, set_company] = useState("");
  const [company_location, set_company_location] = useState("");

  const [counter, setCounter] = useState(59);
  const [customer_two, setCustomer_two] = useState("");
  const [search_results, setSearch_results] = useState([]);
  const [show_results, setShow_results] = useState("d-none");
  const [response, setResponse] = useState("");
  const [response_msg, setResponse_msg] = useState(false);
  const [customer_id, setCustomerID] = useState(0);
  const [load_results, setLoad_results] = useState(false);

  let customerid,
    pos = "";
  if (localStorage.getItem("customerid") != null) {
    customerid = localStorage.getItem("customerid");
  } else {
    customerid = -1;
  }

  if (localStorage.getItem("pos") != null) {
    pos = localStorage.getItem("pos");
  } else {
    pos = 0;
  }

  function store_details() {
    axios
      .get(
        baseURL +
          "?query=STORE_DETAILS&device_id=" +
          deviceid +
          "&customerid=" +
          customerid
      )
      .then((response) => {
        if (response.data.status == "ok") {
          set_company(response.data.company_name);
          set_company_location(response.data.location);
        }
      });
  }

  function virtualPresent() {
    axios
      .get(baseURL + "?query=VIRTUAL_DETAILS&customerid=" + customerid)
      .then((response) => {
        if (response.data.status == "ok") {
          setVirtual_present("yes");
          setAvn(response.data.avn);
          setAvc(response.data.avc);
          setVirtualContent(true);
          setContentLoading(false);
        } else {
          setVirtual_present("no");
          setAvn("Data not found");
          setAvc("Data not found");
          setVirtualContent(true);
          setContentLoading(false);
        }
      });
  }

  function getBalance() {
    axios
      .get(baseURL + "?query=GET_VIRTUAL_BALANCE&customerid=" + customerid)
      .then((response) => {
        if (response.data.status == "ok") {
          setBalance(response.data.balance);
          setPending_total(response.data.pending_total);
          setMessage(
            <span className="w-100 font-size-12 color-grey font-normal">
              <br /> {response.data.message}
            </span>
          );
        } else {
          setBalance(0);
          setPending_total(0);
          setMessage("");
        }
      });
  }

  function handleAddress(event) {
    setNew_address(event.target.value);
  }

  function total_amount() {
    axios
      .get(
        baseURL +
          "?query=TOTAL_AMOUNT&device_id=" +
          deviceid +
          "&customerid=" +
          customerid
      )
      .then((response) => {
        setTotal(response.data.total);
      });
  }

  function itemCount() {
    axios
      .get(
        baseURL +
          "?query=ITEM-COUNT-BASKET&device_id=" +
          deviceid +
          "&customerid=" +
          customerid
      )
      .then((response) => {
        setMedia_count(response.data.count);
      });
  }

  function fetchItems() {
    axios
      .get(
        baseURL +
          "?query=ITEMS-BASKET&device_id=" +
          deviceid +
          "&customerid=" +
          customerid
      )
      .then((response) => {
        setMedia(response.data);
        setNoMediaLoging(true);
        setLoadNote(true);
        setLoadBeat(false);
      });
  }

  function basket_Count() {
    axios
      .get(
        baseURL +
          "?query=BASKET_COUNT&device_id=" +
          deviceid +
          "&customerid=" +
          customerid
      )
      .then((res) => {
        setBasketCount(res.data.count);
      });
  }

  function location_primary() {
    axios
      .get(baseURL + "?query=PRIMARY_LOCATION&customerid=" + customerid)
      .then((res) => {
        setLocation(res.data.location);
        setAddress(res.data.address);
      });
  }

  function approve_transact() {
    axios
      .get(
        baseURL +
          "?query=APPROVE_TRANSACTION&customerid=" +
          customerid +
          "&accountid=" +
          accountid +
          "&deviceid=" +
          deviceid
      )
      .then((res) => {
        if (res.data.status == "ok") {
          setCount(count + 1);
          setCustomer(res.data.customer);
          setToken(res.data.token);

          if (res.data.approve == "no") {
            setApproveTransaction("no");
          } else if (
            res.data.approve == "declined" &&
            res.data.closed == null
          ) {
            setonShow(true);
            setApproveTransaction("declined");
          } else if (
            res.data.approve == "approved" &&
            res.data.closed == null
          ) {
            setApproveTransaction("approved");
            setonShow(true);
          } else {
            // setApproveTransaction("approved");
            // setonShow(true);
          }
        }
      });
  }

  useEffect(() => {
    document.title = "Obarak - Add payment";
    // alert(approve_transaction);
    // localStorage.removeItem("customerid");
    // localStorage.removeItem("OVC");
    store_details();
    if (approve_transaction == "no" && localStorage.getItem("OVC") == "yes") {
      setShowMask(true);
      setonShow(false);
    }
    setVat(Math.round(total * 0.15));
    getBalance();
    virtualPresent();
    location_primary();
    itemCount();
    fetchItems();
    total_amount();
    basket_Count();
    approve_transact();

    // const interval_time = setInterval(() => {
    //   total_amount();
    //   basket_Count();
    //   fetchItems();
    //   getBalance();
    //   approve_transact();
    // }, 5000);
    // return () => clearInterval(interval_time);
  }, [count]);

  const handleClose = (value) => {
    // setShowMask(false);
    setShow(false);
    setMediaDetails(false);
    if (value == "close_decline") {
      axios
        .get(
          baseURL +
            "?query=DECLINE_CLOSE&accountid=" +
            accountid +
            "&customerid=" +
            customerid+
            "&deviceid="+deviceid
        )
        .then((response) => {
          if (response.data.status == "ok") {
            localStorage.removeItem("OVC");
            localStorage.removeItem("customerid");
            setonShow(false);
            setShowMask(false);
            setError(false);
            setCount(count + 1);
          }
        });
    } else {
    }
  };

  const handleShow = () => setShow(true);
  // const selectedMedia = () => setMediaDetails(true);

  function selectMedia(
    media,
    mediaid,
    itemid,
    item_name,
    normal,
    small,
    medium,
    large,
    extra_large,
    bulk,
    item_type,
    description,
    online_availability,
    intervals,
    category,
    type,
    volume,
    unit,
    other_price,
    account_id,
    company_name
  ) {
    // alert(item_type);
    setCompany_name(company_name);
    setAdded_to_basket(false);
    setAdd_button(false);
    setAccountid(account_id);
    setSeeMore(false);
    setMore(true);
    setLess(false);
    setIntervals(intervals);
    setCategory(category);
    setType(type);
    setSelectedMediaID(mediaid);
    setSelectedMediaImg(media);
    setItemID(itemid);
    setItem(item_name);

    setNormal(normal);
    setSmall(small);
    setMedium(medium);
    setLarge(large);
    setExtraLarge(extra_large);
    setBulk(bulk);
    setItem_type(item_type);
    setDes(description);
    setOnline_availability(online_availability);
    setVolume(volume);
    setUnit(unit);
    setOther_price(other_price);

    localStorage.setItem("foodid", itemid);
    localStorage.setItem("food_name", item_name);
    localStorage.setItem("dashboard", "yes");
    setMediaDetails(true);

    setCheckedOne(false);
    setUncheckedOne(true);
    setQuantityOne(false);
    setQuantitesOne("");
    setTotalOne(0);

    setCheckedTwo(false);
    setUncheckedTwo(true);
    setQuantityTwo(false);
    setQuantitesTwo("");
    setTotalTwo(0);

    setCheckedThree(false);
    setUncheckedThree(true);
    setQuantityThree(false);
    setQuantitesThree("");
    setTotalThree(0);

    setCheckedFour(false);
    setUncheckedFour(true);
    setQuantityFour(false);
    setQuantitesFour("");
    setTotalFour(0);

    setCheckedFive(false);
    setUncheckedFive(true);
    setQuantityFive(false);
    setQuantitesFive("");
    setTotalFive(0);

    setCheckedSix(false);
    setUncheckedSix(true);
    setQuantitySix(false);
    setQuantitesSix("");
    setTotalSix(0);

    setGrand_total(0);
  }

  function update_address(e) {
    e.preventDefault();
    const formData = new FormData();
    const customerid = localStorage.getItem("customerid");
    formData.append("new_address", new_address);
    // formData.append("location", location);
    formData.append("customer_id", customerid);
    // formData.append("accountid", accountid);
    formData.append("query", "ADD_DELIVERY_ADDRESS");

    if (add_address == true && new_address == "") {
      setBgColor("red");
      setDisplayMessage(true);
      setErrorMsg("Enter secondary address or cancel the field");
    } else {
      setBtnColor("disabled-btn");
      setDisabled("disabled");
      setLoadSpinner(true);
      setSaveLabel(false);
      // connect to the api

      axios({
        method: "POST",
        url: baseURL,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then((response) => {
        if (response.data.status == "error") {
          setBgColor("red");
          setDisplayMessage(true);
          setErrorMsg(response.data.message);
        } else {
          setCount(count + 1);
          navigate("/continue");
          setBgColor("#4ca64c");
          setDisplayMessage(true);
          setErrorMsg(response.data.message);
          setAdd_address(false);
          setPrimary_address("address");
          setSecondary_address("address-active");

          setPrimary_location_inactive(true);
          setPrimary_location_active(false);
          setSecondary_location_active(true);
          setSecondary_location_inactive(false);
        }

        setBtnColor("karamale-login-btn");
        setDisabled("");
        setLoadSpinner(false);
        setSaveLabel(true);

        setTimeout(() => {
          setDisplayMessage(false);
        }, 5000);
      });
    }
  }

  function moreDetails() {
    setMore(false);
    setLess(true);
    setSeeMore(true);
  }
  function lessDetails() {
    setMore(true);
    setLess(false);
    setSeeMore(false);
  }

  function normalPrice() {
    if (CheckedOne == true) {
      setCheckedOne(false);
      setUncheckedOne(true);
      setQuantityOne(false);
      setQuantitesOne("");
      setTotalOne(0);
    } else {
      setCheckedOne(true);
      setUncheckedOne(false);
      setQuantityOne(true);
    }
    setCount(count + 1);
  }

  function smallPrice() {
    if (CheckedTwo == true) {
      setCheckedTwo(false);
      setUncheckedTwo(true);
      setQuantityTwo(false);
      setQuantitesTwo("");
      setTotalTwo(0);
    } else {
      setCheckedTwo(true);
      setUncheckedTwo(false);
      setQuantityTwo(true);
    }
    setCount(count + 1);
  }

  function mediumPrice() {
    if (CheckedThree == true) {
      setCheckedThree(false);
      setUncheckedThree(true);
      setQuantityThree(false);
      setQuantitesThree("");
      setTotalThree(0);
    } else {
      setCheckedThree(true);
      setUncheckedThree(false);
      setQuantityThree(true);
    }
    setCount(count + 1);
  }

  function largePrice() {
    if (CheckedFour == true) {
      setCheckedFour(false);
      setUncheckedFour(true);
      setQuantityFour(false);
      setQuantitesFour("");
      setTotalFour(0);
    } else {
      setCheckedFour(true);
      setUncheckedFour(false);
      setQuantityFour(true);
    }
    setCount(count + 1);
  }

  function xlargePrice() {
    if (CheckedFive == true) {
      setCheckedFive(false);
      setUncheckedFive(true);
      setQuantityFive(false);
      setQuantitesFive("");
      setTotalFive(0);
    } else {
      setCheckedFive(true);
      setUncheckedFive(false);
      setQuantityFive(true);
    }
    setCount(count + 1);
  }

  function bulkPrice() {
    if (CheckedSix == true) {
      setCheckedSix(false);
      setUncheckedSix(true);
      setQuantitySix(false);
      setQuantitesSix("");
      setTotalSix(0);
    } else {
      setCheckedSix(true);
      setUncheckedSix(false);
      setQuantitySix(true);
    }
    setCount(count + 1);
  }

  function f_quantityOne(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesOne(e.target.value);
      let price = 0;
      const price_one = other_price;
      const price_two = normal;
      if (price_one == "" || price_one == 0) {
        price = normal;
      } else {
        price = other_price;
      }
      setTotalOne(e.target.value * price);
      setCount(count + 1);
    }
  }

  function f_quantityTwo(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesTwo(e.target.value);
      setTotalTwo(e.target.value * small);
      setCount(count + 1);
    }
  }

  function f_quantityThree(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesThree(e.target.value);
      setTotalThree(e.target.value * medium);
      setCount(count + 1);
    }
  }

  function f_quantityFour(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesFour(e.target.value);
      setTotalFour(e.target.value * large);
      setCount(count + 1);
    }
  }

  function f_quantityFive(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesFive(e.target.value);
      setTotalFive(e.target.value * extraLarge);
      setCount(count + 1);
    }
  }

  function f_quantitySix(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesSix(e.target.value);
      setTotalSix(e.target.value * bulk);
      setCount(count + 1);
    }
  }

  function setAlert() {
    setError_modal(true);
    setMediaDetails(false);
  }
  function closeError() {
    setError_modal(false);
    setMediaDetails(true);
  }

  function map_media() {
    let itemid = 0;
    return (
      <Row className="max-height-300">
        {" "}
        {media_count == 0 ? (
          <div>
            {NoMediaLoging && (
              <label className="no-media-text color-silver">
                Basket is empty
              </label>
            )}
          </div>
        ) : (
          media.map((item, index) => {
            return (
              <Col lg={12} md={12} sm={12} xs={12} className="mb-3 text-left">
                <Row className="align-items-center">
                  <Col
                    lg={2}
                    xs={2}
                    sm={2}
                    md={2}
                    className="media-container-basket mb-2"
                    style={{
                      backgroundImage: `url(${root_url}/assets/${item.accountid}/${item.media})`,
                    }}
                    onClick={() =>
                      selectMedia(
                        item.media,
                        item.itemid,
                        item.itemid,
                        item.item_name,
                        item.normal_price,
                        item.small_price,
                        item.medium_price,
                        item.large_price,
                        item.extra_large_price,
                        item.bulk_price,
                        item.item_type,
                        item.description,
                        item.online_availability,
                        item.intervals,
                        item.category,
                        item.type,
                        item.volume,
                        item.unit,
                        item.other_price,
                        item.accountid,
                        item.company_name
                      )
                    }
                  ></Col>
                  <Col lg={4} xs={8} md={6} sm={6}>
                    <b>
                      {item.item_name.length > 7
                        ? item.item_name.substring(0, 7) + "..."
                        : item.item_name}
                    </b>
                    <br />
                    <span className="color-grey font-size-12">
                      {item.category == "solid" || item.category == "soft"
                        ? item.category.charAt(0).toUpperCase() +
                          item.category.slice(1) +
                          " food"
                        : "Soft " + item.category}
                    </span>
                  </Col>
                  <Col
                    lg={2}
                    sm={2}
                    md={2}
                    xs={2}
                    className="font-size-12 text-center desktop-browse-btn"
                  >
                    Qty
                    <br />
                    <label className="color-grey font-size-12 w-100 text-center">
                      {item.quantity}
                    </label>
                  </Col>

                  <Col
                    lg={2}
                    sm={2}
                    md={2}
                    xs={2}
                    className="font-size-12 text-center desktop-browse-btn"
                  >
                    Total
                    <br />
                    <label className="color-grey font-size-12">
                      R{item.total}
                    </label>
                  </Col>
                </Row>
              </Col>
            );
          })
        )}
      </Row>
    );
  }

  function remove(orderid) {
    axios
      .get(
        baseURL +
          "?query=REMOVE_FROM_BASKET&device_id=" +
          deviceid +
          "&orderid=" +
          orderid +
          "&action=REMOVE_ITEM"
      )
      .then((response) => {});
    setCount(count + 1);
  }

  function addAddress() {
    if (add_address == true) {
      setAdd_address(false);
    } else {
      setAdd_address(true);
    }
  }

  function select_secondary_address() {
    setPrimary_address("address");
    setSecondary_address("address-active");
    setPrimary_location_inactive(true);
    setPrimary_location_active(false);
    setSecondary_location_active(true);
    setSecondary_location_inactive(false);
  }

  function select_primary_address() {
    setPrimary_address("address-active");
    setSecondary_address("address");

    setPrimary_location_inactive(false);
    setPrimary_location_active(true);
    setSecondary_location_active(false);
    setSecondary_location_inactive(true);
  }
  function virtual_payment() {
    setCustomerID(0);
    setPending_total(pending_total);
    setVirtual_box("address-active");
    setVirtual_active(true);
    setVirtual_inactive(false);

    setCash_box("address");
    setCash_active(false);
    setCash_inactive(true);

    setCard_box("address");
    setCard_active(false);
    setCard_inactive(true);
    setCount(count + 1);
  }

  function cash_payment() {
    setPending_total(0);
    setCash_box("address-active");
    setCash_active(true);
    setCash_inactive(false);

    setVirtual_box("address");
    setVirtual_active(false);
    setVirtual_inactive(true);

    setCard_box("address");
    setCard_active(false);
    setCard_inactive(true);
  }

  function card_payment() {
    setPending_total(0);
    setCard_box("address-active");
    setCard_active(true);
    setCard_inactive(false);

    setVirtual_box("address");
    setVirtual_active(false);
    setVirtual_inactive(true);

    setCash_box("address");
    setCash_active(false);
    setCash_inactive(true);
  }

  function add_payment_btn() {
    let alias,
      add_accountid,
      check_note,
      change_note,
      deliver_address,
      attainance = "";
    if (localStorage.getItem("accountid") != null) {
      add_accountid = "&accountid=" + accountid;
    } else {
      add_accountid = "&accountid=0";
    }

    if (active_radio_two === true) {
      attainance = "&attainance=delivery";
    } else {
      attainance = "&attainance=collection";
    }

    // add_accountid = "&accountid=" + accountid;

    // alert(add_accountid);

    if (virtual_active == true) {
      alias = "VIRTUAL";
      check_note = "&note=0";
      change_note = "&change=0";
    } else if (cash_active == true) {
      alias = "CASH";
      check_note = "&note=" + note;
      change_note = "&change=" + change;
    } else if (card_active == true) {
      alias = "CARD";
      check_note = "&note=0";
      change_note = "&change=0";
    }

    if (secondary_location_active == true) {
      deliver_address = "SECONDARY";
    } else {
      deliver_address = "PRIMARY";
    }

    // if (localStorage.getItem("OVC") == "yes") {
    if (
      approve_transaction == "" &&
      virtual_active == true &&
      localStorage.getItem("pos") != null
    ) {
      // alert(customerid);

      axios
        .get(
          baseURL +
            "?query=SEND_APPROVAL_NOTE&customerid=" +
            customerid +
            "&accountid=" +
            accountid +
            "&deviceid=" +
            deviceid +
            "&total=" +
            total
        )
        .then((response) => {
          if (response.data.status == "ok") {
            setShowMask(true);
            // setApproveTransaction("");
            setCount(count + 1);
          }
        });
      let counte_r = 59;

      // const interval = setInterval(() => {
      //   if (counte_r == -1) {
      //     return () => clearInterval(interval);
      //   } else {
      //     setCounter(counte_r--);
      //   }
      // }, 1000);
    } else if (
      approve_transaction == "declined" &&
      virtual_active == true &&
      localStorage.getItem("pos") != null
    ) {
      // setShowMask(false);
      // setApproveTransaction("declined");
      // setCount(count + 1);
    } else {
      if (card_active == true) {
        // convert amount to cents;
        const amount_in_cents = Math.round(
          100 * parseFloat(total.replace(/[$,]/g, ""))
        );
        // const amount_in_cents = 300;
        // alert(Math.round(100 * parseFloat(total.replace(/[$,]/g, ""))));
        // adding yoco popup for online payment
        var yoco = new window.YocoSDK({
          publicKey: "pk_live_99241c6byLA1nZKd0f84",
        });

        yoco.showPopup({
          amountInCents: Math.round(
            100 * parseFloat(total.replace(/[$,]/g, ""))
          ),
          currency: "ZAR",
          name: "Obarak",
          description: "Awesome description",
          callback: function (result) {
            // This function returns a token that your server can use to capture a payment
            if (result.error) {
              const errorMessage = result.error.message;
              // alert("error occured: " + errorMessage);
              setBgColor("red");
              setDisplayMessage(true);
              setErrorMsg("Error message: " + errorMessage);
            } else {
              // alert("card successfully tokenised: " + result.id);

              setCount(count + 1);
              setApproveTransaction("approved");
              // setBtnColor("disabled-btn");
              // setDisabled("disabled");
              // setLoadSpinner(true);
              // setSaveLabel(false);

              axios
                .get(
                  baseURL +
                    "?query=ADD_PAYMENT&pos=" +
                    pos +
                    "&alias=" +
                    alias +
                    "&customerid=" +
                    customerid +
                    "&deliver_address=" +
                    deliver_address +
                    "" +
                    add_accountid +
                    "" +
                    check_note +
                    "" +
                    change_note +
                    "&transaction_tokenid=" +
                    result.id +
                    "&amount_in_cents=" +
                    amount_in_cents +
                    "&transaction_amount=" +
                    total +
                    "" +
                    attainance
                )
                .then((response) => {
                  console.log(response.data.status);
                  if (response.data.status == "ok") {
                    navigate("/successful");
                  } else {
                    setShowMask(false);
                    setApproveTransaction("approved");
                    setCount(count + 1);
                    setBgColor("red");
                    setDisplayMessage(true);
                    setErrorMsg(
                      "Something went wrong, couldn't complete transaction"
                    );

                    setBtnColor("karamale-login-btn");
                    setDisabled("");
                    setLoadSpinner(false);
                    setSaveLabel(true);
                  }
                });
            }
            // In a real integration - you would now pass this chargeToken back to your
            // server along with the order/basket that the customer has purchased.
          },
        });
      } else {
        setCount(count + 1);
        setApproveTransaction("approved");
        setBtnColor("disabled-btn");
        setDisabled("disabled");
        setLoadSpinner(true);
        setSaveLabel(false);

        axios
          .get(
            baseURL +
              "?query=ADD_PAYMENT&pos=" +
              pos +
              "&alias=" +
              alias +
              "&customerid=" +
              customerid +
              "&deliver_address=" +
              deliver_address +
              "" +
              add_accountid +
              "" +
              check_note +
              "" +
              change_note +
              "" +
              attainance +
              "&customer_id=" +
              customer_id
          )
          .then((response) => {
            // alert(response.data.customerid);
            if (response.data.status == "ok") {
              navigate("/successful");
            } else {
              setShowMask(false);
              setApproveTransaction("approved");
              setCount(count + 1);
              setBgColor("red");
              setDisplayMessage(true);
              setErrorMsg(
                "Something went wrong, couldn't complete transaction"
              );

              setBtnColor("karamale-login-btn");
              setDisabled("");
              setLoadSpinner(false);
              setSaveLabel(true);
            }
          });
      }
    }
  }

  function bank_note(e) {
    if (isNaN(e.target.value)) {
    } else {
      if (e.target.value == "") {
        setNote(e.target.value);
        setChange(total);
      } else {
        setNote(e.target.value);
        setChange(e.target.value - total);
      }
    }
  }

  function verify_ovc() {
    const accountid = localStorage.getItem("accountid");
    if (OVC.trim() == "") {
      setError(true);
      setMsg("Enter customer cvc");
    } else {
      setError(false);
      setBtnColor("disabled-btn");
      setDisabled("disabled");
      setLoadSpinner(true);
      setSaveLabel(false);
      axios
        .get(
          baseURL +
            "?query=VERIFY_CVC&cvc=" +
            OVC +
            "&accountid=" +
            accountid +
            "&deviceid=" +
            deviceid +
            "&total=" +
            total
        )
        .then((response) => {
          if (response.data.status == "ok") {
            setHolder(response.data.holder);
            setError(true);
            setMsg(
              <span className="color-green font-size-12">
                {response.data.message} <IoIosCheckmark size={20} />
              </span>
            );
            localStorage.setItem("customerid", response.data.customerid);
            localStorage.setItem("OVC", "yes");
            localStorage.setItem("holder", response.data.holder);
            setCount(count + 1);
            // navigate("/continue");
          } else {
            setError(true);
            setMsg(response.data.message);
          }
          setBtnColor("karamale-login-btn");
          setDisabled("");
          setLoadSpinner(false);
          setSaveLabel(true);
        });
    }
  }

  function cancel_transaction(param) {
    setError(false);
    setBtnColor("disabled-btn");
    setDisabled("disabled");
    setLoadSpinner(true);
    setSaveLabel(false);
    axios
      .get(
        baseURL +
          "?query=CANCEL_TRANSACTION&accountid=" +
          accountid +
          "&customerid=" +
          customerid +
          "&token=" +
          token +
          "&deviceid="+deviceid+
          "&param="+param
      )
      .then((response) => {
        if (response.data.status == "ok") {
          setTransaction_aborted(true);
          localStorage.removeItem("OVC");
          localStorage.removeItem("customerid");
          localStorage.removeItem("deviceid");
          setCount(count + 1);

          const interva = setInterval(() => {
            // navigate("/");
            window.location.href = '/';
          }, 3000);
          return () => clearInterval(interva);
        }
      });
  }

  function close_error_message() {
    setDisplayMessage(false);
  }

  function radio_one() {
    set_active_radio_one(true);
    set_inactive_radio_one(false);

    set_active_radio_two(false);
    set_inactive_radio_two(true);

    set_choice_one("active_selection");
    set_choice_two("inactive_selection");
  }

  function radio_two() {
    set_active_radio_two(true);
    set_inactive_radio_two(false);

    set_active_radio_one(false);
    set_inactive_radio_one(true);

    set_choice_two("active_selection");
    set_choice_one("inactive_selection");
  }

  function searchCustomer(e) {
    setResponse_msg(false);
    const search = e.target.value;
    setCustomer_two(search);
    // alert(search);

    if (e.target.value.trim() === "") {
      setShow_results("d-none");
      // setLoad_results(false);
    } else {
      axios
        .get(
          baseURL +
            "?query=SEARCH_LINK_CUSTOMER_PURCHASE&search=" +
            search +
            "&accountid=" +
            accountid
        )
        .then((res) => {
          setSearch_results(res.data);
          setShow_results("d-block");
          setLoad_results(true);
        });
    }
  }

  function link(customer_name, customerid) {
    setResponse_msg(true);
    setCustomer_two("");
    setLoad_results(false);
    axios
      .get(
        baseURL +
          "?query=LINK_CUSTOMER&accountid=" +
          accountid +
          "&customerid=" +
          customerid
      )
      .then((res) => {
        // alert(res.data.status);
        if (res.data.status === "ok") {
          setCustomerID(customerid);
          setResponse(
            <label className="color-green font-size-12">
              <span className="font-medium">{customer_name.toLowerCase()}</span>{" "}
              will be linked <IoIosCheckmark size={25} />
            </label>
          );
        } else if (res.data.status === "exist") {
          setResponse(
            <label className="color-orange">
              <span className="font-medium">{customer_name.toLowerCase()}</span>{" "}
              is already linked
            </label>
          );
        } else {
          setResponse(
            <label className="color-red">
              There was a technical error, try again later.
            </label>
          );
        }
      });
  }

  function results() {
    return (
      <>
        {search_results.length <= 0 ? (
          <label className="font-normal color-grey">
            <span className="font-medium ">No match found!</span> <br />
            Perhaps you can ask the customer to create an account, otherwise you
            may proceed to add payment.
          </label>
        ) : (
          search_results.map((item, index) => {
            return (
              <Row
                className="link_customer"
                onClick={() => link(item.name, item.customerid)}
              >
                <Col lg={12}>
                  <label className="w-100 cursor">{item.name}</label>
                </Col>
              </Row>
            );
          })
        )}
      </>
    );
  }

  // if (counter == 0) {
  //   setError(false);
  //   setBtnColor("disabled-btn");
  //   setDisabled("disabled");
  //   setLoadSpinner(true);
  //   setSaveLabel(false);
  //   axios
  //     .get(
  //       baseURL +
  //         "?query=CANCEL_TRANSACTION&accountid=" +
  //         accountid +
  //         "&customerid=" +
  //         customerid +
  //         "&token=" +
  //         token
  //     )
  //     .then((response) => {
  //       if (response.data.status == "ok") {
  //         setTransaction_aborted(true);
  //         localStorage.removeItem("OVC");
  //         localStorage.removeItem("customerid");

  //       }
  //     });
  // }
  return (
    <div className="body">
      <Container className="container-home">
        <Row>
          <Col lg={2} xs={2} md={3} className="p-0">
            {localStorage.getItem("pos") == 1 &&
            localStorage.getItem("accountid") != null ? (
              <SideNav />
            ) : (
              <HomeNav />
            )}
          </Col>
          <Col lg={10} xs={10} md={9} className={"middle-container"}>
            <Row className="h-100 align-items-center justify-content-center">
              <Col
                lg={6}
                className={
                  localStorage.getItem("pos") != null ? "d-none" : "mb-3"
                }
              >
                <Stack>
                  <>
                    <div
                      className={
                        localStorage.getItem("pos") !== null
                          ? "d-none"
                          : "box-container mt-3 mb-0 p-3 pb-3 pt-3 add-relative-position"
                      }
                    >
                      <Row>
                        <Col lg={6}>
                          <div className={choice_one} onClick={radio_one}>
                            <IoIosRadioButtonOff
                              size={17}
                              className={
                                inactive_radio_one === false ? "d-none" : ""
                              }
                            />
                            <IoIosRadioButtonOn
                              size={17}
                              className={
                                active_radio_one === false ? "d-none" : ""
                              }
                            />
                            &nbsp;&nbsp;Collection
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className={choice_two} onClick={radio_two}>
                            <IoIosRadioButtonOff
                              size={17}
                              className={
                                inactive_radio_two === false ? "d-none" : ""
                              }
                            />
                            <IoIosRadioButtonOn
                              size={17}
                              className={
                                active_radio_two === false ? "d-none" : ""
                              }
                            />
                            &nbsp;&nbsp; Delivery
                          </div>
                        </Col>
                      </Row>
                    </div>
                    {active_radio_two === true || active_radio_one === true ? (
                      <label className="font-medium font-size-17 w-100 text-left mt-4">
                        {active_radio_one === true ? (
                          <label className="font-size-17">
                            <span className="color-grey">{company}</span> will
                            communicate with you soon as your <br />
                            order is ready for collection.
                            <br />
                            <br />
                            <span className="color-grey font-normal">
                              <span className="color-black font-medium">
                                Store location:
                              </span>
                              <br />
                              {company_location}
                            </span>
                            <br />
                            <br />
                            <span className="color-red">
                              Proceed to add payment...
                            </span>
                          </label>
                        ) : active_radio_two === true ? (
                          "Delivery feature coming soon..."
                        ) : (
                          ""
                        )}
                      </label>
                    ) : (
                      ""
                    )}
                  </>
                  <div
                    className={
                      active_radio_two === true || active_radio_one === true
                        ? "d-none"
                        : "box-container mt-3 mb-0 p-4 pb-0 pt-3 add-relative-position"
                    }
                  >
                    <Row className="justify-content-center p-0">
                      {localStorage.getItem("customerid") == null ? (
                        ""
                      ) : basketCount < 1 ? (
                        <label className="w-100 text-center font-size-17 font-medium color-grey pb-3">
                          <>
                            {" "}
                            {loadNote && (
                              <span>
                                There's nothing in the basket
                                <br />
                                <Link
                                  to="/"
                                  className="color-blue anchor font-normal font-size-15"
                                >
                                  Order something
                                </Link>
                              </span>
                            )}
                          </>
                          {LoadBeat && (
                            <label className="text-center w-100 pb-3 mt-4">
                              <BeatLoader color={"#39c"} />
                            </label>
                          )}
                        </label>
                      ) : (
                        <Row className="w-100 p-0 align-items-center justify-content-center">
                          <Col lg={12} className="p-0">
                            <label className="float-left font-medium">
                              Choose delivery address
                            </label>
                          </Col>
                          <Col lg={12} className="p-0">
                            <div className="h-divider" />
                          </Col>
                          <Col lg={12} className="p-0 mb-1">
                            <label className="w-100 font-medium">
                              Primary address
                            </label>
                          </Col>
                          <Row
                            className={
                              primary_address + " w-100 align-items-center"
                            }
                            onClick={select_primary_address}
                          >
                            <Col
                              lg={1}
                              xs={2}
                              sm={2}
                              md={2}
                              className="text-center"
                            >
                              {primary_location_inactive && (
                                <IoMdRadioButtonOff size={25} />
                              )}
                              {primary_location_active && (
                                <IoMdRadioButtonOn
                                  size={25}
                                  className={"color-green"}
                                />
                              )}
                            </Col>
                            <Col
                              lg={11}
                              xs={10}
                              sm={10}
                              md={10}
                              className="text-left"
                            >
                              {location}
                            </Col>
                          </Row>

                          <Col lg={12} className="p-0 mb-1 mt-3">
                            <label className="w-100 font-medium">
                              Secondary address
                            </label>
                          </Col>

                          {address == "" ? (
                            ""
                          ) : (
                            <Row
                              className={
                                secondary_address + " w-100 align-items-center"
                              }
                              onClick={select_secondary_address}
                            >
                              <Col
                                lg={1}
                                xs={2}
                                sm={2}
                                md={2}
                                className="text-center"
                              >
                                {secondary_location_inactive && (
                                  <IoMdRadioButtonOff size={25} />
                                )}
                                {secondary_location_active && (
                                  <IoMdRadioButtonOn
                                    size={25}
                                    className={"color-green"}
                                  />
                                )}
                              </Col>

                              <Col
                                lg={11}
                                xs={10}
                                sm={10}
                                md={10}
                                className="text-left"
                              >
                                {address}
                              </Col>
                            </Row>
                          )}

                          {add_address && (
                            <Row className="p-0 w-100 align-items-center justify-content-center">
                              <form
                                onSubmit={update_address}
                                className="w-100 p-0"
                              >
                                <Col
                                  lg={12}
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  className="p-0 mb-3"
                                >
                                  <label className="font-medium mb-1 mt-3">
                                    {address == ""
                                      ? ""
                                      : "Update secondary address"}
                                  </label>
                                  <GooglePlacesAutocomplete
                                    apiKey="AIzaSyDNn7uMQVzoH91NYn7vzX437q5fp8g92FU"
                                    styles={{
                                      textInputContainer: {
                                        backgroundColor: "grey",
                                      },
                                      textInput: {
                                        height: 38,
                                        color: "#5d5d5d",
                                        fontSize: 16,
                                      },
                                    }}
                                    textInputProps={{
                                      onChange: (text) => {
                                        handleAddress(text);
                                      },
                                    }}
                                    selectProps={{
                                      onChange: (data, details = null) =>
                                        setNew_address(
                                          data["value"]["description"]
                                        ),
                                      fetchDetails: true,
                                      placeholder: "Add delivery address...",
                                      // defaultInputValue: this.state.venue,
                                    }}
                                  />
                                </Col>
                                <Col
                                  lg={12}
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  className="p-0 mb-3 text-right"
                                >
                                  <button
                                    type="submit"
                                    className={
                                      btnColor +
                                      " browse-w-100 font-medium mb-3 update_address_margin_right"
                                    }
                                    disabled={disabled}
                                  >
                                    {saveLabel && (
                                      <span>
                                        {" "}
                                        {address == ""
                                          ? "Add address"
                                          : "Update address"}
                                      </span>
                                    )}
                                    {loadSpinner && <BeatLoader size={10} />}
                                  </button>
                                  <button
                                    type="button"
                                    className={
                                      "cancel-btn browse-w-100 font-medium color-light-black "
                                    }
                                    onClick={addAddress}
                                  >
                                    Cancel
                                  </button>
                                </Col>
                              </form>
                            </Row>
                          )}
                          <div className="address-active w-100">
                            <Col
                              lg={12}
                              className="text-center"
                              onClick={addAddress}
                            >
                              <IoIosAdd size={25} />{" "}
                              {address == ""
                                ? "Add secondary address"
                                : "Update secondary address"}
                            </Col>
                          </div>
                        </Row>
                      )}
                    </Row>
                  </div>
                </Stack>
              </Col>
              {basketCount < 1 ? (
                ""
              ) : (
                <Col
                  lg={
                    localStorage.getItem("pos") == 1 &&
                    localStorage.getItem("accountid") != null
                      ? 6
                      : 4
                  }
                >
                  <Stack>
                    <div className="box-container mt-3 mb-3 p-4 pb-0 pt-3 add-relative-position">
                      {localStorage.getItem("customerid") != null ? (
                        ""
                      ) : (
                        <div
                          className={
                            localStorage.getItem("pos") == 1 &&
                            localStorage.getItem("accountid") != null
                              ? "d-none"
                              : "mask-div"
                          }
                        >
                          <Row>
                            <Col lg={12}>
                              <span className="font-medium">Please note:</span>
                            </Col>
                            <Col lg={12} className="mt-2">
                              <label>
                                First{" "}
                                <Link
                                  to="/login"
                                  className="color-blue cursor textUnderLineNone"
                                >
                                  log in
                                </Link>{" "}
                                or register.
                              </label>
                            </Col>
                          </Row>
                        </div>
                      )}
                      <Row className="align-items-center justify-content-center">
                        {showMask && (
                          <div className="mask-div align-items-start">
                            <div className="font-normal font-size-15 mt-5">
                              {/* <span className="counter">{counter}</span>
                              <br /> */}
                              <BeatLoader size={10} color={"#3399cc"} />
                              <br />
                              Waiting for {localStorage.getItem("holder")} to
                              approve transaction of R{total}.
                              <br />
                              <br />
                              {/* <label
                                className="cancel-btn color-light-black font-medium"
                                onClick={cancel_transaction}
                              >
                                Cancel purchase
                              </label> */}
                              {transaction_aborted && (
                                <Col lg={12} className="text-center mb-3">
                                  <span className="color-red font-size-17">
                                    Transaction aborted
                                  </span>
                                </Col>
                              )}
                              <button
                                type="button"
                                className={btnColor + " font-medium "}
                                disabled={disabled}
                                onClick={() => cancel_transaction('aborted')}
                              >
                                {saveLabel && (
                                  <span className="font-medium">
                                    Abort transaction
                                  </span>
                                )}
                                {loadSpinner && <BeatLoader size={10} />}
                              </button>
                            </div>
                          </div>
                        )}
                        <Col lg={12} className="p-0">
                          <label className="float-left font-medium">
                            Order summary
                          </label>
                        </Col>
                        <Col lg={12} className="p-0">
                          <div className="h-divider" />
                        </Col>

                        <Col
                          lg={6}
                          xs={6}
                          sm={6}
                          md={6}
                          className="text-left mb-2 p-0 font-size-12"
                        >
                          Subtotal
                        </Col>
                        <Col
                          lg={6}
                          xs={6}
                          sm={6}
                          md={6}
                          className="text-right mb-2 p-0 color-grey font-size-12"
                        >
                          R{parseFloat(total).toFixed(2)}
                        </Col>
                        {/* <Col
                          lg={6}
                          xs={6}
                          sm={6}
                          md={6}
                          className="text-left mb-2 p-0 font-size-12"
                        >
                          Delivery
                          <br />
                          <label className="color-grey font-size-12">
                            Will be delivered shortly
                          </label>
                        </Col> */}
                        {/* <Col
                          lg={6}
                          xs={6}
                          sm={6}
                          md={6}
                          className="text-right mb-2 p-0 color-grey font-size-12"
                        >
                          R0.00
                        </Col> */}
                        <Col
                          lg={6}
                          xs={6}
                          sm={6}
                          md={6}
                          className="text-left mb-2 p-0 font-size-12"
                        >
                          VAT
                        </Col>
                        <Col
                          lg={6}
                          xs={6}
                          sm={6}
                          md={6}
                          className="text-right mb-2 p-0 color-grey font-size-12"
                        >
                          {/* R{vat} */}
                          R0.00
                        </Col>

                        <Col
                          lg={6}
                          xs={6}
                          sm={6}
                          md={6}
                          className="text-left mb-2 p-0 font-size-12 font-medium"
                        >
                          Order total
                        </Col>
                        <Col
                          lg={6}
                          xs={6}
                          sm={6}
                          md={6}
                          className="text-right mb-2 p-0 color-grey font-medium font-size-12"
                        >
                          R{parseFloat(total).toFixed(2)}
                        </Col>

                        <Col lg={12} className="p-0 mb-3 text-right">
                          {media_count == 0 ? (
                            ""
                          ) : (
                            <div className="w-100 justify-content-center">
                              <Col lg={12} className="text-left">
                                <div className="w-100 font-medium text-left">
                                  Choose payment method
                                </div>
                              </Col>

                              <div
                                className={
                                  virtual_box +
                                  " w-100 align-items-center mt-3 div-flex"
                                }
                                onClick={virtual_payment}
                              >
                                <Col
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                  className="text-center"
                                >
                                  {virtual_inactive && (
                                    <IoMdRadioButtonOff size={25} />
                                  )}
                                  {virtual_active && (
                                    <IoMdRadioButtonOn
                                      size={25}
                                      className={"color-green"}
                                    />
                                  )}
                                </Col>

                                <Col
                                  lg={10}
                                  xs={10}
                                  sm={10}
                                  md={10}
                                  className="text-left"
                                >
                                  Obarak pay
                                  {virtual_present == "no" &&
                                  localStorage.getItem("pos") == null ? (
                                    <>
                                      <br />
                                      <label className="color-grey font-normal font-size-12">
                                        Card not available.
                                      </label>
                                    </>
                                  ) : (
                                    message
                                  )}
                                </Col>
                              </div>
                              <div className="payment-options w-100">
                                {virtual_active && (
                                  <div>
                                    <Row
                                      className={
                                        localStorage.getItem("accountid") !=
                                          null ||
                                        localStorage.getItem("pos") != null
                                          ? "justify-content-center mb-3 mt-4"
                                          : "d-none"
                                      }
                                    >
                                      <Col lg={12}>
                                        <label className="text-left font-medium font-size-12 mb-2 w-100">
                                          Ask the customer for their CVC
                                          <br />
                                          <span className="color-grey font-size-12 font-normal">
                                            Customer will have to approve this
                                            transaction.
                                          </span>
                                        </label>
                                      </Col>
                                      <Col lg={9} md={9} sm={9} xs={8}>
                                        <input
                                          type="text"
                                          placeholder={"Enter cvc"}
                                          onChange={(e) =>
                                            setOVC(e.target.value)
                                          }
                                          className="w-100"
                                        />
                                      </Col>
                                      <Col lg={3} md={3} sm={3} xs={4}>
                                        <button
                                          type="button"
                                          className={
                                            btnColor +
                                            " font-medium color-white font-medium w-100"
                                          }
                                          disabled={disabled}
                                          onClick={verify_ovc}
                                        >
                                          {saveLabel && <span>Verify</span>}
                                          {loadSpinner && (
                                            <BeatLoader size={10} />
                                          )}
                                        </button>
                                      </Col>
                                      {error && (
                                        <Col lg={12}>
                                          <label
                                            className={
                                              "w-100 text-left font-size-12 font-normal color-red"
                                            }
                                          >
                                            {msg}
                                          </label>
                                        </Col>
                                      )}
                                    </Row>
                                    <div
                                      className={
                                        virtual_present == "no" ? "d-none" : ""
                                      }
                                    >
                                      <div
                                        className={
                                          localStorage.getItem("OVC") == null &&
                                          localStorage.getItem("pos") != null
                                            ? "d-none"
                                            : "azania-virtual-basic"
                                        }
                                      >
                                        <div className="header text-center color-white font-medium p-3">
                                          Grey card
                                          {/* <br />
                                          <span className="color-grey font-normal">
                                            Grey card
                                          </span> */}
                                        </div>
                                        <div className="content text-center">
                                          {virtualContent && (
                                            <Row>
                                              <Col
                                                lg={12}
                                                className="color-black"
                                              >
                                                <span className="font-medium color-black">
                                                  Card #:
                                                </span>{" "}
                                                {avn}
                                              </Col>
                                              <Col lg={12} className="">
                                                <span className="font-medium color-black">
                                                  CVC:
                                                </span>{" "}
                                                {avc}
                                              </Col>
                                              {localStorage.getItem("pos") !=
                                              null ? (
                                                <Col
                                                  lg={12}
                                                  className="font-medium"
                                                >
                                                  Card holder:{" "}
                                                  <span className="font-normal">
                                                    {localStorage.getItem(
                                                      "holder"
                                                    )}
                                                  </span>
                                                </Col>
                                              ) : (
                                                <Col
                                                  lg={12}
                                                  className={
                                                    balance == 0
                                                      ? "color-red"
                                                      : balance <= 10
                                                      ? "color-grey"
                                                      : "color-black"
                                                  }
                                                >
                                                  <span className="font-medium">
                                                    Available funds:
                                                  </span>{" "}
                                                  R{balance.toFixed(2)}
                                                </Col>
                                              )}
                                            </Row>
                                          )}
                                          {contentLoading && (
                                            <Row>
                                              <Col lg={12}>
                                                <BeatLoader
                                                  size={10}
                                                  className="color-blue"
                                                />
                                              </Col>
                                            </Row>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div
                                className={
                                  cash_box +
                                  " w-100 align-items-center mt-3 div-flex"
                                }
                                onClick={cash_payment}
                              >
                                <Col
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                  className="text-center"
                                >
                                  {cash_inactive && (
                                    <IoMdRadioButtonOff size={25} />
                                  )}
                                  {cash_active && (
                                    <IoMdRadioButtonOn
                                      size={25}
                                      className={"color-green"}
                                    />
                                  )}
                                </Col>
                                <Col
                                  lg={10}
                                  xs={10}
                                  sm={10}
                                  md={10}
                                  className="text-left"
                                >
                                  {localStorage.getItem("pos") == 1 &&
                                  localStorage.getItem("accountid") != null
                                    ? "Cash"
                                    : "Cash on delivery/collection"}
                                </Col>
                              </div>
                              <div
                                className={
                                  cash_active == false
                                    ? "d-none"
                                    : localStorage.getItem("accountid") !=
                                        null ||
                                      localStorage.getItem("pos") != null
                                    ? "d-block"
                                    : "d-none"
                                }
                              >
                                <Row className="align-items-center">
                                  <Col lg={12} className="mb-2">
                                    {change == 0 ? (
                                      <label className="text-left color-black w-100 font-medium font-size-12">
                                        {note == total ? (
                                          <span className="font-medium color-green font-size-12">
                                            Nice! You may proceed...
                                          </span>
                                        ) : (
                                          "Bank note or coins"
                                        )}
                                      </label>
                                    ) : change < 0 ? (
                                      <label className="text-left color-red w-100 font-medium font-size-12">
                                        Increase the amount
                                      </label>
                                    ) : (
                                      <label className="text-left color-green w-100 font-medium font-size-12">
                                        {note == "" ? (
                                          <span className="color-red font-medium font-size-12">
                                            Enter the amount given by the
                                            customer
                                          </span>
                                        ) : (
                                          "Nice! You may proceed..."
                                        )}
                                      </label>
                                    )}
                                  </Col>

                                  <Col
                                    lg={4}
                                    md={4}
                                    sm={4}
                                    xs={4}
                                    className="text-left"
                                  >
                                    <input
                                      type="text"
                                      placeholder="Amount"
                                      onChange={(e) => bank_note(e)}
                                      value={note}
                                      className="background-white"
                                    />
                                  </Col>

                                  <Col
                                    lg={4}
                                    md={4}
                                    sm={4}
                                    xs={4}
                                    className="text-center"
                                  >
                                    <label className="font-medium font-size-12">
                                      Order cost
                                      <br />
                                      <span className="color-grey font-normal font-size-12">
                                        R{parseFloat(total).toFixed(2)}
                                      </span>
                                    </label>
                                  </Col>

                                  <Col
                                    lg={4}
                                    md={4}
                                    sm={4}
                                    xs={4}
                                    className="text-center"
                                  >
                                    <label className="font-medium font-size-12">
                                      Change
                                      <br />
                                      <span className="color-grey font-normal font-size-12">
                                        R{parseFloat(change).toFixed(2)}
                                      </span>
                                    </label>
                                  </Col>
                                  {note == "" &&
                                  cash_active == true &&
                                  localStorage.getItem("pos") != null ? (
                                    ""
                                  ) : change < 0 ? (
                                    ""
                                  ) : (
                                    <Col lg={12} className="mt-2">
                                      <label className="w-100 text-left font-medium font-size-12 mb-2 mt-3">
                                        Link customer to this purchase
                                        (Optional)
                                      </label>
                                      <br />
                                      <input
                                        type="text"
                                        placeholder={
                                          "Search customer by name or email"
                                        }
                                        value={customer_two}
                                        onChange={(e) => searchCustomer(e)}
                                        className="background-white font-size-12"
                                      />
                                    </Col>
                                  )}
                                  <Col
                                    lg={12}
                                    className={show_results + " mt-2"}
                                  >
                                    <div className="w-100 display_results d-flex">
                                      <label className="w-100 text-left inner-label-results">
                                        {search_results.length >= 1 ? (
                                          <>
                                            {response_msg && (
                                              <div className="w-100 mb-2">
                                                {response}
                                              </div>
                                            )}
                                            {load_results && (
                                              <>
                                                <label className="font-normal font-size-15 click_to_link color-black">
                                                  <span className="font-medium">
                                                    Important:
                                                  </span>{" "}
                                                  Make sure you link the right
                                                  customer.
                                                </label>
                                                <br />
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}

                                        {load_results && <>{results()}</>}
                                      </label>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              <div
                                className={
                                  localStorage.getItem("pos") == 1 &&
                                  localStorage.getItem("accountid") != null
                                    ? " d-none"
                                    : card_box +
                                      " w-100 align-items-center mt-3 div-flex"
                                }
                                onClick={card_payment}
                              >
                                <Col
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                  className="text-center"
                                >
                                  {card_inactive && (
                                    <IoMdRadioButtonOff size={25} />
                                  )}
                                  {card_active && (
                                    <IoMdRadioButtonOn
                                      size={25}
                                      className={"color-green"}
                                    />
                                  )}
                                </Col>

                                <Col
                                  lg={10}
                                  xs={10}
                                  sm={10}
                                  md={10}
                                  className="text-left"
                                >
                                  Online card payment
                                </Col>
                              </div>
                              <Row>
                                {pending_total == 0 ? (
                                  <Col
                                    lg={12}
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    className="browse-left-padding mt-3"
                                  >
                                    {localStorage.getItem("pos") != null &&
                                    localStorage.getItem("OVC") == null &&
                                    localStorage.getItem("customerid") ==
                                      null &&
                                    virtual_active == true ? (
                                      <label
                                        className={
                                          localStorage.getItem("pos") == 1 &&
                                          localStorage.getItem("accountid") !=
                                            null
                                            ? "disabled-btn btn-w-100 font-medium "
                                            : "disabled-btn  w-100 font-medium"
                                        }
                                      >
                                        <IoShieldCheckmarkOutline size={20} />
                                        &nbsp;&nbsp;Add payment
                                      </label>
                                    ) : (
                                      <label>
                                        {(total > 0 &&
                                          note == 0 &&
                                          localStorage.getItem("pos") != null &&
                                          cash_active == true) ||
                                        (change < 0 && cash_active == true) ? (
                                          <label
                                            className={
                                              localStorage.getItem("pos") ==
                                                1 &&
                                              localStorage.getItem(
                                                "accountid"
                                              ) != null
                                                ? "disabled-btn btn-w-100 font-medium "
                                                : "disabled-btn w-100 font-medium"
                                            }
                                          >
                                            <IoShieldCheckmarkOutline
                                              size={20}
                                            />
                                            &nbsp;&nbsp;Add payment
                                          </label>
                                        ) : (
                                          <>
                                            {active_radio_two === true ? (
                                              <label className="font-size-12 color-grey w-100 text-left">
                                                <span className="font-medium font-size-12 color-black">
                                                  Please note:
                                                </span>
                                                <br />
                                                Delivery is not yet available.
                                                All our customers will be
                                                notified; but for now you can
                                                select "Collection".
                                              </label>
                                            ) : (
                                              <button
                                                type="button"
                                                className={
                                                  localStorage.getItem("pos") ==
                                                    1 &&
                                                  localStorage.getItem(
                                                    "accountid"
                                                  ) != null
                                                    ? btnColor +
                                                      " btn-w-100 font-medium "
                                                    : total > 0 &&
                                                      note == 0 &&
                                                      cash_active == true &&
                                                      localStorage.getItem(
                                                        "pos"
                                                      ) != null
                                                    ? "disabled-btn w-100 font-medium"
                                                    : btnColor +
                                                      " w-100 font-medium"
                                                }
                                                disabled={disabled}
                                                onClick={add_payment_btn}
                                              >
                                                <IoShieldCheckmarkOutline
                                                  size={20}
                                                />
                                                &nbsp;&nbsp;Add payment
                                              </button>
                                            )}
                                          </>
                                        )}
                                      </label>
                                    )}
                                  </Col>
                                ) : (
                                  ""
                                )}

                                <Col lg={12} className="mt-3">
                                  <Accordion flush>
                                    <Accordion.Item eventKey="0">
                                      <Accordion.Header className="accorder-bg">
                                        {basketCount}&nbsp;
                                        {basketCount == 1
                                          ? " item"
                                          : " items"}{" "}
                                        in Basket
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        {map_media()}
                                        {LoadBeat && (
                                          <label className="text-center w-100">
                                            <BeatLoader color={"#39c"} />
                                          </label>
                                        )}
                                        <Link to="/basket">
                                          <label className="color-blue cursor w-100 text-center mt-3 p-0">
                                            Back to basket
                                          </label>
                                        </Link>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Stack>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
      <Modal show={error_modal} onHide={closeError} centered>
        <Modal.Header closeButton>
          <Modal.Title>Note:</Modal.Title>
        </Modal.Header>
        <Modal.Body className="color-red">
          Price not available for the selected size.
        </Modal.Body>
      </Modal>

      <Modal show={mediaDetails} onHide={() => handleClose("item_display")}>
        <Modal.Header closeButton>
          <Modal.Title>
            {item}
            <br />
            <label className="color-grey font-normal font-size-12">
              by {company_name}
            </label>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center align-items-center">
            <img
              className="img-border-radius w-100"
              src={root_url + "/assets/" + account_id + "/" + selectedMediaImg}
            />
            <br />

            <Row>
              <Col lg={12} className="font-size-17 mb-0 mt-3 color-grey p-0">
                <b>Description</b>
                <br />{" "}
                {description == "" || description == "null"
                  ? "Not available"
                  : description}
                <br />
                <br />
                <b>Consumable:</b> {intervals}
                <br />
                <b>Food kind:</b> {category}
                <br />
                <b>Type:</b> {type}
              </Col>
            </Row>
          </Row>
        </Modal.Body>
        {added_to_basket && (
          <Modal.Footer>
            <label className="w-100 text-center color-green">{errorMsg}</label>
            {/* <label className="cancel-btn text-bold" onClick={() => handleClose("close_decline")}>
            Close
          </label> */}
          </Modal.Footer>
        )}
      </Modal>

      <Modal
        show={onShow}
        onHide={() => handleClose("close_decline")}
        keyboard={false}
        backdrop={approve_transaction == "approved" ? "static" : "static"}
      >
        <Modal.Header>
          <Modal.Title>
            <span className="font-medium">Transaction</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {approve_transaction == "approved" ? (
            <label className="color-green font-normal font-size-15 w-100 text-center">
              <IoIosCheckmarkCircleOutline size={25} />
              <br />
              Transaction approved
            </label>
          ) : (
            <label className="color-red font-normal font-size-15 w-100 text-center">
              <IoIosCloseCircleOutline size={25} />
              <br />
              {localStorage.getItem("holder")}  declined transaction
            </label>
          )}
        </Modal.Body>
        <Modal.Footer>
          {approve_transaction == "approved" ? (
            <label
              className={btnColor + " font-medium "}
              disabled={disabled}
              onClick={add_payment_btn}
            >
              Complete transaction
            </label>
          ) : (
            <Row className="w-100 justify-content-center">
              {transaction_aborted && (
                <Col lg={12} className="text-center mb-3">
                  <span className="color-green">Transaction aborted</span>
                </Col>
              )}
              <Col lg={6} md={6} sm={6} xs={6} className="text-center">
                <label
                  className={
                    btnColor +
                    " browse-w-100 font-medium color-white font-medium "
                  }
                  disabled={disabled}
                  onClick={() => cancel_transaction('declined')}
                >
                  {saveLabel && <span>Close</span>}
                  {loadSpinner && <BeatLoader size={10} />}
                </label>
              </Col>
              {/* <Col lg={6} md={6} sm={6} xs={6} className="text-left">
                <label
                  className="browse-w-100 cancel-btn font-medium color-light-black"
                  onClick={() => handleClose("close_decline")}
                >
                  Try a different CVC
                </label>
              </Col> */}
            </Row>
          )}
        </Modal.Footer>
      </Modal>

      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
          onClick={close_error_message}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}

localStorage.removeItem("updated");
