import React, { useState, useEffect, useHistory } from "react";
import Authentication from "./navigation/authentication";
import { Col, Container, Row, Stack } from "react-bootstrap";
import SideNav from "./navigation/SideNav";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Select from "react-select";
import BeatLoader from "react-spinners/BeatLoader";
import validator from "validator";
import MultipleValueTextInput from "react-multivalue-text-input";
import { IoIosCheckmark } from "react-icons/io";
import {
  BsFileEarmarkPdf,
  BsFillFileEarmarkSpreadsheetFill,
  BsFillFileEarmarkFontFill,
} from "react-icons/bs";
import ReactTooltip from "react-tooltip";
import axios from "axios";
const baseURL = process.env.REACT_APP_URL;
const root_url = process.env.REACT_APP_URL_RESOURCE;
export default function Index() {
  const navigate = useNavigate();
  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [btnColor, setBtnColor] = useState("action-btn");
  const [disabled, setDisabled] = useState("");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [count, setCount] = useState(0);
  const [payments, setPayments] = useState([]);

  const accountid = localStorage.getItem("accountid");
  function fetch_due_payments() {
    axios
      .get(baseURL + "?query=DUE-PAYMENTS&accountid=" + accountid)
      .then((response) => {
        setPayments(response.data);
      });
  }
  useEffect(() => {
    document.title = "Obarak / Payouts";
    fetch_due_payments();
  }, [count]);

  function show_payments() {
    return (
      <>
        {payments.length <= 0 ? (
          <label className="color-grey font-medium">No payments due</label>
        ) : (
          <>
            <Row className="mb-3">
              <Col lg={3} xs={3} sm={3} md={3} className="font-medium">
                Amount
              </Col>
              <Col lg={3} xs={3} sm={3} md={3} className="font-medium">
                Month
              </Col>
              <Col lg={2} xs={2} sm={2} md={2} className="font-medium">
                Status
              </Col>
              <Col lg={2} xs={2} sm={2} md={2} className="font-medium">
                Acc number
              </Col>
              <Col lg={2} xs={2} sm={2} md={2} className="font-medium">
                Ref
              </Col>
            </Row>
            {payments.map((item, index) => {
              return (
                <Row className="mb-3">
                  <Col
                    lg={3}
                    xs={3}
                    sm={3}
                    md={3}
                    key={index}
                    className="color-grey font-size-12"
                  >
                    {item.paid == "no" ? (
                      <label
                        className="color-orange font-size-12"
                        data-tip="Pending..."
                        data-effect={"solid"}
                        data-place="top"
                        data-type={"dark"}
                        data-arrow-color={"transparent"}
                        data-class="tool_tips"
                      >
                        Due&nbsp;&nbsp;
                        <ReactTooltip />
                      </label>
                    ) : (
                      <label
                        className="color-orange font-size-12"
                        data-tip="Paid"
                        data-effect={"solid"}
                        data-place="top"
                        data-type={"dark"}
                        data-arrow-color={"transparent"}
                        data-class="tool_tips"
                      >
                        <IoIosCheckmark size={30} className="color-green" />
                        <ReactTooltip />
                      </label>
                    )}{" "}
                    R{item.amount}
                  </Col>
                  <Col
                    lg={3}
                    xs={3}
                    sm={3}
                    md={3}
                    key={index}
                    className="color-grey font-size-12"
                  >
                    {item.month}
                  </Col>
                  <Col
                    lg={2}
                    xs={2}
                    sm={2}
                    md={2}
                    key={index}
                    className="color-grey font-size-12"
                  >
                    {item.paid == "no" ? (
                      <label className="font-size-12">Pending...</label>
                    ) : (
                      <label className="font-size-12">Paid</label>
                    )}
                  </Col>
                  <Col
                    lg={2}
                    xs={2}
                    sm={2}
                    md={2}
                    key={index}
                    className="color-grey font-size-12"
                  >
                    {item.acc_number}
                  </Col>
                  <Col
                    lg={2}
                    xs={2}
                    sm={2}
                    md={2}
                    key={index}
                    className="color-grey font-size-12"
                  >
                    {item.reference}
                  </Col>
                </Row>
              );
            })}
          </>
        )}
      </>
    );
  }

  return (
    <div className="body">
      <Authentication />
      <Container className="container-home">
        <Row>
          <Col lg={2} xs={2} md={3} className="p-0">
            <SideNav />
          </Col>
          <Col lg={10} xs={10} md={9}>
            <Row className="h-100 align-items-center justify-content-center">
              <form>
                <Row className="h-100 align-items-center justify-content-center">
                  <Col lg={9}>
                    <Stack>
                      <div className="box-container">
                        <Row className="align-items-center">
                          <Col lg={12}>
                            <label className="text-medium">Payouts</label>
                            <br />
                            <span className="font-size-12 color-grey">
                              These payouts only apply to online orders.
                            </span>
                          </Col>

                          <Col lg={12}>
                            <div className="h-divider" />
                          </Col>

                          <Col lg={12} className="mb-2">
                            {show_payments()}
                          </Col>
                        </Row>
                      </div>
                    </Stack>
                  </Col>
                </Row>
              </form>
            </Row>
          </Col>
        </Row>
      </Container>
      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}
