import React, { useState, useEffect, useHistory } from "react";
import Authentication from "./navigation/authentication";
import { Col, Container, Row, Stack } from "react-bootstrap";
import SideNav from "./navigation/SideNav";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Select from "react-select";
import BeatLoader from "react-spinners/BeatLoader";
import validator from "validator";
import MultipleValueTextInput from "react-multivalue-text-input";
import { IoIosAttach, IoMdImage, IoIosDocument } from "react-icons/io";
import {
  BsFileEarmarkPdf,
  BsFillFileEarmarkSpreadsheetFill,
  BsFillFileEarmarkFontFill,
} from "react-icons/bs";
import ReactTooltip from "react-tooltip";
import axios from "axios";
const baseURL = process.env.REACT_APP_URL;
const root_url = process.env.REACT_APP_URL_RESOURCE;
export default function Email(props) {
  const navigate = useNavigate();
  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [btnColor, setBtnColor] = useState("action-btn");
  const [disabled, setDisabled] = useState("");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [displayImge, setDisplayImge] = useState([]);
  const [showImage, setshowImage] = useState(false);
  const [allimgs, setAllImgs] = useState("");
  const [count, setCount] = useState(0);
  const params = window.location.search;
  const urlParams = new URLSearchParams(params);
  const encrypted_email = urlParams.get("q_uery");
  const [inHouseEmail, setInHouseEmail] = useState(encrypted_email);
  const [recipientName, setRecipientName] = useState("");

  function get_customer_email() {
    axios
      .get(
        baseURL + "?query=GET_CUSTOMER_EMAIL&encrypted_email=" + encrypted_email
      )
      .then((response) => {
        setInHouseEmail(response.data.email);
        setRecipientName(response.data.fullname);
      });
  }
  useEffect(() => {
    document.title = "Obarak / Email";
    get_customer_email();
  }, []);
  // const history = useHistory();
  function submit(e) {
    e.preventDefault();
    const formData = new FormData();
    const accountid = localStorage.getItem("accountid");
    let encrypted_email, encrypted_email_set;
    if (urlParams.get("q_uery")) {
      encrypted_email = urlParams.get("q_uery");
      encrypted_email_set = "yes";
    } else {
      encrypted_email = "";
      encrypted_email_set = "no";
    }
    for (let i = 0; i < allimgs.length; i++) {
      formData.append("file[]", allimgs[i]);
    }
    formData.append("subject", subject);
    formData.append("message", message);
    formData.append("accountid", accountid);
    formData.append("encrypted_email", encrypted_email);
    formData.append("encrypted_email_set", encrypted_email_set);
    formData.append("query", "SEND_EMAIL");

    if (subject === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Subject cannot be empty");
    } else if (message == "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Type a message, a simple 'Hi' will be a good start.");
    } else {
      setBtnColor("disabled-btn");
      setDisabled("disabled");
      setLoadSpinner(true);
      setSaveLabel(false);
      // connect to the api

      axios({
        method: "POST",
        url: baseURL,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then((response) => {
        setBtnColor("action-btn");
        setDisabled("");
        setLoadSpinner(false);
        setSaveLabel(true);

        if (response.data.status == "error") {
          setBgColor("red");
          setDisplayMessage(true);
          setErrorMsg(response.data.message);
        } else {
          setCount(count + 1);
          setBgColor("#66b266");
          setDisplayMessage(true);
          setMessage("");
          setSubject("");
          setAllImgs("");
          setDisplayImge([]);
          setErrorMsg(response.data.message);
        }
      });
    }
    const timer = setTimeout(() => {
      setDisplayMessage(false);
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  }

  function selectImage(event) {
    setAllImgs(event.target.files);
    setDisplayImge([]);
    const img = event.target.files[0];
    setshowImage(true);
    let images = [];
    for (let i = 0; i < event.target.files.length; i++) {
      images.push({
        img: URL.createObjectURL(event.target.files[i]),
        file: event.target.files[i].type,
        name: event.target.files[i].name,
      });
      console.log(images);
    }
    setDisplayImge(images);
  }

  function remove() {
    setCount(count + 1);
    setAllImgs("");
    setDisplayImge([]);
    navigate("/email");
  }
  let imgAttachment = "";

  return (
    <div className="body">
      <Authentication />
      <Container className="container-home">
        <Row>
          <Col lg={2} xs={2} md={3} className="p-0">
            <SideNav />
          </Col>
          <Col lg={10} xs={10} md={9}>
            <Row className="h-100 align-items-center justify-content-center">
              <form onSubmit={submit}>
                <Row className="h-100 align-items-center justify-content-center">
                  <Col lg={9}>
                    <Stack>
                      <div className="box-container">
                        <Row className="align-items-center">
                          <Col lg={12}>
                            <label className="text-medium">Compose email</label>
                          </Col>

                          <Col lg={12}>
                            <div className="h-divider" />
                          </Col>
                          {urlParams.get("q_uery") ? (
                            <Col lg={12} className="mb-3">
                              <label className="font-size-12 font-medium color-grey">
                                To: {recipientName}
                              </label>
                              <input
                                className="mt-1 encrypted_email_field color-grey"
                                type="text"
                                placeholder="Enter email address"
                                value={inHouseEmail}
                                readOnly
                              />
                            </Col>
                          ) : (
                            ""
                          )}

                          <Col lg={12} className="mb-3">
                            <label className="font-size-12 font-medium color-grey">
                              Add subject
                            </label>
                            <br />
                            <input
                              type="text"
                              name="subject"
                              placeholder="Enter email subject"
                              onChange={(e) => setSubject(e.target.value)}
                              value={subject}
                            />
                          </Col>

                          <Col lg={12} className="mb-2">
                            <label className="font-size-12 font-medium color-grey">
                              Type your message
                            </label>
                            <br />
                            <textarea
                              name="message"
                              placeholder="Write message..."
                              onChange={(e) => setMessage(e.target.value)}
                              value={message}
                            />
                          </Col>
                          <Col lg={12}>
                            {showImage && (
                              <>
                                {" "}
                                <div className="food-container mt-0">
                                  <Row>
                                    {displayImge.length > 0 ? (
                                      <Col lg={12} className="mb-2 remove-text">
                                        <label
                                          onClick={remove}
                                          className="cursor"
                                        >
                                          Remove
                                        </label>
                                      </Col>
                                    ) : (
                                      ""
                                    )}

                                    {displayImge.map((img, index) => {
                                      return (
                                        <>
                                          {img["file"] != "image/jpeg" &&
                                          img["file"] != "image/png" &&
                                          img["file"] != "image/gif" ? (
                                            <Col lg={12} xs={12} sm={12}>
                                              {/* {img["file"]} */}
                                              <a
                                                href={img["img"]}
                                                target="_blank"
                                              >
                                                <label
                                                  classname="color-blue color-blue-hover text-underline-on-hover"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  {img["file"] ==
                                                  "application/pdf" ? (
                                                    <BsFileEarmarkPdf
                                                      size={20}
                                                      color={"red"}
                                                    />
                                                  ) : img["file"] ==
                                                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                                                    <BsFillFileEarmarkSpreadsheetFill
                                                      size={20}
                                                      color={"#4ca64c"}
                                                    />
                                                  ) : img["file"] ==
                                                    "text/plain" ? (
                                                    <BsFillFileEarmarkFontFill
                                                      size={20}
                                                      color={"#39c"}
                                                    />
                                                  ) : (
                                                    <IoIosDocument
                                                      size={20}
                                                      color={"#ffb732"}
                                                    />
                                                  )}
                                                  &nbsp;
                                                  {img["name"]}
                                                </label>
                                              </a>
                                            </Col>
                                          ) : (
                                            <Col
                                              lg={2}
                                              md={4}
                                              xs={6}
                                              sm={4}
                                              className="p-1"
                                            >
                                              <a
                                                href={img["img"]}
                                                target="_blank"
                                                className="cursor"
                                              >
                                                <label
                                                  title={"image " + ++index}
                                                  className={
                                                    "mt-0 border-radius-5 email-upload-image cursor"
                                                  }
                                                  style={{
                                                    backgroundImage:
                                                      "url(" + img["img"] + ")",
                                                  }}
                                                />
                                              </a>
                                            </Col>
                                          )}
                                        </>
                                      );
                                    })}
                                  </Row>
                                </div>
                              </>
                            )}
                          </Col>
                          {displayImge.length > 0 ? (
                            <div classname="w-100" style={{ height: 20 }} />
                          ) : (
                            ""
                          )}
                          <Col lg={3} md={2} sm={2} xs={3} className="">
                            <button
                              type="submit"
                              className={btnColor}
                              disabled={disabled}
                            >
                              {saveLabel && <span>Send</span>}
                              {loadSpinner && <BeatLoader size={10} />}
                            </button>
                          </Col>
                          <Col
                            lg={9}
                            md={10}
                            sm={10}
                            xs={9}
                            className="text-right"
                          >
                            <label
                              htmlFor="image"
                              className="email-icons"
                              data-tip="Insert image"
                              data-effect="solid"
                              data-arrow-color="transparent"
                              data-type="dark"
                              data-place="top"
                            >
                              <IoMdImage size={20} />
                              <ReactTooltip />

                              <input
                                name="file[]"
                                type="file"
                                onChange={selectImage}
                                id="image"
                                accept={".jpg,.jpeg,.png,.gif"}
                                multiple
                                className="hide-file-input-type"
                              />
                            </label>

                            <label
                              htmlFor="file"
                              className="email-icons"
                              data-tip="Attach file"
                              data-effect="solid"
                              data-arrow-color="transparent"
                              data-type="dark"
                              data-place="top"
                            >
                              <IoIosAttach size={20} />
                              <ReactTooltip />

                              <input
                                name="file[]"
                                type="file"
                                onChange={selectImage}
                                id="file"
                                accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                multiple
                                className="hide-file-input-type"
                              />
                            </label>
                          </Col>
                        </Row>
                      </div>
                    </Stack>
                  </Col>
                </Row>
              </form>
            </Row>
          </Col>
        </Row>
      </Container>
      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}
