import React, { useState, useEffect } from "react";
import { Col, Container, Row, Stack, Modal, Button } from "react-bootstrap";
import Nav from "./navigation/Nav";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Select from "react-select";
import BeatLoader from "react-spinners/BeatLoader";
import validator from "validator";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import ReactTooltip from "react-tooltip";
import axios from "axios";

export default function Index() {
  const navigate = useNavigate();
  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [btnColor, setBtnColor] = useState("login-btn");
  const [disabled, setDisabled] = useState("");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const [code, setCode] = useState("");

  useEffect(() => {
    document.title = "Obarak / Set new password";
  });

  const baseURL = process.env.REACT_APP_URL;
  const params = window.location.search;
  const urlParams = new URLSearchParams(params);
  const email_para = urlParams.get("email");
  let email = "";
  if (localStorage.getItem("email") == null) {
    localStorage.setItem("email", email_para);
  }

  email = localStorage.getItem("email");

  function submit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("pass1", pass1);
    formData.append("pass2", pass2);
    formData.append("code", code);
    formData.append("email", email);
    formData.append("query", "SAVE_PASSWORD");

    if (pass1 === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter your new password");
    } else if (pass1.length < 6) {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Password should at least be 6 characters in length");
    } else if (pass1 !== pass2) {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Passwords must be identical");
    } else if (code === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter the code...");
    } else {
      setBtnColor("disabled-btn");
      setDisabled("disabled");
      setLoadSpinner(true);
      setSaveLabel(false);
      // connect to the api

      axios({
        method: "POST",
        url: baseURL,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then((res) => {
        if (res.data.status == "ok") {
          navigate("/perfect", {
            state: { message: res.data.message, token: "saved" },
          });
          //   setBgColor("#66b266");
          //   setDisplayMessage(true);
          //   setErrorMsg(res.data.message);
        } else {
          setBgColor("#ff1919");
          setDisplayMessage(true);
          setErrorMsg(res.data.message);
        }

        setBtnColor("login-btn");
        setDisabled("");
        setLoadSpinner(false);
        setSaveLabel(true);
      });
    }
    setTimeout(() => {
      setDisplayMessage(false);
    }, 5000);
  }

  return (
    <div className="package-body height-100hv">
      <Container className="container-home height-100hv">
        <Row className="height-100hv justify-content-center">
          <Col lg={12} xs={12} md={12} className="p-0">
            <Nav />
          </Col>
          <Col lg={6} xs={12} md={12}>
            <Row className="align-items-center justify-content-center">
              <form onSubmit={submit}>
                <Row className="h-100 align-items-center justify-content-center">
                  <Col lg={7}>
                    <Stack>
                      <div className="text-center w-100 mb-3">
                        <h3>Set new password</h3>
                      </div>
                      <div className="box-container mb-5">
                        <Row>
                          <Col lg={12} className="text-center">
                            <label className="text-medium font-size-17">
                              Set password
                            </label>
                          </Col>

                          <Col lg={12} className="mb-3 mt-3">
                            <input
                              type="password"
                              placeholder="New password"
                              onChange={(e) => setPass1(e.target.value)}
                              value={pass1}
                            />
                          </Col>
                          <Col lg={12} className="mb-3 mt-3">
                            <input
                              type="password"
                              placeholder="Confirm password"
                              onChange={(e) => setPass2(e.target.value)}
                              value={pass2}
                            />
                          </Col>

                          <Col lg={12} className="mb-3 mt-0">
                            <label className="font-medium font-size-12">
                              Check your emails for this code
                            </label>
                            <input
                              type="text"
                              placeholder="Enter code"
                              onChange={(e) => setCode(e.target.value)}
                              value={code}
                            />
                          </Col>

                          <Col lg={12} className="mb-3">
                            <button
                              type="submit"
                              className={
                                btnColor + " w-100 text-bold font-size-17"
                              }
                              disabled={disabled}
                            >
                              {saveLabel && <span>Continue</span>}
                              {loadSpinner && <BeatLoader size={10} />}
                            </button>
                          </Col>

                          <Col lg={12} className="mb-3 text-center">
                            Already a host?{" "}
                            <Link to="/login" className="anchor">
                              Login
                            </Link>
                            <br />
                            Not a host yet?{" "}
                            <Link to="/packages" className="anchor">
                              Become a one
                            </Link>
                          </Col>
                        </Row>
                      </div>
                    </Stack>
                  </Col>
                </Row>
              </form>
            </Row>
          </Col>
        </Row>
      </Container>
      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}
