import React, { useState, useEffect } from "react";
import Auth from "./navigation/auth";
import { Col, Container, Row, Stack, Modal, Button } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import HomeNav from "./navigation/HomeNav";
import BeatLoader from "react-spinners/BeatLoader";
import validator from "validator";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { IoRestaurantOutline } from "react-icons/io5";
import { FaMotorcycle } from "react-icons/fa";
import { BsBagCheck } from "react-icons/bs";
const baseURL = process.env.REACT_APP_URL;
const root_url = process.env.REACT_APP_URL_RESOURCE;

const random_number = Math.floor(Date.now() * Math.random());
const avn_ = random_number;
const avc_ = uuid().substring(0, 3).toUpperCase();

export default function Track() {
  const navigate = useNavigate();
  const [businessname, setBusinessname] = useState(
    localStorage.getItem("company_name")
  );

  const [phone, setPhone] = useState(null);
  const [current_phone, setCurrent_phone] = useState(null);
  const [location, setLocation] = useState("");
  const [current_location, setcurrent_location] = useState("");
  const [email, setEmail] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [btnColor, setBtnColor] = useState("action-btn");
  const [disabled, setDisabled] = useState("");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [count, setCount] = useState(0);
  const [virtual_present, setVirtual_present] = useState("no");
  const [balance, setBalance] = useState(0);
  const [avn, setAvn] = useState("");
  const [avc, setAvc] = useState("");
  const [contentLoading, setContentLoading] = useState(true);
  const [virtualContent, setVirtualContent] = useState(false);

  const accountid = localStorage.getItem("customerid");
  useEffect(() => {
    document.title = "Obarak / Track orders";
  }, [count]);

  return (
    <div className="body">
      <Auth />
      <Container className="container-home">
        <Row>
          <Col lg={2} xs={2} md={3} className="p-0">
            <HomeNav />
          </Col>
          <Col lg={10} xs={10} md={9}>
            <Row className="h-100 align-items-center justify-content-center">
              <Row className="h-100 align-items-center justify-content-center">
                <Col lg={9}>
                  <Stack>
                    <div className="box-container">
                      <Row className="justify-content-center align-items-center">
                        <Col lg={12}>
                          <label className="text-medium">Tracking orders</label>
                        </Col>

                        <Col lg={12}>
                          <div className="h-divider" />
                        </Col>

                        <Col
                          lg={4}
                          sm={4}
                          md={4}
                          xs={4}
                          className="mb-3 p-0 font-size-12 color-grey text-center"
                        >
                          <IoRestaurantOutline size={20} color="darkorange" />
                          <div className="track-rails-orange" />
                          Preparing...
                        </Col>

                        <Col
                          lg={4}
                          sm={4}
                          md={4}
                          xs={4}
                          className="mb-3 p-0 font-size-12 color-grey text-center"
                        >
                          <FaMotorcycle size={20} color="#069" />
                          <div className="track-rails-blue" />
                          On my way...
                        </Col>

                        <Col
                          lg={4}
                          sm={4}
                          md={4}
                          xs={4}
                          className="mb-3 p-0 font-size-12 color-grey text-center"
                        >
                          <BsBagCheck size={20} color="#4ca64c" />
                          <div className="track-rails-green" />
                          Delivered!
                        </Col>
                      </Row>
                    </div>
                  </Stack>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </Container>
      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}
