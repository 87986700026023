import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Stack,
  Modal,
  Button,
  Accordion,
} from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import HomeNav from "./navigation/HomeNav";
import SideNav from "./navigation/SideNav";
import {
  FaWineGlassAlt,
  FaGlassCheers,
  FaWineGlass,
  FaGlassWhiskey,
  FaDirections,
  FaMotorcycle,
} from "react-icons/fa";
import {
  BsPeople,
  BsFileImage,
  BsFillHouseFill,
  BsClockHistory,
  BsPlusCircleDotted,
  BsBarChart,
  BsCurrencyExchange,
  BsFillBagCheckFill,
  BsPerson,
  BsShieldLock,
  BsChatRightQuote,
  BsPersonPlus,
  BsPersonPlusFill,
  BsTelephone,
  BsStopCircle,
} from "react-icons/bs";
import {
  IoIosTrash,
  IoMdPerson,
  IoMdEasel,
  IoIosAdd,
  IoIosCheckmark,
  IoIosClose,
  IoMdCreate,
  IoMdSquareOutline,
  IoMdCheckbox,
  IoMdArrowBack,
  IoIosSearch,
  IoMdClose,
  IoMdGlobe,
  IoIosHeartEmpty,
  IoIosHeart,
} from "react-icons/io";
import {
  IoRestaurantOutline,
  IoRestaurantSharp,
  IoIceCreamOutline,
  IoBeerOutline,
  IoCloseSharp,
  IoQrCodeOutline,
  IoNewspaperOutline,
} from "react-icons/io5";
import { BiEnvelope, BiChevronRight } from "react-icons/bi";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { v4 as uuid } from "uuid";
const baseURL = process.env.REACT_APP_URL;
const root_url = process.env.REACT_APP_URL_RESOURCE;
const accountid = localStorage.getItem("accountid");
export default function Index() {
  let unique_uuid = uuid();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showBanned, setShowBanned] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [companyID, setCompanyID] = useState("");
  const [companyCategory, setcompanyCategory] = useState("");
  const [sanctionReason, setsanctionReason] = useState("");
  const [results, setResults] = useState([]);
  const [results_count, setResults_count] = useState(0);
  const [mediaDetails, setMediaDetails] = useState(false);
  const [error_modal, setError_modal] = useState(false);
  const [displayImge, setDisplayImge] = useState([]);
  const [preimage, setPreImage] = useState("");
  const [imgname, setImgname] = useState("");
  const [path, setPath] = useState("");
  const [showUploadBtn, setShowUploadBtn] = useState(false);
  const [showImage, setshowImage] = useState(false);
  const [arraySize, setArraySize] = useState(0);
  const [disabled, setDisabled] = useState("");
  const [search_value, setSearch_value] = useState("");
  const [btnColor, setBtnColor] = useState("karamale-login-btn");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [allimgs, setAllImgs] = useState("");
  const [media_count, setMedia_count] = useState(0);
  const [media, setMedia] = useState([]);
  const [selectedMediaID, setSelectedMediaID] = useState(0);
  const [selectedMediaImg, setSelectedMediaImg] = useState(null);
  const [successOne, setSuccessOne] = useState(false);
  const [successTwo, setSuccessTwo] = useState(false);
  const [successThree, setSuccessThree] = useState(false);
  const [successFour, setSuccessFour] = useState(false);
  const [textOne, setTextOne] = useState(true);
  const [beatOne, setBeatOne] = useState(false);
  const [textTwo, setTextTwo] = useState(true);
  const [beatTwo, setBeatTwo] = useState(false);
  const [textThree, setTextThree] = useState(true);
  const [beatThree, setBeatThree] = useState(false);
  const [textFour, setTextFour] = useState(true);
  const [beatFour, setBeatFour] = useState(false);
  const [LoadBeat, setLoadBeat] = useState(true);
  const [NoMediaLoging, setNoMediaLoging] = useState(false);
  const [itemID, setItemID] = useState(0);
  const [date_uploaded, setDate_uploaded] = useState(null);
  const [media_size, setMedia_size] = useState(0);
  const [item, setItem] = useState("");
  const [normal, setNormal] = useState("");
  const [small, setSmall] = useState("");
  const [medium, setMedium] = useState("");
  const [fullscreen, setFullscreen] = useState(true);
  const [large, setLarge] = useState("");
  const [extraLarge, setExtraLarge] = useState("");
  const [bulk, setBulk] = useState("");
  const [volume, setVolume] = useState("");
  const [unit, setUnit] = useState("");
  const [other_price, setOther_price] = useState("");
  const [item_type, setItem_type] = useState("");
  const [description, setDes] = useState("");
  const [online_availability, setOnline_availability] = useState("");
  const [more, setMore] = useState(true);
  const [less, setLess] = useState(false);
  const [intervals, setIntervals] = useState("");
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");
  const [seeMore, setSeeMore] = useState(false);
  const [UncheckedOne, setUncheckedOne] = useState(true);
  const [CheckedOne, setCheckedOne] = useState(false);
  const [quantityOne, setQuantityOne] = useState(false);

  const [UncheckedTwo, setUncheckedTwo] = useState(true);
  const [CheckedTwo, setCheckedTwo] = useState(false);
  const [quantityTwo, setQuantityTwo] = useState(false);

  const [UncheckedThree, setUncheckedThree] = useState(true);
  const [CheckedThree, setCheckedThree] = useState(false);
  const [quantityThree, setQuantityThree] = useState(false);

  const [UncheckedFour, setUncheckedFour] = useState(true);
  const [CheckedFour, setCheckedFour] = useState(false);
  const [quantityFour, setQuantityFour] = useState(false);

  const [UncheckedFive, setUncheckedFive] = useState(true);
  const [CheckedFive, setCheckedFive] = useState(false);
  const [quantityFive, setQuantityFive] = useState(false);

  const [UncheckedSix, setUncheckedSix] = useState(true);
  const [CheckedSix, setCheckedSix] = useState(false);
  const [quantitySix, setQuantitySix] = useState(false);

  const [quantitesOne, setQuantitesOne] = useState("");
  const [quantitesTwo, setQuantitesTwo] = useState("");
  const [quantitesThree, setQuantitesThree] = useState("");
  const [quantitesFour, setQuantitesFour] = useState("");
  const [quantitesFive, setQuantitesFive] = useState("");
  const [quantitesSix, setQuantitesSix] = useState("");

  const [totalOne, setTotalOne] = useState(0);
  const [totalTwo, setTotalTwo] = useState(0);
  const [totalThree, setTotalThree] = useState(0);
  const [totalFour, setTotalFour] = useState(0);
  const [totalFive, setTotalFive] = useState(0);
  const [totalSix, setTotalSix] = useState(0);
  const [grand_total, setGrand_total] = useState(0);
  const [count, setCount] = useState(0);
  const [total_value, setTotal_value] = useState(0);
  const [account_id, setAccountid] = useState(0);

  const [added_to_basket, setAdded_to_basket] = useState(false);
  const [add_button, setAdd_button] = useState(false);
  const [tradingHours, setTradingHours] = useState([]);
  const [showTradingHours, setShowTradingHours] = useState(false);

  const params = window.location.search;
  const urlParams = new URLSearchParams(params);

  let customerid,
    accountid = "";
  if (localStorage.getItem("customerid") != null) {
    customerid = localStorage.getItem("customerid");
  } else {
    customerid = 0;
  }

  if (localStorage.getItem("accountid") != null) {
    accountid = localStorage.getItem("accountid");
  } else {
    accountid = 0;
  }

  function fetch_trading_hrs() {
    axios
      .get(
        baseURL +
          "?query=VIEW_TRADING_HOURS&accountid=" +
          urlParams.get("com_id")
      )
      .then((response) => {
        setTradingHours(response.data);
      });
  }

  function itemCount() {
    axios.get(baseURL + "?query=ITEM-COUNT-HOME").then((response) => {
      setMedia_count(response.data.count);
    });
  }

  function fetchItems() {
    axios
      .get(
        baseURL +
          "?query=ITEMS-HOME&display=HOME&customerid=" +
          customerid +
          "&accountid=" +
          accountid +
          "&company_id=" +
          urlParams.get("com_id")
      )
      .then((response) => {
        setMedia(response.data);
        setNoMediaLoging(true);

        setLoadBeat(false);
      });
  }
  useEffect(() => {
    document.title = urlParams.get("q") + " - Obarak";
    setGrand_total(
      totalOne + totalTwo + totalThree + totalFour + totalFive + totalSix
    );

    if (
      CheckedOne == false &&
      CheckedTwo == false &&
      CheckedThree == false &&
      CheckedFour == false &&
      CheckedFive == false &&
      CheckedSix == false
    ) {
      setAdd_button(false);
    } else {
      setAdd_button(true);
    }

    // localStorage.getItem("total_value", total_value);
    fetch_trading_hrs();
    itemCount();
    fetchItems();

    localStorage.removeItem("normal");
    localStorage.removeItem("small");
    localStorage.removeItem("medium");
    localStorage.removeItem("large");
    localStorage.removeItem("extra-large");
    localStorage.removeItem("bulk");
    localStorage.removeItem("other");
    // localStorage.removeItem("foodid");
    localStorage.removeItem("description");
  }, [count]);

  const handleClose = () => {
    setShow(false);
    setMediaDetails(false);
    setShowTradingHours(false);
  };
  const handleBanned = (
    companyName,
    companyEmail,
    companyLogo,
    companyID,
    companyCategory,
    sanctionReason
  ) => {
    setCompanyName(companyName);
    setCompanyEmail(companyEmail);
    setCompanyLogo(companyLogo);
    setCompanyID(companyID);
    setcompanyCategory(companyCategory);
    setsanctionReason(sanctionReason);
    setShowBanned(true);
    setCount(count + 1);
  };

  const handleShow = () => setShow(true);
  // const selectedMedia = () => setMediaDetails(true);

  function selectMedia(
    media,
    mediaid,
    itemid,
    item_name,
    normal,
    small,
    medium,
    large,
    extra_large,
    bulk,
    item_type,
    description,
    online_availability,
    intervals,
    category,
    type,
    volume,
    unit,
    other_price,
    account_id
  ) {
    // alert(category);
    setAdded_to_basket(false);
    setAdd_button(false);
    setAccountid(account_id);
    setSeeMore(false);
    setMore(true);
    setLess(false);
    setIntervals(intervals);
    setCategory(category);
    setType(type);
    setSelectedMediaID(mediaid);
    setSelectedMediaImg(media);
    setItemID(itemid);
    setItem(item_name);

    setNormal(normal);
    setSmall(small);
    setMedium(medium);
    setLarge(large);
    setExtraLarge(extra_large);
    setBulk(bulk);
    setItem_type(item_type);
    setDes(description);
    setOnline_availability(online_availability);
    setVolume(volume);
    setUnit(unit);
    setOther_price(other_price);

    localStorage.setItem("foodid", itemid);
    localStorage.setItem("food_name", item_name);
    localStorage.setItem("dashboard", "yes");
    setMediaDetails(true);

    setCheckedOne(false);
    setUncheckedOne(true);
    setQuantityOne(false);
    setQuantitesOne("");
    setTotalOne(0);

    setCheckedTwo(false);
    setUncheckedTwo(true);
    setQuantityTwo(false);
    setQuantitesTwo("");
    setTotalTwo(0);

    setCheckedThree(false);
    setUncheckedThree(true);
    setQuantityThree(false);
    setQuantitesThree("");
    setTotalThree(0);

    setCheckedFour(false);
    setUncheckedFour(true);
    setQuantityFour(false);
    setQuantitesFour("");
    setTotalFour(0);

    setCheckedFive(false);
    setUncheckedFive(true);
    setQuantityFive(false);
    setQuantitesFive("");
    setTotalFive(0);

    setCheckedSix(false);
    setUncheckedSix(true);
    setQuantitySix(false);
    setQuantitesSix("");
    setTotalSix(0);

    setGrand_total(0);
  }

  function company_profile(companyid, company_name) {
    navigate(
      "/company_profile?q=" +
        company_name.split(" ").join("+") +
        "&q_uuid=" +
        unique_uuid.split("-").join("@").toUpperCase() +
        unique_uuid.split("-").join("@").toUpperCase() +
        unique_uuid.split("-").join("@").toUpperCase() +
        unique_uuid.split("-").join("@").toUpperCase() +
        unique_uuid.split("-").join("@").toUpperCase() +
        "&com_id=" +
        companyid
    );
  }

  function like(foodid) {
    axios
      .get(
        baseURL +
          "?query=ADD_TO_FAVORITES&customerid=" +
          customerid +
          "&foodid=" +
          foodid
      )
      .then((response) => {
        if (response.data.status == "ok") {
          setBgColor("#66b266");
          // setDisplayMessage(true);
          setErrorMsg(response.data.message);
          setCount(count + 1);
        } else {
          setBgColor("red");
          // setDisplayMessage(true);
          setErrorMsg(response.data.message);
        }
      });
  }

  function map_search() {
    let itemid = 0;
    return (
      <div className="w-100 container-search">
        {
          <Row>
            {results.length <= 0 ? (
              <div>
                {NoMediaLoging && (
                  <label>
                    <label className="no-media-text color-silver">
                      No match found
                    </label>
                    <br />
                    <span className="color-grey font-size-12">
                      Try keywords: drinks, alcohol, kota, burger etc..
                    </span>
                  </label>
                )}
              </div>
            ) : (
              results.map((item, index) => {
                return (
                  <label key={index}>
                    {item.entity == undefined ? (
                      ""
                    ) : (
                      <Row
                        className={
                          item.entity == "company"
                            ? "search-results-padding-companies align-items-center"
                            : "search-results-padding align-items-center"
                        }
                      >
                        <Col
                          lg={2}
                          xs={2}
                          sm={2}
                          md={2}
                          className="media-container-search-results"
                          style={{
                            backgroundImage: `url(${
                              item.logo == ""
                                ? "REACT_APP_URL_RESOURCE/assets/search_logo.png"
                                : `${root_url}/assets/${
                                    item.entity == "company"
                                      ? item.companyid
                                      : item.accountid
                                  }/${
                                    item.entity == "company"
                                      ? item.logo
                                      : item.media
                                  }`
                            })`,
                          }}
                          onClick={() =>
                            item.entity == "company"
                              ? company_profile(
                                  item.companyid,
                                  item.companyName
                                )
                              : selectMedia(
                                  item.media,
                                  item.itemid,
                                  item.itemid,
                                  item.item_name,
                                  item.normal_price,
                                  item.small_price,
                                  item.medium_price,
                                  item.large_price,
                                  item.extra_large_price,
                                  item.bulk_price,
                                  item.item_type,
                                  item.description,
                                  item.online_availability,
                                  item.intervals,
                                  item.category,
                                  item.type,
                                  item.volume,
                                  item.unit,
                                  item.other_price,
                                  item.accountid,
                                  item.company_name
                                )
                          }
                        ></Col>
                        <Col
                          lg={10}
                          sm={10}
                          xs={10}
                          md={10}
                          className="text-left font-size-12"
                        >
                          {item.entity == "company" ? (
                            <label>
                              <span className="font-medium">
                                {item.companyName}
                              </span>
                              <br />
                              <span className="color-grey font-size-12">
                                <label className="font-size-12 color-grey">
                                  <IoRestaurantOutline size={14} />
                                  &nbsp; {item.cate_gory}
                                </label>
                                &nbsp; &nbsp;&nbsp;
                                <a
                                  href={"tel:" + item.phone}
                                  className="color-blue cursor"
                                  target="_blank"
                                >
                                  <BsTelephone size={15} />
                                </a>
                                &nbsp; &nbsp;&nbsp;&nbsp;
                                <a
                                  href={
                                    "http://maps.google.com/?q=" + item.address
                                  }
                                  className="color-blue cursor"
                                  target="_blank"
                                >
                                  <FaDirections size={15} />
                                </a>
                                &nbsp; &nbsp;&nbsp;&nbsp;
                                {item.web_address == "" ? (
                                  <IoMdGlobe size={17} className="color-grey" />
                                ) : (
                                  <a
                                    href={item.web_address}
                                    className="color-blue cursor"
                                    target="_blank"
                                  >
                                    <IoMdGlobe size={17} />
                                  </a>
                                )}
                              </span>
                            </label>
                          ) : (
                            <label>
                              <span className="font-medium">
                                {item.item_name}
                              </span>
                              {localStorage.getItem("pos") != null ? "" : ""}
                            </label>
                          )}
                        </Col>
                      </Row>
                    )}
                  </label>
                );
              })
            )}
            {results.length == 0 ? (
              ""
            ) : (
              <Col
                lg={12}
                className="text-center d-none d-lg-block d-md-block d-sm-block"
              >
                <label className="mt-3">
                  <label
                    type="submit"
                    className="search-buttons"
                    onClick={reset}
                  >
                    Clear search
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <label className="search-buttons" onClick={close}>
                    Close search
                  </label>
                </label>
              </Col>
            )}
          </Row>
        }
      </div>
    );
  }

  function map_media() {
    let itemid = 0;
    return (
      <div>
        {
          <Row>
            {" "}
            {media.length <= 0 ? (
              <div>
                {NoMediaLoging && (
                  <label className="no-media-text color-silver">No items</label>
                )}
              </div>
            ) : (
              media.map((item, index) => {
                return (
                  <Col
                    lg={4}
                    md={6}
                    sm={6}
                    xs={6}
                    className={
                      item.media == null ? 'd-none' :
                      item.online_availability == "No" &&
                      localStorage.getItem("pos") == null
                        ? "d-none"
                        : "mb-5 text-left line-height-15 item-box-home"
                    }
                  >
                    {item.accountid == item.account_id &&
                    customerid == item.customer_id &&
                    item.sanctions == 1 ? (
                      <div className="mask-home-items">
                        <label className="sanctions-msg font-size-12 font-medium color-black w-100 text-center">
                          <BsStopCircle size={20} className="color-red" />
                          <br />
                          <span
                            className="color-blue cursor font-size-12"
                            onClick={() =>
                              handleBanned(
                                item.company_name,
                                item.company_email,
                                item.company_logo,
                                item.account_id,
                                item.companyCategory,
                                item.sanctionReason
                              )
                            }
                          >
                            Learn more...
                          </span>
                        </label>
                      </div>
                    ) : item.store_available == "no" &&
                      localStorage.getItem("pos") == null ? (
                      <div className="mask-home-items">
                        <label className="sanctions-msg font-size-12 font-medium color-black w-100 text-center">
                          <label className="color-grey font-medium">
                            Store unavailable
                          </label>
                        </label>
                      </div>
                    ) : item.store_closed == "yes" &&
                      localStorage.getItem("pos") == null ? (
                      <div className="mask-home-items">
                        <label className="sanctions-msg font-size-12 font-medium color-black w-100 text-center">
                          <span className="color-grey font-size-17 font-medium">
                            {item.closed_at == "" ? (
                              <label>
                                Store never <br />
                                opened today
                              </label>
                            ) : (
                              <label>
                                {item.open == "to_open" ? (
                                  <span>
                                    Store opens at <br />
                                    {item.open_at}
                                  </span>
                                ) : (
                                  <span>Store closed</span>
                                )}
                              </label>
                            )}
                          </span>
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                    <Col
                      lg={12}
                      className="media-container-home mb-2"
                      style={{
                        backgroundImage: `url(${root_url}/assets/${item.accountid}/${item.media})`,
                      }}
                      onClick={() =>
                        selectMedia(
                          item.media,
                          item.itemid,
                          item.itemid,
                          item.item_name,
                          item.normal_price,
                          item.small_price,
                          item.medium_price,
                          item.large_price,
                          item.extra_large_price,
                          item.bulk_price,
                          item.item_type,
                          item.description,
                          item.online_availability,
                          item.intervals,
                          item.category,
                          item.type,
                          item.volume,
                          item.unit,
                          item.other_price,
                          item.accountid
                        )
                      }
                    ></Col>
                    <h6>
                      {item.item_name.length > 18
                        ? item.item_name.substring(0, 18) + "..."
                        : item.item_name}

                      {localStorage.getItem("customerid") != null ? (
                        <span className="float-right cursor">
                          {item.like == true ? (
                            <span
                              data-tip="Remove from favorites"
                              data-effect={"solid"}
                              data-place="top"
                              data-type={"dark"}
                              data-arrow-color={"transparent"}
                              data-class="tool_tips"
                              onClick={() => like(item.itemid)}
                            >
                              <IoIosHeart size={20} className="color-green" />
                              <ReactTooltip />
                            </span>
                          ) : (
                            <span
                              data-tip="Add to favorites"
                              data-effect={"solid"}
                              data-place="top"
                              data-type={"dark"}
                              data-arrow-color={"transparent"}
                              data-class="tool_tips"
                              onClick={() => like(item.itemid)}
                            >
                              <IoIosHeartEmpty
                                size={20}
                                className="color-orange"
                              />
                              <ReactTooltip />
                            </span>
                          )}
                        </span>
                      ) : (
                        <Link to={"/login"}>
                          <span
                            className="float-right"
                            data-tip="Add to favorites"
                            data-effect={"solid"}
                            data-place="top"
                            data-type={"dark"}
                            data-arrow-color={"transparent"}
                            data-class="tool_tips"
                            onClick={() => like(item.itemid)}
                          >
                            <IoIosHeartEmpty size={20} className="color-grey" />
                            <ReactTooltip />
                          </span>
                        </Link>
                      )}
                    </h6>
                    <span className="color-grey font-size-12">
                      {item.normal_price == "" ? (
                        <label
                          className="font-size-15 color-blue cursor"
                          onClick={() =>
                            selectMedia(
                              item.media,
                              item.itemid,
                              item.itemid,
                              item.item_name,
                              item.normal_price,
                              item.small_price,
                              item.medium_price,
                              item.large_price,
                              item.extra_large_price,
                              item.bulk_price,
                              item.item_type,
                              item.description,
                              item.online_availability,
                              item.intervals,
                              item.category,
                              item.type,
                              item.volume,
                              item.unit,
                              item.other_price,
                              item.accountid
                            )
                          }
                        >
                          More options...
                        </label>
                      ) : (
                        <label className="font-size-15 color-black font-normal">
                          R {item.normal_price}
                        </label>
                      )}
                      {localStorage.getItem("pos") != null ? "" : ""}
                    </span>
                  </Col>
                );
              })
            )}
          </Row>
        }
      </div>
    );
  }

  function add_to_basket_button(e) {
    let pos = "";

    if (
      localStorage.getItem("pos") != null ||
      localStorage.getItem("pos") == 1
    ) {
      pos = "pos";
    } else {
      pos = "online";
    }

    e.preventDefault();
    const formData = new FormData();
    const deviceid = localStorage.getItem("deviceid");
    const accountid = account_id;
    formData.append("device_id", localStorage.getItem("deviceid"));
    formData.append("foodid", itemID);
    formData.append("accountid", accountid);
    formData.append("normal_quantity", quantitesOne);
    formData.append("small_quantity", quantitesTwo);
    formData.append("medium_quantity", quantitesThree);
    formData.append("large_quantity", quantitesFour);
    formData.append("extra_large_quantity", quantitesFive);
    formData.append("bulk_quantity", quantitesSix);
    formData.append("grand_total", grand_total);
    formData.append("customerid", customerid);
    formData.append("pos", pos);
    formData.append("query", "ADD_TO_BASKET");

    if (
      CheckedOne == false &&
      CheckedTwo == false &&
      CheckedThree == false &&
      CheckedFour == false &&
      CheckedFive == false &&
      CheckedSix == false
    ) {
      setErrorMsg(
        <label className="color-red">
          <IoIosClose size={35} /> Select at least one size
        </label>
      );
      setAdded_to_basket(true);
    } else {
      setBtnColor("disabled-btn");
      setDisabled("disabled");
      setLoadSpinner(true);
      setSaveLabel(false);

      axios({
        method: "POST",
        url: baseURL,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then((response) => {
        // console.log(response.data);
        if (response.data.status == "error") {
          setErrorMsg(
            <label className="color-red">
              <IoIosClose size={35} /> {response.data.message}
            </label>
          );
          setAdded_to_basket(true);
        } else {
          setCheckedOne(false);
          setUncheckedOne(true);
          setQuantityOne(false);
          setQuantitesOne("");
          setTotalOne(0);

          setCheckedTwo(false);
          setUncheckedTwo(true);
          setQuantityTwo(false);
          setQuantitesTwo("");
          setTotalTwo(0);

          setCheckedThree(false);
          setUncheckedThree(true);
          setQuantityThree(false);
          setQuantitesThree("");
          setTotalThree(0);

          setCheckedFour(false);
          setUncheckedFour(true);
          setQuantityFour(false);
          setQuantitesFour("");
          setTotalFour(0);

          setCheckedFive(false);
          setUncheckedFive(true);
          setQuantityFive(false);
          setQuantitesFive("");
          setTotalFive(0);

          setCheckedSix(false);
          setUncheckedSix(true);
          setQuantitySix(false);
          setQuantitesSix("");
          setTotalSix(0);
          setAdd_button(false);
          setGrand_total(0);
          setErrorMsg(
            <label className="color-green p-0">
               {response.data.message} -{" "}
              <span className="color-blue cursor" onClick={go_to_basket}>
                Go to basket
              </span>
               {/* <span class="float-right"><IoIosCheckmark size={30} /></span> */}
            </label>
          );
          setAdded_to_basket(true);
          // navigate("/basket");
        }

        setBtnColor("karamale-login-btn");
        setDisabled("");
        setLoadSpinner(false);
        setSaveLabel(true);
      });
    }
  }

  function go_to_basket() {
    navigate("/basket");
  }
  function moreDetails() {
    setMore(false);
    setLess(true);
    setSeeMore(true);
  }
  function lessDetails() {
    setMore(true);
    setLess(false);
    setSeeMore(false);
  }

  function normalPrice() {
    if (CheckedOne == true) {
      setAdded_to_basket(false);
      setCheckedOne(false);
      setUncheckedOne(true);
      setQuantityOne(false);
      setQuantitesOne("");
      setTotalOne(0);
    } else {
      setCheckedOne(true);
      setUncheckedOne(false);
      setQuantityOne(true);
    }
    setCount(count + 1);
  }

  function smallPrice() {
    if (CheckedTwo == true) {
      setCheckedTwo(false);
      setUncheckedTwo(true);
      setQuantityTwo(false);
      setQuantitesTwo("");
      setTotalTwo(0);
    } else {
      setCheckedTwo(true);
      setUncheckedTwo(false);
      setQuantityTwo(true);
    }
    setCount(count + 1);
  }

  function mediumPrice() {
    if (CheckedThree == true) {
      setCheckedThree(false);
      setUncheckedThree(true);
      setQuantityThree(false);
      setQuantitesThree("");
      setTotalThree(0);
    } else {
      setCheckedThree(true);
      setUncheckedThree(false);
      setQuantityThree(true);
    }
    setCount(count + 1);
  }

  function largePrice() {
    if (CheckedFour == true) {
      setCheckedFour(false);
      setUncheckedFour(true);
      setQuantityFour(false);
      setQuantitesFour("");
      setTotalFour(0);
    } else {
      setCheckedFour(true);
      setUncheckedFour(false);
      setQuantityFour(true);
    }
    setCount(count + 1);
  }

  function xlargePrice() {
    if (CheckedFive == true) {
      setCheckedFive(false);
      setUncheckedFive(true);
      setQuantityFive(false);
      setQuantitesFive("");
      setTotalFive(0);
    } else {
      setCheckedFive(true);
      setUncheckedFive(false);
      setQuantityFive(true);
    }
    setCount(count + 1);
  }

  function bulkPrice() {
    if (CheckedSix == true) {
      setCheckedSix(false);
      setUncheckedSix(true);
      setQuantitySix(false);
      setQuantitesSix("");
      setTotalSix(0);
    } else {
      setCheckedSix(true);
      setUncheckedSix(false);
      setQuantitySix(true);
    }
    setCount(count + 1);
  }

  function f_quantityOne(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesOne(e.target.value);
      let price = 0;
      const price_one = other_price;
      const price_two = normal;
      if (price_one == "" || price_one == 0) {
        price = normal;
      } else {
        price = other_price;
      }
      setTotalOne(e.target.value * price);
      setCount(count + 1);
    }
  }

  function f_quantityTwo(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesTwo(e.target.value);
      setTotalTwo(e.target.value * small);
      setCount(count + 1);
    }
  }

  function f_quantityThree(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesThree(e.target.value);
      setTotalThree(e.target.value * medium);
      setCount(count + 1);
    }
  }

  function f_quantityFour(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesFour(e.target.value);
      setTotalFour(e.target.value * large);
      setCount(count + 1);
    }
  }

  function f_quantityFive(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesFive(e.target.value);
      setTotalFive(e.target.value * extraLarge);
      setCount(count + 1);
    }
  }

  function f_quantitySix(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesSix(e.target.value);
      setTotalSix(e.target.value * bulk);
      setCount(count + 1);
    }
  }

  function setAlert() {
    setError_modal(true);
    setMediaDetails(false);
  }
  function closeError() {
    setError_modal(false);
    setMediaDetails(true);
  }

  function search_field() {
    setShow(true);
  }

  function close() {
    setShow(false);
  }

  function reset() {
    setSearch_value("");
  }

  function search_string(e) {
    // if (e.target.value.length > 0) {
    //   setMask(true);
    // } else {
    //   setMask(false);
    // }
    setSearch_value(e.target.value);
    const string = e.target.value;
    const search_string = string.replace(" ", "+");
    axios
      .get(
        baseURL +
          "?query=ITEMS-HOME&display=SEARCH&search_string=" +
          search_string +
          "&customerid=" +
          customerid +
          "&accountid=" +
          accountid
      )
      .then((response) => {
        setResults(response.data);
        setNoMediaLoging(true);
        setLoadBeat(false);
      });
  }

  function view_trading_hrs() {
    setShowTradingHours(true);
  }

  function trading_hours() {
    return (
      <>
        {tradingHours.length <= 0 ? (
          <label className="color-grey font-size-17 font-medium w-100 text-center">
            There are no existing trading hours
          </label>
        ) : (
          tradingHours.map((item, index) => {
            return (
              <Row key={index}>
                <Col lg={4} className="text-left font-medium">
                  {item.day}
                </Col>
                <Col lg={8} className="text-left color-grey">
                  {item.from}-{item.to}
                </Col>
              </Row>
            );
          })
        )}
      </>
    );
  }
  return (
    <div className="body">
      <Container className="container-home">
        <Row>
          <Col lg={2} xs={2} md={3} sm={2} className="p-0">
            {localStorage.getItem("pos") == 1 &&
            localStorage.getItem("accountid") != null ? (
              <SideNav />
            ) : (
              <HomeNav />
            )}
          </Col>
          <Col lg={10} xs={10} sm={10} md={9}>
            <Row>
              <Col lg={8} xs={12} sm={12} md={6} className={"middle-container"}>
                <Row className="h-100 align-items-start justify-content-center">
                  <Col lg={12} className="">
                    <div
                      className="profile_cover"
                      style={{
                        backgroundImage: `url(${
                          urlParams.get("cover") == ""
                            ? "REACT_APP_URL_RESOURCE/assets/search_logo.png"
                            : `${root_url}assets/${urlParams.get(
                                "com_id"
                              )}/${urlParams.get("cover")}`
                        })`,
                        borderColor: "red"
                      }}
                    >
                      <div className="profile_logo text-center">
                        <img
                          src={
                            urlParams.get("logo") == ""
                              ? ""
                              : root_url +
                                "/assets/" +
                                urlParams.get("com_id") +
                                "/" +
                                urlParams.get("logo")
                          }
                          className="page_logo"
                        />
                        <br />
                        <label className="text-bold font-size-20 mt-2 line-height-0-8 color-white">
                          {urlParams.get("q")}
                          <br />
                          <span className="font-size-15 color-light-grey font-normal">
                            {urlParams.get("category")}
                          </span>
                        </label>
                      </div>
                      <div className="mask-profile-cover" />
                    </div>
                    {localStorage.getItem("updated") != null ? (
                      <div>
                        <h6>
                          <label className="color-green">
                            <IoIosCheckmark size={30} /> Media for{" "}
                            {localStorage.getItem("food_name")} successfully
                            updated
                          </label>
                        </h6>
                      </div>
                    ) : (
                      ""
                    )}
                    <Stack>
                      {localStorage.getItem("pos") == null ? (
                        ""
                      ) : (
                        <Row className="justify-content-end mt-4">
                          <Col lg={4} sm={6} xs={12}>
                            <input
                              type="text"
                              placeholder="Search Obarak"
                              className="bg-white font-size-12"
                              onClick={search_field}
                              readOnly
                            />
                          </Col>
                        </Row>
                      )}
                      <div className="box-container mt-3 mb-3">
                        <Row>
                          <Col lg={12}>
                            <label className="float-left font-medium">
                              {localStorage.getItem("pos") == 1 ? (
                                "Point of sale"
                              ) : (
                                <label className="font-size-20 text-bold">
                                  Menu
                                </label>
                              )}
                            </label>
                          </Col>
                          <Col lg={12}>
                            <div className="h-divider" />
                          </Col>
                          <Col lg={12} className="text-center">
                            {map_media()}
                            {LoadBeat && <BeatLoader color={"#39c"} />}
                          </Col>
                        </Row>
                      </div>
                    </Stack>
                  </Col>
                </Row>
              </Col>

              <Col
                lg={4}
                xs={12}
                sm={12}
                md={6}
                className={"middle-container  d-none d-lg-block d-md-block"}
              >
                <Row className="h-100 align-items-center justify-content-center">
                  <Col lg={12}>
                    {localStorage.getItem("updated") != null ? (
                      <div>
                        <h6>
                          <label className="color-green">
                            <IoIosCheckmark size={30} /> Media for{" "}
                            {localStorage.getItem("food_name")} successfully
                            updated
                          </label>
                        </h6>
                      </div>
                    ) : (
                      ""
                    )}
                    <Stack>
                      {localStorage.getItem("pos") == null ? (
                        ""
                      ) : (
                        <Row className="justify-content-end mt-4">
                          <Col lg={4} sm={6} xs={12}>
                            <input
                              type="text"
                              placeholder="Search Obarak"
                              className="bg-white font-size-12"
                              onClick={search_field}
                              readOnly
                            />
                          </Col>
                        </Row>
                      )}
                      <div className="box-container mt-3 mb-3">
                        <Row>
                          <Col lg={12}>
                            <label className="float-left font-medium font-size-20">
                              Contact details
                            </label>
                            <br />
                            <label className="line-height-2 w-100">
                              <a
                                href={"tel:" + urlParams.get("phone")}
                                className="color-blue cursor textUnderLineNone"
                              >
                                <BsTelephone size={20} />{" "}
                                {urlParams.get("phone")}
                              </a>
                              <br />
                              <a
                                href={"mailto:" + urlParams.get("email")}
                                className="color-blue cursor textUnderLineNone"
                              >
                                <BiEnvelope size={20} />{" "}
                                {urlParams.get("email")}
                              </a>
                              <br />
                              {urlParams.get("web_address") == "" ? (
                                <span className="color-grey">
                                  <IoMdGlobe size={20} /> N/A
                                </span>
                              ) : (
                                <a
                                  href={urlParams.get("web_address")}
                                  className="color-blue cursor textUnderLineNone"
                                >
                                  <IoMdGlobe size={20} />{" "}
                                  {urlParams.get("web_address")}
                                </a>
                              )}
                              <br />
                              <br />
                              <span
                                className="text-bold color-blue cursor"
                                onClick={view_trading_hrs}
                              >
                                See trading hours <BiChevronRight size={20} />
                              </span>
                            </label>
                          </Col>
                          <Col lg={12}>
                            <div className="h-divider" />
                          </Col>
                          <Col lg={12} className="text-center">
                            <div class="mapouter">
                              <div class="gmap_canvas">
                                <iframe
                                  className="map_iframe"
                                  width="100%"
                                  id="gmap_canvas"
                                  src={
                                    "https://maps.google.com/maps?q=" +
                                    urlParams.get("location") +
                                    "&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                  }
                                  frameborder="0"
                                  scrolling="no"
                                  marginheight="0"
                                  marginwidth="0"
                                ></iframe>
                                {/* <a href="https://fmovies-online.net"></a><br> */}
                                {/* <style>.mapouter{position:relative;text-align:right;height:500px;width:600px;}</style> */}
                                {/* <a href="https://www.embedgooglemap.net">embed maps on website</a> */}
                                {/* <style>.gmap_canvas {overflow:hidden;background:none!important;height:500px;width:600px;}</style */}
                              </div>
                            </div>
                            {LoadBeat && <BeatLoader color={"#39c"} />}
                          </Col>
                        </Row>
                      </div>
                    </Stack>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Modal
        show={error_modal}
        onHide={closeError}
        centered
        backdrop={true}
        className="modal-z-index"
      >
        <Modal.Header closeButton className="hide-modal-border">
          <Modal.Title className="color-red font-normal hide-modal-border">
            {" "}
            Price unavailable for the selected size.
          </Modal.Title>
        </Modal.Header>
      </Modal>

      <Modal show={mediaDetails} onHide={handleClose} backdrop={true}>
        <form onSubmit={add_to_basket_button}>
          <Modal.Header closeButton>
            <Modal.Title>{item} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-center align-items-center">
              <img
                className="img-border-radius w-100"
                src={
                  root_url + "/assets/" + account_id + "/" + selectedMediaImg
                }
              />
              <br />

              {/* <Col lg={12}>
                <label className="font-size-12 color-grey mt-3">
                  <b>From customers:</b>
                  <br /> Nice meal, really enjoyed it...
                </label>
              </Col> */}
              <Col lg={12} className="mt-3">
                <h6>Prices</h6>
              </Col>
              <Col lg={4} xs={4} sm={4} className="font-size-12 main-price-box">
                <div
                  className={
                    normal == "" && other_price == ""
                      ? "price-boxes-disabled"
                      : "price-boxes"
                  }
                >
                  <div
                    onClick={
                      other_price == "" && normal == "" ? setAlert : normalPrice
                    }
                  >
                    <b>
                      {category == "drink" && normal == "" ? (
                        <label>
                          {volume}
                          {unit == "litre" ? "L" : unit == "" ? "--" : unit}
                        </label>
                      ) : (
                        <label className="font-size-12">
                          {type == "normal coffee" || type == "tea"
                            ? "Cup"
                            : type == "shot" || type == "tequila shot"
                            ? "Shot"
                            : type == "wine" || type == "whisky"
                            ? "Glass"
                            : "Normal"}{" "}
                        </label>
                      )}
                    </b>
                    <br />
                    {normal == "" ? (
                      <label className="color-grey font-size-12">
                        {category == "drink" && unit == "not set" ? (
                          ""
                        ) : category == "drink" && unit != "" ? (
                          "R" + other_price
                        ) : (
                          <label className="color-red font-size-12">
                            Not set
                          </label>
                        )}
                      </label>
                    ) : (
                      <label className="color-grey font-size-12">
                        {category == "drink" && normal == "" ? (
                          <label className="font-size-12">
                            R {other_price}
                          </label>
                        ) : (
                          <label className="font-size-12">R {normal}</label>
                        )}
                      </label>
                    )}
                    <br />
                    {UncheckedOne && (
                      <IoMdSquareOutline
                        size={25}
                        className={
                          normal == "" && other_price == ""
                            ? "color-light-red"
                            : "color-light-grey"
                        }
                      />
                    )}
                    {CheckedOne && (
                      <IoMdCheckbox size={25} className="color-blue" />
                    )}
                  </div>
                  {quantityOne && (
                    <input
                      type="text"
                      placeholder="Quantity"
                      className="price-inputs"
                      required
                      onChange={(e) => f_quantityOne(e)}
                      value={quantitesOne}
                    />
                  )}
                </div>
                <label className="w-100 text-center font-size-12 mt-2">
                  Total:{" "}
                  <span className="font-size-12 color-grey">R {totalOne}</span>
                </label>
              </Col>
              <Col lg={4} xs={4} sm={4} className="font-size-12 main-price-box">
                <div
                  className={
                    small == "" ? "price-boxes-disabled" : "price-boxes"
                  }
                >
                  <div onClick={small == "" ? setAlert : smallPrice}>
                    <b className="font-size-12">
                      {category == "drink" ? "1.5oz" : "Small"}
                    </b>
                    <br />
                    {small == "" ? (
                      <label className="color-red font-size-12">Not set</label>
                    ) : (
                      <label className="color-grey font-size-12">
                        R {small}
                      </label>
                    )}
                    <br />
                    {UncheckedTwo && (
                      <IoMdSquareOutline
                        size={25}
                        className={
                          small == "" ? "color-light-red" : "color-light-grey"
                        }
                      />
                    )}
                    {CheckedTwo && (
                      <IoMdCheckbox size={25} className="color-blue" />
                    )}
                  </div>
                  {quantityTwo && (
                    <input
                      type="text"
                      placeholder="Quantity"
                      className="price-inputs"
                      required
                      onChange={(e) => f_quantityTwo(e)}
                      value={quantitesTwo}
                    />
                  )}
                </div>
                <label className="w-100 text-center font-size-12 mt-2">
                  Total:{" "}
                  <span className="font-size-12 color-grey">R {totalTwo}</span>
                </label>
              </Col>
              <Col lg={4} xs={4} sm={4} className="font-size-12 main-price-box">
                <div
                  className={
                    medium == "" ? "price-boxes-disabled" : "price-boxes"
                  }
                >
                  <div onClick={medium == "" ? setAlert : mediumPrice}>
                    <b className="font-size-12">
                      {category == "drink" ? "500ml" : "Medium"}
                    </b>
                    <br />
                    {medium == "" ? (
                      <label className="color-red font-size-12">Not set</label>
                    ) : (
                      <label className="color-grey font-size-12">
                        R {medium}
                      </label>
                    )}
                    <br />
                    {UncheckedThree && (
                      <IoMdSquareOutline
                        size={25}
                        className={
                          medium == "" ? "color-light-red" : "color-light-grey"
                        }
                      />
                    )}
                    {CheckedThree && (
                      <IoMdCheckbox size={25} className="color-blue" />
                    )}
                  </div>
                  {quantityThree && (
                    <input
                      type="text"
                      placeholder="Quantity"
                      className="price-inputs"
                      required
                      onChange={(e) => f_quantityThree(e)}
                      value={quantitesThree}
                    />
                  )}
                </div>
                <label className="w-100 text-center font-size-12 mt-2">
                  Total:{" "}
                  <span className="font-size-12 color-grey">
                    R {totalThree}
                  </span>
                </label>
              </Col>
              <Col lg={4} xs={4} sm={4} className="font-size-12 main-price-box">
                <div
                  className={
                    large == "" ? "price-boxes-disabled" : "price-boxes"
                  }
                >
                  <div onClick={large == "" ? setAlert : largePrice}>
                    <b className="font-size-12">
                      {category == "drink" ? "750ml" : "Large"}
                    </b>
                    <br />
                    {large == "" ? (
                      <label className="color-red font-size-12">Not set</label>
                    ) : (
                      <label className="color-grey font-size-12">
                        R {large}
                      </label>
                    )}
                    <br />
                    {UncheckedFour && (
                      <IoMdSquareOutline
                        size={25}
                        className={
                          large == "" ? "color-light-red" : "color-light-grey"
                        }
                      />
                    )}
                    {CheckedFour && (
                      <IoMdCheckbox size={25} className="color-blue" />
                    )}
                  </div>
                  {quantityFour && (
                    <input
                      type="text"
                      placeholder="Quantity"
                      className="price-inputs"
                      required
                      onChange={(e) => f_quantityFour(e)}
                      value={quantitesFour}
                    />
                  )}
                </div>
                <label className="w-100 text-center font-size-12 mt-2">
                  Total:{" "}
                  <span className="font-size-12 color-grey">R {totalFour}</span>
                </label>
              </Col>
              <Col lg={4} xs={4} sm={4} className="font-size-12 main-price-box">
                <div
                  className={
                    extraLarge == "" ? "price-boxes-disabled" : "price-boxes"
                  }
                >
                  <div onClick={extraLarge == "" ? setAlert : xlargePrice}>
                    <b className="font-size-12">
                      {category == "drink" ? "1L" : "xLarge"}
                    </b>
                    <br />
                    {extraLarge == "" ? (
                      <label className="color-red font-size-12">Not set</label>
                    ) : (
                      <label className="color-grey font-size-12">
                        R {extraLarge}
                      </label>
                    )}
                    <br />
                    {UncheckedFive && (
                      <IoMdSquareOutline
                        size={25}
                        className={
                          extraLarge == ""
                            ? "color-light-red"
                            : "color-light-grey"
                        }
                      />
                    )}
                    {CheckedFive && (
                      <IoMdCheckbox size={25} className="color-blue" />
                    )}
                  </div>
                  {quantityFive && (
                    <input
                      type="text"
                      placeholder="Quantity"
                      className="price-inputs"
                      required
                      onChange={(e) => f_quantityFive(e)}
                      value={quantitesFive}
                    />
                  )}
                </div>
                <label className="w-100 text-center font-size-12 mt-2">
                  Total:{" "}
                  <span className="font-size-12 color-grey">R {totalFive}</span>
                </label>
              </Col>
              <Col lg={4} xs={4} sm={4} className="font-size-12 main-price-box">
                <div
                  className={
                    bulk == "" ? "price-boxes-disabled" : "price-boxes"
                  }
                >
                  <div onClick={bulk == "" ? setAlert : bulkPrice}>
                    <b className="font-size-12">
                      {category == "drink" ? "2L" : "Bulk"}
                    </b>
                    <br />
                    {bulk == "" ? (
                      <label className="color-red font-size-12">Not set</label>
                    ) : (
                      <label className="color-grey font-size-12">
                        R {bulk}
                      </label>
                    )}
                    <br />
                    {UncheckedSix && (
                      <IoMdSquareOutline
                        size={25}
                        className={
                          bulk == "" ? "color-light-red" : "color-light-grey"
                        }
                      />
                    )}
                    {CheckedSix && (
                      <IoMdCheckbox size={25} className="color-blue" />
                    )}
                  </div>
                  {quantitySix && (
                    <input
                      type="text"
                      placeholder="Quantity"
                      className="price-inputs"
                      required
                      onChange={(e) => f_quantitySix(e)}
                      value={quantitesSix}
                    />
                  )}
                </div>
                <label className="w-100 text-center font-size-12 mt-2">
                  Total:{" "}
                  <span className="font-size-12 color-grey">R {totalSix}</span>
                </label>
              </Col>

              {seeMore && (
                <Row>
                  <Col
                    lg={12}
                    className="font-size-17 mb-0 mt-3 color-grey p-0"
                  >
                    <b>Description</b>
                    <br />{" "}
                    {description == "" || description == "null"
                      ? "Not available"
                      : description}
                    <br />
                    <br />
                    <b>Consumable:</b> {intervals}
                    <br />
                    <b>Food kind:</b> {category}
                    <br />
                    <b>Type:</b> {type}
                  </Col>
                </Row>
              )}
              <Col lg={12} className="font-size-12 mb-0 mt-3">
                {more && (
                  <label
                    className="color-blue text-underline-on-hover"
                    onClick={moreDetails}
                  >
                    See more details...
                  </label>
                )}
                {less && (
                  <label
                    className="color-blue text-underline-on-hover"
                    onClick={lessDetails}
                  >
                    See less details...
                  </label>
                )}
              </Col>
              <Row lg={12} className="mt-3">
                {added_to_basket && (
                  <Col lg={12} classname="p-0">
                    <label className="w-100 text-left color-green margin-right-5">
                      {errorMsg}
                    </label>
                  </Col>
                )}
              </Row>
              {add_button && (
                <Row className="align-items-center mt-3">
                  <Col
                    lg={6}
                    xs={6}
                    sm={6}
                    md={6}
                    className="mt-2 text-left p-0"
                  >
                    <label className="margin-right-5">
                      <b>Total:</b>{" "}
                      <span className="color-grey">R{grand_total}</span>
                    </label>
                  </Col>

                  <Col
                    lg={6}
                    xs={6}
                    sm={6}
                    md={6}
                    className="mt-2 text-right p-0"
                  >
                    <button
                      type={"submit"}
                      className={btnColor + " font-medium"}
                      disabled={disabled}
                    >
                      {saveLabel && <span>Add to basket</span>}
                      {loadSpinner && <BeatLoader size={10} />}
                    </button>
                  </Col>
                </Row>
              )}
            </Row>
          </Modal.Body>
          {/* {added_to_basket && (
            <Modal.Footer>
              <label className="w-100 text-center color-green">
                {errorMsg}
              </label>
              <label className="cancel-btn text-bold" onClick={handleClose}>
            Close
          </label>
            </Modal.Footer>
          )} */}
        </form>
      </Modal>

      <Modal
        show={show}
        fullscreen={fullscreen}
        onHide={() => setShow(false)}
        backdrop={true}
      >
        <Modal.Body className="search-modal-background-imag">
          <Row className="align-items-center">
            <Col
              xs={1}
              className="text-center p-0 p-0 d-lg-none d-md-none d-sm-none d-xs-block search-field-active"
            >
              <IoMdArrowBack size={20} onClick={close} />
            </Col>
            <Col
              lg={{ span: 4, offset: 4 }}
              sm={{ span: 8, offset: 2 }}
              xs={10}
              className="text-center search-field-active mb-4"
            >
              <input
                type="text"
                placeholder={"Search Obarak"}
                className="border-radius-15 bg-white mt-3 mb-0 w-100"
                value={search_value}
                onChange={(e) => search_string(e)}
              />
            </Col>

            <Col
              xs={1}
              className="text-center p-0 d-lg-none d-md-none d-sm-none d-xs-block search-field-active"
            >
              <IoMdClose size={20} onClick={reset} />
            </Col>
            <Col lg={{ span: 4, offset: 4 }} sm={{ span: 8, offset: 2 }}>
              {search_value.length == 0 ? (
                ""
              ) : (
                <div className="live-search-results pb-3">
                  <div className="sub-live-search-results w-100">
                    {map_search()}
                  </div>
                </div>
              )}
            </Col>

            {(results.length == 0 || results.length > 0) &&
            search_value.length > 0 ? (
              ""
            ) : (
              <Col
                lg={12}
                className="text-center d-none d-lg-block d-md-block d-sm-block"
              >
                <label className="search-buttons" onClick={reset}>
                  Clear search
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="search-buttons" onClick={close}>
                  Close search
                </label>
              </Col>
            )}
          </Row>

          <Container>
            <Row className="mt-3">
              <Col lg={12} className="text-center">
                {map_media()}
                {LoadBeat && <BeatLoader color={"#39c"} />}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>

      <Modal
        show={showBanned}
        // fullscreen={fullscreen}
        centered
        onHide={() => setShowBanned(false)}
        backdrop={true}
      >
        <Modal.Header closeButton className="hide-modal-border">
          <Modal.Title className="color-black font-medium">
            Sanctioned
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={12} className="text-left logo-special-div-ban mb-3">
              {companyLogo == "" ? (
                <>
                  <div
                    className="logo-div"
                    style={{
                      backgroundImage: `url(${root_url}/assets/search_logo.png)`,
                    }}
                  ></div>{" "}
                  <label className="companyName font-medium">
                    {companyName}
                    <br />
                    <span className="font-size-12 font-normal">
                      {companyCategory}
                    </span>
                  </label>
                </>
              ) : (
                <>
                  <div
                    className="logo-div"
                    style={{
                      backgroundImage: `url(${root_url}/assets/${companyID}/${companyLogo})`,
                    }}
                  ></div>{" "}
                  <label className="companyName font-medium">
                    {companyName}
                    <br />
                    <span className="font-size-12 font-normal color-grey">
                      {companyCategory}
                    </span>
                  </label>
                </>
              )}
            </Col>
            <Col lg={12}>
              <label className="text-left pb-3 w-100">
                <span className="font-medium">Reason for sanctions</span>
                <br />
                <label className="color-grey font-normal">
                  {sanctionReason}
                </label>
                <br />
                <br />
                Refer to the{" "}
                <a href="/terms" className="color-blue cursor">
                  terms and conditions
                </a>
                , and if you wish to lay a complaint and request that your
                sanctions be lifted, you can send an email to{" "}
                <a
                  href={"mailto:" + companyEmail}
                  className="color-blue cursor"
                >
                  {companyEmail}
                </a>
                , also include your{" "}
                <span className="font-medium">full name</span>,{" "}
                <span className="font-medium">email</span> and{" "}
                <span className="font-medium">phone number</span>.
              </label>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        show={showTradingHours}
        onHide={handleClose}
        centered
        backdrop={true}
        className="modal-z-index"
      >
        <Modal.Header closeButton className="hide-modal-border">
          <Modal.Title className="color-black font-medium">
            Trading hours
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">{trading_hours()}</Modal.Body>
      </Modal>

      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}
localStorage.removeItem("updated");
