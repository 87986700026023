import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Stack,
  Modal,
  Button,
  Accordion,
} from "react-bootstrap";
import HomeNav from "./navigation/HomeNav";
import SideNav from "./navigation/SideNav";
import { BsFillForwardFill, BsArrowRight } from "react-icons/bs";
import {
  IoIosTrash,
  IoMdPerson,
  IoMdEasel,
  IoIosAdd,
  IoIosCheckmark,
  IoIosClose,
  IoMdCreate,
  IoMdSquareOutline,
  IoMdCheckbox,
  IoIosArrowRoundForward,
} from "react-icons/io";
import {
  IoArrowForwardOutline,
  IoShieldCheckmarkOutline,
} from "react-icons/io5";
import { FaRegHandPointRight } from "react-icons/fa";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import validator from "validator";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import "react-phone-input-2/lib/style.css";
const baseURL = process.env.REACT_APP_URL;
const root_url = process.env.REACT_APP_URL_RESOURCE;
const accountid = localStorage.getItem("accountid");
const deviceid = localStorage.getItem("deviceid");
export default function Checkout() {
  const navigate = useNavigate();
  const [company_name, setCompany_name] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState(null);
  const [show, setShow] = useState(false);
  const [mediaDetails, setMediaDetails] = useState(false);
  const [error_modal, setError_modal] = useState(false);
  const [displayImge, setDisplayImge] = useState([]);
  const [preimage, setPreImage] = useState("");
  const [imgname, setImgname] = useState("");
  const [path, setPath] = useState("");
  const [showUploadBtn, setShowUploadBtn] = useState(false);
  const [showImage, setshowImage] = useState(false);
  const [arraySize, setArraySize] = useState(0);
  const [disabled, setDisabled] = useState("");
  const [btnColor, setBtnColor] = useState("karamale-login-btn");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [allimgs, setAllImgs] = useState("");
  const [media_count, setMedia_count] = useState(0);
  const [media, setMedia] = useState([]);
  const [selectedMediaID, setSelectedMediaID] = useState(0);
  const [selectedMediaImg, setSelectedMediaImg] = useState(null);
  const [successOne, setSuccessOne] = useState(false);
  const [successTwo, setSuccessTwo] = useState(false);
  const [successThree, setSuccessThree] = useState(false);
  const [successFour, setSuccessFour] = useState(false);
  const [textOne, setTextOne] = useState(true);
  const [beatOne, setBeatOne] = useState(false);
  const [textTwo, setTextTwo] = useState(true);
  const [beatTwo, setBeatTwo] = useState(false);
  const [textThree, setTextThree] = useState(true);
  const [beatThree, setBeatThree] = useState(false);
  const [textFour, setTextFour] = useState(true);
  const [beatFour, setBeatFour] = useState(false);
  const [LoadBeat, setLoadBeat] = useState(true);
  const [NoMediaLoging, setNoMediaLoging] = useState(false);
  const [itemID, setItemID] = useState(0);
  const [date_uploaded, setDate_uploaded] = useState(null);
  const [media_size, setMedia_size] = useState(0);
  const [item, setItem] = useState("");
  const [normal, setNormal] = useState("");
  const [small, setSmall] = useState("");
  const [medium, setMedium] = useState("");
  const [large, setLarge] = useState("");
  const [extraLarge, setExtraLarge] = useState("");
  const [bulk, setBulk] = useState("");
  const [volume, setVolume] = useState("");
  const [unit, setUnit] = useState("");
  const [other_price, setOther_price] = useState("");
  const [item_type, setItem_type] = useState("");
  const [description, setDes] = useState("");
  const [online_availability, setOnline_availability] = useState("");
  const [more, setMore] = useState(true);
  const [less, setLess] = useState(false);
  const [intervals, setIntervals] = useState("");
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");
  const [seeMore, setSeeMore] = useState(false);
  const [UncheckedOne, setUncheckedOne] = useState(true);
  const [CheckedOne, setCheckedOne] = useState(false);
  const [quantityOne, setQuantityOne] = useState(false);

  const [UncheckedTwo, setUncheckedTwo] = useState(true);
  const [CheckedTwo, setCheckedTwo] = useState(false);
  const [quantityTwo, setQuantityTwo] = useState(false);

  const [UncheckedThree, setUncheckedThree] = useState(true);
  const [CheckedThree, setCheckedThree] = useState(false);
  const [quantityThree, setQuantityThree] = useState(false);

  const [UncheckedFour, setUncheckedFour] = useState(true);
  const [CheckedFour, setCheckedFour] = useState(false);
  const [quantityFour, setQuantityFour] = useState(false);

  const [UncheckedFive, setUncheckedFive] = useState(true);
  const [CheckedFive, setCheckedFive] = useState(false);
  const [quantityFive, setQuantityFive] = useState(false);

  const [UncheckedSix, setUncheckedSix] = useState(true);
  const [CheckedSix, setCheckedSix] = useState(false);
  const [quantitySix, setQuantitySix] = useState(false);

  const [quantitesOne, setQuantitesOne] = useState("");
  const [quantitesTwo, setQuantitesTwo] = useState("");
  const [quantitesThree, setQuantitesThree] = useState("");
  const [quantitesFour, setQuantitesFour] = useState("");
  const [quantitesFive, setQuantitesFive] = useState("");
  const [quantitesSix, setQuantitesSix] = useState("");

  const [totalOne, setTotalOne] = useState(0);
  const [totalTwo, setTotalTwo] = useState(0);
  const [totalThree, setTotalThree] = useState(0);
  const [totalFour, setTotalFour] = useState(0);
  const [totalFive, setTotalFive] = useState(0);
  const [totalSix, setTotalSix] = useState(0);
  const [grand_total, setGrand_total] = useState(0);
  const [count, setCount] = useState(0);
  const [total_value, setTotal_value] = useState(0);
  const [account_id, setAccountid] = useState(0);

  const [added_to_basket, setAdded_to_basket] = useState(false);
  const [add_button, setAdd_button] = useState(false);
  const [total, setTotal] = useState(0);
  const [address, setAddress] = useState("");
  const [vat, setVat] = useState(0);
  const [basketCount, setBasketCount] = useState(0);

  let customerid = "";
  if (localStorage.getItem("customerid") != null) {
    customerid = localStorage.getItem("customerid");
  } else {
    customerid = -1;
  }

  function handleAddress(event) {
    setAddress(event.target.value);
  }

  function total_amount() {
    axios
      .get(
        baseURL +
          "?query=TOTAL_AMOUNT&device_id=" +
          deviceid +
          "&customerid=" +
          customerid
      )
      .then((response) => {
        setTotal(response.data.total);
      });
  }

  function itemCount() {
    axios
      .get(
        baseURL +
          "?query=ITEM-COUNT-BASKET&device_id=" +
          deviceid +
          "&customerid=" +
          customerid
      )
      .then((response) => {
        setMedia_count(response.data.count);
      });
  }

  function fetchItems() {
    axios
      .get(
        baseURL +
          "?query=ITEMS-BASKET&device_id=" +
          deviceid +
          "&customerid=" +
          customerid
      )
      .then((response) => {
        setMedia(response.data);
        setNoMediaLoging(true);
        setLoadBeat(false);
      });
  }

  function basket_Count() {
    axios
      .get(
        baseURL +
          "?query=BASKET_COUNT&device_id=" +
          deviceid +
          "&customerid=" +
          customerid
      )
      .then((res) => {
        setBasketCount(res.data.count);
      });
  }
  useEffect(() => {
    document.title = "Obarak / Checkout";

    setVat(Math.round(total * 0.15));

    itemCount();
    fetchItems();
    total_amount();
    basket_Count();

    const interval_time = setInterval(() => {
      basket_Count();
      fetchItems();
    }, 5000);
    return () => clearInterval(interval_time);
  }, []);

  const handleClose = () => {
    setShow(false);
    setMediaDetails(false);
  };

  const handleShow = () => setShow(true);
  // const selectedMedia = () => setMediaDetails(true);

  function selectMedia(
    media,
    mediaid,
    itemid,
    item_name,
    normal,
    small,
    medium,
    large,
    extra_large,
    bulk,
    item_type,
    description,
    online_availability,
    intervals,
    category,
    type,
    volume,
    unit,
    other_price,
    account_id,
    company_name
  ) {
    // alert(item_type);
    setCompany_name(company_name);
    setAdded_to_basket(false);
    setAdd_button(false);
    setAccountid(account_id);
    setSeeMore(false);
    setMore(true);
    setLess(false);
    setIntervals(intervals);
    setCategory(category);
    setType(type);
    setSelectedMediaID(mediaid);
    setSelectedMediaImg(media);
    setItemID(itemid);
    setItem(item_name);

    setNormal(normal);
    setSmall(small);
    setMedium(medium);
    setLarge(large);
    setExtraLarge(extra_large);
    setBulk(bulk);
    setItem_type(item_type);
    setDes(description);
    setOnline_availability(online_availability);
    setVolume(volume);
    setUnit(unit);
    setOther_price(other_price);

    localStorage.setItem("foodid", itemid);
    localStorage.setItem("food_name", item_name);
    localStorage.setItem("dashboard", "yes");
    setMediaDetails(true);

    setCheckedOne(false);
    setUncheckedOne(true);
    setQuantityOne(false);
    setQuantitesOne("");
    setTotalOne(0);

    setCheckedTwo(false);
    setUncheckedTwo(true);
    setQuantityTwo(false);
    setQuantitesTwo("");
    setTotalTwo(0);

    setCheckedThree(false);
    setUncheckedThree(true);
    setQuantityThree(false);
    setQuantitesThree("");
    setTotalThree(0);

    setCheckedFour(false);
    setUncheckedFour(true);
    setQuantityFour(false);
    setQuantitesFour("");
    setTotalFour(0);

    setCheckedFive(false);
    setUncheckedFive(true);
    setQuantityFive(false);
    setQuantitesFive("");
    setTotalFive(0);

    setCheckedSix(false);
    setUncheckedSix(true);
    setQuantitySix(false);
    setQuantitesSix("");
    setTotalSix(0);

    setGrand_total(0);
  }

  function register(e) {
    e.preventDefault();
    const formData = new FormData();
    const deviceid = localStorage.getItem("deviceid");
    const accountid = account_id;
    formData.append("fname", fname);
    formData.append("lname", lname);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("address", address);
    formData.append("query", "RESGISTER_CUSTOMER");

    if (fname === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter first name");
    } else if (lname === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter last name");
    } else if (email === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter email address");
    } else if (!validator.isEmail(email)) {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Email address is invalid");
    } else if (!phone) {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter your phone");
    } else if (
      isPossiblePhoneNumber(phone.replace(" ", "")) === false ||
      isValidPhoneNumber(phone.replace(" ", "")) === false
    ) {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Your phone number is invalid");
    } else if (address === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter your address");
    } else {
      setBtnColor("disabled-btn");
      setDisabled("disabled");
      setLoadSpinner(true);
      setSaveLabel(false);
      // connect to the api

      axios({
        method: "POST",
        url: baseURL,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then((response) => {
        if (response.data.status == "error") {
          setBgColor("red");
          setDisplayMessage(true);
          setErrorMsg(response.data.message);
        } else {
          localStorage.setItem("email", email);
          localStorage.setItem("package", "customer");
          localStorage.setItem("password", response.data.password);
          navigate("/verify");
        }

        setBtnColor("cancel-btn");
        setDisabled("");
        setLoadSpinner(false);
        setSaveLabel(true);

        // console.log(response.data.name);
      });
    }
  }

  function setCover() {
    const mediaName = selectedMediaImg;
    const accountid = localStorage.getItem("accountid");
    setTextOne(false);
    setBeatOne(true);
    axios
      .get(
        baseURL +
          "?query=SET-COVER&accountid=" +
          accountid +
          "&mediaName=" +
          mediaName
      )
      .then((response) => {
        setSuccessOne(true);
        setBeatOne(false);
        localStorage.setItem("cover", response.data.cover);
        navigate("/media");

        setTimeout(() => {
          setSuccessOne(false);
          setTextOne(true);
          setBeatOne(false);
          setMediaDetails(false);
        }, 1000);
      });
  }

  function setLogo() {
    const mediaName = selectedMediaImg;
    const accountid = localStorage.getItem("accountid");
    setTextTwo(false);
    setBeatTwo(true);
    axios
      .get(
        baseURL +
          "?query=SET-LOGO&accountid=" +
          accountid +
          "&mediaName=" +
          mediaName
      )
      .then((response) => {
        setSuccessTwo(true);
        setBeatTwo(false);
        localStorage.setItem("logo", response.data.logo);
        navigate("/media");

        setTimeout(() => {
          setSuccessTwo(false);
          setTextTwo(true);
          setBeatTwo(false);
          setMediaDetails(false);
        }, 1000);
      });
  }

  function move_to_bin() {
    const mediaName = selectedMediaImg;
    const mediaid = selectedMediaID;
    const accountid = localStorage.getItem("accountid");
    setTextFour(false);
    setBeatFour(true);
    axios
      .get(
        baseURL +
          "?query=DEL-ITEM&accountid=" +
          accountid +
          "&mediaid=" +
          mediaid
      )
      .then((response) => {
        setSuccessFour(true);
        setBeatFour(false);
        navigate("/dashboard");

        setTimeout(() => {
          setSuccessFour(false);
          setTextFour(true);
          setBeatFour(false);
          setMediaDetails(false);
        }, 1000);
      });
  }

  function attachItem() {
    navigate("/media");
  }

  function editDetails() {
    localStorage.setItem("item_name", item);
    localStorage.setItem("description", description);
    navigate("/addstock");
  }

  function moreDetails() {
    setMore(false);
    setLess(true);
    setSeeMore(true);
  }
  function lessDetails() {
    setMore(true);
    setLess(false);
    setSeeMore(false);
  }

  function normalPrice() {
    if (CheckedOne == true) {
      setCheckedOne(false);
      setUncheckedOne(true);
      setQuantityOne(false);
      setQuantitesOne("");
      setTotalOne(0);
    } else {
      setCheckedOne(true);
      setUncheckedOne(false);
      setQuantityOne(true);
    }
    setCount(count + 1);
  }

  function smallPrice() {
    if (CheckedTwo == true) {
      setCheckedTwo(false);
      setUncheckedTwo(true);
      setQuantityTwo(false);
      setQuantitesTwo("");
      setTotalTwo(0);
    } else {
      setCheckedTwo(true);
      setUncheckedTwo(false);
      setQuantityTwo(true);
    }
    setCount(count + 1);
  }

  function mediumPrice() {
    if (CheckedThree == true) {
      setCheckedThree(false);
      setUncheckedThree(true);
      setQuantityThree(false);
      setQuantitesThree("");
      setTotalThree(0);
    } else {
      setCheckedThree(true);
      setUncheckedThree(false);
      setQuantityThree(true);
    }
    setCount(count + 1);
  }

  function largePrice() {
    if (CheckedFour == true) {
      setCheckedFour(false);
      setUncheckedFour(true);
      setQuantityFour(false);
      setQuantitesFour("");
      setTotalFour(0);
    } else {
      setCheckedFour(true);
      setUncheckedFour(false);
      setQuantityFour(true);
    }
    setCount(count + 1);
  }

  function xlargePrice() {
    if (CheckedFive == true) {
      setCheckedFive(false);
      setUncheckedFive(true);
      setQuantityFive(false);
      setQuantitesFive("");
      setTotalFive(0);
    } else {
      setCheckedFive(true);
      setUncheckedFive(false);
      setQuantityFive(true);
    }
    setCount(count + 1);
  }

  function bulkPrice() {
    if (CheckedSix == true) {
      setCheckedSix(false);
      setUncheckedSix(true);
      setQuantitySix(false);
      setQuantitesSix("");
      setTotalSix(0);
    } else {
      setCheckedSix(true);
      setUncheckedSix(false);
      setQuantitySix(true);
    }
    setCount(count + 1);
  }

  function f_quantityOne(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesOne(e.target.value);
      let price = 0;
      const price_one = other_price;
      const price_two = normal;
      if (price_one == "" || price_one == 0) {
        price = normal;
      } else {
        price = other_price;
      }
      setTotalOne(e.target.value * price);
      setCount(count + 1);
    }
  }

  function f_quantityTwo(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesTwo(e.target.value);
      setTotalTwo(e.target.value * small);
      setCount(count + 1);
    }
  }

  function f_quantityThree(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesThree(e.target.value);
      setTotalThree(e.target.value * medium);
      setCount(count + 1);
    }
  }

  function f_quantityFour(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesFour(e.target.value);
      setTotalFour(e.target.value * large);
      setCount(count + 1);
    }
  }

  function f_quantityFive(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesFive(e.target.value);
      setTotalFive(e.target.value * extraLarge);
      setCount(count + 1);
    }
  }

  function f_quantitySix(e) {
    if (isNaN(e.target.value)) {
    } else {
      setQuantitesSix(e.target.value);
      setTotalSix(e.target.value * bulk);
      setCount(count + 1);
    }
  }

  function setAlert() {
    setError_modal(true);
    setMediaDetails(false);
  }
  function closeError() {
    setError_modal(false);
    setMediaDetails(true);
  }

  function map_media() {
    let itemid = 0;
    return (
      <Row className="max-height-300">
        {" "}
        {media_count == 0 ? (
          <div>
            {NoMediaLoging && (
              <label className="no-media-text color-silver">
                Basket is empty
              </label>
            )}
          </div>
        ) : (
          media.map((item, index) => {
            return (
              <Col lg={12} md={12} sm={12} xs={12} className="mb-3 text-left">
                <Row className="align-items-center">
                  <Col
                    lg={2}
                    xs={2}
                    sm={2}
                    md={2}
                    className="media-container-basket mb-2"
                    style={{
                      backgroundImage: `url(${root_url}/assets/${item.accountid}/${item.media})`,
                    }}
                    onClick={() =>
                      selectMedia(
                        item.media,
                        item.itemid,
                        item.itemid,
                        item.item_name,
                        item.normal_price,
                        item.small_price,
                        item.medium_price,
                        item.large_price,
                        item.extra_large_price,
                        item.bulk_price,
                        item.item_type,
                        item.description,
                        item.online_availability,
                        item.intervals,
                        item.category,
                        item.type,
                        item.volume,
                        item.unit,
                        item.other_price,
                        item.accountid,
                        item.company_name
                      )
                    }
                  ></Col>
                  <Col lg={4} xs={8} md={6} sm={6}>
                    <b>
                      {item.item_name.length > 7
                        ? item.item_name.substring(0, 7) + "..."
                        : item.item_name}
                    </b>
                    <br />
                    <span className="color-grey font-size-12">
                      {item.category == "solid" || item.category == "soft"
                        ? item.category.charAt(0).toUpperCase() +
                          item.category.slice(1) +
                          " food"
                        : "Soft " + item.category}
                    </span>
                  </Col>
                  <Col
                    lg={2}
                    sm={2}
                    md={2}
                    xs={2}
                    className="font-size-12 text-center desktop-browse-btn"
                  >
                    Qty
                    <br />
                    <label className="color-grey font-size-12 w-100 text-center">
                      {item.quantity}
                    </label>
                  </Col>

                  <Col
                    lg={2}
                    sm={2}
                    md={2}
                    xs={2}
                    className="font-size-12 text-center desktop-browse-btn"
                  >
                    Total
                    <br />
                    <label className="color-grey font-size-12">
                      R{item.total}
                    </label>
                  </Col>
                </Row>
              </Col>
            );
          })
        )}
      </Row>
    );
  }

  function remove(orderid) {
    axios
      .get(
        baseURL +
          "?query=REMOVE_FROM_BASKET&device_id=" +
          deviceid +
          "&orderid=" +
          orderid +
          "&action=REMOVE_ITEM"
      )
      .then((response) => {});
    setCount(count + 1);
  }

  return (
    <div className="body">
      <Container className="container-home">
        <Row>
          <Col lg={2} xs={2} md={3} className="p-0">
            {localStorage.getItem("pos") != null &&
            localStorage.getItem("accountid") != null ? (
              <SideNav />
            ) : (
              <HomeNav />
            )}
          </Col>
          <Col lg={10} xs={10} md={9} className={"middle-container"}>
            <Row className="h-100 align-items-center justify-content-center">
              <Col
                lg={6}
                className={
                  localStorage.getItem("pos") != null ||
                  localStorage.getItem("customerid") != null
                    ? "d-none"
                    : "mb-3"
                }
              >
                <Stack>
                  <div className="box-container mt-3 mb-0 p-4 pb-0 pt-3 add-relative-position">
                    {LoadBeat && (
                      <label className="text-center w-100 pb-3">
                        <BeatLoader color={"#39c"} />
                      </label>
                    )}
                    <form onSubmit={register}>
                      <Row className="justify-content-center p-0">
                        {localStorage.getItem("customerid") == null ? (
                          ""
                        ) : basketCount < 1 ? (
                          <label className="w-100 text-center font-size-17 font-medium color-grey pb-3">
                            There's nothing in the basket
                            <br />
                            <Link
                              to="/"
                              className="color-blue anchor font-normal font-size-15"
                            >
                              Order something
                            </Link>
                          </label>
                        ) : (
                          <div className="mask-div">
                            <Row>
                              <Col lg={12}>
                                <span className="font-medium">
                                  Go ahead a complete your order
                                </span>
                              </Col>
                              <Col lg={12} className="mt-2">
                                <label>
                                  hello {localStorage.getItem("first_name")}
                                </label>
                              </Col>
                            </Row>
                          </div>
                        )}
                        {basketCount < 1 ? (
                          ""
                        ) : (
                          <Row className="w-100 p-0">
                            <Col lg={12} className="p-0">
                              <label className="float-left font-medium">
                                Create account
                              </label>
                            </Col>
                            <Col lg={12} className="p-0">
                              <div className="h-divider" />
                            </Col>
                            <Col lg={12} className="p-0 mb-3">
                              <label className="font-size-12 color-grey">
                                Password will be send to you via email.
                              </label>
                            </Col>
                            <Col
                              lg={6}
                              xs={12}
                              sm={6}
                              md={6}
                              className="text-center checkout-fields-right mb-3"
                            >
                              <input
                                type="text"
                                placeholder="First name"
                                value={fname}
                                onChange={(e) => setFname(e.target.value)}
                              />
                            </Col>
                            <Col
                              lg={6}
                              xs={12}
                              sm={6}
                              md={6}
                              className="text-center checkout-fields-left mb-3"
                            >
                              <input
                                type="text"
                                placeholder="Last name"
                                value={lname}
                                onChange={(e) => setLname(e.target.value)}
                              />
                            </Col>
                            <Col
                              lg={6}
                              xs={12}
                              sm={6}
                              md={6}
                              className="text-center checkout-fields-right mb-3"
                            >
                              <input
                                type="text"
                                placeholder="Email address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </Col>
                            <Col
                              lg={6}
                              xs={12}
                              sm={6}
                              md={6}
                              className="checkout-fields-left mb-3"
                            >
                              <PhoneInput
                                name={"phone"}
                                disableDropdown={false}
                                enableSearch={true}
                                showDropdown={false}
                                country={"za"}
                                value={phone}
                                onChange={(phone) => setPhone("+" + phone)}
                                inputStyle={{
                                  width: "100%",
                                  background: "#f0f2f5",
                                  height: "3em",
                                  boxShadow: "none",
                                }}
                                containerStyle={{ marginBottom: 0 }}
                                inputProps={{
                                  name: "phone",
                                  required: true,
                                }}
                              />
                            </Col>
                            <Col
                              lg={12}
                              xs={12}
                              sm={12}
                              md={12}
                              className="p-0 mb-3"
                            >
                              <GooglePlacesAutocomplete
                                apiKey="AIzaSyDNn7uMQVzoH91NYn7vzX437q5fp8g92FU"
                                styles={{
                                  textInputContainer: {
                                    backgroundColor: "grey",
                                  },
                                  textInput: {
                                    height: 38,
                                    color: "#5d5d5d",
                                    fontSize: 16,
                                  },
                                }}
                                textInputProps={{
                                  onChange: (text) => {
                                    handleAddress(text);
                                  },
                                }}
                                selectProps={{
                                  onChange: (data, details = null) =>
                                    setAddress(data["value"]["description"]),
                                  fetchDetails: true,
                                  placeholder: "Add your address...",
                                  // defaultInputValue: this.state.venue,
                                }}
                              />
                            </Col>
                            <Col lg={12} className="p-0 mb-3 text-right">
                              <Row className="align-items-center">
                                <Col
                                  lg={0}
                                  sm={0}
                                  xs={6}
                                  className="browse-right-padding mobile-browse-btn text-left"
                                >
                                  <Link
                                    to="/login"
                                    className="textUnderLineNone"
                                  >
                                    <span className="color-light-black desktop-browse-btn">
                                      Registered?
                                    </span>{" "}
                                    <span className="color-blue cursor browse-w-100">
                                      Log In
                                    </span>
                                  </Link>
                                </Col>
                                <Col
                                  lg={12}
                                  xs={6}
                                  sm={12}
                                  className="browse-left-padding"
                                >
                                  <label className="desktop-browse-btn">
                                    <Link
                                      to="/login"
                                      className="textUnderLineNone"
                                    >
                                      <span className="color-light-black">
                                        Already registered?
                                      </span>{" "}
                                      <span className="color-blue cursor browse-w-100">
                                        Log In
                                      </span>
                                    </Link>
                                    &nbsp;&nbsp;&nbsp;
                                  </label>

                                  <button
                                    type="submit"
                                    className={
                                      btnColor + " browse-w-100 font-medium"
                                    }
                                    disabled={disabled}
                                  >
                                    {saveLabel && <span>Register</span>}
                                    {loadSpinner && <BeatLoader size={10} />}
                                  </button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        )}
                      </Row>
                    </form>
                  </div>
                </Stack>
              </Col>
              {/* {LoadBeat && (
                <label className="text-center w-100 pb-3">
                  <BeatLoader color={"#39c"} />
                </label>
              )} */}
              {basketCount < 1 ? (
                ""
              ) : (
                <Col
                  lg={
                    localStorage.getItem("customerid") != null ||
                    localStorage.getItem("pos") != null
                      ? 6
                      : 4
                  }
                  className={
                    localStorage.getItem("customerid") == null &&
                    localStorage.getItem("pos") == null
                      ? "d-none"
                      : ""
                  }
                >
                  <Stack>
                    <div
                      className={
                        "box-container mt-0 mb-3 p-4 pb-0 pt-3 add-relative-position"
                      }
                    >
                      {localStorage.getItem("customerid") != null ||
                      localStorage.getItem("pos") != null ? (
                        ""
                      ) : (
                        <div className="mask-div">
                          <Row>
                            <Col lg={12}>
                              <span className="font-medium">Please note:</span>
                            </Col>
                            <Col lg={12} className="mt-2">
                              <label>
                                First{" "}
                                <Link
                                  to="/login"
                                  className="color-blue cursor textUnderLineNone"
                                >
                                  log in
                                </Link>{" "}
                                or register.
                              </label>
                            </Col>
                          </Row>
                        </div>
                      )}

                      <Row className="align-items-center">
                        <Col lg={12} className="p-0">
                          <label className="float-left font-medium">
                            Order summary
                          </label>
                        </Col>
                        <Col lg={12} className="p-0">
                          <div className="h-divider" />
                        </Col>

                        <Col
                          lg={6}
                          xs={6}
                          sm={6}
                          md={6}
                          className="text-left mb-2 p-0 font-size-12"
                        >
                          Subtotal
                        </Col>
                        <Col
                          lg={6}
                          xs={6}
                          sm={6}
                          md={6}
                          className="text-right mb-2 p-0 color-grey font-size-12"
                        >
                          R{total}
                        </Col>
                        {/* <Col
                          lg={6}
                          xs={6}
                          sm={6}
                          md={6}
                          className="text-left mb-2 p-0 font-size-12"
                        >
                          Delivery
                          <br />
                          <label className="color-grey font-size-12">
                            Will be delivered shortly
                          </label>
                        </Col>
                        <Col
                          lg={6}
                          xs={6}
                          sm={6}
                          md={6}
                          className="text-right mb-2 p-0 color-grey"
                        >
                          R0
                        </Col> */}
                        <Col
                          lg={6}
                          xs={6}
                          sm={6}
                          md={6}
                          className="text-left mb-2 p-0 font-size-12"
                        >
                          VAT
                        </Col>
                        <Col
                          lg={6}
                          xs={6}
                          sm={6}
                          md={6}
                          className="text-right mb-2 p-0 color-grey"
                        >
                          R{vat}
                        </Col>

                        <Col
                          lg={6}
                          xs={6}
                          sm={6}
                          md={6}
                          className="text-left mb-2 p-0 font-size-12 font-medium"
                        >
                          Order total
                        </Col>
                        <Col
                          lg={6}
                          xs={6}
                          sm={6}
                          md={6}
                          className="text-right mb-2 p-0 color-grey font-medium"
                        >
                          R{parseInt(total) + parseInt(vat)}
                        </Col>

                        <Col lg={12} className="p-0 mb-3 text-right">
                          {media_count == 0 ? (
                            ""
                          ) : (
                            <Row>
                              <Col
                                lg={12}
                                xs={12}
                                sm={12}
                                md={12}
                                className="browse-left-padding mt-3"
                              >
                                <Link to="/continue">
                                  <label
                                    className={
                                      localStorage.getItem("customerid") !=
                                        null ||
                                      localStorage.getItem("pos") != null
                                        ? " btn-w-100 karamale-login-btn font-medium"
                                        : "karamale-login-btn w-100 font-medium"
                                    }
                                  >
                                    Continue
                                    {localStorage.getItem("customerid") !=
                                      null ||
                                    localStorage.getItem("pos") != null ? (
                                      ""
                                    ) : (
                                      <label className="float-right">
                                        <IoArrowForwardOutline size={20} />
                                      </label>
                                    )}
                                  </label>
                                </Link>
                              </Col>

                              <Col lg={12} className="mt-3">
                                <Accordion flush>
                                  <Accordion.Item eventKey="0">
                                    <Accordion.Header className="accorder-bg">
                                      {basketCount}&nbsp;
                                      {basketCount == 1 ? " item" : " items"} in
                                      Basket
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      {map_media()}
                                      {LoadBeat && (
                                        <label className="text-center w-100">
                                          <BeatLoader color={"#39c"} />
                                        </label>
                                      )}
                                      <Row>
                                        <Col xs={6} lg={6} md={6} sm={6}>
                                          <Link to="/">
                                            <label className="color-blue cursor w-100 text-center mt-3 p-0">
                                              Add more items
                                            </label>
                                          </Link>
                                        </Col>

                                        <Col xs={6} lg={6} md={6} sm={6}>
                                          <Link to="/basket">
                                            <label className="color-blue cursor w-100 text-center mt-3 p-0">
                                              Back to basket
                                            </label>
                                          </Link>
                                        </Col>
                                      </Row>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </Col>
                            </Row>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Stack>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
      <Modal show={error_modal} onHide={closeError} centered>
        <Modal.Header closeButton>
          <Modal.Title>Note:</Modal.Title>
        </Modal.Header>
        <Modal.Body className="color-red">
          Price not available for the selected size.
        </Modal.Body>
      </Modal>

      <Modal show={mediaDetails} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {item}
            <br />
            <label className="color-grey font-normal font-size-12">
              by {company_name}
            </label>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center align-items-center">
            <img
              className="img-border-radius w-100"
              src={root_url + "/assets/" + account_id + "/" + selectedMediaImg}
            />
            <br />

            <Row>
              <Col lg={12} className="font-size-17 mb-0 mt-3 color-grey p-0">
                <b>Description</b>
                <br />{" "}
                {description == "" || description == "null"
                  ? "Not available"
                  : description}
                <br />
                <br />
                <b>Consumable:</b> {intervals}
                <br />
                <b>Food kind:</b> {category}
                <br />
                <b>Type:</b> {type}
              </Col>
            </Row>
          </Row>
        </Modal.Body>
        {added_to_basket && (
          <Modal.Footer>
            <label className="w-100 text-center color-green">{errorMsg}</label>
            {/* <label className="cancel-btn text-bold" onClick={handleClose}>
            Close
          </label> */}
          </Modal.Footer>
        )}
      </Modal>

      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}
localStorage.removeItem("updated");
