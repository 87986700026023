import React, { useState, useEffect } from "react";
import Authentication from "./navigation/authentication";
import { Col, Container, Row, Stack, Modal, Button } from "react-bootstrap";
import SideNav from "./navigation/SideNav";
import { BsPlusCircleDotted } from "react-icons/bs";
import {
  IoIosTrash,
  IoMdPerson,
  IoMdEasel,
  IoIosAdd,
  IoIosCheckmark,
  IoMdCreate,
} from "react-icons/io";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
const baseURL = "https://obarak.co.za/restapi.php";
const root_url = "https://obarak.co.za/";
const accountid = localStorage.getItem("accountid");
export default function Dashboard() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [mediaDetails, setMediaDetails] = useState(false);
  const [displayImge, setDisplayImge] = useState([]);
  const [preimage, setPreImage] = useState("");
  const [imgname, setImgname] = useState("");
  const [path, setPath] = useState("");
  const [showUploadBtn, setShowUploadBtn] = useState(false);
  const [showImage, setshowImage] = useState(false);
  const [arraySize, setArraySize] = useState(0);
  const [disabled, setDisabled] = useState("");
  const [btnColor, setBtnColor] = useState("karamale-login-btn");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [allimgs, setAllImgs] = useState("");
  const [media_count, setMedia_count] = useState(0);
  const [media, setMedia] = useState([]);
  const [selectedMediaID, setSelectedMediaID] = useState(0);
  const [selectedMediaImg, setSelectedMediaImg] = useState(null);
  const [successOne, setSuccessOne] = useState(false);
  const [successTwo, setSuccessTwo] = useState(false);
  const [successThree, setSuccessThree] = useState(false);
  const [successFour, setSuccessFour] = useState(false);
  const [successFive, setSuccessFive] = useState(false);
  const [textOne, setTextOne] = useState(true);
  const [beatOne, setBeatOne] = useState(false);
  const [textTwo, setTextTwo] = useState(true);
  const [beatTwo, setBeatTwo] = useState(false);
  const [textThree, setTextThree] = useState(true);
  const [beatThree, setBeatThree] = useState(false);
  const [textFour, setTextFour] = useState(true);
  const [beatFour, setBeatFour] = useState(false);
  const [textFive, setTextFive] = useState(true);
  const [beatFive, setBeatFive] = useState(false);
  const [LoadBeat, setLoadBeat] = useState(true);
  const [NoMediaLoging, setNoMediaLoging] = useState(false);
  const [itemID, setItemID] = useState(0);
  const [date_uploaded, setDate_uploaded] = useState(null);
  const [media_size, setMedia_size] = useState(0);
  const [item, setItem] = useState("");
  const [normal, setNormal] = useState("");
  const [small, setSmall] = useState("");
  const [medium, setMedium] = useState("");
  const [large, setLarge] = useState("");
  const [extraLarge, setExtraLarge] = useState("");
  const [bulk, setBulk] = useState("");
  const [volume, setVolume] = useState("");
  const [unit, setUnit] = useState("");
  const [other_price, setOther_price] = useState("");
  const [item_out_of_stock, setItem_out_of_stock] = useState("");
  const [item_type, setItem_type] = useState("");
  const [description, setDes] = useState("");
  const [online_availability, setOnline_availability] = useState("");
  const [more, setMore] = useState(true);
  const [less, setLess] = useState(false);
  const [intervals, setIntervals] = useState("");
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");
  const [seeMore, setSeeMore] = useState(false);
  // const [count, setCount] = useState(0);

  function itemCount() {
    axios
      .get(baseURL + "?query=ITEM-COUNT&accountid=" + accountid)
      .then((response) => {
        setMedia_count(response.data.count);
      });
  }

  function fetchItems() {
    axios
      .get(baseURL + "?query=ITEMS&accountid=" + accountid)
      .then((response) => {
        setMedia(response.data);
        setNoMediaLoging(true);

        setLoadBeat(false);
      });
  }
  useEffect(() => {
    document.title = "Obarak | Dashboard";

    itemCount();
    fetchItems();

    localStorage.removeItem("normal");
    localStorage.removeItem("small");
    localStorage.removeItem("medium");
    localStorage.removeItem("large");
    localStorage.removeItem("extra-large");
    localStorage.removeItem("bulk");
    localStorage.removeItem("other");
    // localStorage.removeItem("foodid");
    localStorage.removeItem("description");
  }, [media_count]);

  const handleClose = () => {
    setShow(false);
    setMediaDetails(false);
  };
  const handleShow = () => setShow(true);
  // const selectedMedia = () => setMediaDetails(true);

  function selectMedia(
    media,
    mediaid,
    itemid,
    item_name,
    normal,
    small,
    medium,
    large,
    extra_large,
    bulk,
    item_type,
    description,
    online_availability,
    intervals,
    category,
    type,
    volume,
    unit,
    other_price,
    out_of_stock
  ) {
    // alert(item_type);
    setSeeMore(false);
    setMore(true);
    setLess(false);
    setIntervals(intervals);
    setCategory(category);
    setType(type);
    setSelectedMediaID(mediaid);
    setSelectedMediaImg(media);
    setItemID(itemid);
    setItem(item_name);

    setNormal(normal);
    setSmall(small);
    setMedium(medium);
    setLarge(large);
    setExtraLarge(extra_large);
    setBulk(bulk);
    setItem_type(item_type);
    setDes(description);
    setOnline_availability(online_availability);
    setVolume(volume);
    setUnit(unit);
    setOther_price(other_price);
    setItem_out_of_stock(out_of_stock);

    localStorage.setItem("foodid", itemid);
    localStorage.setItem("food_name", item_name);
    localStorage.setItem("dashboard", "yes");
    setMediaDetails(true);
  }

  function map_media() {
    let itemid = 0;
    return (
      <div>
        {
          <Row>
            {" "}
            {media_count == 0 ? (
              <div>
                {NoMediaLoging && (
                  <label className="no-media-text color-silver">No media</label>
                )}
              </div>
            ) : (
              media.map((item, index) => {
                return (
                  <Col lg={3} md={4} sm={4} xs={6} className="mb-5 text-left">
                    {item.media == null ?
                    <Col
                    lg={12}
                    className="media-container-home mb-2"
                    style={{
                      backgroundImage: `url(${root_url}assets/no-image.png)`,
                    }}
                    onClick={() =>
                      selectMedia(
                        item.media,
                        item.itemid,
                        item.itemid,
                        item.item_name,
                        item.normal_price,
                        item.small_price,
                        item.medium_price,
                        item.large_price,
                        item.extra_large_price,
                        item.bulk_price,
                        item.item_type,
                        item.description,
                        item.online_availability,
                        item.intervals,
                        item.category,
                        item.type,
                        item.volume,
                        item.unit,
                        item.other_price,
                        item.out_of_stock
                      )
                    }
                  ></Col> :
                    <Col
                    lg={12}
                    className="media-container-home mb-2"
                    style={{
                      backgroundImage: `url(${root_url}assets/${localStorage.getItem(
                        "accountid"
                      )}/${item.media})`,
                    }}
                    onClick={() =>
                      selectMedia(
                        item.media,
                        item.itemid,
                        item.itemid,
                        item.item_name,
                        item.normal_price,
                        item.small_price,
                        item.medium_price,
                        item.large_price,
                        item.extra_large_price,
                        item.bulk_price,
                        item.item_type,
                        item.description,
                        item.online_availability,
                        item.intervals,
                        item.category,
                        item.type,
                        item.volume,
                        item.unit,
                        item.other_price,
                        item.out_of_stock
                      )
                    }
                  ></Col>
                    }
                    
                    <span className="font-medium">
                      {item.item_name.length > 18
                        ? item.item_name.substring(0, 18) + "..."
                        : item.item_name}
                    </span>
                    <br />
                    <span className="color-grey font-size-12">
                      {item.category == "solid" || item.category == "soft"
                        ? item.category.charAt(0).toUpperCase() +
                          item.category.slice(1) +
                          " food"
                        : "Soft " + item.category}
                    </span>
                    <br />
                    {item.media == null ? <label className="color-red font-size-12">
                        Item removed from listing because there's no image.
                      </label>:  <span> 
                     {item.out_of_stock == "No" ? (
                      <label className="color-green font-size-12">
                        Item still in stock
                      </label>
                    ) : (
                      <label className="color-red font-size-12">
                        Item out of stock
                      </label>
                    )}</span>
                    }
                   
                  </Col>
                );
              })
            )}
          </Row>
        }
      </div>
    );
  }

  function selectImage(event) {
    setAllImgs(event.target.files);
    setDisplayImge([]);
    const img = event.target.files[0];
    setShowUploadBtn(true);
    setshowImage(true);
    let images = [];
    for (let i = 0; i < event.target.files.length; i++) {
      images.push(URL.createObjectURL(event.target.files[i]));
    }
    setDisplayImge(images);
    setArraySize(event.target.files.length);
  }

  function upload(e) {
    e.preventDefault();
    const formData = new FormData();
    // formData.append("media", allimgs);

    for (let i = 0; i < allimgs.length; i++) {
      formData.append("file[]", allimgs[i]);
    }

    formData.append("accountid", localStorage.getItem("accountid"));
    formData.append("query", "UPLOAD-MEDIA");

    setBtnColor("disabled-btn");
    setDisabled("disabled");
    setLoadSpinner(true);
    setSaveLabel(false);

    axios({
      method: "POST",
      url: baseURL,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status == "error") {
        setBgColor("red");
        setDisplayMessage(true);
        setErrorMsg(response.data.message);
      } else {
        // navigate("/media");
        setBgColor("#66b266");
        setDisplayMessage(true);
        setErrorMsg(response.data.message);
        setShow(false);
      }

      setBtnColor("karamale-login-btn");
      setDisabled("");
      setLoadSpinner(false);
      setSaveLabel(true);
    });
    setTimeout(() => {
      setDisplayMessage(false);
    }, 5000);
  }

  function mark_out_of_stock() {
    const mediaName = selectedMediaImg;
    const mediaid = selectedMediaID;
    const accountid = localStorage.getItem("accountid");

    setBtnColor("disabled-btn");
    setDisabled("disabled");
    setLoadSpinner(true);
    setSaveLabel(false);
    // setTextFour(false);
    // setBeatFour(true);
    axios
      .get(
        baseURL +
          "?query=ITEM-OUT-OF-STOCK&accountid=" +
          accountid +
          "&mediaid=" +
          mediaid
      )
      .then((response) => {
        setBtnColor("karamale-login-btn");
        setDisabled("");
        setLoadSpinner(false);
        setSaveLabel(true);

        setSuccessFive(true);
        setBeatFive(false);
        setMedia_count(media_count + 1);
        // navigate("/dashboard");

        setTimeout(() => {
          setSuccessFive(false);
          setTextFive(true);
          setBeatFive(false);
          setMediaDetails(false);
        }, 1000);
      });
  }

  function setCover() {
    const mediaName = selectedMediaImg;
    const accountid = localStorage.getItem("accountid");
    setTextOne(false);
    setBeatOne(true);
    axios
      .get(
        baseURL +
          "?query=SET-COVER&accountid=" +
          accountid +
          "&mediaName=" +
          mediaName
      )
      .then((response) => {
        setSuccessOne(true);
        setBeatOne(false);
        localStorage.setItem("cover", response.data.cover);
        navigate("/media");

        setTimeout(() => {
          setSuccessOne(false);
          setTextOne(true);
          setBeatOne(false);
          setMediaDetails(false);
        }, 1000);
      });
  }

  function setLogo() {
    const mediaName = selectedMediaImg;
    const accountid = localStorage.getItem("accountid");
    setTextTwo(false);
    setBeatTwo(true);
    axios
      .get(
        baseURL +
          "?query=SET-LOGO&accountid=" +
          accountid +
          "&mediaName=" +
          mediaName
      )
      .then((response) => {
        setSuccessTwo(true);
        setBeatTwo(false);
        localStorage.setItem("logo", response.data.logo);
        navigate("/media");

        setTimeout(() => {
          setSuccessTwo(false);
          setTextTwo(true);
          setBeatTwo(false);
          setMediaDetails(false);
        }, 1000);
      });
  }

  function move_to_bin() {
    const mediaName = selectedMediaImg;
    const mediaid = selectedMediaID;
    const accountid = localStorage.getItem("accountid");
    setTextFour(false);
    setBeatFour(true);
    axios
      .get(
        baseURL +
          "?query=DEL-ITEM&accountid=" +
          accountid +
          "&mediaid=" +
          mediaid
      )
      .then((response) => {
        setSuccessFour(true);
        setBeatFour(false);
        navigate("/dashboard");

        setTimeout(() => {
          setSuccessFour(false);
          setTextFour(true);
          setBeatFour(false);
          setMediaDetails(false);
        }, 1000);
      });
  }

  function attachItem() {
    navigate("/media");
  }

  function editDetails() {
    localStorage.setItem("item_name", item);
    localStorage.setItem("description", description);
    navigate("/addstock");
  }

  function moreDetails() {
    setMore(false);
    setLess(true);
    setSeeMore(true);
  }
  function lessDetails() {
    setMore(true);
    setLess(false);
    setSeeMore(false);
  }
  return (
    <div className="body">
      <Authentication />
      <Container className="container-home">
        <Row>
          <Col lg={2} xs={2} md={3} className="p-0">
            <SideNav />
          </Col>
          <Col lg={10} xs={10} md={9} className={"middle-container"}>
            <Row className="h-100 align-items-center justify-content-center">
              <Col lg={9}>
                {localStorage.getItem("updated") != null ? (
                  <div>
                    <h6>
                      <label className="color-green">
                        <IoIosCheckmark size={30} /> Media for{" "}
                        {localStorage.getItem("food_name")} successfully updated
                      </label>
                    </h6>
                  </div>
                ) : (
                  ""
                )}
                <Stack>
                  <div className="box-container mt-1 mb-3">
                    <Row>
                      <Col lg={12}>
                        <label className="float-left font-medium">
                          {media_count} Items
                        </label>
                        <Link to="/addstock">
                          <label className="text-blue text-medium text-hover-underline float-right">
                            Add item
                          </label>
                        </Link>
                      </Col>
                      <Col lg={12}>
                        <div className="h-divider" />
                      </Col>
                      <Col lg={12} className="text-center">
                        {map_media()}
                        {LoadBeat && <BeatLoader color={"#39c"} />}
                      </Col>
                    </Row>
                  </div>
                </Stack>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Modal show={mediaDetails} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><span className="text-bold">{item}</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center align-items-center">
            {selectedMediaImg == null ? 
             <img
             className="img-border-radius w-100"
             src={
               root_url +
               "/assets/no-image.png"
             }
           />
           :
             <img
             className="img-border-radius w-100"
             src={
               root_url +
               "/assets/" +
               localStorage.getItem("accountid") +
               "/" +
               selectedMediaImg
             }
           />
            }
           
            <br />

            <Col lg={12} className="mt-3">
              {successFive && (
                <span className="color-green font-size-12">
                  {" "}
                  <IoIosCheckmark size={15} /> Successful
                </span>
              )}{" "}
              <button
                type="button"
                className={btnColor + " text-bold font-medium"}
                disabled={disabled}
                onClick={mark_out_of_stock}
              >
                {saveLabel && (
                  <span>
                    {item_out_of_stock == "No"
                      ? "Mark item out of stock"
                      : "Mark item in stock"}
                  </span>
                )}
                {loadSpinner && <BeatLoader size={10} />}
              </button>
            </Col>
            <Col lg={12}>
              <div className="h-divider" />
            </Col>

            <Col lg={4} sm={4} xs={12} className="mt-3">
              <label
                className=" font-size-12 w-100 text-left"
                onClick={editDetails}
              >
                <span className="font-size-12 color-blue color-blue-hover">
                  <IoMdCreate size={15} /> Edit details
                </span>

                <br />
                {successTwo && (
                  <span className="color-green font-size-12">
                    {" "}
                    <IoIosCheckmark size={20} /> Successful
                  </span>
                )}
              </label>
            </Col>

            <Col lg={4} sm={4} xs={12} className="mt-3">
              <label
                className=" font-size-12 w-100 text-left"
                onClick={attachItem}
              >
                <span className="font-size-12 color-blue color-blue-hover">
                  <IoIosAdd size={15} /> Update media
                </span>

                <br />
                {successThree && (
                  <span className="color-green font-size-12">
                    {" "}
                    <IoIosCheckmark size={20} /> Successful
                  </span>
                )}
              </label>
            </Col>

            <Col lg={4} sm={4} xs={12} className="mt-3">
              <label
                className="color-red text-underline-on-hover font-size-12 w-100 text-left"
                onClick={move_to_bin}
              >
                {beatFour && (
                  <span>
                    <BeatLoader size={10} color={"#39c"} />
                  </span>
                )}
                {textFour && (
                  <span className="font-size-12">
                    {" "}
                    <IoIosTrash size={15} /> Delete
                  </span>
                )}
                {successFour && (
                  <span className="color-green font-size-12">
                    {" "}
                    <IoIosCheckmark size={15} /> Successful
                  </span>
                )}
              </label>
            </Col>
            <Col lg={12}>
              <label className="font-size-12 color-grey mt-3">
                <b className="color-orange">Important:</b> Deleting this item
                means it won't appear on the listing, this action is
                irreversable. However, sales data to this item will still be
                available.
              </label>
            </Col>
            <Col lg={12} className="mt-3">
              <h6>Price per size</h6>
            </Col>
            <Col lg={2} className="font-size-12 mb-3">
              <b>
                {category == "drink" && normal == "" ? (
                  <label>
                    {volume}
                    {unit == "litre" ? "L" : unit == "" ? "--" : unit}
                  </label>
                ) : (
                  <label className="font-size-12">
                    {type == "normal coffee" || type == "tea"
                      ? "Cup"
                      : type == "shot" || type == "tequila shot"
                      ? "Shot"
                      : type == "wine" || type == "whisky"
                      ? "Glass"
                      : "Normal"}{" "}
                  </label>
                )}
              </b>
              <br />
              {normal == "" ? (
                <label className="color-grey font-size-12">
                  {category == "drink" && unit == "not set"
                    ? ""
                    : category == "drink" && unit != ""
                    ? "R" + other_price
                    : "Not set"}
                </label>
              ) : (
                <label className="color-grey font-size-12">
                  {category == "drink" && normal == "" ? (
                    <label>R{other_price}</label>
                  ) : (
                    <label>R{normal}</label>
                  )}
                </label>
              )}
            </Col>
            <Col lg={2} className="font-size-12 mb-3">
              <b>{category == "drink" ? "1.5oz" : "Small"}</b>
              <br />
              {small == "" ? (
                <label className="color-grey font-size-12">Not set</label>
              ) : (
                <label className="color-grey font-size-12">R{small}</label>
              )}
            </Col>
            <Col lg={2} className="font-size-12 mb-3">
              <b>{category == "drink" ? "500ml" : "Medium"}</b>
              <br />
              {medium == "" ? (
                <label className="color-grey font-size-12">Not set</label>
              ) : (
                <label className="color-grey font-size-12">R{medium}</label>
              )}
            </Col>
            <Col lg={2} className="font-size-12 mb-3">
              <b>{category == "drink" ? "750ml" : "Large"}</b>
              <br />
              {large == "" ? (
                <label className="color-grey font-size-12">Not set</label>
              ) : (
                <label className="color-grey font-size-12">R{large}</label>
              )}
            </Col>
            <Col lg={2} className="font-size-12 mb-3">
              <b>{category == "drink" ? "1L" : "xLarge"}</b>
              <br />
              {extraLarge == "" ? (
                <label className="color-grey font-size-12">Not set</label>
              ) : (
                <label className="color-grey font-size-12">R{extraLarge}</label>
              )}
            </Col>
            <Col lg={2} className="font-size-12 mb-3">
              <b>{category == "drink" ? "2L" : "Bulk"}</b>
              <br />
              {bulk == "" ? (
                <label className="color-grey font-size-12">Not set</label>
              ) : (
                <label className="color-grey font-size-12">R{bulk}</label>
              )}
            </Col>

            <Col lg={12} className="font-size-17 mb-0 mt-2">
              <h6>Description</h6>
              {description == "" ? (
                <label className="font-size-17 color-grey">
                  No description
                </label>
              ) : (
                <label className="font-size-17 color-grey">
                  {" "}
                  {description}
                </label>
              )}
            </Col>
            <Col lg={12} className="font-size-17 mb-0 mt-2">
              <h6>
                Item available to order online:{" "}
                <label className="font-size-17 color-grey">
                  {" "}
                  {online_availability}
                </label>
              </h6>
            </Col>
            {seeMore && (
              <Col lg={12} className="font-size-17 mb-0 mt-2 color-grey">
                <h6 className="color-light-black">More details</h6>
                <b>Served:</b> {intervals}
                <br />
                <b>Food kind:</b> {category}
                <br />
                <b>Type:</b> {type}
              </Col>
            )}
            <Col lg={12} className="font-size-12 mb-0 mt-2">
              {more && (
                <label
                  className="color-blue text-underline-on-hover"
                  onClick={moreDetails}
                >
                  See more details...
                </label>
              )}
              {less && (
                <label
                  className="color-blue text-underline-on-hover"
                  onClick={lessDetails}
                >
                  See less details...
                </label>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <label className="cancel-btn text-bold" onClick={handleClose}>
            Close
          </label>
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={handleClose}>
        <form onSubmit={upload}>
          <Modal.Header closeButton>
            <Modal.Title>Upload media</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-center align-items-center">
              <label className="media-box-upload" htmlFor="image">
                <BsPlusCircleDotted size={20} />
                <input
                  name="file"
                  type="file"
                  onChange={selectImage}
                  id="image"
                  accept={".jpg,.jpeg,.png"}
                  multiple
                  className="hide-file-input-type"
                />
              </label>
              <div>
                {/* {displayImge.length} */}
                {showImage && (
                  <>
                    {" "}
                    <div className="food-container mt-3">
                      <Row>
                        {displayImge.map((img, index) => {
                          return (
                            <Col lg={4} xs={6} sm={4}>
                              <label
                                className={"mt-3 border-radius-5"}
                                style={{
                                  backgroundImage: "url(" + img + ")",
                                  backgroundSize: "cover",
                                  height: 120,
                                  width: "100%",
                                  border: "thin solid #ececec",

                                  // backgroundRepeat: "no-repeat",
                                  backgroundPosition: "center",
                                }}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                  </>
                )}
              </div>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <label className="cancel-btn text-bold" onClick={handleClose}>
              Close
            </label>
            {showUploadBtn && (
              <button
                type="submit"
                className={btnColor + " text-bold font-size-17"}
                disabled={disabled}
              >
                {saveLabel && <span>Upload</span>}
                {loadSpinner && <BeatLoader size={10} />}
              </button>
            )}
          </Modal.Footer>
        </form>
      </Modal>
      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}
localStorage.removeItem("updated");
