import React, { useState, useEffect } from "react";
import { Col, Container, Row, Stack, Modal } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import {
  BsPeople,
  BsFileImage,
  BsFillHouseFill,
  BsClockHistory,
  BsPlusCircleDotted,
  BsBarChart,
  BsCurrencyExchange,
  BsFillBagCheckFill,
  BsPerson,
  BsShieldLock,
  BsChatRightQuote,
  BsPersonPlus,
  BsPersonPlusFill,
} from "react-icons/bs";
import {
  IoIosRestaurant,
  IoIosRemoveCircleOutline,
  IoIosListBox,
  IoMdSettings,
  IoIosSend,
  IoIosMenu,
} from "react-icons/io";
import {
  BiQr,
  BiLogInCircle,
  BiRegistered,
  BiHomeCircle,
} from "react-icons/bi";

export default function Nav() {
  return (
    <div className="mb-0">
      <Container className="container-home nav-container">
        <Row>
          <Col lg={12} className="">
            <Stack>
              <div className="w-100  d-none d-sm-block d-md-block">
                <Row className="align-items-center justify-content-center">
                  <Col lg={2} md={3} sm={3} xs={3} className={""}>
                    <NavLink
                      to="/"
                      className="r-side-nav text-decoration-none"
                      exact
                      activeClassName="r-side-nav-active"
                    >
                      <BiHomeCircle size={20} /> &nbsp;&nbsp;Home
                    </NavLink>
                  </Col>

                  <Col lg={2} md={3} sm={3} xs={3} className={""}>
                    <NavLink
                      to="/login"
                      className="r-side-nav text-decoration-none"
                      exact
                      activeClassName="r-side-nav-active"
                    >
                      <BiLogInCircle size={20} />
                      &nbsp;&nbsp;Log In
                    </NavLink>
                  </Col>

                  {/* <Col lg={2} md={3} sm={3} xs={3} className={""}>
                    <NavLink
                      to="/explore"
                      exact
                      activeClassName="r-side-nav-active"
                      className={"r-side-nav text-decoration-none"}
                    >
                      <BsCurrencyExchange size={20} />
                      &nbsp;&nbsp;Explore
                    </NavLink>
                  </Col> */}

                  <Col lg={2} md={3} sm={3} xs={3} className={""}>
                    <NavLink
                      to="/packages"
                      className="r-side-nav text-decoration-none"
                      exact
                      activeClassName="r-side-nav-active"
                    >
                      <BiRegistered size={20} />
                      &nbsp;&nbsp;Become a host
                    </NavLink>
                  </Col>
                </Row>
              </div>
              <div className="w-100 d-sm-none d-xs-block text-right">
                <IoIosMenu size={30} />
              </div>
            </Stack>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
