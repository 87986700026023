import React, { useState, useEffect } from "react";
import { Col, Container, Row, Stack, Modal, Button } from "react-bootstrap";
import SideNav from "./navigation/SideNav";
import BeatLoader from "react-spinners/BeatLoader";
import axios from "axios";
import { Link } from "react-router-dom";
export default function Logout() {
  useEffect(() => {
    document.title = "Obarak / Loggedout";
    localStorage.clear();
  });
  return (
    <div className="body h-100">
      <Container className="container-home container-logged-out">
        <Row className="h-100 align-items-center justify-content-center">
          <Col lg={7} xs={12} md={10}>
            <Row className="h-100 align-items-center justify-content-center">
              <Row className="h-100 align-items-center justify-content-center">
                <Col lg={9}>
                  <Stack>
                    <div className="box-container">
                      <Row>
                        <Col lg={12}>
                          <label className="text-medium text-center w-100 font-size-20">
                            Obarak
                          </label>
                        </Col>

                        <Col lg={12}>
                          <div className="h-divider" />
                        </Col>
                        <Col lg={12} className="color-green text-center">
                          You have successfully logged out
                          <br />
                          <span className="textUnderlineNone color-light-black">
                            Still craving?
                          </span>{" "}
                          <Link to="/">
                            <label className="color-blue cursor mt-3 font-normal">
                              We got you
                            </label>
                          </Link>
                        </Col>
                      </Row>
                    </div>
                  </Stack>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
