import React, { useState, useEffect } from "react";
import { Col, Container, Row, Stack, Modal, Button } from "react-bootstrap";
import Nav from "./navigation/Nav";
import Select from "react-select";
import BeatLoader from "react-spinners/BeatLoader";
import validator from "validator";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import ReactFlagsSelect from "react-flags-select";
import axios from "axios";
import { Link, NavLink, useNavigate } from "react-router-dom";

export default function Sell() {
  const navigate = useNavigate();
  const [category, setCategory] = useState("");
  const [revenues, setRevenue] = useState("");
  const [booking, setBooking] = useState("");
  const [deliveries, setDeliver] = useState("");
  const [eatins, setEatins] = useState("");

  const [email, setEmail] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [phone, setPhone] = useState(null);

  const [company_name, setCompany_name] = useState("");
  const [company_email, setCompany_email] = useState("");
  const [url, setUrl] = useState("");
  const [registration_number, setRegistration_number] = useState(null);
  const [owner_fname, setOwner_fname] = useState("");
  const [owner_lname, setOwner_lname] = useState("");
  const [comments, setComments] = useState("");

  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [btnColor, setBtnColor] = useState("action-btn");
  const [disabled, setDisabled] = useState("");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [location, setLocation] = useState("");
  const [country, setCountry] = useState("");

  useEffect(() => {
    document.title = "Obarak / Register";
  });
  function handleLocation(event) {
    setLocation(event.target.value);
  }

  function submit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("fname", fname);
    formData.append("lname", lname);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("company_name", company_name);
    formData.append("company_email", company_email);
    formData.append("url", url);
    formData.append("registration_number", registration_number);
    formData.append("category", category);
    formData.append("revenue", revenues);
    formData.append("booking", booking);
    formData.append("location", location);
    formData.append("country", country);
    formData.append("owner_fname", owner_fname);
    formData.append("owner_lname", owner_lname);
    formData.append("deliveries", deliveries);
    formData.append("eatins", eatins);
    formData.append("comments", comments);
    formData.append("query", "PROFESSIONAL");

    if (fname === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter first name");
    } else if (lname === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter last name");
    } else if (email === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter contact email address");
    } else if (!validator.isEmail(email)) {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Contact email address is invalid");
    } else if (category === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Select category");
    } else if (revenues === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("What's your monthly revenue");
    } else if (bookings === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Indicate whether or not reservations are allowed");
    } else if (location === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter your business location");
    } else if (country === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Please select a country");
    } else if (owner_fname === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter director's first name");
    } else if (owner_lname === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter director's last name");
    } else if (deliveries === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Indicate whether or not you do deliver");
    } else if (eatins === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Indicate whether or not your business allow sit-in");
    } else {
      setBtnColor("disabled-btn");
      setDisabled("disabled");
      setLoadSpinner(true);
      setSaveLabel(false);
      // connect to the api

      const baseURL = process.env.REACT_APP_URL;
      axios({
        method: "POST",
        url: baseURL,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then((response) => {
        if (response.data.status == "error") {
          setBgColor("red");
          setDisplayMessage(true);
          setErrorMsg(response.data.message);
        } else {
          localStorage.setItem("email", email);
          localStorage.setItem("package", "professional");
          navigate("/verify");
        }

        setBtnColor("action-btn");
        setDisabled("");
        setLoadSpinner(false);
        setSaveLabel(true);

        // console.log(response.data.name);
      });
    }
    setTimeout(() => {
      setDisplayMessage(false);
    }, 5000);
  }

  const data = [
    { label: "Bar", value: "bar" },
    { label: "Restaurant", value: "restaurant" },
    { label: "Coffee shop", value: "coffee shop" },
    { label: "Fast food", value: "fast food" },
    { label: "Bakery", value: "bakery" },
  ];

  const revenue = [
    { label: "Less than R5k", value: "less than R5k" },
    { label: "R5k - R15k", value: "R5k - R15k" },
    { label: "R15k - R25k", value: "R15k - R25k" },
    { label: "R25k - R50k", value: "R25k - R50k" },
    { label: "More than R50k", value: "More than R50k" },
  ];

  const deliver = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

  const sitins = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

  const bookings = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

  return (
    <div className="body">
      <Container className="container-home">
        <Row>
          <Col lg={12} xs={12} md={12} className="p-0">
            <Nav />
          </Col>
          <Col lg={12} xs={12} md={12}>
            <Row className="h-100 align-items-center justify-content-center">
              <form onSubmit={submit}>
                <Row className="h-100 align-items-center justify-content-center">
                  <Col lg={7}>
                    <Stack>
                      <div className="text-center w-100 mb-3 mt-5">
                        <h3>Become a host on Obarak</h3>
                        <label className="sub-header">
                          Simply fill in the form below and we'll be in touch
                          within 2 days.
                        </label>
                      </div>
                      <div className="box-container mb-5">
                        <Row>
                          <Col lg={12}>
                            <label className="text-medium">Registration</label>
                          </Col>

                          <Col lg={12}>
                            <div className="h-divider" />
                          </Col>
                          <Col lg={12} className="mb-2">
                            <h4>How do we contact you?</h4>
                          </Col>
                          <Col lg={6} sm={6} md={6} xs={12} className="mb-3">
                            <input
                              type="text"
                              placeholder="First name"
                              onChange={(e) => setFname(e.target.value)}
                              value={fname}
                            />
                          </Col>

                          <Col lg={6} sm={6} md={6} xs={12} className="mb-3">
                            <input
                              type="text"
                              placeholder="Last name"
                              onChange={(e) => setLname(e.target.value)}
                              value={lname}
                            />
                          </Col>
                          <Col lg={6} sm={6} md={6} xs={12} className="mb-3">
                            <input
                              type="text"
                              placeholder="Email address"
                              onChange={(e) => setEmail(e.target.value)}
                              value={email}
                            />
                          </Col>
                          <Col lg={6} sm={6} md={6} xs={12} className="mb-3">
                            <input
                              type="text"
                              placeholder="Phone number"
                              onChange={(e) => setPhone(e.target.value)}
                              value={phone}
                            />
                          </Col>
                          <Col lg={12} className="mb-2 mt-3">
                            <h4>Details about your business</h4>
                          </Col>
                          <Col lg={6} sm={6} md={6} xs={12} className="mb-3">
                            <input
                              type="text"
                              placeholder="Company name"
                              onChange={(e) => setCompany_name(e.target.value)}
                              value={company_name}
                            />
                          </Col>
                          <Col lg={6} sm={6} md={6} xs={12} className="mb-3">
                            <input
                              type="email"
                              placeholder="Company's email address"
                              onChange={(e) => setCompany_email(e.target.value)}
                              value={company_email}
                            />
                          </Col>

                          <Col lg={6} sm={6} md={6} xs={12} className="mb-3">
                            <input
                              type="url"
                              placeholder="Website (optional)"
                              onChange={(e) => setUrl(e.target.value)}
                              value={url}
                            />
                          </Col>

                          <Col lg={6} sm={6} md={6} xs={12} className="mb-3">
                            <input
                              type="text"
                              placeholder="Business reg number (Optional)"
                              onChange={(e) =>
                                setRegistration_number(e.target.value)
                              }
                              value={registration_number}
                            />
                          </Col>

                          <Col lg={6} sm={6} md={6} xs={12} className="mb-3">
                            <Select
                              value={data.find((obj) => obj.value === category)}
                              placeholder="Category"
                              options={data}
                              onChange={(e) => setCategory(e.value)}
                              width={"100%"}
                              className="text-left"
                            />
                          </Col>

                          <Col lg={6} sm={6} md={6} xs={12} className="mb-3">
                            <Select
                              value={revenue.find(
                                (obj) => obj.value === revenues
                              )}
                              placeholder="Your monthly revenue"
                              options={revenue}
                              onChange={(e) => setRevenue(e.value)}
                              width={"100%"}
                              className="text-left"
                            />
                          </Col>

                          <Col lg={6} sm={6} md={6} xs={12} className="mb-3">
                            <Select
                              value={bookings.find(
                                (obj) => obj.value === booking
                              )}
                              placeholder="Does your business allow reservations?"
                              options={bookings}
                              onChange={(e) => setBooking(e.value)}
                              width={"100%"}
                              className="text-left"
                            />
                          </Col>

                          <Col lg={6} sm={6} md={6} xs={12} className="mb-3">
                            <GooglePlacesAutocomplete
                              apiKey="AIzaSyDNn7uMQVzoH91NYn7vzX437q5fp8g92FU"
                              styles={{
                                textInputContainer: {
                                  backgroundColor: "grey",
                                },
                                textInput: {
                                  height: 38,
                                  color: "#5d5d5d",
                                  fontSize: 16,
                                },
                              }}
                              textInputProps={{
                                onChange: (text) => {
                                  handleLocation(text);
                                },
                              }}
                              selectProps={{
                                onChange: (data, details = null) =>
                                  setLocation(data["value"]["description"]),
                                fetchDetails: true,
                                placeholder: "Add location to your business",
                                // defaultInputValue: this.state.venue,
                              }}
                            />
                          </Col>

                          <Col lg={12} className="mb-3">
                            <ReactFlagsSelect
                              selected={country}
                              onSelect={(code) => setCountry(code)}
                              searchable
                              searchPlaceholder="Search countries"
                              placeholder="Which country is your business operating from?"
                            />
                          </Col>

                          <Col lg={12} className="mb-2 mt-3">
                            <h4>Business Owner / Director Details</h4>
                          </Col>

                          <Col lg={6} sm={6} md={6} xs={12} className="mb-3">
                            <input
                              type="text"
                              placeholder="Business owner first name"
                              onChange={(e) => setOwner_fname(e.target.value)}
                              value={owner_fname}
                            />
                          </Col>

                          <Col lg={6} sm={6} md={6} xs={12} className="mb-3">
                            <input
                              type="text"
                              placeholder="Business owner last name"
                              onChange={(e) => setOwner_lname(e.target.value)}
                              value={owner_lname}
                            />
                          </Col>

                          <Col lg={12} className="mb-2 mt-3">
                            <h4>About your products</h4>
                          </Col>

                          <Col lg={6} sm={6} md={6} xs={12} className="mb-3">
                            <Select
                              value={deliver.find(
                                (obj) => obj.value === deliveries
                              )}
                              placeholder="Do you also deliver food ordered online?"
                              options={deliver}
                              onChange={(e) => setDeliver(e.value)}
                              width={"100%"}
                              className="text-left"
                            />
                          </Col>

                          <Col lg={6} sm={6} md={6} xs={12} className="mb-3">
                            <Select
                              value={sitins.find((obj) => obj.value === eatins)}
                              placeholder="Does your business allow a sit-in?"
                              options={sitins}
                              onChange={(e) => setEatins(e.value)}
                              width={"100%"}
                              className="text-left"
                            />
                          </Col>

                          <Col lg={12} className="mb-2">
                            <textarea
                              placeholder="Additional comments?"
                              onChange={(e) => setComments(e.target.value)}
                              value={comments}
                            />
                          </Col>

                          <Col lg={12} className="mt-3">
                            <button
                              type="submit"
                              className={btnColor}
                              disabled={disabled}
                            >
                              {saveLabel && (
                                <span className="font-medium">Apply</span>
                              )}
                              {loadSpinner && <BeatLoader size={10} />}
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </Stack>
                  </Col>
                </Row>
              </form>
            </Row>
          </Col>
        </Row>
      </Container>
      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}
