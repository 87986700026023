import React, { useState, useEffect } from "react";
import Authentication from "./navigation/authentication";
import { Col, Container, Row, Stack, Modal, Button } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import SideNav from "./navigation/SideNav";
import BeatLoader from "react-spinners/BeatLoader";
import ReactTooltip from "react-tooltip";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import {
  BsTelephoneOutbound,
  BsEnvelope,
  BsXCircle,
  BsFillPlusCircleFill,
  BsEmojiSmile,
  BsEmojiFrown,
} from "react-icons/bs";

import { IoSendOutline, IoBugOutline, IoCloseOutline } from "react-icons/io5";
const baseURL = process.env.REACT_APP_URL;
export default function Index() {
  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [btnColor, setBtnColor] = useState("action-btn");
  const [disabled, setDisabled] = useState("");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [count, setCount] = useState(0);
  const [reason, setReason] = useState("");
  const [textareaActive, setTextareaActive] = useState(false);
  const [topup, setTopup] = useState("");
  const [LoadBeat, setLoadBeat] = useState(true);
  const [NoMediaLoging, setNoMediaLoging] = useState(false);

  const accountid = localStorage.getItem("accountid");
  function list_customers() {
    axios
      .get(baseURL + "?query=LIST_CUSTOMERS&accountid=" + accountid)
      .then((response) => {
        setCustomers(response.data);
        setLoadBeat(false);
        setNoMediaLoging(true);
      });
  }

  useEffect(() => {
    document.title = "Obarak / Customers";
    list_customers();
  }, [count]);

  function ban(customerid, sanctions) {
    if (reason.trim() == "" && sanctions == 0) {
      setBgColor("red");
      setDisplayMessage(true);
      setErrorMsg("Write why you are imposing sanctions on this customer.");
      setTimeout(() => {
        setDisplayMessage(false);
      }, 5000);
    } else {
      setBtnColor("disabled-btn");
      setDisabled("disabled");
      setLoadSpinner(true);
      setSaveLabel(false);

      const stringVal = reason;
      const reasons = stringVal.split(" ").join("+");
      axios
        .get(
          baseURL +
            "?query=SANCTION&accountid=" +
            accountid +
            "&customerid=" +
            customerid +
            "&reason=" +
            reasons
        )
        .then((res) => {
          setBtnColor("action-btn");
          setDisabled("");
          setLoadSpinner(false);
          setSaveLabel(true);
          if (res.data.status == "error") {
            setBgColor("red");
            setDisplayMessage(true);
            setErrorMsg(res.data.message);
          } else {
            setBgColor("#66b266");
            setDisplayMessage(true);
            setErrorMsg(res.data.message);
            setReason("");
          }
          setCount(count + 1);
        });

      setTimeout(() => {
        setDisplayMessage(false);
      }, 4000);
    }
  }

  function activateReasonField(customerid) {
    axios
      .get(
        baseURL +
          "?query=TEXTAREA_ACTIVATE&accountid=" +
          accountid +
          "&customerid=" +
          customerid
      )
      .then((res) => {
        setTextareaActive(res.data.status);

        setCount(count + 1);
      });
  }

  function add(customerid) {
    if (topup.trim() == "") {
      setBgColor("red");
      setDisplayMessage(true);
      setErrorMsg("Enter amount");
    } else if (isNaN(topup)) {
      setBgColor("red");
      setDisplayMessage(true);
      setErrorMsg("Enter a numeric value");
    } else if (topup < 10) {
      setBgColor("red");
      setDisplayMessage(true);
      setErrorMsg("Amount cannot be less than R10.0");
    } else {
      setBtnColor("disabled-btn");
      setDisabled("disabled");
      setLoadSpinner(true);
      setSaveLabel(false);

      axios
        .get(
          baseURL +
            "?query=TOPUP&accountid=" +
            accountid +
            "&customerid=" +
            customerid +
            "&topup=" +
            topup
        )
        .then((res) => {
          setBtnColor("action-btn");
          setDisabled("");
          setLoadSpinner(false);
          setSaveLabel(true);

          if (res.data.status == "ok") {
            setBgColor("#66b266");
            setDisplayMessage(true);
            setErrorMsg(res.data.message);
            setTopup("");
          } else {
            setBgColor("red");
            setDisplayMessage(true);
            setErrorMsg(res.data.message);
          }
          setCount(count + 1);
        });
    }
    setTimeout(() => {
      setDisplayMessage(false);
    }, 4000);
  }

  function top_up(customerid) {
    axios
      .get(
        baseURL +
          "?query=ACTIVE_TOPUP&accountid=" +
          accountid +
          "&customerid=" +
          customerid
      )
      .then((res) => {
        // alert(res.data.message);
        if (res.data.status == "error") {
          setBgColor("red");
          setDisplayMessage(true);
          setErrorMsg(res.data.message);
        } else {
          setDisplayMessage(false);
        }
        setCount(count + 1);
      });
    setTimeout(() => {
      setDisplayMessage(false);
    }, 4000);
  }

  function my_customers() {
    return (
      <>
        {customers.length <= 0 ? (
          <div className="" lg={12}>
            {NoMediaLoging && (
              <label className="no-media-text color-silver pb-0 mb-0 w-100 text-center">
                No customers at the moment
              </label>
            )}
          </div>
        ) : (
          customers.map((item, index) => {
            return (
              <>
                <Row
                  key={index}
                  className="pt-2 pb-2 customer-row justify-content-center align-items-center"
                >
                  <Col lg={8} xs={10} sm={8}>
                    <Row>
                      <Col
                        lg={8}
                        xs={12}
                        className="font-size-12 mb-2 text-left"
                      >
                        <label
                          data-tip={
                            item.sanctions == 0
                              ? "No sanctions"
                              : "Currently sanctioned"
                          }
                          data-effect={"solid"}
                          data-place="top"
                          data-type={"dark"}
                          data-arrow-color={"transparent"}
                          data-background-color={
                            item.sanctions == 1 ? "red" : "#4ca64c"
                          }
                          data-class="tool_tips"
                        >
                          {item.sanctions == 0 ? (
                            <BsEmojiSmile size={15} className="color-green" />
                          ) : (
                            <BsEmojiFrown size={15} className="color-red" />
                          )}
                          <ReactTooltip />
                        </label>
                        &nbsp;&nbsp;
                        {item.name}
                      </Col>
                      <Col
                        lg={4}
                        xs={12}
                        className="font-size-12 color-grey mb-2"
                      >
                        <label
                          data-tip="Phone number"
                          data-effect={"solid"}
                          data-place="top"
                          data-type={"dark"}
                          data-arrow-color={"transparent"}
                          data-class="tool_tips"
                          className="font-size-12"
                        >
                          <BsTelephoneOutbound size={15} />
                          &nbsp;&nbsp;{item.phone}
                          <ReactTooltip />
                        </label>
                      </Col>
                    </Row>
                  </Col>

                  <Col lg={4} xs={2} sm={4}>
                    <Row>
                      <Col lg={3} sm={3} xs={12} className="text-right mb-2">
                        <label
                          className="text-right"
                          data-tip="Top up"
                          data-effect={"solid"}
                          data-place="top"
                          data-type={"dark"}
                          data-arrow-color={"transparent"}
                          data-class="tool_tips"
                          onClick={() => top_up(item.customerid)}
                        >
                          {item.active == 0 ? (
                            <BsFillPlusCircleFill
                              size={15}
                              className="color-blue cursor"
                            />
                          ) : (
                            <IoCloseOutline
                              size={20}
                              className="color-red cursor"
                            />
                          )}
                          <ReactTooltip />
                        </label>
                      </Col>

                      <Col lg={3} sm={3} xs={12} className="text-right mb-2">
                        <a
                          href={"mailto:" + item.email}
                          className="text-right"
                          data-tip="Send direct email (Using another agent)"
                          data-effect={"solid"}
                          data-place="top"
                          data-type={"dark"}
                          data-arrow-color={"transparent"}
                          data-class="tool_tips"
                        >
                          <BsEnvelope size={15} />
                          <ReactTooltip />
                        </a>
                      </Col>

                      <Col lg={3} sm={3} xs={12} className="text-right mb-2">
                        <Link
                          to={{
                            pathname: "/email",
                            search: "?q_uery=" + item.hash_email,
                          }}
                          className="text-right"
                          data-tip="Send direct email (Using Karamale)"
                          data-effect={"solid"}
                          data-place="top"
                          data-type={"dark"}
                          data-arrow-color={"transparent"}
                          data-class="tool_tips"
                        >
                          <IoSendOutline
                            size={15}
                            className="color-orange cursor"
                          />
                          <ReactTooltip />
                        </Link>
                      </Col>
                      <Col lg={3} sm={3} xs={12} className="text-right mb-2">
                        <label
                          className="text-right"
                          data-tip="Impose sanctions"
                          data-effect={"solid"}
                          data-place="top"
                          data-type={"dark"}
                          data-arrow-color={"transparent"}
                          data-class="tool_tips"
                          onClick={() => activateReasonField(item.customerid)}
                        >
                          {item.textarea_active == 0 ? (
                            <IoBugOutline
                              size={15}
                              className="color-red cursor"
                            />
                          ) : (
                            <IoCloseOutline
                              size={20}
                              className="color-red cursor"
                            />
                          )}
                          <ReactTooltip />
                        </label>
                      </Col>
                    </Row>
                  </Col>

                  {item.textarea_active == 1 ? (
                    <Col
                      lg={12}
                      className={
                        item.sanctions == 1
                          ? "text-left mt-3"
                          : "text-right mt-3"
                      }
                    >
                      <label className="font-medium w-100 text-left">
                        Reason for sanctions
                        <br />
                        <span className="font-size-12 color-grey mb-3 w-100 text-left font-normal">
                          <b>NB:</b> Sanctions will be{" "}
                          {item.sanctions == 1 ? "lifted" : "effective"}{" "}
                          immediately
                        </span>
                      </label>
                      <br />
                      {item.sanctions == 1 ? (
                        ""
                      ) : (
                        <>
                          <textarea
                            placeholder={"Why are you imposing sanctions?"}
                            className="reason"
                            onChange={(e) => setReason(e.target.value)}
                          />
                          <br />
                        </>
                      )}
                      <button
                        type="submit"
                        className={btnColor + " font-medium mt-2"}
                        disabled={disabled}
                        onClick={() => ban(item.customerid, item.sanctions)}
                      >
                        {saveLabel && (
                          <span>
                            {item.sanctions == 1 ? "Lift" : "Impose"} sanctions
                          </span>
                        )}
                        {loadSpinner && <BeatLoader size={10} />}
                      </button>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
                {item.active == 0 || item.active == "" ? (
                  ""
                ) : (
                  <Row className="justify-content-center mt-3">
                    <Col lg={4}>
                      <label className="font-medium font-size-12">
                        Top up card
                      </label>
                      <br />
                      <input
                        type="text"
                        placeholder="Enter amount"
                        className="mb-2 mt-1 font-size-12"
                        onChange={(e) => setTopup(e.target.value)}
                      />
                    </Col>
                    <Col lg={4} className="text-left p-0">
                      <label className="font-medium color-transparent">
                        eta
                      </label>
                      <br />
                      <button
                        type="button"
                        className={
                          btnColor + " font-medium mt-1 mb-2 font-size-12"
                        }
                        disabled={disabled}
                        onClick={() => add(item.customerid)}
                      >
                        {saveLabel && (
                          <span className="font-medium mt-1 mb-2 font-size-12">
                            Add
                          </span>
                        )}
                        {loadSpinner && <BeatLoader size={10} />}
                      </button>

                      <button
                        type="button"
                        className={
                          "cancel-btn font-medium mt-1 mb-2 font-size-12 m-left"
                        }
                        onClick={() => top_up(item.customerid)}
                      >
                        Cancel
                      </button>
                    </Col>
                  </Row>
                )}
              </>
            );
          })
        )}
      </>
    );
  }

  return (
    <div className="body">
      <Authentication />
      <Container className="container-home">
        <Row>
          <Col lg={2} xs={2} md={3} className="p-0">
            <SideNav />
          </Col>
          <Col lg={10} xs={10} md={9} className="middle-container">
            <Row className="h-100 align-items-center justify-content-center">
              <Row className="h-100 align-items-center justify-content-center">
                <Col lg={9}>
                  <Stack>
                    <div className="box-container">
                      <Row>
                        <Col lg={12}>
                          <label className="font-medium">Customers</label>
                        </Col>

                        <Col lg={12}>
                          <div className="h-divider" />
                        </Col>
                        <Col lg={12} className="mb-3 text-center">
                          {my_customers()}
                          {LoadBeat && <BeatLoader color={"#39c"} />}
                        </Col>
                      </Row>
                    </div>
                  </Stack>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </Container>
      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}
