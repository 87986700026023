import React, { useState, useEffect } from "react";
import Authentication from "./navigation/authentication";
import {
  Col,
  Container,
  Row,
  Stack,
  Modal,
  Button,
  Table,
} from "react-bootstrap";
import { IoIosRadioButtonOff, IoIosRadioButtonOn } from "react-icons/io";
import SideNav from "./navigation/SideNav";
import Select from "react-select";
import BeatLoader from "react-spinners/BeatLoader";
import validator from "validator";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import axios from "axios";

const root_url = process.env.REACT_APP_URL_RESOURCE;
export default function Index() {
  const today = new Date();
  const prior_date = new Date(new Date().setDate(today.getDate() - 30));
  let month, day, month_ago, day_ago;
  if (parseInt(today.getMonth()) + 1 <= 9) {
    month = "0" + (parseInt(today.getMonth()) + 1);
  } else {
    month = parseInt(today.getMonth()) + 1;
  }

  if (today.getDate() <= 9) {
    day = "0" + today.getDate();
  } else {
    day = today.getDate();
  }

  if (parseInt(prior_date.getMonth()) + 1 <= 9) {
    month_ago = "0" + (parseInt(prior_date.getMonth()) + 1);
  } else {
    month_ago = parseInt(prior_date.getMonth()) + 1;
  }

  if (parseInt(prior_date.getDate()) <= 9) {
    day_ago = "0" + prior_date.getDate();
  } else {
    day_ago = prior_date.getDate();
  }

  const date_ago = today.getFullYear() + "-" + month_ago + "-" + day_ago;
  const date = today.getFullYear() + "-" + month + "-" + day;
  const navigate = useNavigate();
  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [btnColor, setBtnColor] = useState("karamale-login-btn");
  const [disabled, setDisabled] = useState("");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [report, setReport] = useState([]);
  const [from, setFrom] = useState(date_ago);
  const [to, setTo] = useState(date);
  const [LoadBeat, setLoadBeat] = useState(true);
  const [NoData, setNoData] = useState(false);
  const [count, setCount] = useState(0);
  const [channel, setChannel] = useState("");
  const [all, setAll] = useState("online-pos-active");
  const [online, setOnline] = useState("online-pos");
  const [pos, setPos] = useState("online-pos");
  const [offOne, setoffOne] = useState(false);
  const [onOne, setonOne] = useState(true);

  const [offTwo, setoffTwo] = useState(true);
  const [onTwo, setonTwo] = useState(false);

  const [offThree, setoffThree] = useState(true);
  const [onThree, setonThree] = useState(false);

  const [channels, setChannels] = useState("all");
  const [single_chan, setSingle_chan] = useState("All");
  const [total_income, setTotal_income] = useState(0);
  const baseURL = process.env.REACT_APP_URL;
  const accountid = localStorage.getItem("accountid");
  // alert(date);
  function generate() {
    setCount(count + 1);
    // navigate("/reports");
  }
  function get_total_income() {
    axios
      .get(
        baseURL +
          "?query=TOTAL-REVENUE&accountid=" +
          accountid +
          "&from=" +
          from +
          "&to=" +
          to +
          "&channels=" +
          channels
      )
      .then((response) => {
        setTotal_income(response.data.total_income);
        setSingle_chan(response.data.channel);
      });
  }
  function fetch_report_data() {
    axios
      .get(
        baseURL +
          "?query=REVENUE&accountid=" +
          accountid +
          "&from=" +
          from +
          "&to=" +
          to +
          "&channels=" +
          channels
      )
      .then((response) => {
        setReport(response.data);
        setLoadBeat(false);
        setNoData(true);
      });
  }
  // alert(date_ago);
  useEffect(() => {
    document.title = "Obarak / Revenue";
    fetch_report_data();
    get_total_income();
  }, [count]);

  function generate_report() {
    return (
      <>
        {report.length < 1 ? (
          <>
            {NoData && (
              <div className="w-100 mt-3 font-size-20 color-grey font-medium">
                No data found
              </div>
            )}
          </>
        ) : (
          <>
            <Row className="align-items-center">
              <Col lg={6} xs={12} sm={12} className="mb-2">
                <label
                  htmlFor={"total"}
                  className={"w-100 text-left color-grey"}
                >
                  <span className="font-medium color-black">Total income:</span>{" "}
                  R{total_income}
                </label>
                <br />
                <label
                  htmlFor={"channel"}
                  className={"w-100 text-left color-grey"}
                >
                  <span className="font-medium color-black">Channel:</span>{" "}
                  {single_chan}
                </label>
              </Col>
              <Col lg={6} xs={12} sm={12}>
                <Row className="justify-content-end align-items-center">
                  <Col lg={4} sm={4} className="text-align-left mb-2">
                    <div className={all} onClick={handleAll}>
                      <Row className="align-items-center justify-content-center">
                        <Col
                          lg={3}
                          xs={1}
                          sm={3}
                          classNmae="text-right"
                          style={{ paddingBottom: 4 }}
                        >
                          {offOne && <IoIosRadioButtonOff size={18} />}
                          {onOne && <IoIosRadioButtonOn size={18} />}
                        </Col>
                        <Col lg={8} xs={11} sm={9} className="text-left">
                          All
                        </Col>
                      </Row>
                    </div>
                    <span className="font-size-12 color-grey">
                      Online and POS
                    </span>
                  </Col>
                  <Col lg={4} sm={4} className="text-align-left mb-2">
                    <div className={online} onClick={handleOnline}>
                      <Row className="align-items-center justify-content-center">
                        <Col
                          lg={3}
                          xs={1}
                          sm={3}
                          classNmae="text-right"
                          style={{ paddingBottom: 4 }}
                        >
                          {offTwo && <IoIosRadioButtonOff size={18} />}
                          {onTwo && <IoIosRadioButtonOn size={18} />}
                        </Col>
                        <Col lg={8} xs={11} sm={9} className="text-left">
                          Online
                        </Col>
                      </Row>
                    </div>
                    <span className="font-size-12 color-grey">Orders</span>
                  </Col>

                  <Col lg={4} sm={4} className="text-align-left mb-2">
                    <div className={pos} onClick={handlePos}>
                      <Row className="align-items-center justify-content-center">
                        <Col
                          lg={3}
                          xs={1}
                          sm={3}
                          classNmae="text-right"
                          style={{ paddingBottom: 4 }}
                        >
                          {offThree && <IoIosRadioButtonOff size={18} />}
                          {onThree && <IoIosRadioButtonOn size={18} />}
                        </Col>
                        <Col lg={8} xs={11} sm={9} className="text-left">
                          POS
                        </Col>
                      </Row>
                    </div>
                    <span className="font-size-12 color-grey">
                      Point of sale
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Table responsive striped hover>
              <thead>
                <tr>
                  <th className="text-left">Item</th>
                  <th className="text-left">Quantity</th>
                  <th className="text-left">Income</th>
                </tr>
              </thead>
              <tbody>
                {report.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="font-medium text-left">
                        <div
                          className="icon"
                          style={{
                            backgroundImage: `url(${root_url}/assets/${localStorage.getItem(
                              "accountid"
                            )}/${item.media})`,
                          }}
                        ></div>
                        {item.item_name}
                      </td>
                      <td className="color-grey text-left">{item.quantity}</td>
                      <td className="color-grey text-left">R{item.cost}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </>
        )}
      </>
    );
  }

  function handleChannel(e) {
    setChannel(e.value);
  }

  function handlePos() {
    setoffThree(false);
    setonThree(true);
    setPos("online-pos-active");

    setoffOne(true);
    setonOne(false);
    setOnline("online-pos");

    setoffTwo(true);
    setonTwo(false);
    setAll("online-pos");

    setChannels("pos");
    setCount(count + 1);
  }

  function handleAll() {
    setoffThree(true);
    setonThree(false);
    setPos("online-pos");

    setoffOne(false);
    setonOne(true);
    setOnline("online-pos");

    setoffTwo(true);
    setonTwo(false);
    setAll("online-pos-active");

    setChannels("all");
    setCount(count + 1);
  }

  function handleOnline() {
    setoffThree(true);
    setonThree(false);
    setPos("online-pos");

    setoffOne(true);
    setonOne(false);
    setOnline("online-pos-active");

    setoffTwo(false);
    setonTwo(true);
    setAll("online-pos");

    setChannels("online");
    setCount(count + 1);
  }
  return (
    <div className="body">
      <Authentication />
      <Container className="container-home">
        <Row>
          <Col lg={2} xs={2} md={3} className="p-0">
            <SideNav />
          </Col>
          <Col lg={10} xs={10} md={9} className="middle-container">
            <Row className="h-100 align-items-center justify-content-center">
              <Row className="h-100 align-items-center justify-content-center">
                <Col lg={12}>
                  <Stack>
                    <div className="box-container mt-3 mb-3">
                      <Row className="align-items-center">
                        <Col lg={6}>
                          <label className="text-medium font-size-17">
                            Revenue
                          </label>
                          <br />
                          <span className="font-size-12 color-grey">
                            See{" "}
                            <Link to="/reports" className="font-size-12">
                              transactions
                            </Link>
                          </span>
                        </Col>

                        <Col lg={2} xs={6} sm={4}>
                          <label className="font-medium">From</label>
                          <br />
                          <input
                            type="date"
                            value={from}
                            onChange={(e) => setFrom(e.target.value)}
                            className="orders-input"
                          />
                        </Col>
                        <Col lg={2} xs={6} sm={4}>
                          <label className="font-medium">to</label>
                          <br />
                          <input
                            type="date"
                            value={to}
                            onChange={(e) => setTo(e.target.value)}
                            className="orders-input"
                          />
                        </Col>

                        <Col lg={2} xs={12} sm={4}>
                          <label className="color-white">generate</label>
                          <br />
                          <button
                            type="submit"
                            className={
                              btnColor +
                              " w-100 font-medium orders-input padding-3"
                            }
                            disabled={disabled}
                            onClick={generate}
                          >
                            {saveLabel && <span>Generate</span>}
                            {loadSpinner && <BeatLoader size={10} />}
                          </button>
                        </Col>
                        <Col lg={12} className="text-right mt-1">
                          <span className="font-size-12 color-grey">
                            Default: 30 days
                          </span>
                        </Col>

                        <Col lg={12}>
                          <div className="h-divider" />
                        </Col>

                        {/* <Row className="justify-content-end p-0">
                          <Col lg={2}>
                            <div className={all} onClick={handleAll}>
                              <Row className="align-items-center justify-content-center">
                                <Col
                                  lg={3}
                                  classNmae="text-right"
                                  style={{ paddingBottom: 4 }}
                                >
                                  {offOne && <IoIosRadioButtonOff size={18} />}
                                  {onOne && <IoIosRadioButtonOn size={18} />}
                                </Col>
                                <Col lg={8} className="text-left">
                                  All
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col lg={2}>
                            <div className={online} onClick={handleOnline}>
                              <Row className="align-items-center justify-content-center">
                                <Col
                                  lg={3}
                                  classNmae="text-right"
                                  style={{ paddingBottom: 4 }}
                                >
                                  {offTwo && <IoIosRadioButtonOff size={18} />}
                                  {onTwo && <IoIosRadioButtonOn size={18} />}
                                </Col>
                                <Col lg={8} className="text-left">
                                  Online
                                </Col>
                              </Row>
                            </div>
                          </Col>

                          <Col lg={2}>
                            <div className={pos} onClick={handlePos}>
                              <Row className="align-items-center justify-content-center">
                                <Col
                                  lg={3}
                                  classNmae="text-right"
                                  style={{ paddingBottom: 4 }}
                                >
                                  {offThree && (
                                    <IoIosRadioButtonOff size={18} />
                                  )}
                                  {onThree && <IoIosRadioButtonOn size={18} />}
                                </Col>
                                <Col lg={8} className="text-left">
                                  POS
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row> */}

                        <Col lg={12} className="text-center">
                          {generate_report()}
                          {LoadBeat && <BeatLoader color={"#39c"} />}
                        </Col>

                        <Col lg={12} className="mt-0">
                          <label className="color-grey font-size-12">
                            Click <Link to="/reports">here</Link> for
                            transactions
                          </label>
                        </Col>
                      </Row>
                    </div>
                  </Stack>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </Container>
      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}
