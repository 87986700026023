import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Entry from "./components/Entry";
import Dashboard from "./components/Dashboard";
import Media from "./components/Media";
import Addstock from "./components/Addstock";
import Adduser from "./components/Adduser";
import Addcustomer from "./components/Addcustomer";
import Profile from "./components/Profile";
import Passwords from "./components/Passwords";
import Email from "./components/Email";
import Premium from "./components/Premium";
import Basic from "./components/Basic";
import Packages from "./components/Packages";
import Login from "./components/Login";
import RetrievePassword from "./components/Retrieve_password";
import Startup from "./components/Start_up";
import Verify from "./components/Verify";
import Thanks from "./components/Thank_you";
import Trash from "./components/Trash";
import Adddrinks from "./components/Adddrinks";
import Home from "./components/Home";
import Basket from "./components/Basket";
import Checkout from "./components/Checkout";
import Logout from "./components/Logout";
import My_profile from "./components/My_profile";
import Av from "./components/Av";
import My_password from "./components/My_password";
import Orders from "./components/Orders";
import Track_orders from "./components/Track_orders";
import Continue from "./components/Continue";
import Successful from "./components/Successful";
import Mailer from "./components/Mailer";
import Analytics from "./components/Analytics";
import Reports from "./components/Reports.js";
import Revenue from "./components/Revenue.js";
import Payments from "./components/Payments.js";
import Add_bank_details from "./components/Add_bank_details";
import Customers from "./components/Customers";
import Topups from "./components/Topups";
import History from "./components/History";
import Historical_orders from "./components/Historical_orders";
import Trading_hours from "./components/Trading_hours";
import Company_ from "./components/Company_";
import New_password from "./components/New_password";
import Perfect from "./components/Perfect";

function App() {
  return (
    <Router forceRefresh={false}>
      <Routes>
        <Route path={"/"} exact element={<Home />} />
        <Route path={"/dashboard"} exact element={<Dashboard />} />
        <Route path={"/media"} exact element={<Media />} />
        <Route path={"/addstock"} exact element={<Addstock />} />
        <Route path={"/adduser"} exact element={<Adduser />} />
        <Route path={"/addcustomer"} exact element={<Addcustomer />} />
        <Route path={"/profile"} exact element={<Profile />} />
        <Route path={"/passwords"} exact element={<Passwords />} />
        <Route path={"/email"} exact element={<Email />} />
        <Route path={"/premium"} exact element={<Premium />} />
        <Route path={"/basic"} exact element={<Basic />} />
        <Route path={"/packages"} exact element={<Packages />} />
        <Route path={"/login"} exact element={<Login />} />
        <Route path={"/start_up"} exact element={<Startup />} />
        <Route path={"/verify"} exact element={<Verify />} />
        <Route path={"/thank_you"} exact element={<Thanks />} />
        <Route path={"/trash"} exact element={<Trash />} />
        <Route path={"/adddrinks"} exact element={<Adddrinks />} />
        <Route path={"/basket"} exact element={<Basket />} />
        <Route path={"/checkout"} exact element={<Checkout />} />
        <Route path={"/logout"} exact element={<Logout />} />
        <Route path={"/my_profile"} exact element={<My_profile />} />
        <Route path={"/av"} exact element={<Av />} />
        <Route path={"/my_password"} exact element={<My_password />} />
        <Route path={"/orders"} exact element={<Orders />} />
        <Route path={"/track_orders"} exact element={<Track_orders />} />
        <Route path={"/continue"} exact element={<Continue />} />
        <Route path={"/successful"} exact element={<Successful />} />
        <Route path={"/mailer"} exact element={<Mailer />} />
        <Route path={"/analytics"} exact element={<Analytics />} />
        <Route path={"/reports"} exact element={<Reports />} />
        <Route path={"/revenue"} exact element={<Revenue />} />
        <Route path={"/payments"} exact element={<Payments />} />
        <Route path={"/bank"} exact element={<Add_bank_details />} />
        <Route path={"/customers"} exact element={<Customers />} />
        <Route path={"/topups"} exact element={<Topups />} />
        <Route path={"/history"} exact element={<History />} />
        <Route path={"/trading_hours"} exact element={<Trading_hours />} />
        <Route path={"/company_"} exact element={<Company_ />} />
        <Route path={"/new_password"} exact element={<New_password />} />
        <Route path={"/perfect"} exact element={<Perfect />} />
        <Route
          path={"/historical_orders"}
          exact
          element={<Historical_orders />}
        />
        <Route
          path={"/retrieve_password"}
          exact
          element={<RetrievePassword />}
        />
      </Routes>
    </Router>
  );
}

export default App;
