import React, { useState, useEffect } from "react";
import { Col, Container, Row, Stack, Modal, Button } from "react-bootstrap";
import Nav from "./navigation/Nav";
import Select from "react-select";
import BeatLoader from "react-spinners/BeatLoader";
import validator from "validator";
import { Link, NavLink } from "react-router-dom";
import {
  IoIosCheckmarkCircleOutline,
  IoIosCloseCircleOutline,
} from "react-icons/io";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
export default function Packages() {
  const [category, setCategory] = useState("");
  const [revenues, setRevenue] = useState("");
  const [booking, setBooking] = useState("");
  const [company_name, setCompany_name] = useState("");
  const [company_email, setCompany_email] = useState("");
  const [url, setUrl] = useState("");
  const [registration_number, setRegistration_number] = useState(null);
  const [comments, setComments] = useState("");

  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [btnColor, setBtnColor] = useState("action-btn");
  const [disabled, setDisabled] = useState("");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [location, setLocation] = useState("");

  useEffect(() => {
    document.title = "Obarak / Become a host";
  });

  function handleLocation(event) {
    setLocation(event.target.value);
  }

  function submit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("company_name", company_name);
    formData.append("company_email", company_email);
    formData.append("url", url);
    formData.append("category", category);
    formData.append("revenues", revenues);
    formData.append("booking", category);
    formData.append("category", booking);
    formData.append("location", location);
    formData.append("comments", comments);

    if (company_name === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter your company name");
    } else if (company_email === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter company email address");
    } else if (!validator.isEmail(company_email)) {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Email address is invalid");
    } else if (category === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Choose category");
    } else if (booking === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Please indicate whether or not you allow reservations");
    } else if (location === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Add location to your business");
    } else {
      // connect to the api
      setBtnColor("disabled-btn");
      setDisabled("disabled");
      setLoadSpinner(true);
      setSaveLabel(false);

      setBgColor("#66b266");
      setDisplayMessage(true);
      setErrorMsg("User successfully added");
    }
    setTimeout(() => {
      setDisplayMessage(false);
    }, 5000);
  }

  const data = [
    { label: "Bar", value: "bar" },
    { label: "Restaurant", value: "restaurant" },
    { label: "Coffee shop", value: "coffee shop" },
    { label: "Fast food", value: "fast food" },
    { label: "Buffet", value: "buffet" },
    { label: "Bakery", value: "bakery" },
    { label: "Pop-Up Restaurant", value: "pop-up restaurant" },
    { label: "Ghost Restaurant", value: "ghost restaurant" },
  ];

  const revenue = [
    { label: "Less than R5k", value: "less than R5k" },
    { label: "R5k - R15k", value: "R5k - R15k" },
    { label: "R15k - R25k", value: "R15k - R25k" },
    { label: "R25k - R50k", value: "R25k - R50k" },
    { label: "More than R50k", value: "More than R50k" },
  ];

  const bookings = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

  return (
    <div className="package-body height-100hv">
      <Container className="container-home height-100hv">
        <Row className="height-100hv middle-container">
          <Col lg={12} xs={12} md={12} className="p-0">
            <Nav />
          </Col>
          <Col lg={12} xs={12} md={12}>
            <Row className="align-items-center justify-content-center">
              <Row className="h-100 align-items-center justify-content-center">
                <Col lg={9}>
                  <Stack>
                    <div className="text-center w-100 mb-3 mt-5">
                      <h3>Become a host</h3>
                    </div>
                    <Row className="justify-content-center">
                      {/* <Col lg={4} md={6} sm={6} xs={12}>
                        <div className="box-container mb-5">
                          <Row>
                            <Col lg={12}>
                              <label className="text-medium">Start up</label>
                              <label className="float-right">
                                <strong>Free</strong>
                              </label>
                            </Col>

                            <Col lg={12}>
                              <div className="h-divider" />
                            </Col>

                            <Col lg={12} className="mb-2">
                              <label className="pl-0 pr-0 pt-2 pb-2 w-100">
                                <IoIosCheckmarkCircleOutline
                                  size={20}
                                  color={"#66b266"}
                                />{" "}
                                &nbsp; Point of sale
                              </label>
                              <label className="pl-0 pr-0 pt-2 pb-2 w-100">
                                <IoIosCheckmarkCircleOutline
                                  size={20}
                                  color={"#66b266"}
                                />{" "}
                                &nbsp; Sales reports
                              </label>
                              <label className="pl-0 pr-0 pt-2 pb-2 w-100">
                                <IoIosCloseCircleOutline
                                  size={20}
                                  color={"red"}
                                />{" "}
                                &nbsp; Revenue reports
                              </label>

                              <label className="pl-0 pr-0 pt-2 pb-2 w-100">
                                <IoIosCloseCircleOutline
                                  size={20}
                                  color={"red"}
                                />{" "}
                                &nbsp; Stock inventory
                              </label>

                              <label className="pl-0 pr-0 pt-2 pb-2 w-100">
                                <IoIosCloseCircleOutline
                                  size={20}
                                  color={"red"}
                                />{" "}
                                &nbsp; Obarak virtual
                              </label>
                              <label className="pl-0 pr-0 pt-2 pb-2 w-100">
                                <IoIosCloseCircleOutline
                                  size={20}
                                  color={"red"}
                                />{" "}
                                &nbsp; Ordering food online
                              </label>

                              <label className="mt-4 w-100">
                                <Link
                                  to="/start_up"
                                  className="action-btn textUnderLineNone text-color-white"
                                >
                                  Sign up
                                </Link>
                              </label>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col lg={4} md={6} sm={6} xs={12}>
                        <div className="box-container mb-5">
                          <Row>
                            <Col lg={12}>
                              <label className="text-medium">Basic</label>
                              <label className="float-right">
                                <strong>R 150</strong> / pm
                              </label>
                            </Col>

                            <Col lg={12}>
                              <div className="h-divider" />
                            </Col>

                            <Col lg={12} className="mb-2">
                              <label className="pl-0 pr-0 pt-2 pb-2 w-100">
                                <IoIosCheckmarkCircleOutline
                                  size={20}
                                  color={"#66b266"}
                                />{" "}
                                &nbsp; Point of sale
                              </label>
                              <label className="pl-0 pr-0 pt-2 pb-2 w-100">
                                <IoIosCheckmarkCircleOutline
                                  size={20}
                                  color={"#66b266"}
                                />{" "}
                                &nbsp; Sales reports
                              </label>
                              <label className="pl-0 pr-0 pt-2 pb-2 w-100">
                                <IoIosCheckmarkCircleOutline
                                  size={20}
                                  color={"#66b266"}
                                />{" "}
                                &nbsp; Revenue reports
                              </label>
                              <label className="pl-0 pr-0 pt-2 pb-2 w-100">
                                <IoIosCheckmarkCircleOutline
                                  size={20}
                                  color={"#66b266"}
                                />{" "}
                                &nbsp; Stock inventory
                              </label>
                              <label className="pl-0 pr-0 pt-2 pb-2 w-100">
                                <IoIosCloseCircleOutline
                                  size={20}
                                  color={"red"}
                                />{" "}
                                &nbsp; Obarak virtual
                              </label>
                              <label className="pl-0 pr-0 pt-2 pb-2 w-100">
                                <IoIosCloseCircleOutline
                                  size={20}
                                  color={"red"}
                                />{" "}
                                &nbsp; Ordering food online
                              </label>

                              <label className="mt-4 w-100">
                                <Link
                                  to="/basic"
                                  className="action-btn textUnderLineNone text-color-white"
                                >
                                  Sign up
                                </Link>
                              </label>
                            </Col>
                          </Row>
                        </div>
                      </Col> */}

                      <Col lg={6} md={6} sm={6} xs={12}>
                        <div className="box-container mb-5">
                          <Row>
                            <Col lg={12}>
                              <label className="text-medium">
                                Do the right thing
                              </label>
                              {/* <label className="float-right">
                                <strong>R 200</strong> / pm
                              </label> */}
                            </Col>

                            <Col lg={12}>
                              <div className="h-divider" />
                            </Col>

                            <Col lg={12} className="mb-2">
                              <label className="pl-0 pr-0 pt-2 pb-2 w-100">
                                <IoIosCheckmarkCircleOutline
                                  size={20}
                                  color={"#66b266"}
                                />{" "}
                                &nbsp; Point of sale
                              </label>
                              <label className="pl-0 pr-0 pt-2 pb-2 w-100">
                                <IoIosCheckmarkCircleOutline
                                  size={20}
                                  color={"#66b266"}
                                />{" "}
                                &nbsp; Sales reports
                              </label>
                              <label className="pl-0 pr-0 pt-2 pb-2 w-100">
                                <IoIosCheckmarkCircleOutline
                                  size={20}
                                  color={"#66b266"}
                                />{" "}
                                &nbsp; Revenue reports
                              </label>
                              <label className="pl-0 pr-0 pt-2 pb-2 w-100">
                                <IoIosCheckmarkCircleOutline
                                  size={20}
                                  color={"#66b266"}
                                />{" "}
                                &nbsp; Stock inventory
                              </label>

                              <label className="pl-0 pr-0 pt-2 pb-2 w-100">
                                <IoIosCheckmarkCircleOutline
                                  size={20}
                                  color={"#66b266"}
                                />{" "}
                                &nbsp; Order food online
                              </label>
                              <label className="pl-0 pr-0 pt-2 pb-2 w-100">
                                <IoIosCheckmarkCircleOutline
                                  size={20}
                                  color={"#66b266"}
                                />{" "}
                                &nbsp; Obarak pay
                              </label>

                              <label className="pl-0 pr-0 pt-2 pb-2 w-100">
                                <IoIosCheckmarkCircleOutline
                                  size={20}
                                  color={"#66b266"}
                                />{" "}
                                &nbsp; and more...
                              </label>

                              <label className="mt-4 w-100">
                                <Link
                                  to="/premium"
                                  className="action-btn textUnderLineNone text-color-white font-medium"
                                >
                                  Sign up
                                </Link>
                              </label>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Stack>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </Container>
      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}
