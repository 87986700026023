import React, { useState, useEffect } from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import HomeNav from "./navigation/HomeNav";
import { BsFillForwardFill, BsArrowRight } from "react-icons/bs";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
const baseURL = process.env.REACT_APP_URL;
const root_url = process.env.REACT_APP_URL_RESOURCE;
export default function Mailer() {
  const navigate = useNavigate();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get("token");

  const [orders, setOrders] = useState([]);

  function fetch_mailer() {
    const customerid = localStorage.getItem("customerid");
    axios
      .get(
        baseURL + "?query=MAILER&token=" + token + "&customerid=" + customerid
      )
      .then((response) => {
        setOrders(response.data);
      });
  }
  useEffect(() => {
    document.title = "Azania Systems / Order successful";
    fetch_mailer();
  }, []);

  function loop_orders() {
    return (
      <>
        {orders.length < 1 ? (
          orders.length
        ) : (
          <Row>
            {orders.map((item, key) => {
              return (
                <Row className="align-items-center">
                  <Col lg={1}>
                    <div
                      className="mailer-media mb-3"
                      style={{
                        backgroundImage: `url(${item.media})`,
                      }}
                    />{" "}
                  </Col>
                  <Col lg={4}>
                    <label className="font-medium font-size-15">
                      {item.item_name}
                    </label>
                    <br />
                    <span className="color-grey">{item.type}</span>
                  </Col>

                  <Col lg={4}>
                    <label className="font-medium font-size-12">
                      {item.normal == "" ? (
                        ""
                      ) : (
                        <label className="h-container-size font-size-12 color-grey font-normal">
                          {item.normal}
                        </label>
                      )}

                      {item.small == "" ? (
                        ""
                      ) : (
                        <label className="h-container-size font-size-12 color-grey font-normal">
                          {item.small}
                        </label>
                      )}

                      {item.medium == "" ? (
                        ""
                      ) : (
                        <label className="h-container-size font-size-12 color-grey font-normal">
                          {item.medium}
                        </label>
                      )}

                      {item.large == "" ? (
                        ""
                      ) : (
                        <label className="h-container-size font-size-12 color-grey font-normal">
                          {item.large}
                        </label>
                      )}

                      {item.extra_large == "" ? (
                        ""
                      ) : (
                        <label className="h-container-size font-size-12 color-grey font-normal">
                          {item.extra_large}
                        </label>
                      )}

                      {item.bulk == "" ? (
                        ""
                      ) : (
                        <label className="h-container-size font-size-12 color-grey font-normal">
                          {item.bulk}
                        </label>
                      )}
                    </label>
                  </Col>

                  <Col lg={3}>
                    <label className="font-size-12 color-grey font-normal">
                      Total: R{item.total}
                    </label>
                    <br />
                  </Col>
                </Row>
              );
            })}
          </Row>
        )}
      </>
    );
  }

  return (
    <div className="body">
      <Container className="container-home">
        <Row>
          <Col lg={12} xs={12} md={12} sm={12} className={"middle-container"}>
            <Row className="h-100 align-items-center justify-content-center">
              <Col lg={8} sm={6} md={5} xs={12} className="mb-3">
                <Col lg={12}>
                  <label className="font-medium font-size-17 mt-3">
                    Order details
                  </label>
                </Col>
                <Stack>
                  <div className="box-container mt-3 mb-3 add-relative-position">
                    <Row className="justify-content-center">
                      {loop_orders()}
                    </Row>
                  </div>
                </Stack>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
