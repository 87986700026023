import React, { useState, useEffect } from "react";
import Authentication from "./navigation/authentication";
import { Col, Container, Row, Stack } from "react-bootstrap";
import SideNav from "./navigation/SideNav";
import Select from "react-select";
import { Link, NavLink, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import {
  IoIosCheckboxOutline,
  IoMdSquareOutline,
  IoIosCheckmark,
} from "react-icons/io";
import axios from "axios";
export default function Addstock() {
  const navigate = useNavigate();
  const [sizes, setSizes] = useState("");
  const [category, setCategory] = useState("");
  const [dayTime, setDayTime] = useState("");
  const [meal, setMeal] = useState("");
  const [online_order, setOnline_order] = useState("");
  const [other_sizes, setOther_sizes] = useState("");
  const [item, setItem] = useState(localStorage.getItem("item_name"));
  const [description, setDes] = useState(localStorage.getItem("description"));
  const [priceOne, setPrice_one] = useState("");
  const [priceTwo, setPrice_two] = useState("");
  const [priceThree, setPrice_three] = useState("");
  const [priceFour, setPrice_four] = useState("");
  const [priceFive, setPrice_five] = useState("");
  const [priceSix, setPrice_six] = useState("");
  const [priceSeven, setPrice_seven] = useState("");
  const [volume, setVolume] = useState("");
  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [btnColor, setBtnColor] = useState("action-btn");
  const [disabled, setDisabled] = useState("");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [filledOne, setFilledOne] = useState(false);
  const [emptyOne, setEmptyOne] = useState(true);

  const [filledTwo, setFilledTwo] = useState(false);
  const [emptyTwo, setEmptyTwo] = useState(true);

  const [filledThree, setFilledThree] = useState(false);
  const [emptyThree, setEmptyThree] = useState(true);

  const [filledFour, setFilledFour] = useState(false);
  const [emptyFour, setEmptyFour] = useState(true);

  const [filledFive, setFilledFive] = useState(false);
  const [emptyFive, setEmptyFive] = useState(true);

  const [filledSix, setFilledSix] = useState(false);
  const [emptySix, setEmptySix] = useState(true);

  const [filledSeven, setFilledSeven] = useState(false);
  const [emptySeven, setEmptySeven] = useState(true);

  const [textOne, setTextOne] = useState(false);
  const [textTwo, setTextTwo] = useState(false);
  const [textThree, setTextThree] = useState(false);
  const [textFour, setTextFour] = useState(false);
  const [textFive, setTextFive] = useState(false);
  const [textSix, setTextSix] = useState(false);
  const [textSeven, setTextSeven] = useState(false);
  const [bgOptions, setBgOptions] = useState("size-options");
  const [otherOptions, setOtherOptions] = useState(true);
  const [firstOption, setFirstOption] = useState(true);
  const [loadDivider, setLoadDivider] = useState(true);
  const [foodid, setFoodid] = useState("");
  const [drink, setDrink] = useState("");
  const [drinkType, setDrinkType] = useState(false);
  const [foodFields, setFoodFields] = useState(true);
  const [otherType, setOtherType] = useState(false);
  const [sizeTextOne, setSizeTextOne] = useState("Normal");
  const [sizeTextTwo, setSizeTextTwo] = useState("Small");
  const [sizeTextThree, setSizeTextThree] = useState("Medium");
  const [sizeTextFour, setSizeTextFour] = useState("Large");
  const [sizeTextFive, setSizeTextFive] = useState("Extra large");
  const [sizeTextSix, setSizeTextSix] = useState("Bulk");
  function handleChange(e) {
    setSizes(e.value);
  }

  useEffect(() => {
    document.title = "Obarak | Add items";
  });

  function handleCategory(e) {
    setMeal("");
    if (e.value == "drink") {
      setDrinkType(true);
      setFoodFields(false);
    } else {
      setDrinkType(false);
      setFoodFields(true);

      setOtherType(false);
      setSizeTextTwo("Small");
      setSizeTextThree("Medium");
      setSizeTextFour("Large");
      setSizeTextFive("Extra large");
      setSizeTextSix("Bulk");
    }
    setCategory(e.value);
  }

  function handleDay_time(e) {
    setDayTime(e.value);
  }
  function handleMeals(e) {
    setMeal(e.value);
  }

  function handleOnlineOrder(e) {
    setOnline_order(e.value);
  }

  function handleOtherSize(e) {
    setOther_sizes(e.value);
  }

  const drinks = [
    { label: "Smoothie", value: "smoothie" },
    { label: "Cappuccino (Coffee drink)", value: "cappuccino" },
    { label: "Normal coffee", value: "normal coffee" },
    { label: "Tea", value: "tea" },
    { label: "Milkshake", value: "milkshake" },
    { label: "Shot", value: "shot" },
    { label: "Tequila shot", value: "tequila shot" },
    { label: "Soft drink", value: "soft drink" },
    { label: "Spring water", value: "spring water" },
    { label: "Still water", value: "still water" },
    { label: "Sparkling water", value: "sparkling water" },
    { label: "Champagne", value: "champagne" },
    { label: "Whisky (Glass)", value: "whisky" },
    { label: "Whisky (Bottle)", value: "bottle of whisky" },
    { label: "Wine (Glass)", value: "wine" },
    { label: "Wine (Bottle)", value: "bottle of wine" },
    { label: "Spirit", value: "spirit" },
    { label: "Cider", value: "cider" },
    { label: "Beer", value: "beer" },
    { label: "Tequila", value: "tequila" },
    { label: "Non-Alcohol", value: "non-alcohol" },
    { label: "Alcohol", value: "alcohol" },
    { label: "Non alcoholic cocktail", value: "non alcoholic cocktail" },
    { label: "Alcoholic cocktail", value: "alcoholic cocktail" },
    { label: "Other", value: "other" },
  ];

  const meals = [
    { label: "Meal", value: "meal" },
    { label: "Meal for one", value: "meal for one" },
    { label: "Meal for two", value: "meal for two" },
    { label: "Meal for the whole family", value: "meal for the whole family" },
    { label: "Platta", value: "platta" },
    { label: "Platta for one", value: "platta for one" },
    { label: "Platta for two", value: "platta for two" },
    { label: "Snack", value: "snack" },
    { label: "Starter", value: "starter" },
    { label: "Other", value: "other" },
  ];

  const day_time = [
    { label: "Grocery", value: "grocery" },
    { label: "Breakfast", value: "breakfast" },
    { label: "Brunch", value: "brunch" },
    { label: "Lunch", value: "lunch" },
    { label: "Dinner", value: "dinner" },
    {
      label: "Breakfast, Brunch or Lunch",
      value: "breakfast, brunch or lunch",
    },
    { label: "Brunch or Lunch", value: "brunch or lunch" },
    { label: "Lunch or Dinner", value: "lunch or dinner" },
    { label: "Anytime of the day", value: "any time of the day" },
  ];

  const categories = [
    { label: "Drink", value: "drink" },
    { label: "Solid food", value: "solid" },
    { label: "Soft food", value: "soft" },
  ];
  const data = [
    { label: "Normal", value: "normal" },
    { label: "Small", value: "small" },
    { label: "Medium", value: "medium" },
    { label: "Large", value: "large" },
    { label: "Extra large", value: "extra large" },
  ];

  const orderOnline = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

  const otherSizes = [
    { label: "Litre", value: "litre" },
    { label: "Ounces", value: "ounces" },
    { label: "Kg", value: "kg" },
    { label: "ml", value: "ml" },
    { label: "Grams", value: "grams" },
    { label: "Lbs", value: "lbs" },
  ];

  function submit(e) {
    // alert(localStorage.getItem("normal"));
    e.preventDefault();

    let foodid = 0;
    if (localStorage.getItem("foodid") != null) {
      foodid = localStorage.getItem("foodid");
    } else {
      foodid = foodid;
    }

    const formData = new FormData();
    formData.append("foodid", foodid);
    formData.append("item", item);
    formData.append("description", description);
    formData.append("category", category);
    formData.append("type", meal);
    formData.append("intervals", dayTime);
    formData.append("normal_price", priceOne);
    formData.append("small_price", priceTwo);
    formData.append("medium_price", priceThree);
    formData.append("large_price", priceFour);
    formData.append("extra_large_price", priceFive);
    formData.append("bulk_price", priceSix);
    formData.append("accountid", localStorage.getItem("accountid"));
    formData.append("online_availability", online_order);
    formData.append("other_price", priceSeven);
    formData.append("volume", volume);
    formData.append("unit", other_sizes);
    formData.append("query", "ADD-FOOD");

    if (item === null) {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter item name");
    } else if (category === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Choose category");
    } else if (meal === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg(
        category === "drink" ? "Choose drink type" : "Choose food type."
      );
    } else if (category !== "drink" && dayTime === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Choose food interval");
    } else if (online_order === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg(
        "Indicate whether or not this item will be available to order online."
      );
    } else if (
      filledOne == false &&
      filledTwo == false &&
      filledThree == false &&
      filledFour == false &&
      filledFive == false &&
      filledSix == false &&
      filledSeven == false
    ) {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Add at least one size to the item.");
    } else if (filledOne == true && priceOne == "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter price for the " + sizeTextOne + " size");
    } else if (filledTwo == true && priceTwo == "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter price for the " + sizeTextTwo + " size");
    } else if (filledThree == true && priceThree == "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter price for the " + sizeTextThree + " size");
    } else if (filledFour == true && priceFour == "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter price for the " + sizeTextFour + " size");
    } else if (filledFive == true && priceFive == "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter price for the extra " + sizeTextFive + " size");
    } else if (filledSix == true && priceSix == "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter price for the " + sizeTextSix + " size");
    } else if (
      filledSeven == true &&
      (priceSeven == "" || volume == "" || other_sizes == "")
    ) {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter Unit, Volume and Price for the Other size");
    } else {
      setBtnColor("disabled-btn");
      setDisabled("disabled");
      setLoadSpinner(true);
      setSaveLabel(false);
      // connect to the api

      const baseURL = process.env.REACT_APP_URL;
      axios({
        method: "POST",
        url: baseURL,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then((response) => {
        if (response.data.status == "error") {
          setBgColor("red");
          setDisplayMessage(true);
          setErrorMsg(response.data.message);
        } else {
          // basic dashboard
          setBgColor("#66b266");
          setDisplayMessage(true);
          setErrorMsg(response.data.message);
          localStorage.setItem("foodid", response.data.foodid);
          localStorage.setItem("food_name", response.data.food_name);
          localStorage.setItem("dashboard", "no");
          if (localStorage.getItem("description") != null) {
            // alert(response.data.message);
            navigate("/dashboard");
          } else {
            navigate("/media");
          }
        }

        setBtnColor("action-btn");
        setDisabled("");
        setLoadSpinner(false);
        setSaveLabel(true);

        // console.log(response.data.name);
      });
    }
    setTimeout(() => {
      setDisplayMessage(false);
    }, 5000);
  }

  function sizeOne() {
    if (filledOne == true) {
      setBgOptions("size-options");
      setFilledOne(false);
      setEmptyOne(true);
      setTextOne(false);
      setOtherOptions(true);
      setLoadDivider(true);
      setPrice_one("");
      if (category == "drink") {
        setOtherType(true);
      }

      localStorage.removeItem("normal");
    } else {
      setBgOptions("size-options-active");
      setOtherOptions(false);
      setOtherType(false);
      setPrice_two("");
      setPrice_three("");
      setPrice_four("");
      setPrice_five("");
      setPrice_six("");
      setPrice_seven("");
      setOther_sizes("");
      setVolume("");
      localStorage.removeItem("small");
      localStorage.removeItem("medium");
      localStorage.removeItem("large");
      localStorage.removeItem("extra-large");
      localStorage.removeItem("bulk");

      setFilledOne(true);
      setEmptyOne(false);
      setTextOne(true);

      setFilledTwo(false);
      setEmptyTwo(true);
      setTextTwo(false);

      setFilledThree(false);
      setEmptyThree(true);
      setTextThree(false);

      setFilledFour(false);
      setEmptyFour(true);
      setTextFour(false);

      setFilledFive(false);
      setEmptyFive(true);
      setTextFive(false);

      setFilledSix(false);
      setEmptySix(true);
      setTextSix(false);

      setFilledSeven(false);
      setEmptySeven(true);
      setTextSeven(false);

      setLoadDivider(false);
      localStorage.setItem("normal", "normal");
    }
  }

  function sizeTwo() {
    if (filledTwo == true) {
      setBgOptions("size-options");
      setFilledTwo(false);
      setEmptyTwo(true);
      setTextTwo(false);
      setPrice_two("");
      localStorage.removeItem("small");
    } else {
      setBgOptions("size-options-active");
      setFilledTwo(true);
      setEmptyTwo(false);
      setTextTwo(true);

      localStorage.setItem("small", "small");
    }
  }

  function sizeThree() {
    if (filledThree == true) {
      setBgOptions("size-options");
      setFilledThree(false);
      setEmptyThree(true);
      setTextThree(false);
      setPrice_three("");
      localStorage.removeItem("medium");
    } else {
      setBgOptions("size-options-active");
      setFilledThree(true);
      setEmptyThree(false);
      setTextThree(true);
      localStorage.setItem("medium", "medium");
    }
  }

  function sizeFour() {
    if (filledFour == true) {
      setBgOptions("size-options");
      setFilledFour(false);
      setEmptyFour(true);
      setTextFour(false);
      setPrice_four("");
      localStorage.removeItem("large");
    } else {
      setBgOptions("size-options-active");
      setFilledFour(true);
      setEmptyFour(false);
      setTextFour(true);
      localStorage.setItem("large", "large");
    }
  }

  function sizeFive() {
    if (filledFive == true) {
      setBgOptions("size-options");
      setFilledFive(false);
      setEmptyFive(true);
      setTextFive(false);
      setPrice_five("");
      localStorage.removeItem("extra-large");
    } else {
      setBgOptions("size-options-active");
      setFilledFive(true);
      setEmptyFive(false);
      setTextFive(true);
      localStorage.setItem("extra-large", "extra-large");
    }
  }

  function sizeSix() {
    if (filledSix == true) {
      setBgOptions("size-options");
      setFilledSix(false);
      setEmptySix(true);
      setTextSix(false);
      setPrice_six("");
      localStorage.removeItem("bulk");
    } else {
      setBgOptions("size-options-active");
      setFilledSix(true);
      setEmptySix(false);
      setTextSix(true);
      localStorage.setItem("bulk", "bulk");
    }
  }

  function sizeSeven() {
    if (filledSeven == true) {
      setBgOptions("size-options");
      setFilledSeven(false);
      setEmptySeven(true);
      setTextSeven(false);
      setPrice_seven("");
      localStorage.removeItem("other");
    } else {
      setBgOptions("size-options-active");
      setFilledSeven(true);
      setEmptySeven(false);
      setTextSeven(true);
      localStorage.setItem("other", "other");
    }
  }

  // function attachMedia() {
  //   navigate("/media");
  // }

  function click_here() {
    localStorage.removeItem("description");
    localStorage.removeItem("foodid");
    localStorage.removeItem("item_name");
    navigate("/addstock");
  }

  function handleDrink(e) {
    if (
      e.value == "soft drink" ||
      e.value == "shot" ||
      e.value == "spring water" ||
      e.value == "still water" ||
      e.value == "sparkling water" ||
      e.value == "spirit" ||
      e.value == "cider" ||
      e.value == "beer" ||
      e.value == "tequila" ||
      e.value == "tequila shot" ||
      e.value == "bottle of whisky" ||
      e.value == "bottle of wine" ||
      e.value == "champagne"
    ) {
      setSizeTextTwo("1.5 ounces");
      setSizeTextThree("500ml");
      setSizeTextFour("750ml");
      setSizeTextFive("1ltr");
      setSizeTextSix("2ltr");
      setOtherType(true);
    } else {
      setOtherType(false);
      setSizeTextTwo("Small");
      setSizeTextThree("Medium");
      setSizeTextFour("Large");
      setSizeTextFive("Extra large");
      setSizeTextSix("Bulk");
    }
    setMeal(e.value);
  }

  return (
    <div className="body">
      <Authentication />
      <Container className="container-home">
        <Row>
          <Col lg={2} xs={2} md={3} className="p-0">
            <SideNav />
          </Col>
          <Col lg={10} xs={10} md={9} className={"middle-container"}>
            <Row className="h-100 align-items-center justify-content-center">
              <form onSubmit={submit}>
                <Row className="h-100 align-items-center justify-content-center">
                  <Col lg={9}>
                    <h6>
                      <span className="color-green">
                        {localStorage.getItem("success-message") == null ? (
                          ""
                        ) : (
                          <span>
                            <IoIosCheckmark size={30} />{" "}
                            {localStorage.getItem("success-message")}
                          </span>
                        )}
                      </span>
                    </h6>
                    {localStorage.getItem("description") != null ? (
                      <label className="mt-3 mb-3">
                        Unless you are updating an item, click{" "}
                        <label
                          className="color-blue text-underline-on-hover"
                          onClick={click_here}
                        >
                          here
                        </label>{" "}
                        before adding a new one.
                      </label>
                    ) : (
                      ""
                    )}
                    <Stack>
                      <div className="box-container mt-1 mb-3">
                        <Row>
                          <Col lg={6} sm={6} xs={6} md={6}>
                            <label className="text-medium">Add item</label>
                          </Col>
                          <Col
                            lg={6}
                            sm={6}
                            xs={6}
                            md={6}
                            className={"d-none d-md-block"}
                          >
                            <label className="text-medium w-100">
                              {localStorage.getItem("description") != null ? (
                                <label className="color-red">
                                  Add size to the item{" "}
                                </label>
                              ) : (
                                <label>Add size to the item</label>
                              )}

                              {/* <label
                                className="text-blue float-right text-normal text-hover-underline"
                                onClick={() => attachMedia(foodid)}
                              >
                                Attach media
                              </label> */}
                            </label>
                          </Col>
                          <Col lg={12}>
                            <div className="h-divider" />
                          </Col>

                          <Col lg={6} sm={12} md={6}>
                            <Col lg={12} className="mb-3">
                              <input
                                type="text"
                                name="item"
                                placeholder="Item name"
                                onChange={(e) => setItem(e.target.value)}
                                value={item}
                              />
                            </Col>
                            <Col lg={12}>
                              <Select
                                value={data.find(
                                  (obj) => obj.value === category
                                )}
                                placeholder="Choose category"
                                options={categories}
                                onChange={handleCategory}
                                width={"100%"}
                                className="text-left mt-2 mb-3 select"
                              />
                            </Col>
                            {drinkType && (
                              <Col lg={12}>
                                <Select
                                  value={data.find((obj) => obj.value === meal)}
                                  placeholder="Choose drink type"
                                  options={drinks}
                                  onChange={handleDrink}
                                  width={"100%"}
                                  className="text-left mt-2 mb-3 select"
                                />
                              </Col>
                            )}
                            {foodFields && (
                              <Col lg={12}>
                                <Select
                                  value={data.find((obj) => obj.value === meal)}
                                  placeholder="Choose food type..."
                                  options={meals}
                                  onChange={handleMeals}
                                  width={"100%"}
                                  className="text-left mt-2 mb-3 select"
                                />
                              </Col>
                            )}
                            {foodFields && (
                              <Col lg={12}>
                                <Select
                                  value={data.find(
                                    (obj) => obj.value === dayTime
                                  )}
                                  placeholder="Choose food interval"
                                  options={day_time}
                                  onChange={handleDay_time}
                                  width={"100%"}
                                  className="text-left mt-2 mb-3 select"
                                />
                              </Col>
                            )}
                            <Col lg={12} className="mb-3">
                              <span className="font-medium">
                                Item description (optional but recommended)
                              </span>
                              <textarea
                                placeholder={
                                  "e.g. mouth watering burger with double patty, two slices of cheese and extra sauce, etc... Make people want it."
                                }
                                className="mt-2"
                                onChange={(e) => setDes(e.target.value)}
                                value={description}
                                style={{ height: "8em" }}
                              />
                            </Col>
                            <Col lg={12}>
                              <Select
                                value={data.find(
                                  (obj) => obj.value === online_order
                                )}
                                placeholder="Will this item be available to order online?"
                                options={orderOnline}
                                onChange={handleOnlineOrder}
                                width={"100%"}
                                className="text-left mt-2 mb-0 select"
                              />
                              <label className="font-size-12 color-grey mb-3">
                                You can change this later if you decide
                                otherwise.
                              </label>
                            </Col>
                          </Col>

                          <Col
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            className="mb-2 mt-3 d-block d-md-none"
                          >
                            <label className="text-medium w-100">
                              {localStorage.getItem("description") != null ? (
                                <label className="color-red">
                                  Add size to the item
                                </label>
                              ) : (
                                <label>Add size to the item</label>
                              )}
                              <NavLink to="/media">
                                {" "}
                                <label className="text-blue float-right text-normal text-hover-underline">
                                  Upload
                                </label>
                              </NavLink>
                            </label>
                          </Col>

                          <Col lg={6} sm={12} md={6}>
                            <Col lg={12} className="mb-3">
                              {firstOption && (
                                <div className={bgOptions + " w-100"}>
                                  <Row>
                                    <Col
                                      lg={2}
                                      md={2}
                                      sm={2}
                                      xs={2}
                                      className="text-center"
                                    >
                                      {filledOne && (
                                        <IoIosCheckboxOutline
                                          size={25}
                                          className={"color-green"}
                                          onClick={sizeOne}
                                        />
                                      )}
                                      {emptyOne && (
                                        <IoMdSquareOutline
                                          size={25}
                                          className="color-silver"
                                          onClick={sizeOne}
                                        />
                                      )}
                                    </Col>
                                    <Col lg={10} md={10} sm={10} xs={10}>
                                      {sizeTextOne}
                                      <br />
                                      {textOne && (
                                        <input
                                          type="number"
                                          step="any"
                                          min="0"
                                          placeholder={"Set price"}
                                          onChange={(e) =>
                                            setPrice_one(e.target.value)
                                          }
                                          value={priceOne}
                                          className="mt-3 price-fields"
                                        />
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              )}
                              {loadDivider && (
                                <div className="h-divider mb-3" />
                              )}
                              {otherOptions && (
                                <div className="size-options w-100">
                                  <Row>
                                    <Col
                                      lg={2}
                                      md={2}
                                      sm={2}
                                      xs={2}
                                      className="text-center"
                                    >
                                      {filledTwo && (
                                        <IoIosCheckboxOutline
                                          size={25}
                                          className={"color-green"}
                                          onClick={sizeTwo}
                                        />
                                      )}
                                      {emptyTwo && (
                                        <IoMdSquareOutline
                                          size={25}
                                          className="color-silver"
                                          onClick={sizeTwo}
                                        />
                                      )}
                                    </Col>
                                    <Col lg={10} md={10} sm={10} xs={10}>
                                      {sizeTextTwo}
                                      <br />
                                      {textTwo && (
                                        <input
                                          type="number"
                                          step="any"
                                          min="0"
                                          placeholder={"Set price"}
                                          onChange={(e) =>
                                            setPrice_two(e.target.value)
                                          }
                                          value={priceTwo}
                                          className="mt-3 price-fields"
                                        />
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              )}

                              {otherOptions && (
                                <div className="size-options w-100">
                                  <Row>
                                    <Col
                                      lg={2}
                                      md={2}
                                      sm={2}
                                      xs={2}
                                      className="text-center"
                                    >
                                      {filledThree && (
                                        <IoIosCheckboxOutline
                                          size={25}
                                          className={"color-green"}
                                          onClick={sizeThree}
                                        />
                                      )}
                                      {emptyThree && (
                                        <IoMdSquareOutline
                                          size={25}
                                          className="color-silver"
                                          onClick={sizeThree}
                                        />
                                      )}
                                    </Col>
                                    <Col lg={10} md={10} sm={10} xs={10}>
                                      {sizeTextThree}
                                      <br />
                                      {textThree && (
                                        <input
                                          type="number"
                                          step="any"
                                          min="0"
                                          placeholder={"Set price"}
                                          onChange={(e) =>
                                            setPrice_three(e.target.value)
                                          }
                                          value={priceThree}
                                          className="mt-3 price-fields"
                                        />
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              )}

                              {otherOptions && (
                                <div className="size-options w-100">
                                  <Row>
                                    <Col
                                      lg={2}
                                      md={2}
                                      sm={2}
                                      xs={2}
                                      className="text-center"
                                    >
                                      {filledFour && (
                                        <IoIosCheckboxOutline
                                          size={25}
                                          className={"color-green"}
                                          onClick={sizeFour}
                                        />
                                      )}
                                      {emptyFour && (
                                        <IoMdSquareOutline
                                          size={25}
                                          className="color-silver"
                                          onClick={sizeFour}
                                        />
                                      )}
                                    </Col>
                                    <Col lg={10} md={10} sm={10} xs={10}>
                                      {sizeTextFour}
                                      <br />
                                      {textFour && (
                                        <input
                                          type="number"
                                          step="any"
                                          min="0"
                                          placeholder={"Set price"}
                                          onChange={(e) =>
                                            setPrice_four(e.target.value)
                                          }
                                          value={priceFour}
                                          className="mt-3 price-fields"
                                        />
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              )}
                              {otherOptions && (
                                <div className="size-options w-100">
                                  <Row>
                                    <Col
                                      lg={2}
                                      md={2}
                                      sm={2}
                                      xs={2}
                                      className="text-center"
                                    >
                                      {filledFive && (
                                        <IoIosCheckboxOutline
                                          size={25}
                                          className={"color-green"}
                                          onClick={sizeFive}
                                        />
                                      )}
                                      {emptyFive && (
                                        <IoMdSquareOutline
                                          size={25}
                                          className="color-silver"
                                          onClick={sizeFive}
                                        />
                                      )}
                                    </Col>
                                    <Col lg={10} md={10} sm={10} xs={10}>
                                      {sizeTextFive}
                                      <br />
                                      {textFive && (
                                        <input
                                          type="number"
                                          step="any"
                                          min="0"
                                          placeholder={"Set price"}
                                          onChange={(e) =>
                                            setPrice_five(e.target.value)
                                          }
                                          value={priceFive}
                                          className="mt-3 price-fields"
                                        />
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              )}

                              {otherOptions && (
                                <div className="size-options w-100">
                                  <Row>
                                    <Col
                                      lg={2}
                                      md={2}
                                      sm={2}
                                      xs={2}
                                      className="text-center"
                                    >
                                      {filledSix && (
                                        <IoIosCheckboxOutline
                                          size={25}
                                          className={"color-green"}
                                          onClick={sizeSix}
                                        />
                                      )}
                                      {emptySix && (
                                        <IoMdSquareOutline
                                          size={25}
                                          className="color-silver"
                                          onClick={sizeSix}
                                        />
                                      )}
                                    </Col>
                                    <Col lg={10} md={10} sm={10} xs={10}>
                                      {sizeTextSix}
                                      <br />
                                      {textSix && (
                                        <input
                                          type="number"
                                          step="any"
                                          min="0"
                                          placeholder={"Set price"}
                                          onChange={(e) =>
                                            setPrice_six(e.target.value)
                                          }
                                          value={priceSix}
                                          className="mt-3 price-fields"
                                        />
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              )}

                              {otherType && (
                                <div className="size-options w-100">
                                  <Row>
                                    <Col
                                      lg={2}
                                      md={2}
                                      sm={2}
                                      xs={2}
                                      className="text-center"
                                    >
                                      {filledSeven && (
                                        <IoIosCheckboxOutline
                                          size={25}
                                          className={"color-green"}
                                          onClick={sizeSeven}
                                        />
                                      )}
                                      {emptySeven && (
                                        <IoMdSquareOutline
                                          size={25}
                                          className="color-silver"
                                          onClick={sizeSeven}
                                        />
                                      )}
                                    </Col>
                                    <Col lg={10} md={10} sm={10} xs={10}>
                                      Other
                                      <br />
                                      {textSeven && (
                                        <Row>
                                          <Col lg={6}>
                                            <Select
                                              value={data.find(
                                                (obj) =>
                                                  obj.value === other_sizes
                                              )}
                                              placeholder="Select unit"
                                              options={otherSizes}
                                              onChange={handleOtherSize}
                                              width={"100%"}
                                              className="text-left mt-2 mb-0 select"
                                            />
                                          </Col>
                                          <Col lg={6}>
                                            <input
                                              type="text"
                                              placeholder={"Enter volume"}
                                              onChange={(e) =>
                                                setVolume(e.target.value)
                                              }
                                              value={volume}
                                              className="mt-2 price-fields-other"
                                            />
                                          </Col>
                                          <Col lg={12}>
                                            <input
                                              type="number"
                                              step="any"
                                              min="0"
                                              placeholder={"Set price"}
                                              onChange={(e) =>
                                                setPrice_seven(e.target.value)
                                              }
                                              value={priceSeven}
                                              className="mt-3 price-fields-other"
                                            />
                                          </Col>
                                        </Row>
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              )}
                            </Col>
                          </Col>
                          <Col lg={12} sm={6} className="">
                            <button
                              type="submit"
                              className={btnColor}
                              disabled={disabled}
                            >
                              {saveLabel && <span>Add item</span>}
                              {loadSpinner && <BeatLoader size={10} />}
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </Stack>
                  </Col>
                </Row>
              </form>
            </Row>
          </Col>
        </Row>
      </Container>
      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}

localStorage.removeItem("success-message");

if (localStorage.getItem("success-message") == null) {
  localStorage.removeItem("foodid");
}
