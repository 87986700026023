import React, { useState, useEffect } from "react";
import Authentication from "./navigation/authentication";
import { Col, Container, Row, Stack, Modal, Button } from "react-bootstrap";
import SideNav from "./navigation/SideNav";
import BeatLoader from "react-spinners/BeatLoader";
import axios from "axios";

const baseURL = process.env.REACT_APP_URL;
const root_url = process.env.REACT_APP_URL_RESOURCE;
var OSName = "Unknown OS";
if (navigator.userAgent.indexOf("Win") != -1) OSName = "Windows";
if (navigator.userAgent.indexOf("Mac") != -1) OSName = "MacOS";
if (navigator.userAgent.indexOf("X11") != -1) OSName = "UNIX";
if (navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";
export default function Passwords() {
  const [surname, setSurname] = useState("");
  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [btnColor, setBtnColor] = useState("action-btn");
  const [disabled, setDisabled] = useState("");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [current_password, setCurrent_Password] = useState("");
  const [new_password, setNew_Password] = useState("");
  const [confirm_password, setConfirm_Password] = useState("");
  const [count, setCount] = useState(0);

  useEffect(() => {
    document.title = "Obarak / Password settings";
  });

  function submit(e) {
    e.preventDefault();
    const accountid = localStorage.getItem("accountid");
    // alert(confirm_password);
    const formData = new FormData();
    formData.append("current_password", current_password);
    formData.append("new_password", new_password);
    formData.append("confirm_password", confirm_password);
    formData.append("accountid", accountid);
    formData.append("OSName", OSName);
    formData.append("alias", "PROFESSIONAL");
    formData.append("query", "CHANGE_PASSWORD");

    if (current_password === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter your current password");
    } else if (new_password === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter new password");
    } else if (new_password.length < 5) {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Password should at least have 5 characters");
    } else if (confirm_password !== new_password) {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Both New and Confirm passwords must match");
    } else {
      setBtnColor("disabled-btn");
      setDisabled("disabled");
      setLoadSpinner(true);
      setSaveLabel(false);
      // connect to the api

      axios({
        method: "POST",
        url: baseURL,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then((response) => {
        setBtnColor("action-btn");
        setDisabled("");
        setLoadSpinner(false);
        setSaveLabel(true);

        if (response.data.status == "error") {
          setBgColor("red");
          setDisplayMessage(true);
          setErrorMsg(response.data.message);
        } else {
          setCount(count + 1);
          setBgColor("#66b266");
          setDisplayMessage(true);
          setErrorMsg(response.data.message);
        }
      });
    }
    setTimeout(() => {
      setDisplayMessage(false);
    }, 5000);
  }

  return (
    <div className="body">
      <Authentication />
      <Container className="container-home">
        <Row>
          <Col lg={2} xs={2} md={3} className="p-0">
            <SideNav />
          </Col>
          <Col lg={10} xs={10} md={9}>
            <Row className="h-100 align-items-center justify-content-center">
              <form onSubmit={submit}>
                <Row className="h-100 align-items-center justify-content-center">
                  <Col lg={9}>
                    <Stack>
                      <div className="box-container">
                        <Row>
                          <Col lg={12}>
                            <label className="text-medium">
                              Change password
                            </label>
                          </Col>

                          <Col lg={12}>
                            <div className="h-divider" />
                          </Col>
                          <Col lg={6} className="mb-3">
                            <input
                              type="password"
                              name="c_password"
                              placeholder="Current password"
                              onChange={(e) =>
                                setCurrent_Password(e.target.value)
                              }
                              value={current_password}
                            />
                          </Col>
                          <Col lg={6} className="mb-3">
                            <input
                              type="password"
                              name="new_password"
                              placeholder="Enter new password"
                              onChange={(e) => setNew_Password(e.target.value)}
                              value={new_password}
                            />
                          </Col>

                          <Col lg={12} className="mb-3">
                            <input
                              type="password"
                              name="confirm_password"
                              placeholder="Confirm password"
                              onChange={(e) =>
                                setConfirm_Password(e.target.value)
                              }
                              value={confirm_password}
                            />
                          </Col>

                          <Col lg={12} className="">
                            <button
                              type="submit"
                              className={btnColor}
                              disabled={disabled}
                            >
                              {saveLabel && <span>Save</span>}
                              {loadSpinner && <BeatLoader size={10} />}
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </Stack>
                  </Col>
                </Row>
              </form>
            </Row>
          </Col>
        </Row>
      </Container>
      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}
