import React, { useState, useEffect, useHistory } from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import SideNav from "./navigation/SideNav";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Select from "react-select";
import BeatLoader from "react-spinners/BeatLoader";
import validator from "validator";
import MultipleValueTextInput from "react-multivalue-text-input";
import { IoIosAttach, IoMdImage, IoIosDocument } from "react-icons/io";
import {
  BsFileEarmarkPdf,
  BsFillFileEarmarkSpreadsheetFill,
  BsFillFileEarmarkFontFill,
} from "react-icons/bs";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
const baseURL = process.env.REACT_APP_URL;
const root_url = process.env.REACT_APP_URL_RESOURCE;
export default function Analytics() {
  const navigate = useNavigate();
  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [btnColor, setBtnColor] = useState("action-btn");
  const [disabled, setDisabled] = useState("");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [displayImge, setDisplayImge] = useState([]);
  const [showImage, setshowImage] = useState(false);
  const [allimgs, setAllImgs] = useState("");
  const [count, setCount] = useState(0);
  useEffect(() => {
    document.title = "Obarak / Analtyics";
  });

  const data = [
    {
      name: "Jan",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Feb",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Mar",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Apr",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "May",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Jun",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Jul",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "Aug",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Sep",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Oct",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Nov",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Dec",
      uv: 490,
      pv: 9900,
      amt: 2100,
    },
  ];

  return (
    <div className="body">
      <Container className="container-home">
        <Row>
          <Col lg={2} xs={2} md={3} className="p-0">
            <SideNav />
          </Col>
          <Col lg={10} xs={10} md={9}>
            <Row className="h-100 align-items-center justify-content-center">
              <Row className="h-100 align-items-center justify-content-center">
                <Col lg={12}>
                  <Stack>
                    <div className="box-container">
                      <Row>
                        <Col lg={6}>
                          <ResponsiveContainer width="100%" height={300}>
                            <AreaChart
                              data={data}
                              margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 0,
                              }}
                            >
                              <defs>
                                <linearGradient
                                  id="colorUv"
                                  x1="0"
                                  y1="0"
                                  x2="0"
                                  y2="1"
                                >
                                  <stop
                                    offset="5%"
                                    stopColor="#8884d8"
                                    stopOpacity={0.8}
                                  />
                                  <stop
                                    offset="95%"
                                    stopColor="#8884d8"
                                    stopOpacity={0}
                                  />
                                </linearGradient>
                                <linearGradient
                                  id="colorPv"
                                  x1="0"
                                  y1="0"
                                  x2="0"
                                  y2="1"
                                >
                                  <stop
                                    offset="5%"
                                    stopColor="#82ca9d"
                                    stopOpacity={0.8}
                                  />
                                  <stop
                                    offset="95%"
                                    stopColor="#82ca9d"
                                    stopOpacity={0}
                                  />
                                </linearGradient>
                              </defs>
                              <XAxis dataKey="name" />
                              <YAxis />
                              <CartesianGrid strokeDasharray="3 3" />
                              <Tooltip />
                              <Area
                                type="monotone"
                                dataKey="uv"
                                stroke="#8884d8"
                                fillOpacity={1}
                                fill="url(#colorUv)"
                              />
                              <Area
                                type="monotone"
                                dataKey="pv"
                                stroke="#82ca9d"
                                fillOpacity={1}
                                fill="url(#colorPv)"
                              />
                            </AreaChart>
                          </ResponsiveContainer>
                        </Col>
                        <Col lg={6}>
                          <ResponsiveContainer width="100%" height={300}>
                            <AreaChart
                              data={data}
                              margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 0,
                              }}
                            >
                              <defs>
                                <linearGradient
                                  id="colorUv"
                                  x1="0"
                                  y1="0"
                                  x2="0"
                                  y2="1"
                                >
                                  <stop
                                    offset="5%"
                                    stopColor="#8884d8"
                                    stopOpacity={0.8}
                                  />
                                  <stop
                                    offset="95%"
                                    stopColor="#8884d8"
                                    stopOpacity={0}
                                  />
                                </linearGradient>
                                <linearGradient
                                  id="colorPv"
                                  x1="0"
                                  y1="0"
                                  x2="0"
                                  y2="1"
                                >
                                  <stop
                                    offset="5%"
                                    stopColor="#82ca9d"
                                    stopOpacity={0.8}
                                  />
                                  <stop
                                    offset="95%"
                                    stopColor="#82ca9d"
                                    stopOpacity={0}
                                  />
                                </linearGradient>
                              </defs>
                              <XAxis dataKey="name" />
                              <YAxis />
                              <CartesianGrid strokeDasharray="3 3" />
                              <Tooltip />
                              <Area
                                type="monotone"
                                dataKey="uv"
                                stroke="#8884d8"
                                fillOpacity={1}
                                fill="url(#colorUv)"
                              />
                              <Area
                                type="monotone"
                                dataKey="pv"
                                stroke="#82ca9d"
                                fillOpacity={1}
                                fill="url(#colorPv)"
                              />
                            </AreaChart>
                          </ResponsiveContainer>
                        </Col>
                      </Row>
                    </div>
                  </Stack>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </Container>
      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}
