import React, { useState, useEffect } from "react";
import { Col, Container, Row, Stack, Modal, Button } from "react-bootstrap";
import Nav from "./navigation/Nav";
import { Link, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import axios from "axios";
export default function Retrieve_password() {
  const navigate = useNavigate();
  const [category, setCategory] = useState("");
  const [revenues, setRevenue] = useState("");
  const [booking, setBooking] = useState("");
  const [password, setPassword] = useState("");
  const [company_email, setCompany_email] = useState("");
  const [url, setUrl] = useState("");
  const [registration_number, setRegistration_number] = useState(null);
  const [comments, setComments] = useState("");

  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [btnColor, setBtnColor] = useState("login-btn");
  const [disabled, setDisabled] = useState("");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [location, setLocation] = useState("");

  useEffect(() => {
    document.title = "Obarak / Retrieve password";
  });

  const baseURL = process.env.REACT_APP_URL;

  function submit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", company_email);
    formData.append("query", "RETRIEVE_PASSWORD");

    if (company_email === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter email address");
    } else {
      setBtnColor("disabled-btn");
      setDisabled("disabled");
      setLoadSpinner(true);
      setSaveLabel(false);
      // connect to the api

      axios({
        method: "POST",
        url: baseURL,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then((res) => {
        if (res.data.status == "ok") {
          navigate("/perfect", {
            state: { message: res.data.message, token: "reset" },
          });
          // setBgColor("#66b266");
          // setDisplayMessage(true);
          // setErrorMsg(res.data.message);
        } else {
          setBgColor("#ff1919");
          setDisplayMessage(true);
          setErrorMsg(res.data.message);
        }

        setBtnColor("login-btn");
        setDisabled("");
        setLoadSpinner(false);
        setSaveLabel(true);
      });
    }
    setTimeout(() => {
      setDisplayMessage(false);
    }, 5000);
  }

  return (
    <div className="package-body height-100hv">
      <Container className="container-home height-100hv">
        <Row className="height-100hv justify-content-center">
          <Col lg={12} xs={12} md={12} className="p-0">
            <Nav />
          </Col>
          <Col lg={6} xs={12} md={12}>
            <Row className="align-items-center justify-content-center">
              <form onSubmit={submit}>
                <Row className="h-100 align-items-center justify-content-center">
                  <Col lg={7}>
                    <Stack>
                      <div className="text-center w-100 mb-3">
                        <h3>Retrieve password</h3>
                      </div>
                      <div className="box-container mb-5">
                        <Row>
                          <Col lg={12} className="text-center">
                            <label className="text-medium font-size-17">
                              Enter email
                            </label>
                          </Col>

                          <Col lg={12} className="mb-3 mt-3">
                            <input
                              type="email"
                              placeholder="Email address"
                              onChange={(e) => setCompany_email(e.target.value)}
                              value={company_email}
                            />
                          </Col>

                          <Col lg={12} className="mb-3">
                            <button
                              type="submit"
                              className={
                                btnColor + " w-100 text-bold font-size-17"
                              }
                              disabled={disabled}
                            >
                              {saveLabel && <span>Continue</span>}
                              {loadSpinner && <BeatLoader size={10} />}
                            </button>
                          </Col>

                          <Col lg={12} className="mb-3 text-center">
                            Already a host?{" "}
                            <Link to="/login" className="anchor">
                              Login
                            </Link>
                            <br />
                            Not a host yet?{" "}
                            <Link to="/packages" className="anchor">
                              Become a one
                            </Link>
                          </Col>
                        </Row>
                      </div>
                    </Stack>
                  </Col>
                </Row>
              </form>
            </Row>
          </Col>
        </Row>
      </Container>
      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}
