import React, { useState, useEffect } from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import Nav from "./navigation/Nav";
import { Link, useLocation } from "react-router-dom";
export default function Index() {
  const location = useLocation();
  useEffect(() => {
    document.title = "Obarak / Perferct!";
  });

  const message = location.state.message;
  const token = location.state.token;

  return (
    <div className="package-body height-100hv">
      <Container className="container-home height-100hv">
        <Row className="height-100hv justify-content-center">
          <Col lg={12} xs={12} md={12} className="p-0">
            <Nav />
          </Col>
          <Col lg={7} xs={12} md={12}>
            <Row className="align-items-center justify-content-center">
              <Row className="h-100 align-items-center justify-content-center">
                <Col lg={7}>
                  <Stack>
                    <div className="text-center w-100 mb-3">
                      <h4 className="color-green">Perfect! All is in order</h4>
                    </div>
                    <div className="box-container mb-5">
                      <Row>
                        <Col
                          lg={12}
                          className={
                            token == "reset"
                              ? "text-center"
                              : "text-center mb-3"
                          }
                        >
                          <label className="font-normal font-size-17">
                            {message}
                          </label>
                        </Col>

                        <Col
                          lg={12}
                          className={
                            token == "reset" ? "d-none" : "mb-0 text-center"
                          }
                        >
                          Go ahead and{" "}
                          <Link to="/login" className="anchor">
                            login
                          </Link>
                        </Col>
                      </Row>
                    </div>
                  </Stack>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
