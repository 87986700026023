import React, { useState, useEffect } from "react";
import Auth from "./navigation/auth";
import { Col, Container, Row, Stack, Modal, Button } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import HomeNav from "./navigation/HomeNav";
import BeatLoader from "react-spinners/BeatLoader";
import validator from "validator";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { IoIosSwap } from "react-icons/io";
import {
  IoPersonAddOutline,
  IoPeopleOutline,
  IoAddOutline,
  IoFlashOutline,
} from "react-icons/io5";
import { BsTrash, BsCheck2 } from "react-icons/bs";
const baseURL = process.env.REACT_APP_URL;
const root_url = process.env.REACT_APP_URL_RESOURCE;

const random_number = Math.floor(Date.now() * Math.random());
const avn_ = random_number;
const avc_ = uuid().substring(0, 3).toUpperCase();

export default function Virtual() {
  const navigate = useNavigate();
  const [businessname, setBusinessname] = useState(
    localStorage.getItem("company_name")
  );
  const [show, setShow] = useState(false);
  const [phone, setPhone] = useState(null);
  const [current_phone, setCurrent_phone] = useState(null);
  const [location, setLocation] = useState("");
  const [current_location, setcurrent_location] = useState("");
  const [email, setEmail] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [btnColor, setBtnColor] = useState("action-btn");
  const [disabled, setDisabled] = useState("");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [count, setCount] = useState(0);
  const [virtual_present, setVirtual_present] = useState("no");
  const [balance, setBalance] = useState(0);
  const [avn, setAvn] = useState("");
  const [avc, setAvc] = useState("");
  const [contentLoading, setContentLoading] = useState(true);
  const [virtualContent, setVirtualContent] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalHeader, setModalHeader] = useState("");
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [recent, setRecent] = useState([]);
  const [results, setResults] = useState([]);
  const [search_value, setSearch_value] = useState("");
  const [search_field, setSearch_field] = useState(false);
  const [amount, setAmount] = useState(null);
  const [beneficiaryid, setBeneficiaryid] = useState(null);
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const [payField, setPayField] = useState(false);
  const [after, setAfter] = useState("--");
  const [amountColor, setAmountColor] = useState();
  const [errorAmount, setErrorAmount] = useState(false);
  const [displayPayBtn, setDisplayPayBtn] = useState();
  const [validateAmount, setValidateAmount] = useState("");

  const customerid = localStorage.getItem("customerid");

  function virtualPresent() {
    axios
      .get(baseURL + "?query=VIRTUAL_DETAILS&customerid=" + customerid)
      .then((response) => {
        if (response.data.status == "ok") {
          setVirtual_present("yes");
          setAvn(response.data.avn);
          setAvc(response.data.avc);
          setVirtualContent(true);
          setContentLoading(false);
          setBtnColor("karamale-login-btn");
        } else {
          setVirtual_present("no");
          setAvn(avn_);
          setAvc(avc_);
          setVirtualContent(true);
          setContentLoading(false);
        }
      });
  }

  function getBalance() {
    axios
      .get(baseURL + "?query=GET_VIRTUAL_BALANCE&customerid=" + customerid)
      .then((response) => {
        if (response.data.status == "ok") {
          setBalance(response.data.balance);
        } else {
          setBalance(0);
        }
      });
  }

  // check for existing beneficiaries

  function get_beneficiaries() {
    axios
      .get(baseURL + "?query=BENEFICIARIES&customerid=" + customerid)
      .then((res) => {
        setBeneficiaries(res.data);
      });
  }

  function get_recent() {
    axios
      .get(baseURL + "?query=RECENT_PAYMENTS&customerid=" + customerid)
      .then((res) => {
        setRecent(res.data);
      });
  }
  useEffect(() => {
    document.title = "Obarak / Obarak pay";
    getBalance();
    virtualPresent();
    get_beneficiaries();
    get_recent();
  }, [count]);

  function activate() {
    if (virtual_present == "yes") {
      alert("yes");
    } else {
      setCount(count + 1);
      setBtnColor("disabled-btn");
      setDisabled("disabled");
      setLoadSpinner(true);
      setSaveLabel(false);

      axios
        .get(
          baseURL +
            "?query=ACTIVATE&customerid=" +
            customerid +
            "&avn=" +
            avn +
            "&avc=" +
            avc
        )
        .then((response) => {
          if (response.data.status == "ok") {
            setBgColor("#66b266");
            setDisplayMessage(true);
            setErrorMsg(response.data.message);
          } else {
            setBgColor("red");
            setDisplayMessage(true);
            setErrorMsg(response.data.message);
          }

          setDisabled("");
          setLoadSpinner(false);
          setSaveLabel(true);
          navigate("/av");
        });
    }
  }

  function close() {
    setShow(false);
  }
  function pay_ben(beneficiaryid) {
    if (isNaN(amount)) {
      setBgColor("red");
      setDisplayMessage(true);
      setErrorMsg("Enter a numeric value");
    } else if (amount < 10) {
      setBgColor("red");
      setDisplayMessage(true);
      setErrorMsg("Enter at least R10 or more to complete the payment.");
    } else {
      setBtnColor("disabled-btn");
      setDisabled("disabled");
      setLoadSpinner(true);
      setSaveLabel(false);
      // console.log(amount + "-" + beneficiaryid + "-" + customerid);
      axios
        .get(
          baseURL +
            "?query=PAY_BENEFICIARY&beneficiary_id=" +
            beneficiaryid +
            "&customerid=" +
            customerid +
            "&amount=" +
            amount
        )
        .then((res) => {
          setBgColor("#66b266");
          setDisplayMessage(true);
          setErrorMsg(res.data.message);
          setShow(false);
          setCount(count + 1);
          setBtnColor("disabled-btn");
          setDisabled("");
          setLoadSpinner(false);
          setSaveLabel(true);
        });

      setTimeout(() => {
        setDisplayMessage(false);
      }, 5000);
    }
  }

  function list_recent() {
    return (
      <>
        {recent.length <= 0 ? (
          <label className="font-fize-17 color-grey font-medium">
            No recent activities{" "}
            <span
              className="color-blue font-size-12 font-normal cursor"
              onClick={() => transfer("beneficiaries", 0, "", "")}
            >
              <IoPeopleOutline size={15} /> Beneficiaries
            </span>
          </label>
        ) : (
          recent.map((data, index) => {
            return (
              <>
                <Row key={index} className="mb-3">
                  <Col lg={6} sm={6} md={6}>
                    {data.beneficiary}
                  </Col>
                  <Col lg={3} sm={3} md={3}>
                    <label className="color-grey w-100 text-left font-size-12">
                      <span className="font-medium font-size-12">Recent:</span>{" "}
                      R{data.amount}
                    </label>
                  </Col>
                  <Col lg={3} sm={3} md={3} className="text-right">
                    <label
                      className="add-beneficiary color-blue font-medium text-center"
                      onClick={() =>
                        transfer(
                          "Prepare_funds",
                          data.beneficiary_id,
                          "",
                          data.beneficiary
                        )
                      }
                    >
                      <IoIosSwap size={15} /> Next
                    </label>
                  </Col>
                </Row>
              </>
            );
          })
        )}
      </>
    );
  }

  function list_beneficiaries() {
    return (
      <>
        {beneficiaries.length <= 0 ? (
          <label className="font-fize-17 color-grey font-medium">
            You have no beneficiaries.{" "}
            <span
              className="color-blue font-size-12 font-normal cursor"
              onClick={() => transfer("Add beneficiary", 0, "", "")}
            >
              <IoAddOutline size={15} /> Add
            </span>
          </label>
        ) : (
          beneficiaries.map((data, index) => {
            return (
              <>
                <Row key={index} className="mb-3">
                  <Col lg={6}>{data.beneficiary}</Col>
                  <Col lg={6} className="text-right">
                    <label
                      className="add-beneficiary color-blue font-medium text-center font-size-12"
                      onClick={() =>
                        transfer(
                          "Prepare_funds",
                          data.beneficiary_id,
                          "",
                          data.beneficiary
                        )
                      }
                    >
                      <IoIosSwap size={12} /> Pay
                    </label>
                    <label
                      className="delete-beneficiary color-white font-medium text-center font-size-12"
                      onClick={() =>
                        add_ben(
                          data.beneficiary_id,
                          customerid,
                          data.beneficiary,
                          "delete"
                        )
                      }
                    >
                      <BsTrash size={12} /> Remove
                    </label>
                  </Col>
                </Row>
              </>
            );
          })
        )}
      </>
    );
  }

  function transfer(value, beneficiary_id, email, name) {
    if (value == "transfer") {
      setSearch_field(false);
      setPayField(false);
      setModalContent(<>{list_recent()}</>);
      setModalHeader("Recent payments");
    } else if (value == "beneficiaries") {
      setSearch_field(false);
      setPayField(false);

      setModalContent(<>{list_beneficiaries()}</>);
      setModalHeader("Beneficiaries");
    } else if (value == "Prepare_funds") {
      setSearch_field(false);
      setPayField(true);
      setBeneficiaryName(name);
      setBeneficiaryid(beneficiary_id);
      setModalContent();
      setModalHeader("Make payment");
    } else if (value == "add_btn_click") {
      setSearch_field(false);
      setPayField(false);
      setModalContent(
        <>
          <Row>
            <Col lg={12}>
              <label>
                {name}
                <br />
                <span className="color-grey">{email}</span>
              </label>
            </Col>
            <Col lg={12} className="text-right">
              <button
                type="button"
                className="add-beneficiary color-blue font-medium text-center"
                onClick={() => add_ben(beneficiary_id, customerid, name, "add")}
                disabled={disabled}
              >
                {saveLabel && (
                  <span>
                    <IoAddOutline size={15} /> Add
                  </span>
                )}
                {loadSpinner && <BeatLoader size={5} color="#fff" />}
              </button>
            </Col>
          </Row>
        </>
      );
      setModalHeader("Confirm details");
    } else {
      setSearch_value("");
      setModalContent();
      setSearch_field(true);
      setPayField(false);
      setModalHeader("Add beneficiary");
    }
    setShow(true);
  }

  function search_string(e) {
    setSearch_value(e.target.value);
    const string = e.target.value;
    console.log(string);
    const search_string = string.replace(" ", "+");
    axios
      .get(
        baseURL +
          "?query=SEARCH_POTENTIAL_BENEFICIARIES&customerid=" +
          customerid +
          "&search=" +
          search_string
      )
      .then((response) => {
        setResults(response.data);
        console.log(response.data);
      });
  }

  function add_ben(beneficiary_id, customerid, beneficiaryName, action) {
    setBtnColor("disabled-btn");
    setDisabled("disabled");
    setLoadSpinner(true);
    setSaveLabel(false);
    axios
      .get(
        baseURL +
          "?query=ADD_BENEFICIARY&beneficiary_id=" +
          beneficiary_id +
          "&customerid=" +
          customerid
      )
      .then((res) => {
        setBtnColor("disabled-btn");
        setDisabled("");
        setLoadSpinner(false);
        setSaveLabel(true);

        if (res.data.status == "ok") {
          if (action === "delete") {
            setShow(false);
            setBgColor("red");
          } else {
            setBgColor("#66b266");
          }
          setDisplayMessage(true);
          setErrorMsg(res.data.message);
          setCount(count + 1);

          setSearch_field(false);
          setPayField(true);
          setBeneficiaryName(beneficiaryName);
          setBeneficiaryid(beneficiary_id);
          setModalContent();
          setModalHeader("Make payment");
          // setShow(false);
        } else {
          setBgColor("red");
          setDisplayMessage(true);
          setErrorMsg(res.data.message);
        }
      });
  }

  function display_results() {
    return (
      <>
        {results.length <= 0 || results == "" ? (
          <label className="color-grey font-medium">No results returned</label>
        ) : (
          results.map((item, index) => {
            return (
              <Row key={index} className="mb-3 align-items-center">
                <Col lg={6}>{item.name}</Col>
                <Col lg={6} className="text-right">
                  {item.added == "yes" ? (
                    <label className="color-green">
                      <BsCheck2 size={20} />
                    </label>
                  ) : (
                    <label
                      className={
                        "add-beneficiary color-blue font-medium text-center"
                      }
                      onClick={() =>
                        transfer(
                          "add_btn_click",
                          item.beneficiary_id,
                          item.email,
                          item.name
                        )
                      }
                    >
                      <IoAddOutline size={15} /> Add
                    </label>
                  )}
                </Col>
              </Row>
            );
          })
        )}
      </>
    );
  }

  function close_response_message_note() {
    setDisplayMessage(false);
  }

  function enterAmount(e) {
    setAmount(e.target.value);
    let afterAmount,
      enteredAmount = "";
    enteredAmount = e.target.value;
    let num = balance;
    afterAmount = num.toFixed(2) - enteredAmount;

    if (isNaN(e.target.value)) {
      setErrorAmount(true);
      setDisplayPayBtn("d-none");
      setValidateAmount("Invalid amount.");
    } else {
      if (enteredAmount == 0 || enteredAmount == "") {
        setAfter("--");
      } else {
        if (afterAmount < 0) {
          setAmountColor("color-red");
          setValidateAmount(
            "You have insufficient funds to complete this transaction."
          );
          setErrorAmount(true);
          setDisplayPayBtn("d-none");
        } else {
          setErrorAmount(false);
          setAmountColor();
          setDisplayPayBtn();
        }
        setAfter(afterAmount);
      }
    }
  }

  return (
    <div className="body">
      <Auth />
      <Container className="container-home">
        <Row>
          <Col lg={2} xs={2} md={3} className="p-0">
            <HomeNav />
          </Col>
          <Col lg={10} xs={10} md={9}>
            <Row className="h-100 align-items-center justify-content-center">
              <Row className="h-100 align-items-center justify-content-center">
                <Col lg={9}>
                  <Stack>
                    <div className="box-container">
                      <Row className="justify-content-center align-items-center">
                        <Col lg={12}>
                          <label className="font-medium">Obarak pay</label>

                          {/* <label className="float-right">
                            {virtual_present == "yes" ? (
                              <label
                                className={" cancel-btn font-medium"}
                                disabled={disabled}
                                onClick={activate}
                              >
                                {saveLabel && (
                                  <span>
                                    <IoAddOutline size={15} /> Top up
                                  </span>
                                )}
                                {loadSpinner && <BeatLoader size={10} />}
                              </label>
                            ) : (
                              ""
                            )}
                          </label> */}
                        </Col>

                        <Col lg={12}>
                          <div className="h-divider" />
                        </Col>
                        <Col lg={6} sm={8} md={8} className="mb-3">
                          <div className="azania-virtual-basic">
                            <div className="header text-center color-white font-medium p-3">
                              Grey card
                              {/* <br />
                              <span className="color-grey font-normal">
                                Grey card
                              </span> */}
                            </div>
                            <div className="content text-center">
                              {virtualContent && (
                                <Row>
                                  <Col lg={12} className="">
                                    <span className="font-medium">Card #:</span>{" "}
                                    {avn}
                                  </Col>
                                  <Col lg={12} className="">
                                    <span className="font-medium">CVC:</span>{" "}
                                    {avc}
                                  </Col>
                                  <Col
                                    lg={12}
                                    className={
                                      balance == 0
                                        ? "color-red"
                                        : balance <= 10
                                        ? "color-grey"
                                        : ""
                                    }
                                  >
                                    {!balance ? (
                                      <label className="color-grey">
                                        -----
                                      </label>
                                    ) : (
                                      <>
                                        <span className="font-medium">
                                          Available funds:
                                        </span>{" "}
                                        R{balance.toFixed(2)}
                                      </>
                                    )}
                                  </Col>
                                </Row>
                              )}
                              {contentLoading && (
                                <Row>
                                  <Col lg={12}>
                                    <BeatLoader
                                      size={10}
                                      className="color-blue"
                                    />
                                  </Col>
                                </Row>
                              )}
                            </div>
                          </div>
                          {/* <label className="w-100 mt-2">
                              <span className="font-medium">Benefits</span>
                              <br />
                              <span className="color-grey font-size-12">
                                Transfer funds to someone.
                                <br />
                                Earn 5% interest to your current balance
                                monthly.
                                <br />
                                Get R60 credit when you reach R0 funds.
                                <br />
                              </span>
                            </label> */}
                        </Col>

                        {/* <Col lg={4} sm={6} className="mb-3">
                            <div className="azania-virtual-green">
                              <div className="header text-center color-white font-medium">
                                Azania Virtual
                                <br />
                                <span className="font-size-12 color-green">
                                  <i>Green virtual</i>
                                </span>
                              </div>
                              <div className="content text-center">
                                <Row>
                                  <Col lg={12}>
                                    <span className="font-medium">AVN:</span>{" "}
                                    {avn}
                                  </Col>
                                  <Col lg={12}>
                                    <span className="font-medium">AVC:</span>{" "}
                                    {avc}
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Col> */}

                        {/* <Col lg={4} sm={6} className="mb-3">
                            <div className="azania-virtual-black">
                              <div className="header text-center color-white font-medium">
                                Azania Virtual
                                <br />
                                <span className="font-size-12 color-grey">
                                  <i>Black virtual</i>
                                </span>
                              </div>
                              <div className="content text-center">
                                <Row>
                                  <Col lg={12}>
                                    <span className="font-medium">AVN:</span>{" "}
                                    {avn}
                                  </Col>
                                  <Col lg={12}>
                                    <span className="font-medium">AVC:</span>{" "}
                                    {avc}
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Col> */}

                        <Col lg={12} className="text-center">
                          {virtual_present == "yes" ? (
                            ""
                          ) : (
                            <label
                              className={btnColor + " font-medium"}
                              disabled={disabled}
                              onClick={activate}
                            >
                              {saveLabel && (
                                <span>
                                  <IoFlashOutline size={15} /> Activate
                                </span>
                              )}
                              {loadSpinner && <BeatLoader size={10} />}
                            </label>
                          )}
                        </Col>
                      </Row>
                      {virtual_present == "yes" ? (
                        <Row className="justify-content-center mt-3">
                          <Col lg={3} className="mb-3">
                            <label
                              className="color-blue w-100 text-align-left cursor"
                              onClick={() => transfer("transfer", 0, "", "")}
                            >
                              <IoIosSwap size={15} /> Payments
                            </label>
                          </Col>
                          <Col lg={3} className="mb-3">
                            <label
                              className="color-blue w-100 text-align-left cursor"
                              onClick={() =>
                                transfer("beneficiaries", 0, "", "")
                              }
                            >
                              <IoPeopleOutline size={15} /> Beneficiaries
                            </label>
                          </Col>
                          <Col lg={3} className="mb-3">
                            <label
                              type="button"
                              className="color-blue w-100 text-align-left cursor"
                              onClick={() =>
                                transfer("Add beneficiary", 0, "", "")
                              }
                            >
                              <IoPersonAddOutline size={15} /> Add beneficiary
                            </label>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                    </div>
                  </Stack>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </Container>
      <Modal show={show} onHide={close} centered className="modal-z-index">
        <Modal.Header closeButton className="hide-modal-border">
          <Modal.Title className="font-medium">{modalHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={12}>{modalContent}</Col>
            <Col lg={12} className={search_field == false ? "d-none" : ""}>
              <div className="w-100 search-fields-box">
                <label className="font-medium">Search for beneficiary</label>
                <input
                  type="text"
                  placeholder="Search by name"
                  className="font-size-12 w-100"
                  value={search_value}
                  onChange={search_string}
                />
                <div
                  className={
                    search_value.length <= 0
                      ? "d-none return-results w-100"
                      : "return-results w-100"
                  }
                >
                  {display_results()}
                </div>
              </div>
            </Col>
          </Row>
          <Row className={payField == false ? "d-none" : ""}>
            {errorAmount && (
              <Col lg={12}>
                <div className="p-2 alert alert-danger font-size-12">
                  {validateAmount}
                </div>
              </Col>
            )}
            <Col lg={6} className="text-left">
              <label>
                <span className="font-size-12 color-grey">Before</span>
                <br />
                <span className="font-medium font-size-12"> R {balance.toFixed(2)}</span>
              </label>
            </Col>
            <Col lg={6} className="text-right">
              <label>
                <span className="font-size-12 color-grey">After</span>
                <br />
                <span className={amountColor + " font-medium font-size-12"}>
                  {" "}
                  R {after}
                </span>
              </label>
            </Col>

            <Col lg={12}>
              <div className="h-divider" />
            </Col>
            <Col lg={6}>
              <label className="color-grey">{beneficiaryName}</label>
            </Col>
            <Col lg={6} className="text-right">
              <label className="w-100px text-center">
                <input
                  type="text"
                  className="amount-input"
                  placeholder="0.0"
                  value={amount}
                  onChange={enterAmount}
                />
              </label>
              <button
                type="button"
                className={
                  displayPayBtn +
                  " add-beneficiary color-blue font-medium text-center"
                }
                onClick={() => pay_ben(beneficiaryid)}
                disabled={disabled}
              >
                {saveLabel && (
                  <span>
                    <IoIosSwap size={15} /> Pay
                  </span>
                )}
                {loadSpinner && <BeatLoader size={5} color="#fff" />}
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
          onClick={close_response_message_note}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}
