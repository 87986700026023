import React, { useState, useEffect } from "react";
import { Col, Container, Row, Stack, Modal, Button } from "react-bootstrap";
import SideNav from "./navigation/SideNav";
import BeatLoader from "react-spinners/BeatLoader";
import validator from "validator";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function Addcustomer() {
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState("");
  const [fname, setFname] = useState("");
  const [surname, setSurname] = useState("");
  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [btnColor, setBtnColor] = useState("action-btn");
  const [disabled, setDisabled] = useState("");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);

  function submit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("fname", fname);
    formData.append("email", email);
    formData.append("surname", surname);
    formData.append("phone", phone);

    if (fname === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter customer's name");
    } else if (surname === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter customer's surname");
    } else if (email === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter customer's email address");
    } else if (!validator.isEmail(email)) {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Email address is invalid");
    } else if (!phone) {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter customer's phone number");
    } else if (
      isPossiblePhoneNumber(phone.replace(" ", "")) === false ||
      isValidPhoneNumber(phone.replace(" ", "")) === false
    ) {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Phone number is invalid");
    } else {
      // connect to the api
      setBtnColor("disabled-btn");
      setDisabled("disabled");
      setLoadSpinner(true);
      setSaveLabel(false);

      setBgColor("#66b266");
      setDisplayMessage(true);
      setErrorMsg("Customer successfully added");
    }
    setTimeout(() => {
      setDisplayMessage(false);
    }, 5000);
  }
  return (
    <div className="body">
      <Container className="container-home">
        <Row>
          <Col lg={2} xs={2} md={3} className="p-0">
            <SideNav />
          </Col>
          <Col lg={10} xs={10} md={9}>
            <Row className="h-100 align-items-center justify-content-center">
              <form onSubmit={submit}>
                <Row className="h-100 align-items-center justify-content-center">
                  <Col lg={9}>
                    <Stack>
                      <div className="box-container">
                        <Row>
                          <Col lg={12}>
                            <label className="text-medium">Add customer</label>
                          </Col>

                          <Col lg={12}>
                            <div className="h-divider" />
                          </Col>
                          <Col lg={6} sm={6} className="mb-3">
                            <input
                              type="text"
                              name="fname"
                              placeholder="First name"
                              onChange={(e) => setFname(e.target.value)}
                              value={fname}
                            />
                          </Col>
                          <Col lg={6} sm={6} className="mb-3">
                            <input
                              type="text"
                              name="surname"
                              placeholder="Surname"
                              onChange={(e) => setSurname(e.target.value)}
                              value={surname}
                            />
                          </Col>
                          <Col lg={6} sm={6} className="mb-3">
                            <input
                              type="text"
                              name="email"
                              placeholder="Email address"
                              onChange={(e) => setEmail(e.target.value)}
                              value={email}
                            />
                          </Col>
                          <Col lg={6} sm={6} className="mb-3">
                            <PhoneInput
                              name={"phone"}
                              disableDropdown={false}
                              enableSearch={true}
                              showDropdown={false}
                              country={"za"}
                              value={phone}
                              onChange={(phone) => setPhone("+" + phone)}
                              inputStyle={{
                                width: "100%",
                                background: "#f0f2f5",
                                height: "3em",
                                boxShadow: "none",
                              }}
                              containerStyle={{ marginBottom: 0 }}
                              inputProps={{
                                name: "phone",
                                required: true,
                              }}
                            />
                          </Col>

                          <Col lg={12} className="">
                            <button
                              type="submit"
                              className={btnColor}
                              disabled={disabled}
                            >
                              {saveLabel && <span>Add</span>}
                              {loadSpinner && <BeatLoader size={10} />}
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </Stack>
                  </Col>
                </Row>
              </form>
            </Row>
          </Col>
        </Row>
      </Container>
      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}
