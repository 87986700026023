import React, { useState, useEffect } from "react";
import { Col, Container, Row, Stack, Modal, Button } from "react-bootstrap";
import Nav from "./navigation/Nav";
import { Link, NavLink, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import axios from "axios";
import { IoIosCheckmark } from "react-icons/io";
export default function Thanks() {
  const navigate = useNavigate();
  const [verification_code, setVerification_code] = useState("");

  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [btnColor, setBtnColor] = useState("login-btn");
  const [disabled, setDisabled] = useState("");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [location, setLocation] = useState("");

  function submit(e) {
    e.preventDefault();
    const email = localStorage.getItem("email");
    const package_type = localStorage.getItem("package");
    const formData = new FormData();
    formData.append("verification_code", verification_code);
    formData.append("email", email);
    formData.append("query", "VERIFY");

    if (verification_code === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter the verification code");
    } else {
      setBtnColor("disabled-btn");
      setDisabled("disabled");
      setLoadSpinner(true);
      setSaveLabel(false);
      // connect to the api

      const baseURL = process.env.REACT_APP_URL;
      axios({
        method: "POST",
        url: baseURL,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then((response) => {
        if (response.data.status == "error") {
          setBgColor("red");
          setDisplayMessage(true);
          setErrorMsg(response.data.message);
        } else {
          //   localStorage.setItem("email", email);
          const package_type = localStorage.getItem("package");
          if (package_type == "professional") {
            navigate("/thank_you");
          } else {
            navigate("/dashboard");
          }
        }

        setBtnColor("login-btn");
        setDisabled("");
        setLoadSpinner(false);
        setSaveLabel(true);

        // console.log(response.data.name);
      });
    }
    setTimeout(() => {
      setDisplayMessage(false);
    }, 5000);
  }

  return (
    <div className="package-body height-100hv">
      <Container className="container-home height-100hv">
        <Row className="height-100hv justify-content-center">
          <Col lg={6} xs={12} md={12}>
            <Row className="align-items-center justify-content-center h-100">
              <form onSubmit={submit}>
                <Row className="h-100 align-items-center justify-content-center">
                  <Col lg={8}>
                    <Stack>
                      <div className="text-center w-100 mb-3">
                        <h4 className="color-green">
                          <IoIosCheckmark size={60} />
                          <br />
                          Email verified
                        </h4>
                      </div>
                      <div className="box-container mb-5">
                        <Row>
                          <Col lg={12} className="text-center">
                            Thank you for taking interest in becoming a host on
                            Obarak, as part of our security measure; your
                            application will be reviewed by our sales team and
                            shall revert to you within the next 3 - 7 days.
                            <br />
                            <br />
                            <Link to="/">
                              <label className="karamale-login-btn">
                                <b> Home</b>
                              </label>
                            </Link>
                          </Col>
                        </Row>
                      </div>
                    </Stack>
                  </Col>
                </Row>
              </form>
            </Row>
          </Col>
        </Row>
      </Container>
      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}
