import React, { useState, useEffect } from "react";
import Auth from "./navigation/auth";
import { Col, Container, Row, Stack, Modal, Button } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import HomeNav from "./navigation/HomeNav";
import BeatLoader from "react-spinners/BeatLoader";
import ReactTooltip from "react-tooltip";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import { BsGeoAlt } from "react-icons/bs";
import { BiStore } from "react-icons/bi";

import {
  IoCardOutline,
  IoTimerOutline,
  IoCalendarNumberOutline,
} from "react-icons/io5";
const baseURL = process.env.REACT_APP_URL;
const root_url = process.env.REACT_APP_URL_RESOURCE;
export default function Index() {
  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [btnColor, setBtnColor] = useState("action-btn");
  const [disabled, setDisabled] = useState("");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [topups, setTopups] = useState([]);
  const [count, setCount] = useState(0);
  const [total, setTotal] = useState(0);

  const customerid = localStorage.getItem("customerid");

  function get_total() {
    axios
      .get(baseURL + "?query=TOP_UP_TOTAL&customerid=" + customerid)
      .then((res) => {
        setTotal(res.data.total);
      });
  }

  function list_topups() {
    axios
      .get(baseURL + "?query=MY_TOP_UPS&customerid=" + customerid)
      .then((response) => {
        setTopups(response.data);
      });
  }

  useEffect(() => {
    document.title = "Obarak | Top up history";
    list_topups();
    get_total();
  }, [count]);

  function my_topups() {
    return (
      <>
        {topups.length <= 0 ? (
          <div className="text-center" lg={12}>
            <label className="color-grey text-bold font-size-17">You have no top up history</label>
          </div>
        ) : (
          <>
            <Col
              lg={12}
              className="d-none d-lg-block header-container-topups mb-2 w-100"
            >
              <Row>
                <Col lg={3}>
                  <span className="text-medium font-size-12">
                    <BiStore size={15} />
                    &nbsp;Stores
                  </span>
                </Col>
                <Col lg={4}>
                  <span className="text-medium font-size-12">
                    <BsGeoAlt size={15} />
                    &nbsp;Location
                  </span>
                </Col>
                <Col lg={1}>
                  <span className="text-medium font-size-12">
                    <IoCardOutline size={15} />
                    &nbsp;Funds
                  </span>
                </Col>
                <Col lg={2}>
                  <span className="text-medium font-size-12">
                    <IoTimerOutline size={15} />
                    &nbsp;Time
                  </span>
                </Col>
                <Col lg={2}>
                  <span className="text-medium font-size-12">
                    <IoCalendarNumberOutline size={15} />
                    &nbsp;Date
                  </span>
                </Col>
              </Row>
            </Col>
            {topups.map((item, index) => {
              return (
                <>
                  <Row
                    key={index}
                    className="customer-row align-items-center pt-2 mb-2"
                  >
                    <Col
                      lg={3}
                      xs={12}
                      className="mb-2 text-left font-size-12 font-medium"
                    >
                      <label
                        data-tip="Transaction store"
                        data-effect={"solid"}
                        data-place="top"
                        data-type={"dark"}
                        data-arrow-color={"transparent"}
                        data-class="tool_tips"
                      >
                        <span className="font-medium d-lg-none float-left">
                          <BiStore size={15} />
                          &nbsp;
                        </span>
                        <>
                          {item.logo == "" ? (
                            <div
                              className="logo-topus"
                              style={{
                                backgroundImage: `url(${root_url}/assets/search_logo.png)`,
                              }}
                            ></div>
                          ) : (
                            <div
                              className="logo-topus"
                              style={{
                                backgroundImage: `url(${root_url}/assets/${item.companyid}/${item.logo})`,
                              }}
                            ></div>
                          )}
                        </>
                        {item.company_name}
                        {/* <br />
                        <label
                          className="font-size-12 color-grey font-normal"
                          style={{ border: "thin solid red" }}
                        >
                          {item.category}
                        </label> */}
                        <ReactTooltip />
                      </label>
                    </Col>
                    <Col lg={4} xs={12} className="mb-2 text-left font-size-12">
                      <label
                        data-tip="Transaction location"
                        data-effect={"solid"}
                        data-place="top"
                        data-type={"dark"}
                        data-arrow-color={"transparent"}
                        data-class="tool_tips"
                        className="color-grey font-size-12"
                      >
                        <span className="font-medium d-lg-none float-left">
                          <BsGeoAlt size={15} />
                          &nbsp;
                        </span>
                        {item.location}
                        <ReactTooltip />
                      </label>
                    </Col>
                    <Col lg={1} xs={12} className="color-grey mb-2 text-left">
                      <label
                        data-tip="Top up amount"
                        data-effect={"solid"}
                        data-place="top"
                        data-type={"dark"}
                        data-arrow-color={"transparent"}
                        data-class="tool_tips"
                        className="font-size-12"
                      >
                        <span className="font-medium d-lg-none float-left">
                          <IoCardOutline size={15} />
                          &nbsp;
                        </span>
                        R{item.amount}
                        <ReactTooltip />
                      </label>
                    </Col>

                    <Col lg={2} xs={12} className="color-grey mb-2 text-left">
                      <label
                        data-tip="Transanction time"
                        data-effect={"solid"}
                        data-place="top"
                        data-type={"dark"}
                        data-arrow-color={"transparent"}
                        data-class="tool_tips"
                        className="font-size-12"
                      >
                        <span className="font-medium d-lg-none float-left">
                          <IoTimerOutline size={15} />
                          &nbsp;
                        </span>
                        {item.time}
                        <ReactTooltip />
                      </label>
                    </Col>
                    <Col lg={2} xs={12} className="color-grey mb-2 text-left">
                      <label
                        data-tip="Transaction date"
                        data-effect={"solid"}
                        data-place="top"
                        data-type={"dark"}
                        data-arrow-color={"transparent"}
                        data-class="tool_tips"
                        className="font-size-12"
                      >
                        <span className="font-medium d-lg-none float-left">
                          <IoCalendarNumberOutline size={15} />
                          &nbsp;
                        </span>
                        {item.date}
                        <ReactTooltip />
                      </label>
                    </Col>
                  </Row>
                </>
              );
            })}
          </>
        )}
      </>
    );
  }

  return (
    <div className="body">
      <Auth />
      <Container className="container-home">
        <Row>
          <Col lg={2} xs={2} md={3} className="p-0">
            <HomeNav />
          </Col>
          <Col lg={10} xs={10} md={9} className="middle-container">
            <Row className="h-100 align-items-center justify-content-center">
              <Row className="h-100 align-items-center justify-content-center">
                <Col lg={11}>
                  <Stack>
                    <div className="box-container mt-3 mb-3">
                      <Row>
                        <Col lg={12}>
                          <label className="text-bold">Top up records</label>
                          <label className="float-right">
                            <span className="text-bold">Total: </span> R
                            {total}
                          </label>
                        </Col>

                        <Col lg={12}>
                          <div className="h-divider" />
                        </Col>
                        <Col lg={12} className="mb-3">
                          {my_topups()}
                        </Col>
                      </Row>
                    </div>
                  </Stack>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </Container>
      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}
