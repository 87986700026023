import React, { useState, useEffect } from "react";
import { Col, Container, Row, Stack, Modal } from "react-bootstrap";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import {
  BsPeople,
  BsFileImage,
  BsFillHouseFill,
  BsClockHistory,
  BsPlusCircleDotted,
  BsBarChart,
  BsCurrencyExchange,
  BsFillBagCheckFill,
  BsPerson,
  BsShieldLock,
  BsChatRightQuote,
  BsPersonPlus,
  BsPersonPlusFill,
} from "react-icons/bs";
import {
  IoIosRestaurant,
  IoIosRemoveCircleOutline,
  IoIosListBox,
  IoMdSettings,
  IoIosSend,
  IoIosLogOut,
  IoIosTrash,
  IoIosDesktop,
  IoIosCheckmark,
  IoIosClose,
  IoMdClock,
} from "react-icons/io";
import { BiQr, BiDetail } from "react-icons/bi";
import axios from "axios";
import ReactAudioPlayer from "react-audio-player";
import { v4 as uuid } from "uuid";
const url = process.env.REACT_APP_URL_RESOURCE;
const root_url = process.env.REACT_APP_URL;
const baseURL = process.env.REACT_APP_URL;

export default function SideNav() {
  let location = useLocation();
  let deviceid = "";
  let unique_uuid = uuid();
  if (!localStorage.getItem("deviceid")) {
    localStorage.setItem("deviceid", uuid());
    deviceid = uuid();
  } else {
    deviceid = localStorage.getItem("deviceid");
  }

  const [trash_count, set_trash_count] = useState(0);
  const [media_count, set_media_count] = useState(0);
  const [customer_count, set_customer_count] = useState(0);
  const [orderCount, setOrderCount] = useState(0);
  const [orderCountH, setOrderCountH] = useState(0);
  const [notification, setNotifications] = useState(0);
  const [order_notification, setOrders_Notifications] = useState(0);
  const [loadNotifications, setLoadNotifications] = useState(false);
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [btnColor, setBtnColor] = useState("search-buttons");
  const [statusColor, setStatusColor] = useState("store-available");
  const [disabled, setDisabled] = useState("");
  const [count, setCount] = useState(0);
  const [show, setShow] = useState(false);
  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const accountid = localStorage.getItem("accountid");
  const navigate = useNavigate();

  function notify() {
    axios
      .get(root_url + "?query=NOTIFICATIONS&accountid=" + accountid)
      .then((res) => {
        setNotifications(res.data.count);
      });
  }

  function order_notifications() {
    axios
      .get(root_url + "?query=ORDERS_NOTIFICATIONS&accountid=" + accountid)
      .then((res) => {
        setOrders_Notifications(res.data.count);
      });
  }

  function order_count() {
    axios
      .get(root_url + "?query=ORDER_COUNT&accountid=" + accountid)
      .then((res) => {
        setOrderCount(res.data.count);
      });
  }

  function order_count_history() {
    axios
      .get(root_url + "?query=ORDER_COUNT_HISTORY&accountid=" + accountid)
      .then((res) => {
        setOrderCountH(res.data.count);
      });
  }

  function trashCount() {
    axios
      .get(root_url + "?query=MEDIA-TRASH-COUNT&accountid=" + accountid)
      .then((res) => {
        set_trash_count(res.data.count);
      });
  }

  function mediaCount() {
    axios
      .get(root_url + "?query=MEDIA-COUNT&accountid=" + accountid)
      .then((res) => {
        set_media_count(res.data.count);
      });
  }

  function customerCount() {
    axios
      .get(root_url + "?query=CUSTOMER-COUNT&accountid=" + accountid)
      .then((res) => {
        set_customer_count(res.data.count);
      });
  }

  function storeAvailability(){
    if(localStorage.getItem('store_available') == 'yes'){
      setStatusColor('store-available');
    } else{
      setStatusColor('store-unavailable');
    }
  }
  useEffect(() => {
    const currentLocation = location.pathname;
    trashCount();
    mediaCount();
    customerCount();
    order_count();
    order_count_history();
    notify();
    order_notifications();
    storeAvailability();

    const interval_time = setInterval(() => {
      order_count();
      order_count_history();
      notify();
      order_notifications();
      customerCount();
    }, 5000);
    return () => clearInterval(interval_time);
  }, [count]);

  function pos() {
    localStorage.setItem("pos", 1);
  }

  function mark_store_unavailable() {
    // setBtnColor("disabled-btn");
    setDisabled("disabled");
    setLoadSpinner(true);
    setSaveLabel(false);
    axios
      .get(root_url + "?query=STORE_AVAILABLE&accountid=" + accountid)
      .then((response) => {
        // setBtnColor("search-buttons");
        setDisabled("");
        setLoadSpinner(false);
        setSaveLabel(true);

        if (response.data.status == "ok") {
          if (response.data.store_available == "yes") {
            setBgColor("#66b266");
            setStatusColor("store-available");
          } else {
            setBgColor("red");
            setStatusColor("store-unavailable");
          }

          setDisplayMessage(true);
          setErrorMsg(response.data.message);
          // alert(response.data.accountid);
          localStorage.setItem(
            "store_available",
            response.data.store_available
          );
        } else {
          setBgColor("red");
          setDisplayMessage(true);
          setErrorMsg(response.data.message);
        }
        setShow(false);
        setCount(count + 1);
      });
  }

  function confirmationModal() {
    setShow(true);
  }

  function close() {
    setShow(false);
  }

  function close_error() {
    setDisplayMessage(false);
  }
  return (
    <div className="">
      <Container className="container-home side-nav-container-busisnes nav-container-host">
        <Row>
          <Col lg={12} className="p-0">
            <Stack>
              <div className="w-100 ">
                <Row className="align-items-center">
                  <Col lg={12} className="text-center logo-special-div mt-3">
                    {localStorage.getItem("logo") == "" ? (
                      <>
                        <Link to={"/dashboard"}>
                          <div
                            className="logo-div d-none d-lg-block d-md-block"
                            style={{
                              backgroundImage: `url(${url}/assets/logo2.jpg)`,
                              marginRight: 5,
                            }}
                          ></div>
                        </Link>
                        <div
                          className="logo-div"
                          style={{
                            backgroundImage: `url(${url}/assets/search_logo.png)`,
                          }}
                        ></div>
                      </>
                    ) : (
                      <>
                        <Link to={"/dashboard"}>
                          <div
                            className="logo-div d-none d-lg-block d-md-block"
                            style={{
                              backgroundImage: `url(${url}/assets/logo2.jpg)`,
                              marginRight: 5,
                            }}
                          ></div>
                        </Link>

                        <Link to={"/dashboard"}>
                          <div
                            className="logo-div d-none d-lg-block d-md-block"
                            style={{
                              backgroundImage: `url(${url}/assets/${localStorage.getItem("accountid")}/${localStorage.getItem("logo")})`,
                              marginRight: 5,
                            }}
                          ></div>
                        </Link>
                      </>
                    )}
                  </Col>

                  <Col
                    lg={12}
                    className={"d-none d-sm-none d-md-block mt-3 text-center"}
                  >
                    <b className={"business-name-color-text-blue"}>
                      {localStorage.getItem("company_name")}
                      <br />
                    </b>
                    <label class={statusColor + " mt-3 cursor font-size-12"} onClick={confirmationModal}>
                            {localStorage.getItem('store_available') == 'yes' ? 'Store available to public' : 'Store unavailable'}
                        
                    </label>
                  </Col>
                </Row>
              </div>
              <Row className="side-nav-container-host mt-3">
                <NavLink
                  to="/dashboard"
                  className="side-nav text-decoration-none"
                  exact
                  activeClassName="side-nav-active"
                >
                  <div className="w-100">
                    <Row className="align-items-center">
                      <Col lg={2} md={3} className="text-center">
                        <BsFillHouseFill size={20} />
                        <label
                          className={
                            "d-block d-lg-none d-md-none font-size-10 mt-2"
                          }
                        >
                          Dashboard
                        </label>
                      </Col>
                      <Col
                        lg={9}
                        md={8}
                        className={"d-none d-sm-none d-md-block"}
                      >
                        Dashboard
                      </Col>
                    </Row>
                  </div>
                </NavLink>
                <NavLink
                  to="/"
                  className="side-nav text-decoration-none"
                  exact
                  activeClassName="side-nav-active"
                  onClick={pos}
                >
                  <div className="w-100">
                    <Row className="align-items-center">
                      <Col lg={2} md={3} className="text-center">
                        <IoIosDesktop size={20} />
                        <label
                          className={
                            "d-block d-lg-none d-md-none font-size-10 mt-2"
                          }
                        >
                          P.O.S
                        </label>
                      </Col>
                      <Col
                        lg={9}
                        md={8}
                        className={"d-none d-sm-none d-md-block"}
                      >
                        Point of sale
                      </Col>
                    </Row>
                  </div>
                </NavLink>
                <div className="h-divider" />
                <Link
                  to="/orders"
                  className="side-nav text-decoration-none"
                  exact
                  activeClassName="side-nav-active"
                >
                  <div className="w-100">
                    <Row className="align-items-center">
                      <Col
                        lg={2}
                        md={3}
                        className="text-center mobile-icon-container-menu"
                      >
                        <IoIosRestaurant size={20} />{" "}
                        <label
                          className={
                            orderCount < 1
                              ? "d-none"
                              : "basket_count font-size-12 d-lg-none d-md-none d-sm-block"
                          }
                        >
                          {orderCount}
                        </label>
                        <label
                          className={
                            "d-block d-lg-none d-md-none font-size-10 mt-2"
                          }
                        >
                          Orders
                        </label>
                      </Col>
                      <Col
                        lg={9}
                        md={8}
                        className={"d-none d-sm-none d-md-block"}
                      >
                        Orders{" "}
                        {orderCount > 0 ? (
                          <label className="basket_count color-white font-size-12">
                            {orderCount}
                          </label>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </div>
                </Link>

                <Link
                  to="/history"
                  className="side-nav text-decoration-none"
                  exact
                  activeClassName="side-nav-active"
                >
                  <div className="w-100">
                    <Row className="align-items-center">
                      <Col
                        lg={2}
                        md={3}
                        className="text-center mobile-icon-container-menu"
                      >
                        <BsClockHistory size={20} />{" "}
                        <span
                          className={
                            orderCountH < 1
                              ? "d-none"
                              : "media_in_count font-size-12 d-lg-none d-md-none d-sm-block"
                          }
                        >
                          {orderCountH}
                        </span>
                        <label
                          className={
                            "d-block d-lg-none d-md-none font-size-10 mt-2"
                          }
                        >
                          History
                        </label>
                      </Col>
                      <Col
                        lg={9}
                        md={8}
                        className={"d-none d-sm-none d-md-block"}
                      >
                        History{" "}
                        {orderCountH > 0 ? (
                          <span className="media_in_count font-size-12">
                            {orderCountH}
                          </span>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </div>
                </Link>
                <div className="h-divider" />
                <NavLink
                  to="/customers"
                  className="side-nav text-decoration-none"
                  exact
                  activeClassName="side-nav-active"
                >
                  <div className="w-100">
                    <Row className="align-items-center">
                      <Col
                        lg={2}
                        md={3}
                        className="text-center mobile-icon-container-menu"
                      >
                        <BsPeople size={20} />{" "}
                        <span className="media_in_count font-size-12 d-lg-none d-md-none d-sm-block">
                          {customer_count}
                        </span>
                        <label
                          className={
                            "d-block d-lg-none d-md-none font-size-10 mt-2"
                          }
                        >
                          Customers
                        </label>
                      </Col>
                      <Col
                        lg={9}
                        md={8}
                        className={"d-none d-sm-none d-md-block"}
                      >
                        Customers{" "}
                        <span className="media_in_count font-size-12">
                          {customer_count}
                        </span>
                      </Col>
                    </Row>
                  </div>
                </NavLink>

                <NavLink
                  to="/media"
                  className="side-nav text-decoration-none"
                  exact
                  activeClassName="side-nav-active"
                >
                  <div className="w-100">
                    <Row className="align-items-center">
                      <Col
                        lg={2}
                        md={3}
                        className="text-center mobile-icon-container-menu"
                      >
                        <BsFileImage size={20} />{" "}
                        <span className="media_in_count font-size-12 d-lg-none d-md-none d-sm-block">
                          {media_count}
                        </span>
                        <label
                          className={
                            "d-block d-lg-none d-md-none font-size-10 mt-2"
                          }
                        >
                          Media
                        </label>
                      </Col>
                      <Col
                        lg={9}
                        md={8}
                        className={"d-none d-sm-none d-md-block"}
                      >
                        Media{" "}
                        <span className="media_in_count font-size-12">
                          {media_count}
                        </span>
                      </Col>
                    </Row>
                  </div>
                </NavLink>
                <NavLink
                  to="/trash"
                  className="side-nav text-decoration-none"
                  exact
                  activeClassName="side-nav-active"
                >
                  <div className="w-100">
                    <Row className="align-items-center">
                      <Col
                        lg={2}
                        md={3}
                        className="text-center mobile-icon-container-menu"
                      >
                        <IoIosTrash size={20} color={"red"} />{" "}
                        <span className="media_in_count font-size-12 d-lg-none d-md-none d-sm-block">
                          {trash_count}
                        </span>
                        <label
                          className={
                            "d-block d-lg-none d-md-none font-size-10 mt-2"
                          }
                        >
                          Trash
                        </label>
                      </Col>
                      <Col
                        lg={9}
                        md={8}
                        className={"d-none d-sm-none d-md-block"}
                      >
                        Trash{" "}
                        <span className="media_in_count font-size-12">
                          {trash_count}
                        </span>
                      </Col>
                    </Row>
                  </div>
                </NavLink>
                <NavLink
                  to="/addstock"
                  className="side-nav text-decoration-none"
                  exact
                  activeClassName="side-nav-active"
                >
                  <div className="w-100">
                    <Row className="align-items-center">
                      <Col lg={2} md={3} className="text-center">
                        <BsPlusCircleDotted size={20} />
                        <label
                          className={
                            "d-block d-lg-none d-md-none font-size-10 mt-2"
                          }
                        >
                          Add it..
                        </label>
                      </Col>
                      <Col
                        lg={9}
                        md={8}
                        className={"d-none d-sm-none d-md-block"}
                      >
                        Add item
                      </Col>
                    </Row>
                  </div>
                </NavLink>

                <div className="h-divider" />
                {/* <NavLink
                to="/analytics"
                className="side-nav text-decoration-none"
                exact
                activeClassName="side-nav-active"
              >
                <div className="w-100">
                  <Row className="align-items-center">
                    <Col lg={2} md={3} className="text-center">
                      <BsBarChart size={20} />
                    </Col>
                    <Col
                      lg={9}
                      md={8}
                      className={"d-none d-sm-none d-md-block"}
                    >
                      Analytics
                    </Col>
                  </Row>
                </div>
              </NavLink> */}
                <NavLink
                  to="/reports"
                  className="side-nav text-decoration-none"
                  exact
                  activeClassName="side-nav-active"
                >
                  <div className="w-100">
                    <Row className="align-items-center">
                      <Col lg={2} md={3} className="text-center">
                        <IoIosListBox size={20} />
                        <label
                          className={
                            "d-block d-lg-none d-md-none font-size-10 mt-2"
                          }
                        >
                          Trans...
                        </label>
                      </Col>
                      <Col
                        lg={9}
                        md={8}
                        className={"d-none d-sm-none d-md-block"}
                      >
                        Transactions
                      </Col>
                    </Row>
                  </div>
                </NavLink>
                <NavLink
                  to="/revenue"
                  className="side-nav text-decoration-none"
                  exact
                  activeClassName="side-nav-active"
                >
                  <div className="w-100">
                    <Row className="align-items-center">
                      <Col lg={2} md={3} className="text-center">
                        <BsCurrencyExchange size={20} />
                        <label
                          className={
                            "d-block d-lg-none d-md-none font-size-10 mt-2"
                          }
                        >
                          Revenue
                        </label>
                      </Col>
                      <Col
                        lg={9}
                        md={8}
                        className={"d-none d-sm-none d-md-block"}
                      >
                        Revenue
                      </Col>
                    </Row>
                  </div>
                </NavLink>
                <NavLink
                  to="/payments"
                  className="side-nav text-decoration-none"
                  exact
                  activeClassName="side-nav-active"
                >
                  <div className="w-100">
                    <Row className="align-items-center">
                      <Col lg={2} md={3} className="text-center">
                        <BsFillBagCheckFill size={20} />
                        <label
                          className={
                            "d-block d-lg-none d-md-none font-size-10 mt-2"
                          }
                        >
                          Payouts
                        </label>
                      </Col>
                      <Col
                        lg={9}
                        md={8}
                        className={"d-none d-sm-none d-md-block"}
                      >
                        Payouts
                      </Col>
                    </Row>
                  </div>
                </NavLink>
                {/* <div className="h-divider" /> */}
                {/* <NavLink
                to="/adduser"
                className="side-nav text-decoration-none"
                exact
                activeClassName="side-nav-active"
              >
                <div className="w-100">
                  <Row className="align-items-center">
                    <Col lg={2} md={3} className="text-center">
                      <BsPersonPlus size={20} />
                    </Col>
                    <Col
                      lg={9}
                      md={8}
                      className={"d-none d-sm-none d-md-block"}
                    >
                      Add user
                    </Col>
                  </Row>
                </div>
              </NavLink>

              <NavLink
                to="/addcustomer"
                className="side-nav text-decoration-none"
                exact
                activeClassName="side-nav-active"
              >
                <div className="w-100">
                  <Row className="align-items-center">
                    <Col lg={2} md={3} className="text-center">
                      <BsPersonPlusFill size={20} />
                    </Col>
                    <Col
                      lg={9}
                      md={8}
                      className={"d-none d-sm-none d-md-block"}
                    >
                      Add customer
                    </Col>
                  </Row>
                </div>
              </NavLink> */}

                <div className="h-divider" />
                {/* <div className="w-100 side-nav">
                <Row className="align-items-center">
                  <Col lg={2} md={3} className="text-center">
                    <BiQr size={20} />
                  </Col>
                  <Col lg={9} md={8} className={"d-none d-sm-none d-md-block"}>
                    Enable qrcode
                  </Col>
                </Row>
              </div> */}
                <div className="font-bold font-20">Settings</div>
                <NavLink
                  to="/profile"
                  className="side-nav text-decoration-none"
                  exact
                  activeClassName="side-nav-active"
                >
                  <div className="w-100">
                    <Row className="align-items-center">
                      <Col lg={2} md={3} className="text-center">
                        <BsPerson size={20} />
                        <label
                          className={
                            "d-block d-lg-none d-md-none font-size-10 mt-2"
                          }
                        >
                          Profile
                        </label>
                      </Col>
                      <Col
                        lg={9}
                        md={8}
                        className={"d-none d-sm-none d-md-block"}
                      >
                        Profile
                      </Col>
                    </Row>
                  </div>
                </NavLink>
                <NavLink
                  to="/bank"
                  className="side-nav text-decoration-none"
                  exact
                  activeClassName="side-nav-active"
                >
                  <div className="w-100">
                    <Row className="align-items-center">
                      <Col lg={2} md={3} className="text-center">
                        <BiDetail size={20} />
                        <label
                          className={
                            "d-block d-lg-none d-md-none font-size-10 mt-2"
                          }
                        >
                          Bank...
                        </label>
                      </Col>
                      <Col
                        lg={9}
                        md={8}
                        className={"d-none d-sm-none d-md-block"}
                      >
                        Bank details
                      </Col>
                    </Row>
                  </div>
                </NavLink>
                <NavLink
                  to="/passwords"
                  className="side-nav text-decoration-none"
                  exact
                  activeClassName="side-nav-active"
                >
                  <div className="w-100">
                    <Row className="align-items-center">
                      <Col lg={2} md={3} className="text-center">
                        <BsShieldLock size={20} />
                        <label
                          className={
                            "d-block d-lg-none d-md-none font-size-10 mt-2"
                          }
                        >
                          Pass...
                        </label>
                      </Col>
                      <Col
                        lg={9}
                        md={8}
                        className={"d-none d-sm-none d-md-block"}
                      >
                        Passwords
                      </Col>
                    </Row>
                  </div>
                </NavLink>
                <NavLink
                  to="/email"
                  className="side-nav text-decoration-none"
                  exact
                  activeClassName="side-nav-active"
                >
                  <div className="w-100">
                    <Row className="align-items-center">
                      <Col lg={2} md={3} className="text-center">
                        <IoIosSend size={20} />
                        <label
                          className={
                            "d-block d-lg-none d-md-none font-size-10 mt-2"
                          }
                        >
                          Email
                        </label>
                      </Col>
                      <Col
                        lg={9}
                        md={8}
                        className={"d-none d-sm-none d-md-block"}
                      >
                        Email
                      </Col>
                    </Row>
                  </div>
                </NavLink>

                <NavLink
                  to="/trading_hours"
                  className="side-nav text-decoration-none"
                  exact
                  activeClassName="side-nav-active"
                  onClick={pos}
                >
                  <div className="w-100">
                    <Row className="align-items-center">
                      <Col lg={2} md={3} className="text-center">
                        <IoMdClock size={20} />
                        <label
                          className={
                            "d-block d-lg-none d-md-none font-size-10 mt-2"
                          }
                        >
                          Trading...
                        </label>
                      </Col>
                      <Col
                        lg={9}
                        md={8}
                        className={"d-none d-sm-none d-md-block"}
                      >
                        Trading hours
                      </Col>
                    </Row>
                  </div>
                </NavLink>

                {/* <div className="w-100 side-nav">
                  <Row className="align-items-center">
                    <Col lg={2} md={3} className="text-center">
                      <IoMdSettings size={20} />
                    </Col>
                    <Col
                      lg={9}
                      md={8}
                      className={"d-none d-sm-none d-md-block"}
                    >
                      Settings
                    </Col>
                  </Row>
                </div> */}
                <NavLink
                  to="/logout"
                  className="side-nav text-decoration-none"
                  exact
                  activeClassName="side-nav-active"
                >
                  <div className="w-100">
                    <Row className="align-items-center">
                      <Col lg={2} md={3} className="text-center">
                        <IoIosLogOut size={20} />
                        <label
                          className={
                            "d-block d-lg-none d-md-none font-size-10 mt-2"
                          }
                        >
                          Log out
                        </label>
                      </Col>
                      <Col
                        lg={9}
                        md={8}
                        className={"d-none d-sm-none d-md-block"}
                      >
                        Log out
                      </Col>
                    </Row>
                  </div>
                </NavLink>
              </Row>
            </Stack>
          </Col>
        </Row>

        <Modal
          show={show}
          onHide={close}
          centered
          backdrop={true}
          className="modal-z-index"
        >
          <Modal.Header closeButton className="hide-modal-border">
            <Modal.Title className="color-black font-medium">
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            {localStorage.getItem("store_available") == "yes"
              ? "Are you sure you want to mark this store unavailable?"
              : "Do you want to make this store available?"}
            <br />
            <button
              type="button"
              className={btnColor + " mt-3"}
              disabled={disabled}
              onClick={mark_store_unavailable}
            >
              {saveLabel && <span>Yes</span>}
              {loadSpinner && <BeatLoader size={5} />}
            </button>{" "}
            &nbsp;&nbsp;
            <label className={btnColor + " font-medium"} onClick={close}>
              Cancel
            </label>
          </Modal.Body>
        </Modal>
        {displayMessage && (
          <div
            className={"message-error w-100 text-center"}
            onClick={close_error}
            style={{ background: bgColor }}
          >
            {errorMsg}
          </div>
        )}
      </Container>
      {notification == 0 ? (
        ""
      ) : (
        <div>
          <label className="order_notifications color-white">
            {notification == 1 ? (
              <label>
                You have {notification} new order.{" "}
                <Link to="/orders" className="textUnderlineNone">
                  <span className="color-blue cursor">View</span>
                </Link>
              </label>
            ) : (
              <label>
                You have {notification} new orders.{" "}
                <Link to="/orders" className="textUnderlineNone">
                  <span className="color-blue cursor">View</span>
                </Link>
              </label>
            )}
          </label>
          <div className="mobile_order_notifications">
            <Link to="/orders" className="textUnderlineNone">
              <label className="dot" />
              <label className="font-size-12 color-white">{notification}</label>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
