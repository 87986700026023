export default function auth() {
  if (
    localStorage.getItem("accountid") === null ||
    localStorage.getItem("accountid") === ""
  ) {
    window.location.href = "../";
  }

  return <></>;
}
