import React, { useState, useEffect } from "react";
import Authentication from "./navigation/authentication";
import { Col, Container, Row, Stack, Modal, Button } from "react-bootstrap";
import SideNav from "./navigation/SideNav";
import BeatLoader from "react-spinners/BeatLoader";
import validator from "validator";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const baseURL = process.env.REACT_APP_URL;
const root_url = process.env.REACT_APP_URL_RESOURCE;

export default function Profile() {
  const [businessname, setBusinessname] = useState(
    localStorage.getItem("company_name")
  );
  const [phone, setPhone] = useState(null);
  const [current_phone, setCurrent_phone] = useState(null);
  const [location, setLocation] = useState("");
  const [current_location, setcurrent_location] = useState("");
  const [email, setEmail] = useState("");
  const [url, setUrl] = useState("");
  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [btnColor, setBtnColor] = useState("action-btn");
  const [disabled, setDisabled] = useState("");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [count, setCount] = useState(0);

  function profile_details() {
    const accountid = localStorage.getItem("accountid");
    axios
      .get(
        baseURL +
          "?query=PROFILE-DETAILS&accountid=" +
          accountid +
          "&package=PROFESSIONAL"
      )
      .then((res) => {
        setEmail(res.data.email);
        setUrl(res.data.url);
        setCurrent_phone(res.data.phone);
        setcurrent_location(res.data.location);
      });
  }
  useEffect(() => {
    document.title = "Obarak / Profile";
    profile_details();
  }, [count]);

  function handleLocation(event) {
    setLocation(event.target.value);
  }

  function submit(e) {
    e.preventDefault();

    let { local, phone_number } = "";

    if (phone == null) {
      phone_number = current_phone;
    } else {
      phone_number = phone;
    }
    if (location == "") {
      local = current_location;
    } else {
      local = location;
    }
    const accountid = localStorage.getItem("accountid");
    const formData = new FormData();
    formData.append("company_name", businessname);
    formData.append("company_email", email);
    formData.append("website", url);
    formData.append("phone", phone_number);
    formData.append("location", local);
    formData.append("accountid", accountid);
    formData.append("package", "PORFESSIONAL");
    formData.append("query", "UPDATE_PROFILE");

    if (businessname === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter business name");
    } else if (email === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter your email address");
    } else if (!validator.isEmail(email)) {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Your email address is invalid");
    } else if (!phone_number) {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter phone number");
    } else if (
      isPossiblePhoneNumber(phone_number.replace(" ", "")) === false ||
      isValidPhoneNumber(phone_number.replace(" ", "")) === false
    ) {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Your phone number is invalid");
    } else if (local === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Add location to your business");
    } else {
      setBtnColor("disabled-btn");
      setDisabled("disabled");
      setLoadSpinner(true);
      setSaveLabel(false);
      // connect to the api

      axios({
        method: "POST",
        url: baseURL,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then((response) => {
        setBtnColor("action-btn");
        setDisabled("");
        setLoadSpinner(false);
        setSaveLabel(true);

        if (response.data.status == "error") {
          setBgColor("red");
          setDisplayMessage(true);
          setErrorMsg(response.data.message);
        } else {
          localStorage.setItem("company_name", response.data.company_name);
          setCount(count + 1);
          setBgColor("#66b266");
          setDisplayMessage(true);
          setErrorMsg(response.data.message);
        }
      });
    }
    setTimeout(() => {
      setDisplayMessage(false);
    }, 5000);
  }
  return (
    <div className="body">
      <Authentication />
      <Container className="container-home">
        <Row>
          <Col lg={2} xs={2} md={3} className="p-0">
            <SideNav />
          </Col>
          <Col lg={10} xs={10} md={9} className={"middle-container"}>
            <Row className="h-100 align-items-center justify-content-center">
              <form onSubmit={submit}>
                <Row className="h-100 align-items-center justify-content-center">
                  <Col lg={9}>
                    <Stack>
                      <div className="box-container">
                        <Row>
                          <Col lg={12}>
                            <label className="font-medium">Profile</label>
                          </Col>

                          <Col lg={12}>
                            <div className="h-divider" />
                          </Col>
                          <Col lg={6} sm={6} className="mb-3">
                            <input
                              type="text"
                              name="businessname"
                              placeholder="Business name"
                              onChange={(e) => setBusinessname(e.target.value)}
                              value={businessname}
                            />
                          </Col>
                          <Col lg={6} sm={6} className="mb-3">
                            <input
                              type="email"
                              name="email"
                              placeholder="Email address"
                              onChange={(e) => setEmail(e.target.value)}
                              value={email}
                            />
                          </Col>
                          <Col lg={6} sm={6} className="mb-3">
                            <label className="text-right w-100 mb-1 font-size-12">
                              Current:{" "}
                              <span className="color-grey font-size-12">
                                {url == "" ? "Not set" : url}
                              </span>
                            </label>
                            <input
                              type="url"
                              name="website"
                              placeholder="https://www.example.com"
                              onChange={(e) => setUrl(e.target.value)}
                              value={url}
                            />
                          </Col>
                          <Col lg={6} sm={6} className="mb-3">
                            <label className="text-right w-100 mb-1 font-size-12">
                              Current:{" "}
                              <span className="color-grey font-size-12">
                                {current_phone}
                              </span>
                            </label>
                            <PhoneInput
                              name={"phone"}
                              disableDropdown={false}
                              enableSearch={true}
                              showDropdown={false}
                              country={"za"}
                              value={phone}
                              onChange={(phone) => setPhone("+" + phone)}
                              inputStyle={{
                                width: "100%",
                                background: "#f0f2f5",
                                height: "3em",
                                boxShadow: "none",
                              }}
                              containerStyle={{ marginBottom: 0 }}
                              inputProps={{
                                name: "phone",
                                required: true,
                              }}
                            />
                          </Col>

                          <Col lg={12} className="mb-3">
                            <label className="w-100 mb-1 font-size-12">
                              Current:{" "}
                              <span className="color-grey font-size-12">
                                {current_location}
                              </span>
                            </label>
                            <GooglePlacesAutocomplete
                              apiKey="AIzaSyDNn7uMQVzoH91NYn7vzX437q5fp8g92FU"
                              styles={{
                                textInputContainer: {
                                  backgroundColor: "grey",
                                },
                                textInput: {
                                  height: 38,
                                  color: "#5d5d5d",
                                  fontSize: 16,
                                },
                              }}
                              textInputProps={{
                                onChange: (text) => {
                                  handleLocation(text);
                                },
                              }}
                              selectProps={{
                                onChange: (data, details = null) =>
                                  setLocation(data["value"]["description"]),
                                fetchDetails: true,
                                placeholder: "Change business location",
                                // defaultInputValue: this.state.venue,
                              }}
                            />
                          </Col>

                          <Col lg={12} className="">
                            <button
                              type="submit"
                              className={btnColor}
                              disabled={disabled}
                            >
                              {saveLabel && <span>Save</span>}
                              {loadSpinner && <BeatLoader size={10} />}
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </Stack>
                  </Col>
                </Row>
              </form>
            </Row>
          </Col>
        </Row>
      </Container>
      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}
