import React, { useState, useEffect } from "react";
import { Col, Container, Row, Stack, Modal, Button } from "react-bootstrap";
import Nav from "./navigation/Nav";
import Select from "react-select";
import BeatLoader from "react-spinners/BeatLoader";
import validator from "validator";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import ReactTooltip from "react-tooltip";
export default function Point_of_sale() {
  const [category, setCategory] = useState("");
  const [revenues, setRevenue] = useState("");
  const [booking, setBooking] = useState("");
  const [company_name, setCompany_name] = useState("");
  const [company_email, setCompany_email] = useState("");
  const [url, setUrl] = useState("");
  const [registration_number, setRegistration_number] = useState(null);
  const [comments, setComments] = useState("");

  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [btnColor, setBtnColor] = useState("action-btn");
  const [disabled, setDisabled] = useState("");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [location, setLocation] = useState("");

  useEffect(() => {
    document.title = "Obarak / Basic registration";
  });

  function handleLocation(event) {
    setLocation(event.target.value);
  }

  function submit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("company_name", company_name);
    formData.append("company_email", company_email);
    formData.append("url", url);
    formData.append("category", category);
    formData.append("revenues", revenues);
    formData.append("booking", category);
    formData.append("category", booking);
    formData.append("location", location);
    formData.append("comments", comments);

    if (company_name === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter your company name");
    } else if (company_email === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter company email address");
    } else if (!validator.isEmail(company_email)) {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Email address is invalid");
    } else if (category === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Choose category");
    } else if (booking === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Please indicate whether or not you allow reservations");
    } else if (location === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Add location to your business");
    } else {
      // connect to the api
      setBtnColor("disabled-btn");
      setDisabled("disabled");
      setLoadSpinner(true);
      setSaveLabel(false);

      setBgColor("#66b266");
      setDisplayMessage(true);
      setErrorMsg("User successfully added");
    }
    setTimeout(() => {
      setDisplayMessage(false);
    }, 5000);
  }

  const data = [
    { label: "Bar", value: "bar" },
    { label: "Restaurant", value: "restaurant" },
    { label: "Coffee shop", value: "coffee shop" },
    { label: "Fast food", value: "fast food" },
    { label: "Buffet", value: "buffet" },
    { label: "Bakery", value: "bakery" },
    { label: "Pop-Up Restaurant", value: "pop-up restaurant" },
    { label: "Ghost Restaurant", value: "ghost restaurant" },
  ];

  const revenue = [
    { label: "Less than R5k", value: "less than R5k" },
    { label: "R5k - R15k", value: "R5k - R15k" },
    { label: "R15k - R25k", value: "R15k - R25k" },
    { label: "R25k - R50k", value: "R25k - R50k" },
    { label: "More than R50k", value: "More than R50k" },
  ];

  const bookings = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

  return (
    <div className="body">
      <Container className="container-home">
        <Row>
          <Col lg={12} xs={12} md={12} className="p-0">
            <Nav />
          </Col>
          <Col lg={12} xs={12} md={12}>
            <Row className="h-100 align-items-center justify-content-center">
              <form onSubmit={submit}>
                <Row className="h-100 align-items-center justify-content-center">
                  <Col lg={7}>
                    <Stack>
                      <div className="text-center w-100 mb-3 mt-5">
                        <h3>
                          Become a host on Obarak{" "}
                          <label className="co-za">.co.za</label>
                        </h3>
                        <label className="sub-header">
                          Process in-store purchases with a powerful point of
                          sale.
                          <br />
                          Let us manage your stock, sales, revenues and more...
                        </label>
                      </div>
                      <div className="box-container mb-5">
                        <Row>
                          <Col lg={12}>
                            <label className="text-medium">Registration</label>
                          </Col>

                          <Col lg={12}>
                            <div className="h-divider" />
                          </Col>

                          <Col lg={12} className="mb-2">
                            <h4>Details about your business</h4>
                          </Col>
                          <Col lg={6} sm={6} md={6} xs={12} className="mb-3">
                            <input
                              type="text"
                              placeholder="Company name"
                              onChange={(e) => setCompany_name(e.target.value)}
                              value={company_name}
                            />
                          </Col>
                          <Col lg={6} sm={6} md={6} xs={12} className="mb-3">
                            <input
                              type="email"
                              placeholder="Company's email address"
                              onChange={(e) => setCompany_email(e.target.value)}
                              value={company_email}
                            />
                          </Col>

                          <Col lg={6} sm={6} md={6} xs={12} className="mb-3">
                            <input
                              type="url"
                              placeholder="Website (optional)"
                              onChange={(e) => setUrl(e.target.value)}
                              value={url}
                            />
                          </Col>

                          <Col lg={6} sm={6} md={6} xs={12} className="mb-3">
                            <input
                              type="number"
                              placeholder="Business registration number (Optional)"
                              onChange={(e) =>
                                setRegistration_number(e.target.value)
                              }
                              value={registration_number}
                            />
                          </Col>

                          <Col lg={6} sm={6} md={6} xs={12} className="mb-3">
                            <Select
                              value={data.find((obj) => obj.value === category)}
                              placeholder="Category"
                              options={data}
                              onChange={(e) => setCategory(e.value)}
                              width={"100%"}
                              className="text-left"
                            />
                          </Col>

                          <Col lg={6} sm={6} md={6} xs={12} className="mb-3">
                            <Select
                              value={revenue.find(
                                (obj) => obj.value === revenues
                              )}
                              placeholder="Your monthly revenue"
                              options={revenue}
                              onChange={(e) => setRevenue(e.value)}
                              width={"100%"}
                              className="text-left"
                            />
                          </Col>

                          <Col
                            lg={6}
                            sm={6}
                            md={6}
                            xs={12}
                            className="mb-3"
                            data-tip="Only available to a professional package"
                          >
                            <Select
                              value={bookings.find(
                                (obj) => obj.value === booking
                              )}
                              placeholder="Does your business allow reservations?"
                              options={bookings}
                              onChange={(e) => setBooking(e.value)}
                              width={"100%"}
                              className="text-left"
                              isDisabled={true}
                            />
                            <ReactTooltip />
                          </Col>

                          <Col lg={6} sm={6} md={6} xs={12} className="mb-3">
                            <GooglePlacesAutocomplete
                              apiKey="AIzaSyDNn7uMQVzoH91NYn7vzX437q5fp8g92FU"
                              styles={{
                                textInputContainer: {
                                  backgroundColor: "grey",
                                },
                                textInput: {
                                  height: 38,
                                  color: "#5d5d5d",
                                  fontSize: 16,
                                },
                              }}
                              textInputProps={{
                                onChange: (text) => {
                                  handleLocation(text);
                                },
                              }}
                              selectProps={{
                                onChange: (data, details = null) =>
                                  setLocation(data["value"]["description"]),
                                fetchDetails: true,
                                placeholder: "Add location to your business",
                                // defaultInputValue: this.state.venue,
                              }}
                            />
                          </Col>

                          <Col lg={12} className="mb-2">
                            <textarea
                              placeholder="Any additional comments?"
                              onChange={(e) => setComments(e.target.value)}
                              value={comments}
                            />
                          </Col>

                          <Col lg={12} className="mt-3">
                            <button
                              type="submit"
                              className={btnColor}
                              disabled={disabled}
                            >
                              {saveLabel && <span>Apply</span>}
                              {loadSpinner && <BeatLoader size={10} />}
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </Stack>
                  </Col>
                </Row>
              </form>
            </Row>
          </Col>
        </Row>
      </Container>
      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}
