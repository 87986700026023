import React, { useState, useEffect } from "react";
import { Col, Container, Row, Stack, Modal, Button } from "react-bootstrap";
import Nav from "./navigation/Nav";
import { Link, NavLink, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import axios from "axios";
export default function Login() {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [company_email, setCompany_email] = useState("");

  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [btnColor, setBtnColor] = useState("login-btn");
  const [disabled, setDisabled] = useState("");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [location, setLocation] = useState("");

  useEffect(() => {
    document.title = "Obarak / Login";
  });

  function handleLocation(event) {
    setLocation(event.target.value);
  }

  function submit(e) {
    e.preventDefault();
    const formData = new FormData();
    const deviceid = localStorage.getItem("deviceid");
    formData.append("password", password);
    formData.append("email", company_email);
    formData.append("device_id", deviceid);
    formData.append("query", "LOGIN");

    if (company_email === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter email or phone");
    } else if (password === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter password");
    } else {
      setBtnColor("disabled-btn");
      setDisabled("disabled");
      setLoadSpinner(true);
      setSaveLabel(false);
      // connect to the api

      const baseURL = process.env.REACT_APP_URL;
      axios({
        method: "POST",
        url: baseURL,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then((response) => {
        // console.log(response.data.status);
        if (response.data.status == "error") {
          setBgColor("red");
          setDisplayMessage(true);
          setErrorMsg(response.data.message);
        } else {
          if(response.data.package == 'professional'){

            console.log(response.data.status);
            localStorage.setItem("company_name", response.data.company_name);
            localStorage.setItem("accountid", response.data.accountid);
            localStorage.setItem("logo", response.data.logo);
            localStorage.setItem("cover", response.data.cover);
            localStorage.setItem("package", response.data.package);
            localStorage.setItem("store_available", response.data.store_available);
            // navigate("/dashboard");
            window.location.href = "dashboard";

          }else{
            console.log(response.data.status);
            localStorage.setItem("customerid", response.data.customerid);
            localStorage.setItem("first_name", response.data.first_name);
            localStorage.setItem("last_name", response.data.last_name);
            localStorage.setItem("package", response.data.package);
            // navigate("/");
            window.location.href = "/";
          }
          
        }

        setBtnColor("action-btn");
        setDisabled("");
        setLoadSpinner(false);
        setSaveLabel(true);

        // console.log(response.data.name);
      });
    }
    setTimeout(() => {
      setDisplayMessage(false);
    }, 5000);
  }

  return (
    <div className="package-body height-100hv">
      <Container className="container-home height-100hv">
        <Row className="height-100hv justify-content-center">
          <Col lg={12} xs={12} md={12} className="p-0">
            <Nav />
          </Col>
          <Col lg={6} xs={12} md={12}>
            <Row className="align-items-center justify-content-center">
              <form onSubmit={submit}>
                <Row className="h-100 align-items-center justify-content-center">
                  <Col lg={7}>
                    <Stack>
                      <div className="text-center w-100 mb-3">
                        <h3>Log In</h3>
                      </div>
                      <div className="box-container mb-5">
                        <Row>
                          <Col lg={12} className="text-center">
                            <label className="text-medium font-size-17">
                              Enter log in details
                            </label>
                          </Col>

                          <Col lg={12} className="mb-3 mt-3">
                            <input
                              type="text"
                              placeholder="Email or phone"
                              onChange={(e) => setCompany_email(e.target.value)}
                              value={company_email}
                            />
                          </Col>
                          <Col lg={12} className="mb-3">
                            <input
                              type="password"
                              placeholder="Password"
                              onChange={(e) => setPassword(e.target.value)}
                              value={password}
                            />
                          </Col>
                          <Col lg={12} className="mb-3">
                            <button
                              type="submit"
                              className={
                                btnColor + " w-100 text-bold font-size-17"
                              }
                              disabled={disabled}
                            >
                              {saveLabel && <span>Log In</span>}
                              {loadSpinner && <BeatLoader size={10} />}
                            </button>
                          </Col>
                          <Col lg={12} className="mb-3 text-center">
                          <Link to="/retrieve_password" className="anchor">
                            Retrieve password
                            </Link><br/>
                            Not host yet?{ }<Link to="/packages" className="anchor">
                            Become a one
                            </Link>
                          </Col>
                        </Row>
                      </div>
                    </Stack>
                  </Col>
                </Row>
              </form>
            </Row>
          </Col>
        </Row>
      </Container>
      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}
