import React, { useState, useEffect } from "react";
import Authentication from "./navigation/authentication";
import { Col, Container, Row, Stack, Modal, Button } from "react-bootstrap";
import SideNav from "./navigation/SideNav";
import BeatLoader from "react-spinners/BeatLoader";
import validator from "validator";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import axios from "axios";
import Select from "react-select";
const baseURL = process.env.REACT_APP_URL;
const root_url = process.env.REACT_APP_URL_RESOURCE;

export default function Index() {
  const [phone, setPhone] = useState(null);
  const [current_phone, setCurrent_phone] = useState(null);
  const [branchname, setBranchname] = useState("");
  const [bank, setBank] = useState("");
  const [accounttype, setAccounttype] = useState("");
  const [branchcode, setBranchcode] = useState("");
  const [accountnumber, setAccountnumber] = useState("");
  const [bgColor, setBgColor] = useState("#66b266");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [btnColor, setBtnColor] = useState("action-btn");
  const [disabled, setDisabled] = useState("");
  const [saveLabel, setSaveLabel] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [count, setCount] = useState(0);

  function handleChange(e) {
    setBank(e.value);
    // alert(bank);

    if (e.value == "absa") {
      setBranchcode("632005");
    } else if (e.value == "capitec") {
      setBranchcode("470010");
    } else if (e.value == "fnb") {
      setBranchcode("250655");
    } else if (e.value == "nedbank") {
      setBranchcode("198765");
    } else if (e.value == "standard bank") {
      setBranchcode("051001");
    } else if (e.value == "bidvest bank") {
      setBranchcode("462005");
    } else if (e.value == "tymebank") {
      setBranchcode("678910");
    } else if (e.value == "discovery bank") {
      setBranchcode("679000");
    }
  }

  function handleBranchname(e) {
    setBranchname(e.value);
  }

  function handle_Account_Type(e) {
    setAccounttype(e.value);
  }

  function profile_details() {
    const accountid = localStorage.getItem("accountid");
    axios
      .get(baseURL + "?query=BANK_DETAILS&accountid=" + accountid)
      .then((res) => {
        setBranchcode(res.data.branch_code);
        setBranchname(res.data.branch_name);
        setAccounttype(res.data.account_type);
        setBank(res.data.bank);
        setAccountnumber(res.data.account_number);
      });
  }
  useEffect(() => {
    document.title = "Obarak / Bank details";
    profile_details();
  }, [count]);

  function submit(e) {
    e.preventDefault();

    const accountid = localStorage.getItem("accountid");
    const formData = new FormData();
    formData.append("bank", bank);
    formData.append("accountnumber", accountnumber);
    formData.append("accounttype", accounttype);
    formData.append("branchname", branchname);
    formData.append("branchcode", branchcode);
    formData.append("accountid", accountid);
    formData.append("query", "UPDATE_BANK_DETAILS");

    if (bank === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Select your bank");
    } else if (accounttype === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Select bank type");
    } else if (accountnumber === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter your account number");
    } else if (branchname === "") {
      setBgColor("#ff1919");
      setDisplayMessage(true);
      setErrorMsg("Enter branch name");
    } else {
      setBtnColor("disabled-btn");
      setDisabled("disabled");
      setLoadSpinner(true);
      setSaveLabel(false);
      // connect to the api

      axios({
        method: "POST",
        url: baseURL,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then((response) => {
        setBtnColor("action-btn");
        setDisabled("");
        setLoadSpinner(false);
        setSaveLabel(true);

        if (response.data.status == "error") {
          setBgColor("red");
          setDisplayMessage(true);
          setErrorMsg(response.data.message);
        } else {
          localStorage.setItem("company_name", response.data.company_name);
          setCount(count + 1);
          setBgColor("#66b266");
          setDisplayMessage(true);
          setErrorMsg(response.data.message);
        }
      });
    }
    setTimeout(() => {
      setDisplayMessage(false);
    }, 5000);
  }

  const data = [
    { label: "Absa", value: "absa" },
    { label: "Bidvest bank", value: "bidvest bank" },
    { label: "Capitec", value: "capitec" },
    { label: "Discovery bank", value: "discovery bank" },
    { label: "Nedbank", value: "nedbank" },
    { label: "Standard bank", value: "standard bank" },
    { label: "FNB", value: "fnb" },
    { label: "Tymebank", value: "tymebank" },
  ];

  const account_type = [
    { label: "Savings", value: "savings" },
    { label: "Cheque", value: "cheque" },
  ];

  return (
    <div className="body">
      <Authentication />
      <Container className="container-home">
        <Row>
          <Col lg={2} xs={2} md={3} className="p-0">
            <SideNav />
          </Col>
          <Col lg={10} xs={10} md={9}>
            <Row className="h-100 align-items-center justify-content-center">
              <form onSubmit={submit}>
                <Row className="h-100 align-items-center justify-content-center">
                  <Col lg={9}>
                    <Stack>
                      <div className="box-container">
                        <Row>
                          <Col lg={12}>
                            <label className="text-medium">
                              Banking details
                            </label>
                          </Col>

                          <Col lg={12}>
                            <div className="h-divider" />
                          </Col>
                          <Col lg={6} sm={6} xs={6} className="mb-1">
                            <label className="w-100 mb-1 font-size-12">
                              Current:{" "}
                              <span className="color-grey font-size-12">
                                {bank}
                              </span>
                            </label>
                            <Select
                              value={data.find((obj) => obj.value === bank)}
                              placeholder="Select your bank"
                              options={data}
                              onChange={handleChange}
                              width={"100%"}
                              className="text-left mt-0 mb-3 select"
                            />
                          </Col>
                          <Col lg={6} sm={6} xs={6} className="mb-1">
                            <label className="w-100 mb-1 font-size-12">
                              Current:{" "}
                              <span className="color-grey font-size-12">
                                {accounttype}
                              </span>
                            </label>
                            <Select
                              value={account_type.find(
                                (obj) => obj.value === accounttype
                              )}
                              placeholder="Account type..."
                              options={account_type}
                              onChange={handle_Account_Type}
                              width={"100%"}
                              className="text-left mt-0 mb-3 select"
                            />
                          </Col>
                          <Col lg={12} className="mb-2">
                            <input
                              placeholder="Account number"
                              onChange={(e) => setAccountnumber(e.target.value)}
                              value={accountnumber}
                            />
                          </Col>
                          <Col lg={6} sm={6} className="mb-3">
                            <label className="w-100 mb-1 font-size-12">
                              Current:{" "}
                              <span className="color-grey font-size-12">
                                {branchname}
                              </span>
                            </label>
                            <GooglePlacesAutocomplete
                              apiKey="AIzaSyDNn7uMQVzoH91NYn7vzX437q5fp8g92FU"
                              styles={{
                                textInputContainer: {
                                  backgroundColor: "grey",
                                },
                                textInput: {
                                  height: 38,
                                  color: "#5d5d5d",
                                  fontSize: 16,
                                },
                              }}
                              textInputProps={{
                                onChange: (text) => {
                                  handleBranchname(text);
                                },
                              }}
                              selectProps={{
                                onChange: (data, details = null) =>
                                  setBranchname(data["value"]["description"]),
                                fetchDetails: true,
                                placeholder: "Branch name",
                                // defaultInputValue: this.state.venue,
                              }}
                            />
                          </Col>
                          <Col lg={6} sm={6} className="mb-3">
                            <label className="w-100 mb-1 font-size-12 color-white">
                              Current:{" "}
                              <span className="color-grey font-size-12 color-white">
                                {branchcode}
                              </span>
                            </label>
                            <input
                              readOnly
                              placeholder="Branch code"
                              onChange={(e) => setBranchcode(e.target.value)}
                              value={branchcode}
                            />
                          </Col>

                          <Col lg={12} className="">
                            <button
                              type="submit"
                              className={btnColor + " font-medium"}
                              disabled={disabled}
                            >
                              {saveLabel && <span>Save</span>}
                              {loadSpinner && <BeatLoader size={10} />}
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </Stack>
                  </Col>
                </Row>
              </form>
            </Row>
          </Col>
        </Row>
      </Container>
      {displayMessage && (
        <div
          className={"message-error w-100 text-center"}
          style={{ background: bgColor }}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}
