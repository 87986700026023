import React, { useState, useEffect } from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import HomeNav from "./navigation/HomeNav";
import { BsFillForwardFill, BsArrowRight } from "react-icons/bs";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

import { useNavigate, Link } from "react-router-dom";
const baseURL = process.env.REACT_APP_URL;
const root_url = process.env.REACT_APP_URL_RESOURCE;
export default function Successful() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Obarak - Successful";
    localStorage.removeItem("OVC");
    localStorage.removeItem("deviceid");
    if (localStorage.getItem("pos") != null) {
      localStorage.removeItem("customerid");
    }
  }, []);

  return (
    <div className="body">
      <Container className="container-home">
        <Row>
          <Col lg={12} xs={12} md={12} sm={12} className={"middle-container"}>
            <Row className="h-100 align-items-center justify-content-center">
              <Col lg={5} sm={6} md={5} xs={12} className="mb-3">
                <Stack>
                  <div className="box-container mt-3 mb-3 add-relative-position">
                    <Row className="justify-content-center">
                      <Col lg={12} className="text-center">
                        <span className="font-size-17 color-green font-normal">
                          <IoIosCheckmarkCircleOutline size={20} />
                          <br />
                          {localStorage.getItem("pos") != null
                            ? "Transaction successful"
                            : "Congrats!!!"}
                        </span>{" "}
                        {localStorage.getItem("pos") != null ? (
                          ""
                        ) : (
                          <span>
                            <br />
                            Your order has gone through and you shall receive
                            communication shortly.
                          </span>
                        )}
                        <br />
                        <br />
                        {localStorage.getItem("pos") != null ? (
                          <Link to="/">
                            <label className="action-btn font-medium">
                              Back to Point of Sale
                            </label>
                          </Link>
                        ) : (
                          <span className="color-grey">
                            Still craving for more?{" "}
                            <Link to="/" className="color-blue anchor">
                              We got you
                            </Link>
                          </span>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Stack>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
